.mains {
  width: 100%;
}

.components {
  padding: 20px;
  background-color: #f6f6f6;
  border-radius: 10px;
}
.contents {
  width: 50%;
  margin: 0 auto;
}
.main_divs {
  margin-bottom: 10px;
}
.inputs_div_margin {
  margin-bottom: 5px;
}
.input_label {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #1c1c1c;
}
.btn_div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px 0px 0px;
}
.aply_btn{
  padding: 10px 35px;
    border-radius: 10px;
    background: #1c1c1c;
    border: 1px solid #1c1c1c;
    color: #fff;
font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    cursor: pointer;
    text-decoration: none !important;
    display: inline-block;
}
.aply_btn:hover{
  background: #fff;
  color: #1c1c1c;
}

@media (max-width:767px) {
  .contents {
    width: 100%;
  }
}
