.mains {
    width: 100%;
  }
  .components {
    background-color: #f6f6f6;
    border-radius: 10px;
    padding: 20px;
  }
  
  @media (max-width: 600px) {
    .mains {
      width: 100%;
    }
  }
  