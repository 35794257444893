.headerCategory {
  width: 100%;
  background: url("./banner bg img.png");
  background-size: cover; 
  padding-top: 50px;
  padding-bottom: 50px;
  margin-top: 125px;
}
.pageitle{
  text-align: center;
}
.pageitle h1{
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: 0;
  margin-bottom: 10px;
  margin-top: 0;
}
.pageitle ul{
  padding: 0;
  list-style-type: none;
  margin: 0;
}
.pageitle ul li{
  display: inline-block;
  margin-top: 0;
}
.pageitle ul li:last-child{
  padding-left: 5px;
}
.pageitle ul li a{
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #14142B;
  text-decoration: none !important;
}
.searchheading {
  display: flex;
  align-items: center;
  gap: 100px;
  flex-wrap: wrap;
}
.searchheading h3{
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: #1c1c1c;
  margin: 0;
}
.headerSubTitle {
  margin-left: 3%;
}
.tosearch{
  padding-bottom: 20px;
}
.otherartistcards {
  background-color: white;
  padding: 13px;
  box-shadow: 0px 0px 7.24px 0px #0000000F;
  border: 0.9px solid #63636533;
  position: relative;
}
.Other_otheim {
  max-height: 200px;
  min-height: 200px;
  overflow: hidden;
}
.otherartimg {
  width: 100%;
  object-fit: fill;
  height: 100%;
}
.otherartistimaged {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid #fff;
}
.otherartistimg {
  cursor: pointer;
  max-width: 100%;
  height: 100%;
  width: 100%;
}
.Other_otherartist {
  margin-top: 40px;
}

.Other_otherartist h4 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: #1c1c1c;
  text-transform: capitalize;
  margin: 0;
}

.Other_otherartist p {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #636365;
  opacity: 0.6;
  margin: 0;
}

.exprlobtn {
  padding: 10px 35px;
  border-radius: 10px;
  background: #fff;
  border: 1px solid #1c1c1c;
  color: #1c1c1c;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  display: block;
  width: 100%;
  margin-top: 15px;
}

.exprlobtn:hover {
  background: #1c1c1c;
  color: #fff;
}

.otherartistnamerating {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 27px;
}

.widthfull{
  width: 100%;
}
.mt_2{
  margin-top: 20px;
}
.price_explore p {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #636365;
  margin: 0px;
  opacity: 0.6;
  margin-bottom: 20px;
}

.text_center {
  text-align: center;
}
.ratingdiv {
  text-align: right;
}
.rngd{
  font-size: 17px !important;
  color: #1c1c1c !important;
  opacity: 1 !important;
  align-items: center;
  display: flex;
  justify-content: end;
  gap: 5px;
}
.rsic{
  font-size: 20px;
  color:#FFDC64;
}
.art_de_rating_img {
  margin-right: 5px;
}
.artist__grid {
  width: 100%;
  margin-left: 1%;
}

.artist__card {
  width: 100%;
  margin-bottom: 5%;
  border: 0.905px solid rgba(99, 99, 101, 0.2);
  box-shadow: 0px 0px 7.24px rgba(0, 0, 0, 0.06);
  padding: 3%;
}
.artist__top {
  max-height: 230px;
  overflow: hidden;
}

.actualart {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.artist__photo {
  width: 100%;
}

.artistphoto__div {
  width: 26%;
  margin-left: 38%;
  margin-top: -9%;
}
.artistphoto__div > img {
  border-radius: 50%;
}

.artist__namestars {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rating {
  display: flex;
  justify-content: center;
  align-items: center;
}

.artist__location {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.artistlist__main {
  display: flex;
  /* justify-content: center; */
  margin-left: 7%;
  align-items: flex-start;
}

.artist__sidebar {
  margin-top: 3%;
}

.ProductSidebar {
  margin-bottom: 10%;
  box-shadow: 0 0 6px 2px #edebeb;
  border-radius: 10px;
  padding: 15px;
  background: #fff;
}

.radio__button {
  margin-bottom: 5%;
}

.radio__div {
  margin-top: -10%;
  margin-left: -2%;
  margin-bottom: -5%;
}

.orientation {
  margin-right: 5%;
}

.color__image {
  margin-right: 3%;
}

.radio__label {
  margin-left: 0.5%;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;
}
.headerMain{
  margin: 50px 0;
}
.artdtl{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

@media (max-width: 900px) {
  .ProductSidebar {
    display: none;
  }

  .headerCategory {
    margin-top: -12.5%;
  }
}
.selectedFilters {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}
.filterButtonsStyles {
  color: rgb(51, 51, 51);
    font-size: 16px;
    background: rgb(243, 243, 243);
    width: max-content;
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: .25rem;
    font-family: "Roboto", sans-serif;
    padding: 4px 8px;
    border: 0;
    text-transform: capitalize;
}
.filterButtonsStyles:hover .filterButtonsStylesIcon {
  text-decoration: line-through;
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
}
.filterButtonsStylesIcon {
  color: gray;
  font-size: 17px !important;
  cursor: pointer;
}
