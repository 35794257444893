.mains {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-bottom: 50px; */
}
.contst{
    margin-top: 20px;
    box-shadow: 0 0 9px 1px #e7e9ec;
    border-radius: 10px;
}

.img_div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 20px;
}

.img_div>img {
    width: 150px;
    height: 100px;
    cursor: pointer;
}

.card {
    background: #FFFFFF;
    box-shadow: 0px 6.14465px 19.6629px rgba(8, 15, 52, 0.06);
   
}

.main_heading {
    text-align: center;
    background: #F6F6F6;
    padding: 20px;
}

.main_heading>h2 {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #1C1C1C;
    margin: 0;
}

.content {
    text-align: center;
    margin: auto auto;
    padding:20px;
}

.content>p {
    line-height: 27px;
    text-align: center;
font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #636365;
    opacity: .8;
}
.logo_img{
    margin: 0 auto;
    max-width: 150px;
}
@media(max-width:767px){
    .main_heading{
        padding: 10px;
    }
}