.banner {
  background: url("Banner-1.png");
  background-size: cover;
  padding: 5% 0;
  margin-top: 125px;
}
.bannercont h3{
  font-family: "Roboto", sans-serif;
    font-size: 28px;
    font-weight: 400;
    line-height: 37.62px;
    text-align: left;
    color: #fff;
    margin-top: 0;
    margin-bottom: 10px;
}
.bannercont h1{
  font-family: "Roboto", sans-serif;
    font-size: 41px;
    font-weight: 400;
    line-height: 51px;
    text-align: left;
    color: #fff;
    margin-top: 0;
    margin-bottom: 25px;
}
.bannercont ul{
  padding-left: 15px;
}
.bannercont ul li{
  font-family: "Roboto", sans-serif;
font-size: 16px;
font-weight: 400;
line-height: 20.83px;
text-align: left;
color: #fff;
}
.img_fluid{
  max-width: 100%;
  height: auto;
}
.giftcarduser {
  margin-bottom: 5%;
}
.gcimg {
  width: 80%;
  height: 300px;
}
.gclabel {
  margin-left: 54%;
}

.verticalLine {
  border-left: 4px solid white;
  border-radius: 4px;
  height: 3.5vw;
  margin-right: 5%;
}
.graduation {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  padding: 2%;
  margin-left: -3%;
  border-radius: 3px;
}
.graduationDiv {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.bannercontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10%;
}
.gcimgDiv {
  width: 100%;
}
.gradImg {
  width: 20%;
}
.bulletdiv {
  width: 90%;
}
.gctext {
  width: 100%;
}
.giftcard__heading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  margin-left: 5%;
}
.heading__maindiv{
  text-align: center;
}
.designImage {
  width: 50%;
}
.stepscontainer {
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: 1px solid #eeeeee;
  margin-top: 10%;
  height: 80%;
}
.id {
  background-color: black;
  height: 2.9vw;
  width: 2.9vw;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 1px solid black;
  outline-offset: 3.5px;
}

.logoMain {
  height: 3.375vw;
  width: 3.375vw;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eeeeee;
  margin-left: 3%;
}
.logo {
  width: 45%;
}
.logos__flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 5%;
  margin-top: -6%;
}
.main__Content {
  padding: 1% 15%;
}
.gc5img {
  width: 100%;
}
.giftcard__grid {
  margin-top: 5%; 
}
.textpad {
  padding: 0% 5%;
}
.contactlink {
  color: black;
}
.giftcard__form {
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 4px 24px 0px #00000012;
}
.formnumber {
  background-color: black;
  color: white;
  height: 35px;
    width: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.formHeading {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2%;
}
.content1 {
  margin-top: 2%;
}
.bottomBullet {
  padding: 0% 5%;
}
.bullets {
  color: #636365;
  margin-left: 0.15%;
}
.bottomText{
  padding: 2% 17.5%;
}
.text_center{
  text-align: center;
}
.title_image{
  max-width: 110px;
}
.sec_title{
  font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 50px;
    text-transform: capitalize;
    color: #1c1c1c;
    margin: 0px;
    margin-bottom: 10px;
}
.giftsec{
  padding: 50px 0;
}
.stepbox{
  box-shadow: 0px 4px 24px 0px #00000012;
padding: 20px;
border-radius:10px ;
position: relative;
}
.stecont{
  font-family: "Roboto", sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 25px;
text-align: left;
color: #636365;
opacity: .6;
padding-top: 25px;
min-height: 50px;
}
.stpsel{
  list-style-type: none;
  display: flex;
  align-items: center;
  padding-left: 0;
  position: absolute;
  margin: 0;
  top: -25px;
}
.stpsel li{
  margin-top: 0;
  display: inline-block;
}
.stpsel li:first-child{
  width: 45px;
  height: 45px;
  border-radius:50px;
  background: #1C1C1C;
  text-align: center;
  line-height: 45px;
  color: #fff;
  outline: 1px solid black;
  outline-offset: 3.5px;
}
.stpsel li:last-child{
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background: #EEEEEE;
  font-size: 28px;
  text-align: center;
  line-height: 60px;
  color: #000000;
  margin-left: 10px;
}
.stepsdescription{
  margin-top: 45px;
}
.hero_main6 {
  background: #ffffff;
  /* padding: 10px 50px 50px 50px; */
  box-shadow: 0px 6.14465px 19.6629px rgba(8, 15, 52, 0.06);
  border-radius: 22.1207px;
  display: flex;
  flex-direction: column;
  width: 85%;
  margin: auto;
  justify-content: center;
  /* align-items: center; */
 }
 .accordian_show {
  display: flex;
  justify-content: space-between;
  align-items: center;
 }
 .show_content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  column-gap: 20px;
 }
 .accodian_no {
  height: 40px;
  width: 40px;
  background: #1c1c1c;
  /* box-shadow: 0px 10px 30px rgba(28, 28, 28, 0.3); */
  border-radius: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
 }
@media(max-width:767px){
  .stepbox{
    margin-top: 35px;
  }
  .stepsdescription{
    margin-top: 5px;
  }
  .stecont{
    min-height: auto;
  }
  .banner {
    margin-top: 57px;
  }
}
@media (max-width: 600px) {
  .graduation {
    width: 20%;
  }
  .verticalLine {
    height: 3vw;
  }
  .bulletdiv {
    margin-left: -15%;
  }
  .banner {
    margin-top: 57px;
  }
  .designImage {
    width: 30%;
    /* margin-left: 17.5%; */
  }
  .logoMain {
    height: 5.5vw;
    width: 5.5vw;
  }
  .logos__flex {
    margin-top: -3%;
  }
}
