.component {
  background-color: #f6f6f6;
  padding-bottom: 20px;
  border-radius: 10px;
}
.moreoption_div {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-right: 30px;
}
.selects {
  width: 150px;
}
@media (max-width: 600px) {
  .mains {
    width: 100%;
  }
  .moreoption_div {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-right: 0px;
  }
  .selects_div {
    margin-top: 6px;
    margin-right: 10px;
  }
}
@media (max-width: 280px) {
  .selects {
    width: 120px;
    margin-top: 15px;
  }
}
