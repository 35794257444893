.components {
    background-color: #f6f6f6;
    border-radius: 10px;
    padding-bottom: 20px;
}

.category_btn {
    display: flex;
    justify-content: flex-end; 
    padding: 20px;
}

.card_main {
    display: flex; 
    padding:0 20px 20px;
}

.card {
    background-color: white;
    width: 85%;
    padding: 10px 0px 10px 20px;
    border-radius: 10px;
    margin-left: 35px;
}
.card_enter{
    width: 100%;
}
.heading_main {
    display: flex;
    align-items: center;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    color: #1C1C1C;
    padding: 20px 0px;
    margin-left: 60px;
}

.hrline {
    opacity: 0.5;
    height: 0.5px;
    background-color: #000000;
    margin-left: 2px;
    /* border: 0.5px solid #000000; */
    width: 100%;
}

.subheading {
    min-width: 118px;
}

.inputs_flex {
    display: flex;
    justify-content: space-between;
    /* height: 20px; */
}

.inputs_flexs {
    display: flex;
    justify-content: space-between;
    /* column-gap: 15px; */
    margin-top: 5px;
    width: 100%;
}
 
.inputs_flex_nest {
    display: flex;
    justify-content: space-between;
    column-gap: 73px;
}

.inputs_flex_nest>div {
    width: 50%;
}

.menu_inputs {
    margin: 0px 60px;
}

.input_detail_div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
}

.subheading_head {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #1C1C1C;
}

.detail_inputs {
    margin: 10px 60px
}

.img_text_add {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.img_text_add>p {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #1C1C1C;
    margin: 0px 0px;
    margin-left: 5px;
}

.input {

    background: #F3F3F3;
    border-radius: 6px;
    border: none;
    width: 100%;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    /* padding: 5px 20px; */
    color: #636365;
}

.save_btn {
    display: flex;
    justify-content: center;
    /* padding: 20px 0px; */
    padding: 20px 0px 20px 0px;
    background-color: white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-left: 35px;
}

.toggle_btn {
    display: flex;
    flex-direction: column;
    margin-bottom: -4px;
    align-self: end;
}

.slider.round {
    border-radius: 35px;
}

.slider.round:before {
    border-radius: 50%;
}

.switch {
    position: relative;
    display: inline-block;
    width: 43px;
    height: 24px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #FFFFFF;
    -webkit-transition: .2s;
    transition: .2s;
    border: 0.5px solid #E2E2E2;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.07);
}

.slider:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 17px;
    left: 4px;
    bottom: 2.5px;
    background-color: #1C1C1C;
    -webkit-transition: .3s;
    transition: .3s;
}

input:checked+.slider {
    background-color: #FFFFFF;
}

input:focus+.slider {
    box-shadow: 0 0 1px #FFFFFF;
}

input:checked+.slider:before {
    -webkit-transform: translateX(17px);
    -ms-transform: translateX(17px);
    transform: translateX(17px);
}

@media(max-width:913px) {
    .card_main {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        justify-self: normal;
        padding: 10px 10px;
    }
    .card{
        margin:0px;
    }
    .tabs_div{
        display: flex;
        justify-content: center;
        align-items: center;
        margin:auto;
        width: 100%;
    }
}

@media(max-width:767px) {
    .mains {
        width: 100%;
    }

    .card_main {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        justify-self: normal;
        padding: 10px 10px;
    }

    .card {
        background-color: white;
        width: 100%;
        padding: 0px;
        border-radius: 10px;
        margin: 0px
    }

    .heading_main {
        margin: 0px;
        padding: 20px 15px
    }

    .upload_img {
        margin: 0px;
        padding: 0px 15px
    }

    .detail_inputs {
        margin: 10px 0px;
        padding: 0px 15px
    }

    .menu_inputs {
        margin: 0px 10px;
    }

    .inputs_flex {
        flex-direction: column;
    }

    .inputs_flexs {
        flex-direction: column;
    }

    .inputs_flex>div {
        margin: 10px 0px;
    }

    .inputs_flexs>div {
        margin: 10px 0px;
    }

    .inputs_flex_nest>div {
        width: 100%;
    }

    .inputs_flex_nest {
        flex-direction: column;
        row-gap: 10px;
    }.save_btn {
        margin:0px;
    }
}