.dispute_flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.disput_status {
    color: #35BE43;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
}

.dispute_status_open {
    color: #EE0909;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
}

.refund_pending {
    color: #FFD51A;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
}

.loader_box {
    display: flex;
    justify-content: center;
    align-items: center;
}