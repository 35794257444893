.modal_del {
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    outline: none !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #FFFFFF;
    width: 60%;
    border: none;
}
.main_div {
    display: flex;
}
.right_div {
    text-align: center;
    margin: auto;
    padding: 15px 0 0;
    padding-right: 16px;
}
.right_div h2{
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 28px;
    color: #1C1C1C;
    line-height: 33px;
    margin-bottom: 5px
}
.cancel_img {
    text-align: right;
    margin-right: -35px;
}
.scribuebtn{
    text-transform: capitalize;
font-family: "Roboto", sans-serif;
    font-size: 16px;
    padding: 11px 30px;
    height: auto;
    border-radius: 0;
    box-shadow: none;
    border: 0;
    background: #1C1C1C;
    color: #fff;
    cursor: pointer;
    font-weight: 400;
}
.right_div>h1 {
    font-family: 'Marcellus';
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 63px;
    text-transform: capitalize;
    color: #1C1C1C;
    margin: 0px;
}

.right_div>h6 {
    max-width: 85%;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    text-align: center;
    color: #1C1C1C;
    opacity: 0.5;
    /* margin: 0px;
    width: 400px; */
    text-align: center;
    margin: 0px auto;
}

.right_div>p {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #636365;
}

.input_div {
    display: flex;
    justify-self: center;
    align-items: center;
    margin: 20px 0px;
}
.input_div input{
    padding: 11px 20px;
    outline: none !important;
}

.social_links {
    padding: 0;
    margin-bottom: 0;
    list-style-type: none;
}
.social_links li{
    padding: 0 10px;
    margin-top: 0;
    display: inline-block;
}
.social_links li a{
    color: #1C1C1C;
    font-size: 19px;
    line-height: 30px;
}
.closebtn{
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 29px;
    color: #1c1c1c;
    cursor: pointer;
}
.img_fluid{
    max-width: 100%;
    height: auto;
}
@media(min-width:1025px) and (max-width:1285px){
    .modal_del {
        width: 70%;
    }
}
@media(min-width:992px) and (max-width:1025px){
    .modal_del {
        width: 80%;
    }
}
@media(min-width:768px) and (max-width:992px){
    .modal_del {
        width: 80%;
    }
}
@media(max-width:767px){
    .right_div>h6{
        max-width: 100%;
    }
    .right_div{
        padding-top: 0;
        padding-left: 15px;
        padding-right: 15px;
    }
    .right_div h2 {
        font-size: 20px;
    color: #1C1C1C;
    line-height: 27px;
    margin-bottom: 10px;
    margin-top: 0;
    }
    .left_div {
        width: 50%;
        margin: 0 auto;
    }
    .social_links{
        padding-bottom: 15px;
    }
}
@media(max-width:600px) {
    .modal_del {
        /* padding: 50px 20px; */
        /* border-radius: 10px; */
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);

        position: absolute;
        top: 50%;
        left: 50%;
        max-height: 90%;
        overflow-y: scroll;
        transform: translate(-50%, -50%);
        /* // height:700px, */
        background-color: #FFFFFF;
        width: 95%;
        max-height: 85%;
        /* bgcolor: 'background.paper', */
        border: 0px solid #000;
        /* padding:10px 50px; */
        padding-bottom: 0px;
    }
    .main_div {
        display: flex;
        flex-direction: column;
    }

    
.input_div {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    justify-self: center;
    align-items: center;
    margin: 20px 0px;
}
}

@media(max-width:280px) {
    .modal_del {
        /* padding: 50px 20px; */
        /* border-radius: 10px; */
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        /* // height:700px, */
        background-color: #FFFFFF;
        width: 75%;
        max-height: 85%;
        /* bgcolor: 'background.paper', */
        border: none;
        padding: 10px 30px;
    }

    .cancel_img {
        text-align: right;
        margin-right: -20px;
    }
}