.header {
  background: url("../Rectangle 5372.png");
  background-size: cover;
  margin-top: 125px;
  padding-top: 5%;
  padding-bottom: 5%;
}

.hero_main {
  background-image: url(../../../../assets/img/Banner.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-top: 125px;
  padding: 5% 0px;
}

.max_width {
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
}
.spcebtnm{
  margin-top: 35px;
}
.btnmt2{
  text-align: center;
  margin-top: 20px;
}
.max_width .sub_title {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  color: #636365;

}

.whitext {
  color: #fff !important;
}

.pageitle {
  text-align: center;
}

.pageitle h1 {
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  font-weight: 600;
  line-height: 50px;
  letter-spacing: 0;
  margin-bottom: 10px;
  margin-top: 0;
  color: #ffffff;
}

.withor {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  color: #636365;
}

.sec_sc {
  padding: 50px 0;
}

.mt_2 {
  margin-top: 20px !important;
}

a.apply_btnwht {
  text-decoration: none !important;
  display: inline-block;
}

.apply_btnwht {
  padding: 10px 35px;
  border-radius: 10px;
  background: #fff;
  border: 1px solid #fff;
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  cursor: pointer;
}

.apply_btnwht:hover {
  background: #1c1c1c;
  color: #fff;
}

.apply_btn {
  padding: 10px 35px;
  border-radius: 10px;
  background: #1c1c1c;
  border: 1px solid #1c1c1c;
  color: #fff !important;
  font-family: DM Sans !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 26px !important;
  cursor: pointer;
  text-decoration: none !important;
  display: inline-block;
}

.apply_btn:hover {
  background: #fff;
  color: #1c1c1c !important;
}

.abttitle {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  text-transform: capitalize;
  color: #1c1c1c;
  margin: 0px; 
}

.abttitlefr {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  text-transform: capitalize;
  color: #1c1c1c;
  margin: 0px;
  margin-bottom: 8px;
}

.prtra {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: #636365;
  opacity: .8;
  margin: 0;
  margin-bottom: 10px;

}

.agtd {
  margin-top: 45px;
}

.feabc {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.feabc h4 {
  margin: 0 0;
  text-transform: capitalize;
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

.feabc p {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: #636365;
  margin: 0;
}

.iconst {
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  background: #1C1C1C;
  text-align: center;
  min-width: 30px;
  box-shadow: 0px 3.14px 5.66px -1px #080f344d;
}

.usicon {
  color: #fff;
  font-size: 20px;
}

.stcgbox {
  box-shadow: 0 0 6px 2px #e7e9ec;
  border-radius: 22px;
  padding: 25px;
}

.stcgbox h4 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  line-height: 25px;
  color: #1c1c1c;
  margin: 0px;
  margin-bottom: 18px;
}

.stcgbox label {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: #1c1c1c;
}

.sbychsbu {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: #636365;
  margin: 0;
  margin-top: 15px;
}

.textContent p {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: #636365;
  opacity: .8;
  margin: 0;
}

.textContent h5 {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  color: #1C1C1C;
  margin-top: 6px;
  margin-bottom: 0;
}

.textContent ul {
  margin-bottom: 0;
}

.textContent ul li {
  font-size: 15px;
  color: #636365;
  opacity: .8;
  margin-top: 0;
}

.rechhbox {
  box-shadow: 0 0 6px 2px #edebeb;
  border-radius: 22px;
  padding: 20px;
  background: #fff;
  position: relative;
  height: 100%;
}

.rechhbox h4 {
  margin: 0 0 5px;
  text-transform: capitalize;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  padding-top: 25px;
}

.rechhbox p {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: #636365;
  opacity: .8;
  margin: 0;
}

.sticon {
  top: -30px;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  position: absolute;
  box-shadow: 0px 10px 30px rgba(28, 28, 28, 0.3);
  background: #1c1c1c;
  padding: 12px;
}

.hgrd {
  text-align: center !important;
  font-size: 18px !important;
  color: #fff !important;
  opacity: 1 !important;
  font-weight: 600 !important;
  line-height: 30px !important;
}

.swithry {
  padding-bottom: 50px;
}

.ariconb {
  font-size: 19px;
  padding-left: 5px;
}

.bannerheading {
  margin-left: -18%;
}

.bannersubheading {
  margin-left: -10%;
}

.top__flex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2%;
  margin-top: 2%;
}

.iconimg {
  width: 80%;
}

.greybg {
  width: 100%;
}

.greybgDiv {
  width: 80%;
}

.vintageframe {
  width: 100%;
}

.aboutuscontainer {
  margin-bottom: 5%;
  padding: 2%;
}

.aboutusTop {
  margin-bottom: 7.5%;
}

.welcomeheading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.leftImage {
  width: 60%;
  margin-right: 2%;
}

.leftcard {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 7%;
}

.curatorcards {
  margin-top: 1.5%;
}

.cardInfo__right {
  margin-left: 6%;
  /* margin-top: 1%; */
}

.logos_flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 2%;
}

.loc__logo {
  width: 3%;
}

/* .leftText {
  margin-right: 10%;
} */

.rightcard {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 7%;
}

.rightImage {
  width: 50%;
  margin-left: 15%;
}

.info__div {
  width: 80%;
  margin-left: 10%;
  text-align: justify;
}

li {
  color: #636365;
  margin-top: 2%;
}

.image__flex {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.image__flex2 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-left: 10%;
}

.image__flex3 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-left: 9%;
}

.rightImages {
  width: 96%;
  margin-bottom: 3%;
}

.rightImages2 {
  width: 100%;
  margin-bottom: 3%;
}

.leftImage2 {
  width: 60.5%;
  margin-right: 2%;
}

.rightImages3 {
  width: 82%;
  margin-bottom: 3%;
}

.leftImage3 {
  width: 56%;
  margin-right: 2%;
}

.aboutusTop {
  margin-left: 12%;
}

.maingrid__container {
  padding: 0% 10%;
}

.info__div2 {
  width: 80%;
  margin-left: 9%;
}

.ourartistcard {
  width: 100%;
}

.ourartistinfo {
  background: #fff;
  display: block;
  position: relative;
  padding: 10px;
  padding-bottom: 15px;
}

.ourartistinfo h4 {
  margin: 0px 0 5px;
  text-transform: capitalize;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
}

.tdr {
  color: #1c1c1c !important;
  opacity: .7;
}

.ourartistinfo p {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
  color: #636365;
  opacity: .8;
  margin: 0;
}

.socilist {
  list-style-type: none;
  padding: 0;
  margin: 7px auto 0;
  text-align: center;
}

.socilist li {
  display: inline-block;
  margin-right: 5px;
}

.socilist li:last-child {
  margin-right: 0;
}

.socialmediaflex {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8%;
  margin-left: 45%;
  margin-bottom: 4%;
}

.sociallogo {
  width: 100%;
  margin-right: 25%;
}

.ourartistimg {
  width: 100%;
}

.text__flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ourartistdiv {
  padding: 2% 13.5%;
  background-color: #F9F9F9;
  margin-top: 5%;
}

.artistTypo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sec_shgn {
  background: #F6F6F6;
  padding: 50px 0;
}

.tablist {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.tablist li {
  margin-top: 0;
  display: inline-block;
  margin-right: 5px;
}

.tablist li a {
  text-decoration: none;
  padding: 8px 20px;
  border-radius: 10px;
  background: #fff;
  border: 1px solid #fff;
  color: #1c1c1c;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  display: block;
  cursor: pointer;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.sec_shgn .tab_content {
  box-shadow: 0px 6.14px 19.66px 0px #080F340F;
  border-radius: 22px;
  border-top-left-radius: 0;
  padding: 25px;
  background: #fff;
}

.sec_shgn .tab_content p {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: #636365;
  opacity: .8;
  margin: 0;
}

.stboxh {
  padding-top: 40px;
  height: 100%;
}

.stvobx {
  border-radius: 10px;
  padding: 20px;
  background: #F6F6F6;
  position: relative;
  height: 100%;
}

.stvobx h4 {
  margin: 0 0 5px;
  text-transform: capitalize;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
}

.stvobx p {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
  color: #636365;
  opacity: .8;
  margin: 0;
}

.accordsc {
  box-shadow: 0px 6.14px 19.66px 0px #080F340F;
  border-radius: 22px;
  padding: 25px;
  background: #fff;
  height: 100%;
}

.benfhdgn {
  margin: 0;
  text-transform: capitalize;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #1C1C1C;
}

.bendesc {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: #636365;
  opacity: .8;
  margin: 0;
}

.sec_title {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  text-transform: capitalize;
  color: #1c1c1c;
  margin: 0px; 
}

.sub_title {
  letter-spacing: 0.00938em;
  color: #1C1C1C;
  z-index: 10;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  line-height: 26px;
  margin-bottom: 20px;
  text-transform: capitalize;
  font-size: 20px;
  margin-top: 0;
}

.withor {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  color: #636365;
}

.text_center {
  text-align: center;
}

.title_image {
  max-width: 110px;
}

.img_fluid {
  max-width: 100%;
  height: auto;
}
.leftText h4{
  margin-bottom: 10px;
}
.max_width {
  max-width: 600px;
  margin: 0 auto 10px;
  text-align: center;
}

.max_width .sub_title {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  color: #636365;

}

.whitext {
  color: #fff !important;
}

.mt_2 {
  margin-top: 10px;
}

.talhg {
  padding-bottom: 25px;
  height: 100%;
}

.talgbx {
  box-shadow: 0 0 6px 2px #edebeb;
  border-radius: 22px;
  padding: 20px;
  background: #fff;
  height: 100%;
}

.taldartist .talnartst {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  overflow: hidden;
  zoom: 1;
}

.talenimg {
  /* min-width: 165px;
  max-height: 130px;
  max-width: 165px;
  min-height: 130px; */
  display: block;
  border-radius: 20px;
}

.talenimg img {
  border-radius: 20px;
  padding: 0;
}

.talentcont {
  margin-top: 10px;
}

.talentcont h4 {
  margin: 0 0 5px;
  text-transform: capitalize;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #1C1C1C;
}

.talentcont p {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: #636365;
  opacity: .8;
  margin: 0;
}

.hders {
  display: flex;
  gap: 15px;
  align-items: center;
  margin-bottom: 15px;
}

.tehgt {
  height: 100%;
}

.testmibox {
  padding: 25px;
  background: url(/public/img/testimon-bg.png);
  background-size: cover;
  background-position: center;
  padding-bottom: 35px;
  height: 100%;
}

.tecont {
  padding: 0;
}

.test_cont {
  padding: 0 25px;
  position: relative;
  min-height: 139px;
}

.test_cont::before {
  content: '';
  background: url(/public/img/test-quote.png);
  position: absolute;
  left: 0;
  background-size: cover;
  width: 15px;
  height: 10px;
}

.test_cont::after {
  content: '';
  background: url(/public/img/test-quote.png);
  position: absolute;
  right: 0;
  background-size: cover;
  width: 15px;
  height: 10px;
}

.test_card_heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 180%;
  color: #ededed;
  margin: 0px;
}

.test_card_subhead {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 180%;
  color: #b6b6b6;
  margin: 0px;
}

.test_card_des {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  color: #f6f6f6;
  margin: 0px;
}

.arconsec {
  padding: 50px 0;
  background: url(/public/img/testimon-bg.png);
  background-size: cover;
  background-position: center;
}

.arconsec h3 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
    font-size: 32px;
    line-height: 40px;
  color: #fff;
}

.pytrg {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  color: #fff;
}

.quectionarepra {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  color: #636365;
  margin-bottom: 20px;
  opacity: .8;
}

.readMoreBtn {
  background: none;
  cursor: pointer;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 25px;
  text-align: left;
  color: #1c1c1c;
  padding-top: 6px;
  border: 0;
}
.timeline{
  padding: 0;
  margin-bottom: 0;
  list-style-type: none;
  position: relative;
  counter-reset: timeline-counter;
}
.timeline li{
  padding-left: 75px;
  margin-top: 0;
  counter-increment: timeline-counter;
  position: relative;
}
.timeline li::before{
  content: counter(timeline-counter);
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background: #fff;
  text-align: center;
  color: #1c1c1c;
  font-family: "Roboto", sans-serif;
  font-size: 23px;
  font-weight: 600;
  line-height: 55px;
  position: absolute;
  left: 0;
  top: -15px;
  z-index: 99;
  box-shadow: 0px 6.14px 19.66px 0px #080F340F;
}
/* .timeline li::after{
  content: '';
  height: 100%;
  width: 1px;
  background: #1c1c1c;
  position: absolute;
  top: 20%;
  left: 4.4%;
} */
.timeline li:last-child:after{
  display: none;
}
.whibg .timeline li::before{
  box-shadow: 0px 6.14px 19.66px 0px #080f3426;
}
.stepli{
  margin-bottom: 20px;
}
.stepli h4{
  margin: 0 0 5px;
  text-transform: capitalize;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #1C1C1C;
}
.stepli p{
  font-family: "Roboto", sans-serif;
   font-size: 15px;
   font-weight: 400;
   line-height: 25px;
   text-align: left;
   color: #636365;
   opacity: .8;
   margin: 0;
}
.text_left .withor{
  text-align: left;
}
.idnumbr{width: 55px;
  height: 55px;
  border-radius: 50%;
  background: #1c1c1c;
  text-align: center;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 25px;
  font-weight: 600;
  line-height: 55px;
  margin-bottom: 10px;}
.hbox1 h4{
  margin: 0 0 5px;
  text-transform: capitalize;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}
.hbox1 p{
  font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    text-align: left;
    color: #636365;
    opacity: .8;
    margin: 0;
}
@media(min-width:768px) and (max-width:991px) {
  .arconsec {
    text-align: center;
  }
}

@media(max-width:767px) {
.pageitle h1{
  font-size: 25px;
  line-height: 26px;
  margin-bottom: 5px;
}
  .hero_main {
    margin-top: 57px;
  }

  .tablist li {
    display: block;
    margin-bottom: 5px;
    margin-right: 0;
  }

  .tablist li a {
    border-radius: 10px;
    text-align: center;
  }

  .sec_shgn .tab_content {
    border-radius: 22px;
    margin-top: 15px;
  }

  .talhg {
    padding-bottom: 0;
  }

  .arconsec {
    text-align: center;
  }

  .abttitle,
  .sec_title {
    font-size: 23px;
    line-height: 26px;
    text-align: center;
  }

  .arconsec h3 {
    font-size: 23px;
    line-height: 26px;
  }

  .talentcont h4,
  .talentcont p {
    text-align: center;
  }

  .talgbx {
    text-align: center;
  }
  .leftText{
    text-align: center;
  }
  .leftText .prtra{
    text-align: center;
  }
}

@media (max-width: 600px) {

  /* .iconimg {
    width: 40%;
  } */
  .leftImage {
    width: 51%;
  }

  .rightImages {
    width: 65%;
  }

  .image__flex {
    margin-left: 7%;
  }

  .image__flex2 {
    margin-right: 3%;
  }
}