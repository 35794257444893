.dashboard_main {
    padding: 20px;
    background-color: #f6f6f6;
    border-radius: 10px;
}
.dashboard_header{
    border-bottom: 1px solid #ced4da;
    margin-bottom: 30px;
}
 .paneltitle{
    font-family: "Roboto", sans-serif;
    color: #333333;
    font-size: 21px;
    font-weight: 700;
    padding-bottom: 10px;
    margin-bottom: 0;
 }
.dashboard_main>h1 {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
}

.select {
    height: 44px;
    width: 150px;
    border: 0.6px solid #665D6F;
    border-radius: 6px;
    color:red;
}
.select>menuItem{

    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color:red;
}


