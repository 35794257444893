.hero_main {
   background-image: url(../../assets/img/Banner.png);
   background-repeat: no-repeat;
   background-size: 100% 100%;
   margin-top: 125px;
   padding: 5% 0px;
}

.max_width {
   max-width: 600px;
   margin: 0 auto 10px;
   text-align: center;
}

.max_width .sub_title {
   font-family: "Roboto", sans-serif;
   font-size: 16px;
   font-weight: 400;
   line-height: 27px;
   text-align: center;
   color: #636365;

}

.whitext {
   color: #fff !important;
}

.hero_main_gallery {
   background-image: url(/public/img/gallery-background.svg);
   background-repeat: no-repeat;
   background-size: cover;
   margin-top: 125px;
   padding: 5% 0px;
}

.hero_main_curator {
   background-image: url(/public/img/curator-application-background.svg);
   background-repeat: no-repeat;
   background-size: cover;
   text-align: center;
   flex-direction: column;
   padding: 5% 0px;
   margin-top: 125px;
}

.archdv {
   background: #F6F6F6;
   padding: 40px 0;
   padding-top: 10%;
   margin-top: -7%;
}

.pageitle h1 {
   font-family: "Roboto", sans-serif;
   font-size: 40px;
   font-weight: 600;
   line-height: 50px;
   letter-spacing: 0;
   margin-bottom: 10px;
   margin-top: 0;
   color: #ffffff;
}

.apply_btn {
   padding: 10px 35px;
   border-radius: 10px;
   background: #fff;
   border: 1px solid #fff;
   color: #000;
   font-family: "Roboto", sans-serif;
   font-size: 16px;
   font-weight: 500;
   line-height: 26px;
   cursor: pointer;
}

.apply_btn:hover {
   background: #1c1c1c;
   color: #fff;
}

.sec_title {
   font-family: "Roboto", sans-serif;
   font-weight: 500;
   font-size: 32px;
   line-height: 40px;
   text-transform: capitalize;
   color: #1c1c1c;
   margin: 0px; 
}

.sub_title {
   letter-spacing: 0.00938em;
   color: #1C1C1C;
   z-index: 10;
   font-weight: 400;
   font-family: "Roboto", sans-serif;
   line-height: 26px;
   margin-bottom: 20px;
   text-transform: capitalize;
   font-size: 20px;
   margin-top: 0;
}

.withor {
   font-family: "Roboto", sans-serif;
   font-size: 16px;
   font-weight: 400;
   line-height: 27px;
   text-align: center;
   color: #636365;
}

.text_center {
   text-align: center;
}

.title_image {
   max-width: 110px;
}

.img_fluid {
   max-width: 100%;
   height: auto;
}

.sec_sc {
   padding: 50px 0;
}

.stboxh {
   padding-top: 30px;
   height: 100%;
}

.stvob {
   border-radius: 10px;
   padding: 20px 25px;
   background: #F6F6F6;
   position: relative;
   height: 100%;
}

.sticon {
   top: -30px;
   width: 55px;
   height: 55px;
   border-radius: 50%;
   position: absolute;
   box-shadow: 0px 10px 30px rgba(28, 28, 28, 0.3);
   background: #1c1c1c;
   padding: 12px;
}

.stvob h4 {
   margin: 0 0 5px;
   text-transform: capitalize;
   font-family: "Roboto", sans-serif;
   font-size: 18px;
   font-weight: 600;
   line-height: 24px;
   text-align: left;
   padding-top: 25px;
}

.stvob p {
   font-family: "Roboto", sans-serif;
   font-size: 15px;
   font-weight: 400;
   line-height: 25px;
   text-align: left;
   color: #636365;
   opacity: .8;
   margin: 0;
}

.befnbox {
   margin-top: 30px;
}

.befnbox h4 {
   margin: 20px 0 7px;
   text-transform: capitalize;
   font-family: "Roboto", sans-serif;
   font-size: 18px;
   font-weight: 600;
   line-height: 24px;
   text-align: center;
   color: #fff;
}

.befnbox p {
   font-family: "Roboto", sans-serif;
   font-size: 15px;
   font-weight: 400;
   line-height: 25px;
   text-align: center;
   color: #fff;
   margin: 0;
}

.befnmbr {
   width: 50px;
   height: 50px;
   line-height: 50px;
   background: #fff;
   border-radius: 50%;
   color: #1c1c1c;
   margin: 0 auto;
   margin-bottom: 15px;
   outline: 1px solid #636365;
   outline-offset: 5px;
}

.befnmbr p {
   color: #1C1C1C;
   text-align: center;
   line-height: 51px;
   font-size: 18px;
   font-weight: 700;
   letter-spacing: -1px;
}

.text_white {
   color: #fff !important;
   opacity: 1 !important;
}

.ste {
   margin-top: 35px;
}

.faq_box {
   box-shadow: 0px 6.14px 19.66px 0px #080F340F;
   border-radius: 22px;
   padding: 25px;
}

.hero_main_affiliate {
   background-image: url(/public/img/affiliate-background.svg);
   background-repeat: no-repeat;
   background-size: cover;
   margin-top: 125px;
   padding: 5% 0px;
}

.hero_main_curator_benefits {
   background-image: url(/public/img/curator-benefits.svg);
   background-repeat: no-repeat;
   background-size: cover;
   text-align: center;
   padding: 60px 0px;
   background-attachment: fixed;
}

.hero_main_affiliate_benefits {
   background-image: url(/public/img/affiliate-benefits.svg);
   background-repeat: no-repeat;
   background-size: cover;
   text-align: center;
   padding: 60px 0px;
   background-attachment: fixed;
}

.hero_main_curator_benefits_top {
   padding-top: 0px;
   text-align: center;

}

.hero_main_curator_benefits_top_box {
   max-width: 250px;
   color: white;
   padding-bottom: 30px;
}

.hero_main_affiliate_benefits_top_box {
   max-width: 250px;
   color: white;
   padding-bottom: 30px;
   border-right: dashed 2px #412d1f;
   border-collapse: separate;
   border-spacing: 5px;
   padding-right: 30px;
}

.hero_main_curator_circle {
   width: 28px;
   height: 28px;
   margin: 10px 0px;
   background-color: #ffffff;
   border-radius: 50px;
   color: #000;
   display: flex;
   justify-content: center;
   align-items: center;
} 
.hero_heading h1 {
   color: #ffffff;
   font-family: "Roboto", sans-serif;
   font-size: 40px;
   font-weight: 400;
   line-height: 63px;
   letter-spacing: 0em;
   text-align: center;
}

.hero_heading_button {
   height: 63px;
   outline: 0;
   border-style: none;
   width: 158.93182373046875px;
   left: 881px;
   top: 416px;
   border-radius: 8.590909004211426px;
   background-color: #ffffff;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
}

.hero_heading_button h3 {
   font: DM Sans;
   font-size: 20px;
   font-weight: 700;
   line-height: 26px;
   letter-spacing: 0em;
   text-align: left;
}

.hero_main1 {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   max-width: 1900px;
   height: 100%;
   padding: 20px;
   /* margin-bottom: 30px; */
}

.hero_card {
   display: flex;
   justify-content: space-between;
   flex-direction: row;
   margin-top: 63px;
   height: auto;
}

.hero_card_gallery {
   display: flex;
   justify-content: space-between;
   flex-direction: row;
   margin-top: 63px;
   width: 80%;
   gap: 10px;
   flex-wrap: wrap;
}

.Avatar1 {
   top: -35px !important;
   left: 50px;
   width: 60px !important;
   height: 60px !important;
   border-radius: 35px !important;
   position: absolute !important;
   box-shadow: 0px 10px 30px rgba(28, 28, 28, 0.3);
   z-index: 6 !important;
   background: #1c1c1c !important;
   display: flex;
   justify-content: center;
   align-items: center;
}

.Avatar2 {
   top: -35px !important;
   left: 0;
   right: 0;
   margin: 0 auto;
   width: 70px !important;
   height: 70px !important;
   border-radius: 35px !important;
   position: absolute !important;
   /* box-shadow: 0px 10px 30px rgba(28, 28, 28, 0.3); */
   z-index: 6 !important;
   border: 0.896296px solid #636365;
   /* background-color: #1C1C1C !important; */
   color: white;
   display: flex;
   justify-content: center;
   align-items: center;
}

.benifits_box {
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: black;
}

.benifits_card {
   width: 210px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

.dot_line {
   border-right: 1px dotted white;
   height: 100px;
   width: 0.1px;
}

.Avatar_ligth {
   /* top: -35px !important;
      left: 100px; */
   width: 50px !important;
   height: 50px !important;
   border-radius: 35px !important;
   /* position: absolute !important; */
   /* box-shadow: 0px 10px 30px rgba(28, 28, 28, 0.3); */
   z-index: 6 !important;
   border: 0.896296px solid white;
   /* background-color: #1C1C1C !important; */
   color: black;
   /* background-color: white ; */
   display: flex;
   justify-content: center;
   align-items: center;
}

.Avatar3 {
   background: #1c1c1c !important;
   height: 57px;
   border-radius: 35px !important;
   width: 57px;
   display: flex;
   justify-content: center;
   align-items: center;
}

.Avatar4 {
   background: white !important;
   height: 40px;
   border-radius: 35px !important;
   width: 40px;
   display: flex;
   justify-content: center;
   align-items: center;
}

.benifits_head {
   font-family: "Roboto", sans-serif;
   font-style: normal;
   font-weight: 500;
   font-size: 18px;
   line-height: 25px;
   color: #ffffff;
   margin: 0px;
}

.benifits_des {
   font-family: "Roboto", sans-serif;
   font-style: normal;
   font-weight: 400;
   font-size: 14px;
   line-height: 25px;
   text-align: center;
   color: #ffffff;
   margin: 0px;
}

.hrs {
   width: 100px;
   opacity: 0.5;
   /* border: 1px solid #000000; */
   /* color:#1C1C1C; */
   margin-top: 65px;
   height: 0.1px;
   background-color: #1c1c1c;
}

.hero_main6 {
   background: #ffffff;
   /* padding: 10px 50px 50px 50px; */
   box-shadow: 0px 6.14465px 19.6629px rgba(8, 15, 52, 0.06);
   border-radius: 22.1207px;
   display: flex;
   flex-direction: column;
   width: 85%;
   margin: auto;
   justify-content: center;
   align-items: center;
}

.hero_main7 {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   margin: 50px 0px;
}

.hero_main8 {
   /* position: relative; */
   /* width: 100%;
      height: 619px;
      left: 0px;
      background: #F6F6F6; */
   border-radius: 0px;
   z-index: 0;
}

.hero_main8_image {
   /* position: relative; */
   /* top: -250px;
      width: 100%;
      height: 409.36px;
      display: flex;
      flex-direction: column;
      justify-content: center; */
   align-items: center;
   /* border-radius: 0px; */
}

.hero_main8_image img {
   /* position: absolute; */
   width: 650px;
   height: 409.36px;
   border-radius: 0px;
}

.cards_div {
   background-color: #f6f6f6;
   width: 100%;
   padding: 30px 100px;
   padding-top: 210px;
   margin-top: -200px;
}

.card_div {
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.cards {
   width: 300px;
   height: 298px;
}

.headings {
   font-family: "Roboto", sans-serif;
   font-style: normal;
   font-weight: 700;
   font-size: 28px;
   line-height: 36px;
   color: #1c1c1c;
   margin: 0px;
}

.contents {
   font-family: "Roboto", sans-serif;
   font-style: normal;
   font-weight: 400;
   font-size: 18px;
   line-height: 30px;
   color: #636365;
   margin: 0px;
   margin-top: 10px;
}

.round_img_div {
   height: 70px;
   width: 70px;
   background: #1c1c1c;
   box-shadow: 0px 10px 30px rgba(28, 28, 28, 0.3);
   border-radius: 35px;
   display: flex;
   justify-content: center;
   align-items: center;
}

.btn_div {
   display: flex;
   justify-content: center;
   align-items: center;
}

.card_four {
   background: #ffffff;
   box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.1);
   border-radius: 10px;
   padding: 20px 25px;
   position: relative;
   height: 100%;
}

.card_div {
   display: flex;
   justify-content: center;
   align-items: center;
   column-gap: 30px;
}

.card_testimonials {
   display: flex;
   justify-content: center;
   align-items: flex-start;
   width: 500px;
   column-gap: 30px;
   background-color: black;
   padding: 20px 30px;
   box-shadow: 0px 20px 50px rgba(18, 17, 39, 0.08);
}

.test_card_heading {
   font-family: "Poppins";
   font-style: normal;
   font-weight: 600;
   font-size: 14px;
   line-height: 180%;
   color: #ededed;
   margin: 0px;
}

.test_card_subhead {
   font-family: "Poppins";
   font-style: normal;
   font-weight: 400;
   font-size: 12px;
   line-height: 180%;
   color: #b6b6b6;
   margin: 0px;
}

.test_card_des {
   font-family: "Roboto", sans-serif;
   font-style: normal;
   font-weight: 500;
   font-size: 16px;
   line-height: 180%;
   color: #f6f6f6;
   margin: 0px;
}

.service_card {
   background: #ffffff;
   border: 0.970041px solid rgba(99, 99, 101, 0.2);
   box-shadow: 0px 0px 7.76033px rgba(0, 0, 0, 0.06);
   padding: 10px;
   height: auto;
   width: 330px;
}

.service_card_div {
   display: flex;
   justify-content: center;

   column-gap: 20px;
   flex-wrap: wrap;
}

.service_card_heading {
   font-family: "Roboto", sans-serif;
   font-style: normal;
   font-weight: 700;
   font-size: 25px;
   line-height: 33px;
   color: #1c1c1c;
   margin: 0px;
}

.service_card_description {
   font-family: "Roboto", sans-serif;
   font-style: normal;
   font-weight: 400;
   font-size: 18px;
   line-height: 32px;
   color: #636365;
   margin: 0px;
}

.accordian_show {
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.accodian_no {
   height: 40px;
   width: 40px;
   line-height: 40px;
   background: #1c1c1c;
   border-radius: 50%;
   color: #fff;
   text-align: center;
   margin-right: 15px;
}

.faq_box {
   align-items: center;
}

.faqhdr {
   margin: 0;
   font-family: "Roboto", sans-serif;
   font-size: 18px;
   font-weight: 600;
   line-height: 24px;
   text-align: left;
   color: #1C1C1C;
}

.faqhdrExpanded {
   color: #1C1C1C;
}

.faq_box p {
   font-family: "Roboto", sans-serif;
   font-size: 15px;
   font-weight: 400;
   line-height: 25px;
   text-align: left;
   color: #636365;
   opacity: .8;
   margin: 0;
}

.btnx_div {
   margin-top: 30px;
   text-align: center;
}

.apply_btnbl {
   padding: 10px 25px;
   border-radius: 10px;
   background: #000;
   border: 1px solid #000;
   color: #fff;
   font-family: "Roboto", sans-serif;
   font-size: 16px;
   font-weight: 500;
   line-height: 26px;
   cursor: pointer;
}

.apply_btnbl:hover {
   background: transparent;
   color: #1c1c1c;
}

.show_content {
   display: flex;
   justify-content: flex-start;
   align-items: center;
   white-space: nowrap;
   column-gap: 20px;
}

.subscribe_banner {
   display: flex;
   justify-content: center;
   column-gap: 10px;
   align-items: center;
   background-color: black;
   padding: 20px 10px;
}

.subscribe_head {
   font-family: "Roboto", sans-serif;
   font-style: normal;
   font-weight: 400;
   font-size: 40px;
   line-height: 50px;
   text-transform: capitalize;
   margin: 0px;
   color: #ffffff;
   width: 470px;
   margin: 0px 20px;
}

.card_four h4 {
   margin: 0 0 5px;
   text-transform: capitalize;
   font-family: "Roboto", sans-serif;
   font-size: 18px;
   font-weight: 600;
   line-height: 24px;
   text-align: left;
   padding-top: 25px;
}

.card_four p {
   font-family: "Roboto", sans-serif;
   font-size: 15px;
   font-weight: 400;
   line-height: 25px;
   text-align: left;
   color: #636365;
   opacity: .8;
   margin: 0;
}

.card_four::before {
   content: '';
   width: 10%;
   height: 2px;
   background: #000;
   position: absolute;
   right: -10.5%;
   top: 16%;
}

.cdrf::before {
   display: none;
}

.testmibox {
   padding: 25px;
   background: url(/public/img/testimon-bg.png);
   background-size: cover;
   background-position: center;
   padding-bottom: 35px;
}

.tecont {
   padding: 0 20px;
}

.test_metainfo {
   padding-bottom: 18px;
}

.test_cont {
   padding: 0 25px;
   position: relative;
   min-height: 96px;
}

.test_cont::before {
   content: '';
   background: url(/public/img/test-quote.png);
   position: absolute;
   left: 0;
   background-size: cover;
   width: 15px;
   height: 10px;
}

.test_cont::after {
   content: '';
   background: url(/public/img/test-quote.png);
   position: absolute;
   right: 0;
   background-size: cover;
   width: 15px;
   height: 10px;
}

.exclli {
   height: 100%;
   padding-bottom: 25px;
}

.exbox {
   height: 100%;
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   box-shadow: 0px 0px 7.76px 0px #0000002b;
   padding: 15px;
   flex: 1;
}

.exboxmg {
   max-height: 195px;
   min-height: 195px;
   overflow: hidden;
}

.exbox h4 {
   margin: 0 0 5px;
   text-transform: capitalize;
   font-family: "Roboto", sans-serif;
   font-size: 18px;
   font-weight: 600;
   line-height: 24px;
   text-align: left;
   padding-top: 25px;
}

.exbox p {
   font-family: "Roboto", sans-serif;
   font-size: 15px;
   font-weight: 400;
   line-height: 25px;
   text-align: left;
   color: #636365;
   opacity: .8;
   margin: 0;
}

.sec_scexcl {
   background: #F6F6F6;
   padding: 50px 0;
}

.benfhdgn {
   margin: 0;
   text-transform: capitalize;
   font-family: "Roboto", sans-serif;
   font-size: 18px;
   font-weight: 600;
   line-height: 24px;
   text-align: left;
   color: #1C1C1C;
}

.bendesc {
   font-family: "Roboto", sans-serif;
   font-size: 15px;
   font-weight: 400;
   line-height: 25px;
   text-align: left;
   color: #636365;
   opacity: .8;
   margin: 0;
}

.plansec .bendesc {
   font-family: "Roboto", sans-serif;
   font-size: 14px;
   font-weight: 400;
   line-height: 23px;
   text-align: left;
   color: #636365;
   opacity: .8;
   margin: 0;
}

.accordsc {
   box-shadow: 0px 6.14px 19.66px 0px #080F340F;
   border-radius: 22px;
   padding: 25px;
   background: #fff;
   height: 100%;
}

.spcebtnm {
   margin-top: 25px;
}

.btn_div1 {
   text-align: center;
   margin-top: 25px;
}

.taldartist ul {
   margin: 0;
   padding: 0;
   list-style-type: none;
}

.taldartist li {
   padding: 20px;
   background: #fff;
   border: 1px solid #D9DBE9;
   margin-top: 0;
   margin-bottom: 15px;
   border-radius: 10px;
}

.talhg {
   padding-bottom: 25px;
   height: 100%;
}

.talgbx {
   box-shadow: 0 0 6px 2px #edebeb;
   border-radius: 22px;
   padding: 20px;
   background: #fff;
   height: 100%;
}

.taldartist .talnartst {
   display: -ms-flexbox;
   display: flex;
   -ms-flex-align: start;
   align-items: flex-start;
   overflow: hidden;
   zoom: 1;
}

.talenimg {
   /* min-width: 165px;
   max-height: 130px;
   max-width: 165px;
   min-height: 130px; */
   display: block;
   border-radius: 20px;
}

.talenimg img {
   border-radius: 20px;
   padding: 0;
}

.talentcont {
   margin-top: 10px;
}

.talentcont h4 {
   margin: 0 0 5px;
   text-transform: capitalize;
   font-family: "Roboto", sans-serif;
   font-size: 18px;
   font-weight: 600;
   line-height: 24px;
   text-align: left;
   color: #1C1C1C;
}

.talentcont p {
   font-family: "Roboto", sans-serif;
   font-size: 15px;
   font-weight: 400;
   line-height: 25px;
   text-align: left;
   color: #636365;
   opacity: .8;
   margin: 0;
}

.subscriptionbg {
   background: url(/public/img/subscrplbg.png);
   padding: 50px 0;
   background-position: center;
   background-size: cover;
}

.premimd {
   background: #1C1C1C;
}

.sucrhndg {
   font-family: "Roboto", sans-serif;
   font-weight: 500;
   font-size: 30px;
   line-height: 40px;
   text-transform: capitalize;
   margin: 0;
   text-align: left;
   margin-bottom: 15px;
}
.sticonnb{
   width: 30px;
   height: 30px;
   line-height: 30px;
   border-radius: 50%;
   background: #000000;
   text-align: center;
   min-width: 30px;
}
.sticonnb .usicon{
   color: #fff;
}
.prisec {
   display: flex;
   align-items: center;
   justify-content: start;
   margin-bottom: 5px;
}
.faqx li {
   font-family: "Roboto", sans-serif;
   font-size: 15px;
   font-weight: 400;
   line-height: 25px;
   text-align: left;
   color: #848485;
   margin: 0;
   margin-bottom: 10px;
 }
 
 .faqx li span {
   color: #1C1C1C;
   font-weight: 600;
 }
 
.subsprice {
   font-weight: 400;
   margin: 0;
   font-family: "Roboto", sans-serif;
   font-size: 18px;
}

.pric {
   font-family: "Roboto", sans-serif;
   font-size: 26px;
   font-weight: 700;
   color: #1c1c1c;
}

.predg {
   font-family: "Roboto", sans-serif;
   font-size: 17px;
}

.accorscc {
   padding-left: 15px;
}

.scbhdrng {
   margin: 0;
   text-transform: capitalize;
   font-family: "Roboto", sans-serif;
   font-size: 15px;
   font-weight: 600;
   line-height: 24px;
   text-align: left;
   color: #1C1C1C;
}

.plansec {
   background: #fff;
   padding: 20px;
   border-radius: 26px;
}

.faqx p {
   font-family: "Roboto", sans-serif;
   font-size: 15px;
   font-weight: 400;
   line-height: 25px;
   text-align: left;
   color: #636365;
   opacity: .8;
   margin: 0;
}

.whybox {
   box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.1);
   border-radius: 10px;
   padding: 25px;
   background: #fff;
   height: 100%;
   width: 100%;
   display: -webkit-box;
   display: -webkit-flex;
   display: -ms-flexbox;
   display: flex;
   -webkit-flex-direction: column;
   -ms-flex-direction: column;
   flex-direction: column;
   -webkit-box-pack: justify;
   -webkit-justify-content: start;
   justify-content: start;
   text-align: center;
}

.whyboxh {
   height: 100%;
}

.whybox h4 {
   margin: 0 0 5px;
   text-transform: capitalize;
   font-family: "Roboto", sans-serif;
   font-size: 18px;
   font-weight: 600;
   line-height: 24px;
}

.whycont p {
   font-family: "Roboto", sans-serif;
   font-size: 15px;
   font-weight: 400;
   line-height: 25px;
   color: #636365;
   opacity: .8;
   margin: 0;
}
.ourartistcard {
   width: 100%;
 }
 
 .ourartistinfo {
   background: #fff;
   display: block;
   position: relative;
   padding: 10px;
   padding-bottom: 15px;
 }
 
 .ourartistinfo h4 {
   margin: 0px 0 5px;
   text-transform: capitalize;
   font-family: "Roboto", sans-serif;
   font-size: 18px;
   font-weight: 600;
   line-height: 24px;
   text-align: center;
 }
 
 .tdr {
   color: #1c1c1c !important;
   opacity: .7;
 }
 
 .ourartistinfo p {
   font-family: "Roboto", sans-serif;
   font-size: 15px;
   font-weight: 400;
   line-height: 25px;
   text-align: center;
   color: #636365;
   opacity: .8;
   margin: 0;
 }
.rechhbox {
   box-shadow: 0px 6.14px 19.66px 0px #080F340F;
   border-radius: 22px;
   padding: 25px;
   background: #fff;
   position: relative;
   height: 100%;
}

.rechhbox h4 {
   margin: 0 0 5px;
   text-transform: capitalize;
   font-family: "Roboto", sans-serif;
   font-size: 18px;
   font-weight: 600;
   line-height: 24px;
   text-align: left;
   padding-top: 25px;
}

.rechhbox p {
   font-family: "Roboto", sans-serif;
   font-size: 15px;
   font-weight: 400;
   line-height: 25px;
   text-align: left;
   color: #636365;
   opacity: .8;
   margin: 0;
}

.rechhbox ul {
   margin: 10px 0;
}

.rechhbox ul li {
   font-family: "Roboto", sans-serif;
   font-size: 15px;
   font-weight: 400;
   line-height: 25px;
   text-align: left;
   color: #636365;
   opacity: .8;
}

.textContent {
   display: -webkit-box;
   -webkit-line-clamp: 2;
   line-clamp: 2;
   /* Limit 
   to 3 lines when collapsed */
   -webkit-box-orient: vertical;
   overflow: hidden;
   text-overflow: ellipsis;
}

.expanded {
   display: block;
   /* Show full content when expanded */
   -webkit-line-clamp: unset;
   line-clamp: unset;
}

.readMoreBtn {
   background: none;
   cursor: pointer;
   padding: 0;
   font-family: "Roboto", sans-serif;
   font-size: 15px;
   font-weight: 500;
   line-height: 25px;
   text-align: left;
   color: #1c1c1c;
   padding-top: 6px;
   border: 0;
}

.hgrd {
   text-align: center !important;
   font-size: 18px !important;
   color: #fff !important;
   opacity: 1 !important;
   font-weight: 600 !important;
   line-height: 30px !important;
}

.feabc {
   display: flex;
   gap: 10px;
}

.feabc h4 {
   margin: 0 0;
   text-transform: capitalize;
   font-family: "Roboto", sans-serif;
   font-size: 17px;
   font-weight: 600;
   line-height: 24px;
   text-align: left;
}

.feabc p {
   font-family: "Roboto", sans-serif;
   font-size: 15px;
   font-weight: 400;
   line-height: 25px;
   text-align: left;
   color: #636365;
   opacity: .8;
   margin: 0;
}

.iconst {
   width: 30px;
   height: 30px;
   line-height: 30px;
   border-radius: 50%;
   background: #fff;
   text-align: center;
   min-width: 30px;
   box-shadow: 0px 3.14px 5.66px -1px #080f344d;
}

.usicon {
   color: #1C1C1C;
   font-size: 20px;
}

.accodian_no {
   display: none;
}
.faq li{
   font-family: "Roboto", sans-serif;
   font-size: 15px;
   font-weight: 400;
   line-height: 25px;
   text-align: left;
   color: #848485;
   margin: 0;
   margin-bottom: 10px;
}
.faq li strong{
   color: #1C1C1C;
   font-weight: 600;
}
.incl{
   font-family: "Roboto", sans-serif;
   font-size: 16px;
   color: rgb(132, 129, 153);
}
.rechhbox .sticon{
   text-align: center;
}
.rechhbox .sticon span{
   text-transform: capitalize;
   font-family: "Roboto", sans-serif;
   font-size: 20px;
   font-weight: 600;
   color: #fff;
   text-align: center;
}
.idnumbr{
   width: 55px;
   height: 55px;
   border-radius: 50%;
   background: #1c1c1c;
   text-align: center;
   color: #fff;
   font-family: "Roboto", sans-serif;
   font-size: 25px;
   font-weight: 600;
   line-height: 55px;
   margin-bottom: 10px;
}
.hbox1 h4{
   margin: 0 0 5px;
   text-transform: capitalize;
   font-family: "Roboto", sans-serif;
   font-size: 18px;
   font-weight: 600;
   line-height: 24px;
   text-align: left;
}
.hbox1 p{
   font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    text-align: left;
    color: #636365;
    opacity: .8;
    margin: 0;
}
.acpra{
   font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    text-align: left;
    color: #636365;
    opacity: .8;
    margin: 0;
}
.stepli{
   margin-bottom: 20px;
}
.stepli h4{
   margin: 0 0 5px;
   text-transform: capitalize;
   font-family: "Roboto", sans-serif;
   font-size: 18px;
   font-weight: 600;
   line-height: 24px;
   text-align: left;
   color: #1C1C1C;
}
.stepli p{
   font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    text-align: left;
    color: #636365;
    opacity: .8;
    margin: 0;
}
.whyusboox{
   background: #1C1C1C;
   border-radius: 22px;
   padding: 15px;
}
.whubody{
   background: #fff;
   padding: 10px;
   border-radius: 10px;
   min-height: 145px;
}
.whyusboox h4{
   margin: 0 0 15px;
   text-transform: capitalize;
   font-family: "Roboto", sans-serif;
   font-size: 18px;
   font-weight: 600;
   line-height: 24px;
   color: #ffffff;
}
.whyusboox h5{
   margin: 0 0 5px;
   text-transform: capitalize;
   font-family: "Roboto", sans-serif;
   font-size: 16px;
   font-weight: 600;
   line-height: 24px;
   color: #ffffff;
}
.whubody p{
   font-family: "Roboto", sans-serif;
   font-size: 15px;
   font-weight: 400;
   line-height: 25px;
   color: #636365;
   opacity: .8;
   margin: 0;
}
.archdvb{
   padding-top: 30px;
}
.timeline{
   padding: 0;
   margin-bottom: 0;
   list-style-type: none;
   position: relative;
   counter-reset: timeline-counter;
}
.timeline li{
   padding-left: 75px;
   margin-top: 0;
   counter-increment: timeline-counter;
   position: relative;
}
.timeline li::before{
   content: counter(timeline-counter);
   width: 55px;
   height: 55px;
   border-radius: 50%;
   background: #fff;
   text-align: center;
   color: #1c1c1c;
   font-family: "Roboto", sans-serif;
   font-size: 23px;
   font-weight: 600;
   line-height: 55px;
   position: absolute;
   left: 0;
   top: -15px;
   z-index: 99;
   box-shadow: 0px 6.14px 19.66px 0px #080F340F;
}
/* .timeline li::after{
   content: '';
   height: 100%;
   width: 1px;
   background: #1c1c1c;
   position: absolute;
   top: 20%;
   left: 4.4%;
} */
.timeline li:last-child:after{
   display: none;
}
.whibg .timeline li::before{
   box-shadow: 0px 6.14px 19.66px 0px #080f3426;
}
.btnmt2{
   text-align: center;
    margin-top: 50px;
}
.spesc .whubody{
   min-height: 100px;
}
@media(max-width:767px) {
   .pageitle h1{
      font-size: 25px;
        line-height: 26px;
        margin-bottom: 10px;
   }
   .whyusboox h4, .whyusboox p{
      text-align: center;
   }
   .sucrhndg, .pric{
      font-size: 22px;
        line-height: 23px;
   }
   .btnmt2{
      margin-top: 10px;
   }
   .timeline li{
padding-left: 0;
   }
   .timeline li::before{
      position: relative;
      display: block;
      margin: 10px auto;
      margin-top: 20px;
      top: 0;
   }
   .stepli h4, .stepli p{
      text-align: center;
   }
   .hbox1 h4, .hbox1 p, .hbox1{
      text-align: center;
   }
   .idnumbr{        
      text-align: center;
      margin: 0 auto 10px;
   }
   .btn_div1{
      text-align: center;
   }
   .hero_main,
   .hero_main_affiliate,
   .hero_main_gallery,
   .hero_main_curator {
      margin-top: 57px;
   }

   .sec_title {
      font-size: 23px;
        line-height: 26px;
      text-align: center;
   }

   .card_four::before {
      display: none;
   }

   .Avatar2 {
      right: 0;
      left: 0;
      margin: 0 auto;
   }

   .card_four {
      margin: 20px 0;
      height: auto;
   }

   .card_four h4,
   .card_four p,
   .talentcont h4,
   .talentcont p {
      text-align: center;
   }

   .taldartist .talnartst {
      display: block;
   }

   .talenimg {
      margin: 0 auto;
   }
}