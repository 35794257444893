.proddets__top {
  padding-top: 5%;
}
.main__img {
  width: 85%;
  margin-left: 7%;
}
.small_img {
  margin-right: 2%;
  width: 14%;
  width: 10%;
}
.previewimg__div {
  margin-left: 7.5%;
  margin-top: 1.5%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.outlineimg {
  margin-left: -10%;
  width: 6.5%;
}

.prodDetails {
  margin-top: -1%;
  margin-left: -1%;
  height: auto;
  width: 91%;
}
.pricing__section {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.ellipseshare {
  /* border: 1px solid black; */
  background-color: #ccc;
  border-radius: 50%;
  width: 1.75rem;
  height: 1.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 30%;
}
.ellipseheart {
  background-color: #ccc;
  border-radius: 50%;
  width: 1.75rem;
  height: 1.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2%;
}
.ratings__reactions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: -2%;
}
.starrating {
  margin-right: 0.5%;
  margin-top: 1.5%;
}
.likeIcon {
  margin-top: 1%;
  margin-left: 45%;
}
.viewIcon {
  margin-top: 1%;
  margin-left: 2%;
}
.proddets_dets {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3%;
}

.dets__divider {
  width: 100%;
  margin-right: 18%;
  margin-top: 2%;
}
.prints__customization {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -0.5%;
}
.custLink {
  margin-right: 18.5%;
  color: black;
}
.offerSection {
  margin-top: 2%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.offerIcon {
  width: 4.5%;
}
.vectorheart {
  width: 60%;
}
.vectorimages {
  width: 60%;
}
.description {
  margin-top: 2%;
  width: 75%;
  margin-left: 22%;
}
.choose__frameDiv {
  width: 40% !important;
  display: flex;
}
.choose__frame {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10%;
  background: rgb(237, 235, 235);
  padding: 1.5%;
  border-radius: 4px;
  width: 60%;
  margin-left: 1%;
}
.verticalLine {
  border-left: 2.5px solid grey;
  height: 1.5vw;
  margin-left: 1%;
}
.general__information {
  margin-top: 2%;
  background-color: rgb(237, 235, 235);
  width: 62%;
  margin-left: 1%;
}
.att__color {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 2%;
  margin-bottom: 1%;
}
.orientation1 {
  margin-left: 37%;
  margin-right: 2%;
  width: 6%;
}
.orientation2 {
  margin-right: 2%;
  width: 6%;
}
.orientation3 {
  width: 12%;
}
.features {
  padding: 3% 12%;
  margin-top: 7%;
  background-color: rgb(237, 235, 235);
}
.mini__truck {
  width: 25%;
  height: 25%;
}
.safe__shipping {
  display: flex;
}
.package {
  width: 25%;
  height: 25%;
}
.secure {
  width: 25%;
  height: 25%;
}
.proddets__middle {
  margin-top: 5%;
}
.design__headingImage {
  display: flex;
  justify-content: center;
  align-items: center;
}
.design__image {
  width: 7.5%;
}
.design__heading {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cardImage {
  width: 100%;
  object-fit: contain;
}
.card__middle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card__main {
  /* background-color: #ccc; */
  width: 85%;
}
.card__middleRight {
  display: flex;
  margin-bottom: 6.5%;
}
.card__bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.verticalLine {
  border-left: 1.5px solid grey;
  height: 2.75vw;
}
.buy__text {
  margin-top: -8%;
}
.rent__text {
  margin-top: -8%;
}
.print__text {
  margin-top: -8%;
}
.ProductCards {
  margin-left: 1%;
}
.card__main {
  margin-bottom: 20%;
}
.ProductCards {
  margin-top: 1%;
}
.divider {
  margin-top: 3%;
}
.bidders__flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bidders {
  overflow: scroll;
  overflow-x: hidden;
  height: 4.8vw;
}
.morepictures {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eaeaf1;
  width: 70%;
  padding: 10% 5%;
  margin-left: 6.5%;
}
.morepicsDiv {
  margin-left: 12.5%;
}
.frameimg {
  margin-left: 30%;
}
