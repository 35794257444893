.components{
    background-color: #f6f6f6;
    padding:20px;
    border-radius: 10px;
}
.aply_btn{
    padding: 10px 35px;
      border-radius: 10px;
      background: #1c1c1c;
      border: 1px solid #1c1c1c;
      color: #fff;
  font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
      cursor: pointer;
      text-decoration: none !important;
      display: block; 
      margin-bottom: 10px;
  }
  .aply_btn:hover{
    background: #fff;
    color: #1c1c1c;
  }
.news_heading {
    display: flex;
    column-gap: 10px;
    align-items: center;
}

.news_heading>h3 {
    line-height: 30px;
    white-space: nowrap;
    margin: 0px;
    font-family: "Roboto", sans-serif;
    color: #333333;
    font-size: 21px;
    font-weight: 700;
    margin-bottom: 0;
}

.hrs {
    opacity: 0.3;
    width: 100%;
    border: 1px solid #665D6F;
}


.inputs_gird {
    margin: 0px 0px;
}

.inputs_gird>label {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #1C1C1C;
}

.inputs_gird>input {
    background: #F3F3F3;
    height: 40px;
    padding-left: 10px;
    border-radius: 6px;
    border: none;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #636365;
    width: 92%;
    margin: 5px 0px 0px 0px;
}

.access_roles {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.access_roles>div {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #636365;
}


.toggle_btn {
    text-align: left;
}

.switch {
    position: relative;
    display: inline-block;
    width: 43px;
    height: 24px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}.slider.round {
    border-radius: 35px;
}

.slider.round:before {
    border-radius: 50%;
}
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #FFFFFF;
    -webkit-transition: .2s;
    transition: .2s;
    border: 0.5px solid #E2E2E2;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.07);
}

.slider:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 17px;
    left: 4px;
    bottom: 2.5px;
    background-color: #1C1C1C;
    -webkit-transition: .3s;
    transition: .3s;
}

input:checked+.slider {
    background-color: #FFFFFF;
}

input:focus+.slider {
    box-shadow: 0 0 1px #FFFFFF;
}

input:checked+.slider:before {
    -webkit-transform: translateX(17px);
    -ms-transform: translateX(17px);
    transform: translateX(17px);
}
.btn_div{
    display: flex;
    justify-content: center;
    align-items: center;
    padding:30px 0px 0px 0px;
}