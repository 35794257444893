body {
  overflow-x: hidden;
}

.homepage {
  padding-top: 125px;
  width: 100%;
  /* overflow-x: hidden !important; */
  /* max-width: 100%; */
}

.homepage__LandingPage {
  background: url("../../../assets/img/Background Image.png");
  background-color: #f7f7f7;
  /* width: 100%; */
  height: max-content;
  /* background-color: #f7f7f7; */
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0px;
  height: auto;
  position: relative;
  padding: 60px 0px 120px 0px;
}

.landingpage__Text {
  /* width: 50%; */
  margin: auto;
  /*margin-top: 6.5%; */
}

.landingpage__Images {
  /* padding-right: 15%; */
  /* display: flex; */
  position: relative;
  /* width: 50%;
  margin-left: -20%; */
  padding-right: 15%;
}

.images {
  position: relative;
}

.image__item2 {
  z-index: 1;
  position: absolute;
  top: 20%;
  left: 12%;
  width: 59%;
}

.image__item1 {
  z-index: 2;
  left: 0%;
  position: relative;
  width: 60%;
  height: 60%;
}

.image__item3 {
  z-index: 3;
  position: absolute;
  left: 39%;
  top: 36%;
  height: 90%;
  width: 40%;
}

.top__Image2 {
  width: 100%;
  height: 75%;
  /* position: a; */
  /* margin-left: -47%;
  margin-top: 37.5%; */
}

.top__Image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  /* margin-left: 10%;
  margin-top: -5%; */
}

.top__Image3 {
  width: 100%;
  height: 100%;
  object-fit: contain;
  /* margin-left: -128%;
  margin-top: 55%; */
}

.homepage__TopImg {
  /* margin-top: 40%; */
  width: 25%;
  height: 25%;
}

.best_seller_main {
  width: 100%;
  margin: 20px 0px;
}

.banner {
  /* width: 100%; */
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
}

.banner>h2 {
  margin: 0px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 45px;
  text-transform: capitalize;
  color: #1c1c1c;
  margin-bottom: 10px;
}

.card_box {
  /* background-color: green; */
  /* display: flex;
  justify-content: center;
  align-items: flex-start;
  column-gap: 20px; */
  width: 70%;

  margin: auto;
}

.cards_head {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  color: #1c1c1c;
  margin: 0px;
}

.cards_artist {
  margin: 0px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #636365;
}

.offer_banner {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  column-gap: 130px;
  background-color: #f2f2f2;
  padding: 50px 0px;
}

/* 
.explore_side {
  width: 250px;
} */

.explore_side>h3 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 45px;
  text-transform: capitalize;
  color: #1c1c1c;
  margin: 0px;
}

.explore_side>p {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #636365;
  margin: 0px;
  opacity: 0.6;
  margin-bottom: 20px;
}

.offer_card {
  color: white;
  background-color: #1c1c1c;
  padding: 30px;
}

/* .price_shop {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* column-gap: 15px; 
  padding: 45px 0px;
} */

/* .price_explore {
  padding-top: 7%;
  padding-left: 10%;
  padding-bottom: 10%;
  width: 15%;
} */

.price_explore>h3 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 45px;
  text-transform: capitalize;
  color: #1c1c1c;
  margin: 0px;
} 
.remobtn {
  background: #1C1C1C;
  padding: 10px 25px;
  border: 1px solid #1C1C1C;
  border-radius: 10px;
  color: #fff;
  font-size: 17px;
  font-weight: 500;
  line-height: 26px;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  position: relative;
  overflow: hidden;
  transition: 0.3s;
  z-index: 99;
  display: flex;
    align-items: center;
    justify-content: center;
}
.remobtn::before {
  content: '';
  display: inline-block;
  width: 0;
  height: 100%;
  background-color: #fff;
  position: absolute;
  z-index: -1;
  transition: 0.3s;
  width: 0;
    height: 0;
    border-radius: 100px;
}
.remobtn:hover:before {
  width: 180px;
  height: 180px;
}
.remobtn:hover {
  color: #1C1C1C;
  background: transparent;
}

.price_explore>p {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #636365;
  margin: 0px;
  opacity: 0.6;
  margin-bottom: 20px;
  font-family: "Roboto", sans-serif;
}

.offer_card>div>h2 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 25px;
  line-height: 44px;
  text-transform: capitalize;
  margin: 0px;
}

.off {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 44px;
  text-transform: capitalize;
  color: #ffffff;
  margin: 0px;
  /* border: 0.958773px solid #FFFFFF; */
}

.off {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-weight: 400;
  font-size: 23px;
  line-height: 32px;
  text-transform: capitalize;
  color: #ffffff;
  margin: 0px;
  /* border: 0.958773px solid #FFFFFF; */
}

.off span {
  display: block;
  padding: 10px 0;
  font-weight: 700;
}

.offerimgsc {
  min-height: 170px;
  border: 1px solid #453939;
  display: flex;
  align-items: center;
  max-height: 170px;
  overflow: hidden;
  border-radius: 5px;
}

/* .offers_div {
  display: flex;
  justify-content: center;
  column-gap: 30px;
  align-items: center;
} */

.hero_main7 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px 0px 0px 0px;
  text-align: center;
}

.image_list {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  cursor: pointer;
}

.partner_content_div {
  width: 70%;
  /* margin: auto; */
  margin-top: 20px;
  margin-right: 11%;
}

.trending_card {
  width: 10%;
  position: relative;
  cursor: pointer;
}

.trending_img_div {
  height: 200px;
}

.type_div {
  background-color: white;
  padding: 10px;
  position: absolute;
  bottom: 0%;
}

.type_div>h4 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  text-transform: capitalize;
  color: #1c1c1c;
  margin: 0px;
}

.partners_card {
  width: 18%;
  /* margin: 0px 20px; */
  /* position: relative; */
  /* margin: 0px 10px; */
}

.partners_img_div {
  background: #fff;
  margin: 0 auto;
  text-align: center;
  border-radius: 10px;
  padding: 10px;
}

.subscribe_banner {
  display: flex;
  justify-content: center;
  column-gap: 10px;
  align-items: center;
  background-color: black;
  padding: 20px 10px;
}

.subscribe_head {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 50px;
  text-transform: capitalize;
  margin: 0px;
  color: #ffffff;
  width: 470px;
  margin: 0px 20px;
}

.headings {
  color: black;
}

.carousel__main {
  width: 70%;
  margin: auto;
}

.card__main {
  width: 25%;
}

.pic_info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.category_card {
  min-width: 18.1%;
  height: 300px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.category_img_div {
  height: 300px;
}

.category_text {
  /* background-color: white; */
  /* padding: 10px; */
  position: absolute;
  bottom: 8%;
  /* height: 20px; */
  width: 100%;
  margin: auto;
  /* align-self: center; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.category_text_child {
  background-color: white;
  margin: 0px auto;
  width: 85%;
  /* display: flex;
   */
  text-align: center;
}

.category_text_child>h4 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  margin: 10px 0px;
  text-align: center;
  text-transform: capitalize;
  color: #1c1c1c;
}

.feature_card {
  border: 1px solid rgba(99, 99, 101, 0.2);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.06);
  padding: 10px;
}

.feature_action {
  display: flex;
  justify-content: space-between;
}

.category_div {
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  align-items: center;
  column-gap: 20px;
  width: 80%;
  margin: auto;
  position: relative;
}

.left_btn {
  border: 1px solid black;
  border-radius: 50%;
  position: absolute;
  left: 0%;
  bottom: 0%;
}

.swipers {
  width: 70%;
  /* height: 200px; */
  margin: auto;
  margin-bottom: 20px;
  overflow: visible;
}

.mySwiper {
  padding: 0px 50px;

  overflow-x: visible;
}

.swiper_enter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rating_main {
  width: 85%;
  margin: auto;
  display: flex;
  min-height: 300px;
  justify-content: space-evenly;
  /* column-gap: 40px; */
  /* margin: 0px 30px; */
  align-items: center;
  margin-bottom: 20px;
}

.rating_div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 5px;
  margin: 0 auto 12px;
}

.rtpra {
  font-size: 13px;
  line-height: 16px;
  margin: 0 0 16px;
  display: block;
}

.rating_main>div>h2 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  line-height: 44px;
  text-transform: capitalize;
  margin: 0px;
}

.rating_main>div>p {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  text-transform: capitalize;
  margin: 0px;
}

.img_type {
  display: flex;
  align-items: center;
  column-gap: 5px;
  margin: 10px 0px;
}

.img_type>h3 {
  text-transform: capitalize;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #1C1C1C;
  margin: 0px;
}

.sticon {
  font-size: 19px;
  background: #00b67a;
  color: #fff;
}

.side_links {
  position: absolute;
  top: 170%;
  left: 0px;
  display: flex;
  flex-direction: column;
  column-gap: 0px;
  cursor: pointer;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 100px 0px;
}

.loaders {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px 0px;
}

.sec_main {
  padding: 50px 0;
}

.sec_title {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 45px;
  text-transform: capitalize;
  color: #1c1c1c;
  margin: 0px;
}

.sub_title {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  color: #636365;
  margin-bottom: 0;
}

.text_center {
  text-align: center;
}

.title_image {
  max-width: 110px;
}

.img_fluid {
  max-width: 100%;
  height: auto;
}

.extitle {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 45px;
  text-transform: capitalize;
  color: #1c1c1c;
}
.partnrs{
  background-color: #f2f2f2;
  padding: 50px 0px;
}
.titlsec{
  margin-bottom: 10px;
}
.btnmt2{
  text-align: center;
  margin-top: 30px;
}
.mtb2{
  text-align: center;
  margin-top: 20px;
}
@media(min-width:768px) and (max-width:991px) {
  .explore_side {
    text-align: center;
  }

  .off {
    text-align: center;
  }

  .off span {
    display: inline-block;
  }

  .price_explore {
    text-align: center;
  }
}

@media(max-width:767px) {
  .mtb2{
    margin-top: 0;
  }
  .mobcebter{
    text-align: center;
  }
  .rating_div{
    justify-content: center;
  }
  .img_type{
    margin: 0;
    justify-content: center;
  }
  .sub_title {
    font-size: 16px;
  }

  .sec_title {
    font-size: 23px;
    line-height: 26px;
  }

  .off span {
    display: inline;
  }

  .off {
    text-align: center;
    font-size: 20px;
    line-height: 24px;
  }

  .homepage {
    padding-top: 57px;
  }
  .banner>h2 {
    font-size: 23px;
        line-height: 26px;
  }
  .price_explore>h3 {
    font-size: 23px;
          line-height: 26px;
  }
  .explore_side>h3, .extitle {
    font-size: 23px;
            line-height: 26px;
  }
}

@media (max-width: 540px) {
  .offer_banner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    row-gap: 50px;
    background-color: #f2f2f2;
    padding: 50px 0px;
  }

  .landingpage__Text {
    margin-left: 30px;
  }

  .rating_main {
    width: 85%;
    margin: auto;
    display: flex;
    flex-direction: column;
    min-height: 300px;
    justify-content: space-evenly;
    /* column-gap: 40px; */
    /* margin: 0px 30px; */
    align-items: center;
    margin-bottom: 20px;
  }

  .subscribe_banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 10px;
    align-items: center;
    background-color: black;
    padding: 20px 10px;
  }

  .subscribe_head {
    width: 100%;
    margin: 0px 20px;
  }

  .trending_content_div {
    width: 95%;
    margin: auto;
    margin-top: 20px;
  }

  .price_explore {
    padding-bottom: 2%;
    text-align: center;
  }

  .explore_side {
    text-align: center;
  }

  .price_div {
    width: 100%;
    margin-top: 2%;
  }

  .price_shop {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 45px 0;
  }

  .side_links {
    position: absolute;
    top: 64%;
  }

  

  .price_explore {
    padding-left: 0;
  }
}

@media (max-width: 300px) {
  .side_links {
    position: absolute;
    top: 147%;
  }
}