.main_heading {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 22px;
  line-height: 25px;
  color: #1c1c1c;
  padding-bottom: 5px;
  margin-bottom: 0;
  text-transform: capitalize;
}
.rbent{    
  border-bottom: 1px solid #ced4da;
  margin-bottom: 30px;
}
.backbn{
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 600;
  padding-bottom: 10px;
  margin-bottom: 0;
  color: #665D6F;
  opacity: .8;
  text-decoration: none !important;
  cursor: pointer;
  display: block;
}
.odlist{
  color: #1C1C1C;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  margin-bottom: 10px;
}
.odlist span{
  color: #636365;
  opacity: .8;
}
.hrs {
  opacity: 0.3;
  border: 1px solid #665d6f;
}
.textfeild_color {
  color: red;
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  color: "red";
}
.details{
  margin-bottom: 15px;
}
.trd{
  cursor: pointer;
  color: #1c1c1c !important;
  opacity: 1 !important;
  text-decoration: underline;
}
.title {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: #1c1c1c;
  width: 150px;
  height: 40px;
}
.selector{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.content {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #636365;
}

.image_div {
  display: flex;
  column-gap: 10px;
  align-items: center;
  width: 115px;
  height: 115px;
  overflow: hidden;
}
.gallery_status {
  display: flex;
  justify-content: center;
  column-gap: 5px;
  align-items: center;
  
}
.gallery_status > h6 {
  margin: 0px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: #1c1c1c;
}
.ga_status {
  color: #ee0909 !important;
}
@media (max-width: 767px) {
  .selector {
    display: block;
  }
  .title {
    width: 99px;
  } 
  .image_div {
    display: flex;
    row-gap: 10px;
    padding: 0px 10px;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .gallery_status{
    justify-content: start;
  }
}
