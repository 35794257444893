.cancel {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #EB5757;
    min-width: 80px;
    display: inline-block;
}

.pending {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #F2C94C !important;
    min-width: 80px;
    display: inline-block;
}

.career_lastcol {
    display: flex;
    justify-content: space-between;
}

.curator_order {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.table_column {
    white-space: nowrap;
}

.column_gallery_artist_true {
    /* color:red; */
    white-space: nowrap;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.column_gallery_artist {
    color: red;
    white-space: nowrap;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.quoterequest {
    display: flex;
    column-gap: 20px;
    justify-content: center;
    align-items: center;
}

.rejected {
    color: #FF2222;
}

.loader_box {
    display: flex;
    justify-content: center;
    align-items: center;
}