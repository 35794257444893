.mains {
    background-color: #F6F6F6;
    padding: 20px;
    border-radius: 10px;
}

@media(max-width:600px) {
    .mains {
        width: 100%;
    }
}