.mains {
  width: 100%;
}
.table_column {
  font-family: "DM Sans", sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #636365 !important;
  white-space: nowrap !important;
  cursor: pointer !important;
}
.deleicon{
  color: #1C1C1C;
  font-size: 22px;
}