.headerCategory {
  width: 100%;
  background: url("./banner bg img.png");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  padding-top: 5%;
  padding-bottom: 5%;
  margin-top: 125px;
}
.pageitle{
  text-align: center;
}
.pageitle h1{
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  font-weight: 500;
  line-height: 1.235;
  letter-spacing: 0;
  margin-bottom: 10px;
  margin-top: 0;
}
.pageitle ul{
  padding: 0;
  list-style-type: none;
  margin: 0;
}
.pageitle ul li{
  display: inline-block;
  margin-top: 0;
}
.pageitle ul li:last-child{
  padding-left: 5px;
}
.pageitle ul li a{
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #14142B;
  text-decoration: none !important;
}
.formsec{
  padding: 50px 0;
}
.totalvalue__div {
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 3%;
}
.itemnm{
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #1C1C1C;
  text-transform: capitalize;
}
.iprisubt{
  font-family: "Roboto", sans-serif;
font-size: 16px;
font-weight: 600;
line-height: 24px;
text-align: right;
color: #1C1C1C;
}
.validation_error_color {
  color: red !important;
}
.headerSubTitle {
  margin-left: 0%;
}
.paymentmode__div {
  background-color: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8% 0%;
  width: 100%;
}
.paymentmode__grid {
  padding: 2% 22.5%;
  margin-left: -2.5%;
}
.state__zip {
  display: flex;
}
.checkout__grid {
  margin-top: 40px;
}
.subttl{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #111111;
  padding-bottom: 7px;
  margin-bottom: 15px;
}
.frdimns{
  font-family: "Roboto", sans-serif;
font-size: 14px;
font-weight: 400;
line-height: 19.53px;
text-align: left;
color: #1C1C1C;
opacity: .4;
margin: 0;
padding-top: 5px;
}
.fillbtn{ 
  width: 100%;
}
.fillbtn:hover{
  background: transparent;
  color: #000;
}
.coicon{
  color: #1C1C1C;
  font-size: 18px;
}
@media(max-width:767px){
  .headerCategory{
    margin-top: 57px;
  }
}
@media (max-width: 600px) {
  .headerCategory {
    margin-top: -12.5%;
  }
}
