.buyer_main { 
    background-color: #f6f6f6;
    border-radius: 10px;
    padding-bottom: 20px;
}

.buyer_header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    /* margin:10px 30px; */
    padding: 20px 30px 20px 30px;
}

.header_action {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.search_input {
    /* margin: 20px 0px 20px 30px; */
    width: 20%;
    border: 0.6px solid #665D6F;
    border-radius: 6px;
    height: 37px;
    background-color: #F6F6F6;
    padding: 0px 15px;
}

.search_image {
    position: relative;
    left: -33px;
    top: 6px;

}

.table_head {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #1C1C1C;
}

.table_column {
    color: #636365;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    white-space: nowrap;
    cursor: pointer;
    /* max-width: 40px; */
}

.table_columns {
    color: #636365;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    white-space: nowrap;
    cursor: pointer;
    display: flex;
    /* height: 100px; */
    /* max-width: 40px; */
}



.pagination_box {
    display: flex;
    justify-content: flex-end;
    background-color: #F6F6F6;
    padding-top: 25px;
    padding-right: 25px;
    /* margin-bottom: 20px; */
  }
  
  .pagination_box>div {
    margin-bottom: 25px;
  }

.pagination_btn {
    background-color: #FFFFFF;
    width: 20px;
    border: 0.841897px solid black;
    border-radius: 4.20949px;
    color: black;
    font-family: "Roboto", sans-serif;
    border: 1px solid rgba(255, 0, 0, .5);
    -webkit-background-clip: padding-box;
    /* for Safari */
    background-clip: padding-box;
}


.header_action_filter {
    margin: 5px 16px;

}

.header_action_btn {
    margin: 5px 8px;
}

.page_btn {
    height: 29.47px;
    width: 35px;
}

.more_categories {
    margin-left: 20px;
    display: flex;
    justify-content: space-between;
}
.dowcsv{
    text-align: right;
}

.more_categories_btn_active {
    background-color: black;
}

.loader_box {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media(max-width:520px) {
    .search_image {
        position: relative;
        left: 279px;
        top: -32px;

    }

    .buyer_header {
        display: flex;
        flex-wrap: wrap;
        /* flex-direction: column; */
        justify-content: flex-end;
        align-items: center;
        /* margin-left: 30px; */
        padding: 20px 0px;
    }
    .header_action_filter {
        margin: 8px 0px;
        /* width:50% */
    }

    .header_action_btn {
        margin: 2px 0px;
    }

    .page_btn {
        height: 20px;
        width: 10px;
    }

    .more_categories {
        /* width: 100%; */
        /* margin: 0px 0px; */
        /* padding: 0px 10px; */
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .search_input {
        align-self: center;
        /* width: 100%; */
        border: 0.6px solid #665D6F;
        border-radius: 6px;
        height: 44px;
        background-color: #F6F6F6;
        padding: 0px 30px;
    }
}

@media(max-width:767px) {
    .search_image {
        position: relative;
        left: 279px;
        top: -32px;

    }

    .buyer_header {
        display: flex;
        flex-wrap: wrap;
        /* flex-direction: column; */
        justify-content: space-between;
        align-items: center;
        /* margin-left: 30px; */
        padding: 20px 10px;
    }

    .search_input {
        /* margin: 20px 0px 20px 30px; */
        align-self: center;
        /* width: 50%; */
        width: 100%;
        border: 0.6px solid #665D6F;
        border-radius: 6px;
        height: 44px;
        background-color: #F6F6F6;
        padding: 0px 30px;
    }

    .header_action {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: -10px;
        flex-wrap: wrap;
        width: 100%;

    }

    .more_categories {
        margin: auto auto;
        padding: 10px 10px;
        flex-direction: column-reverse;
    }
}