
.modalBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30%;
    background-color: #fff;
    border-radius: 10px;
    padding: 30px; 
  }
  .mpophndg{
font-family: "Roboto", sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    text-align: center;
  }
  .clobtn{
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .clobtn a{
    cursor: pointer;
  }
  .iconru{
    font-size: 30px;
    color: #1C1C1C;
  }
  .actionc{
    text-align: center;
  }
  .mains{
    width: 100%;
    background-color: #f6f6f6;
    padding-bottom: 20px;
    border-radius: 10px;
}
.invite_btn{
    display: flex;
    justify-content: end;
    padding-right: 20px;
    margin-bottom: 10px;
    width: 100%;
}
.mpophndg{
font-family: "Roboto", sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    text-align: center;
    padding-bottom: 20px;
    text-transform: capitalize;
  }
.pagination_box {
    display: flex;
    justify-content: flex-end;
    background-color: #F6F6F6;
    padding-top: 25px;
    padding-right: 25px;
    /* margin-bottom: 20px; */
}

.pagination_box>div {
    margin-bottom: 25px;
}

.page_btn {
    height: 29.47px;
    width: 35px;
    margin-left: 8px;
    min-width: 20px;
    height: 29.47px;
    border: 0.841897px solid #d5d5d5;
    box-shadow: none;
}
.actionc{
    text-align: center;
}
 
@media(min-width:993px) and (max-width:1025px){
    .modalBox {
      width: 60%;
    }
   
  }
  
  @media (min-width: 768px) and (max-width:992px) {
    .modalBox {
      width: 60%;
    }
   
  }
  @media (max-width: 767px) {
    .modalBox {
      width: 95%;
    }
    .invite_btn{
        padding-right: 10px;
    }
  }