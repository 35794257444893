.mains {
    background-color: #f6f6f6;
    width: 100%;
    padding-bottom: 25px;
    border-radius: 6px;
}


@media(max-width:600px) {
    .mains {
        width: 100%;
    }
}

.pagination_box {
    display: flex;
    justify-content: flex-end;
    background-color: #F6F6F6;
    padding-top: 25px;
    padding-right: 25px;
   
}