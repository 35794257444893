.mains{
    width:69%;
}
.components{
    background-color: #f6f6f6;
}

@media(max-width:600px){
    .mains{
        width:100%;
    }
}