.cancel_img {
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
    margin-top: -24px;
}

.cancel_imgweb {
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
    margin-top: -64px;
}

.cancel_img_hide_web {
    visibility: hide;
    display: none;
    justify-content: flex-end;
    margin-right: 10px;
    /* margin-top:-64px; */
}

.cancel_img_hide {
    visibility: hide;
    display: none;
    justify-content: flex-end;
    margin-right: 10px;
    margin-top: -24px;
}

.card {
    /* height: 350px; */
    width: 350px;
    background-color: #f6f6f6;
}

.card_content {
    border: none;
    outline: none;
    display: flex;
    flex-direction: column;
    flex: 1 1;
    justify-content: space-between;
}
.icdvn{
    margin: 0 auto;
    text-align: center;
    
}
.ml_auto{
    margin:  0 auto;
    text-align: center;
}
.radius_image {
    width: 100px;
    height: 100px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card_content h3 {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 25px;
    line-height: 33px;
    text-transform: capitalize;
    margin: 0;
    text-align: center;
    margin-bottom: 0px;
    margin-top: 15px;
}

.card_content h6 {
    text-align: center;
font-family: "Roboto", sans-serif;
    font-size: 17px;
    font-weight: 400;
    line-height: 26px;
    color: #636365;
    opacity: .8;
}

@media(max-width:600px) {
}