
.components {
    background-color: #f6f6f6;
    border-radius: 10px;
}

.table_header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px 20px;
}

@media(max-width:600px) {
    .mains {
        width: 100%;
    }
}