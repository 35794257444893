.components {
 background-color: #f6f6f6;
 border-radius: 10px;
 padding: 20px;
}

.card_title {
 font-family: "Roboto", sans-serif;
 font-style: normal;
 font-weight: 500;
 font-size: 18px;
 line-height: 23px;
 color: #1c1c1c;
 margin: 0px 0px 5px 0px;
}

.card_div {
 width: 55%;
 margin: 0 auto; 
}

.card {
 background-color: white;
 width: 100%;
 height: 45px;
 padding: 0px 18px;
 display: flex;
 align-items: center;
 border-radius: 10px;
 font-family: "Roboto", sans-serif;
 font-style: normal;
 font-weight: 400;
 font-size: 16px;
 line-height: 21px;
 letter-spacing: 0.1em;
 color: #636365;
 border: none;
}

.forgot_pass {
 display: flex;
 justify-content: flex-end;
 width: 64%;
 margin: 10px auto;
 padding: 0px;
 font-family: "Roboto", sans-serif;
 font-style: normal;
 font-weight: 400;
 font-size: 16px;
 line-height: 21px;
 color: #636365;
 /* margin:10px 0px; */
}

.forgot_pass > div {
 cursor: pointer;
}

.save_btn {
 display: flex;
 justify-content: center;
 border-radius: 10px;
}
.schntext{
    text-align: right;
    color: #636365;
    opacity: .8;
    font-size: 16px;
    cursor: pointer;
    display: flex
;
    justify-content: end;
}
@media (max-width: 767px) {
 .mains {
  width: 100%;
 }

 .card_div {
  width: 100%;
 }

 .forgot_pass {
  margin-right: 10px;
 }
}
