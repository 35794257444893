.components{
    background-color: #f6f6f6;
    border-radius: 10px;
    padding-bottom: 20px;
}

.pagination_box {
    display: flex;
    justify-content: flex-end;
    background-color: #F6F6F6;
    padding-top: 25px;
    padding-right: 25px;
    /* margin-bottom: 20px; */
  }
  
.pagination_box>div {
    margin-bottom: 25px;
  }



@media(max-width:600px){
    .mains{
        width: 100%;
    }
}