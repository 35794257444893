.modal_del {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #FFFFFF;
    box-shadow: none;
    border-radius: 10px;
    border: none;
    padding:30px;
    width:35%;
    max-height: 90%;
    overflow-y: auto;
}

.main_detail {
    display: flex;
    column-gap: 20px;
    align-items: flex-start;
    width:100%;
}
.main_detail>div{
    width: 100%;
}

 

.details {
    display: flex;
    column-gap: 20px;
    align-items: center;
    margin: 10px 0px;
}

.title {
    white-space: nowrap;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #1C1C1C;
    margin: 0px 0px;
}

.details>p {
    white-space: nowrap;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #636365;
    margin: 0px;
}
.description_div{
    margin-top:20px;
}
.description_div>h3{
    margin-bottom: 10px;
}
.description {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #665D6F;
    margin: 0px;
}

.btn_div {
    display: flex;
    justify-content: center;
    column-gap:20px;
    margin: 20px 0px 0;
    align-items: center;
}

.input_label {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #1C1C1C;
    margin-bottom:10px;
}
.odlist{
    color: #1C1C1C;
font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    margin-bottom: 10px;
}
.odlist span{
    color: #636365;
    opacity: .8;
}
.action_btn{
    padding: 10px 25px;
  border-radius: 10px;
  background: #000;
  border: 1px solid #000;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  cursor: pointer;
}
.action_btn:hover{
    background: transparent;
    color: #000;
}

.rejected {
    color: #FF2222;
    text-decoration: underline;
  }
.accepted {
    color: #2FAC4B;
    text-decoration: underline;
  }


.actiwht_btn{
    padding: 10px 25px;
    border-radius: 10px;
    background: #fff;
    border: 1px solid #000;
    color: #000;
font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    cursor: pointer;
}
.actiwht_btn:hover{
    background: #000;
    color: #fff;
}
@media(min-width:993px) and (max-width:1025px){
    .modal_del{
      width: 50%;
    }
  }
@media(min-width:768px) and (max-width:992px){
    .modal_del {
        width: 75%;
    }
}
@media(max-width:767px) {
    .modal_del {
        width: 95%;
    }
    .main_detail {
        display: flex;
        flex-direction: column;
        column-gap: 20px;
        align-items: flex-start;
        width:100%;
    }
}