 
.upload_pic_div {
  height: 200px;
  width: 200px;
  border-radius: 10px;
  margin: 0 auto;
}

.components {
  padding: 20px 20px;
  background-color: #f6f6f6;
}
.inputs_div_margin {
  margin-bottom: 5px;
}
.input_label {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  color: #1c1c1c;
}
.new_div_inputs {
  display: flex;
  align-items: center;
  /* justify-content: cente; */
  column-gap: 10px;
}
.new_div_inputs > h2 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 33px;
  color: #1c1c1c;
  white-space: nowrap;
}
.hrs {
  width: 100%;
  opacity: 0.3;
  border: 1px solid #665d6f;
}
.btn_div {
  text-align: center;
}
.aply_btn{
  padding: 10px 35px;
    border-radius: 10px;
    background: #1c1c1c;
    border: 1px solid #1c1c1c;
    color: #fff;
font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    cursor: pointer;
    text-decoration: none !important;
    display: inline-block;
}
.aply_btn:hover{
  background: #fff;
  color: #1c1c1c;
}
.cancl_btn{
  padding: 10px 35px;
    border-radius: 10px;
    background: #fff;
    border: 1px solid #1c1c1c;
    color: #1c1c1c;
font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    cursor: pointer;
    text-decoration: none !important;
    display: inline-block;
}
.cancl_btn:hover{
  background: #1c1c1c;
  color: #fff;
}
.main_divs {
  margin-bottom: 10px;
}
.inputs {
  width: 80%;
  padding: 0;
}
.textright{
  text-align: right;
  padding-bottom: 20px;
}
.forgot{
  text-decoration: none !important;
  color:#636365;
  cursor: pointer;
}
.choose_btn{
  padding: 10px 35px;
    border-radius: 10px;
    background: #DCDCDC;
    border: 1px solid #DCDCDC;
    color: #1c1c1c;
font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    cursor: pointer;
    text-decoration: none !important;
    display: inline-block;
}
.choose_btn:hover{
  background: #fff;
  color: #1c1c1c;
}
.btn_divb{
  text-align: center;
  margin-top: 15px;
}
.closicon{
  font-size: 28px;
    color: #1C1C1C;
    cursor: pointer;
}
.editicon {
  display: flex;
  flex-direction: row-reverse;
}
@media (min-width:768px) and (max-width:991px){
  .upload_pic_div{
    height: auto;
    width: auto;
  }
}
@media (max-width: 767px) {
  .mains {
    width: 100%;
  }
  .inputs {
    width: 100%;
  }
  
}
