.mains {
  width: 100%;
  background-color: #1C1C1C;
  position: fixed;
  z-index: 200;
  padding: 12px 0;
}

/* .header_main {
  min-height: 50px;
  color: #fff;
} */
.content_divider {
  height: 22px;
  border: 1px solid #666666;
  margin: 0px 15px;
  align-self: center;
}

.con_langu {
  /* padding-top: 10px;
  padding-bottom: 10px; */
}

.tophercont {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex_first ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  align-items: center;
  margin: 0;
  height: 21px;
}

.flex_first ul li {
  margin-top: 0;
  position: relative;
}

.flex_first ul li:first-child {
  padding-right: 10px;
  margin-right: 10px;
}

.flex_first ul li:first-child::after {
  content: '';
  width: 1.3px;
  height: 95%;
  background: #666666;
  position: absolute;
  right: 0;
  top: 1px;
  font-size: 23px;
}

.flex_first ul li a {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.coninfo a {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #fff;
  text-decoration: none;
}

.iconse {
  font-size: 19px;
  padding-right: 4px;
}

.signup_btn {
  cursor: pointer;
  white-space: nowrap;
}

.flex_first>div {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.flex_first>div>p {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
}

.flex_second ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  align-items: center;
  margin: 0;
  height: 21px;
}

.flex_second ul li {
  margin-top: 0;
  position: relative;
  padding-right: 10px;
  margin-right: 10px;
}

.flex_second ul li:last-child {
  padding-right: 0px;
  margin-right: 0px;
}

.flex_second ul li::after {
  content: '';
  width: 1.3px;
  height: 95%;
  background: #666666;
  position: absolute;
  right: 0;
  top: 1px;
  font-size: 23px;
}

.flex_second ul li:last-child::after {
  display: none;
}

.flex_second ul li a {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.wishic {
  height: 20.6px;
}

.flex_second>div {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.flex_second>div>p {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 0;
  margin-top: 0;
}

.heart_img_div {
  align-self: center;
}

.langslect {
  display: flex;
  align-items: center;
}

@media(max-width:991px) {
  .flex_second ul li:nth-child(2) {
    display: none;
  }
}

@media (max-width: 700px) {
  .mains {
    display: none;
  }
}