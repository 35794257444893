.mains {
  background-color: #F6F6F6;
  padding: 20px;
  border-radius: 10px;
   }
  
.mains1 {
  width: auto;
}

.top_div {
  padding: 2% 2% 2% 4%;
}

.top_div > h1 {
  margin: 0px;
  padding: 0px;
}
.back_button {
  margin-top: 50px;
  margin-bottom: 20px;
}
.del_img {
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
  margin: 10px 0px;
  height: 20px;
  padding: 0px 10px;
  margin: 0px;
  margin-top: -20px;
}

.detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0% 4%;
  /* padding: 0%; */
  margin: 0%;
}

.detail_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0%;
  /* margin: 5% 0%; */
}

.title {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #1c1c1c;
  padding: 0%;
  margin: 0%;
  margin-right: 30px;
  /* min-width: 30%; */
}

.content {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-align: right;
  color: #636365;
  /* padding: 5% 0%; */
  margin: 0% 0%;
}

.detail_earning {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1% 4% 3% 4%;
  width: 50%;
}

@media (max-width: 600px) {
  .mains {
    width: 100%;
  }
  .mains1 {
    width: 100%;
  }
  .detail {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0% 2%;
    margin: 0%;
  }
  .detail_content_div {
    width: 100%;
  }
  .detail_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0%;
    /* margin: 5% 0%; */
    width: 100%;
  }

  .detail_earning {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    padding: 1% 2% 3% 2%;
    width: auto;
  }
  .top_div {
    padding: 2% 2% 2% 2%;
  }
  .del_img {
    margin-top: -29px;
    padding: 0px 5px;
  }
}
