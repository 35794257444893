.components{
    background-color: #f6f6f6;
    border-radius: 10px;
    padding-bottom: 20px;
}
.downloadcv_btn{
    display: flex;
    justify-content: flex-end;
    margin-right: 30px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 18px;
    /* max-width: 156px; */
}
.download_btn{
}

@media(max-width:600px){
    .mains{
        width:100%;
    }
    .downloadcv_btn{
        display: flex;
        justify-content: flex-end;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: #FFFFFF;
        margin: 10px 0px;
        margin-right: 10px;
    }
}