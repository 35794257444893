.loader_box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.end_col_auction {
  display: flex;
  justify-content: space-between;
}
.end_col_auction {
  display: flex;
  /* color: ; */
  justify-content: space-around;
}
.buyer_flex {
  display: flex;
  justify-content: space-between;
}
.career_lastcol {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.action {
  display: flex;
  align-items: center;
  gap: 5px;
}
.action img {
  cursor: pointer;
}
.editicon{
  color: #665D6F;
  font-size: 22px;
}
.deleicon{
  color: #1C1C1C;
  font-size: 22px;
}