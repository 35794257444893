.mains {
  width: 69%;
  margin-bottom: 20px;
}

.components {
  background-color: #f6f6f6;
}
.moreoption_div {
  display: flex;
  justify-content: space-between;
  margin-right: 30px;
}
@media (max-width: 600px) {
  .mains {
    width: 100%;
  }
  .moreoption_div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* margin-right: 30px; */
  }
  .btn_div {
    /* width: 100%; */
    /* height: 44px; */
    padding: 0px 10px;
  }
  .moreoption_div {
    margin: 0px 0px;
    padding: 0px 0px;
  }
}
