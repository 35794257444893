.mains {
  background-color: #F6F6F6;
  padding: 20px;
  border-radius: 10px;
}

.top_div {
  padding: 2% 2% 2% 4%;
}

.top_div > h1 {
  margin: 0px;
  padding: 0px;
}
.back_button {
  border-bottom: 1px solid #ced4da;
  margin-bottom: 30px;
}
.back_button a{
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 10px;
  margin-bottom: 0;
  color: #665D6F !important;
  opacity: .8;
  text-decoration: none !important;
  cursor: pointer;
  display: block;
} 
.del_img {
  text-align: right;
}
.bsrd{
  padding-bottom: 30px;
  border-bottom: 1px solid #ced4da; 
  margin-bottom: 30px;
}
.arndng{
  font-weight: 400;
    font-size: 22px;
    line-height: 27px;
    color: #1c1c1c;
    text-align: left;
    text-transform: capitalize;
    margin-bottom: 0px;
    font-family: "Roboto", sans-serif;
}
.odlist{
  color: #1C1C1C;
font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    margin-bottom: 10px;
    display: flex;
}
.odlist span{
  max-width: 20%;
  width: 20%;
  display: block;
}
.odlist span + span{
  color: #636365;
  opacity: .8;
  max-width: initial;
  width: 80%;
}
.delicon{
  color: #1C1C1C;
  font-size: 25px;
}
.mainsvb{
  margin-top: 20px;
}
.detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0% 4%;
  /* padding: 0%; */
  margin: 0%;
}

.detail_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0%;
  /* margin: 5% 0%; */
}

.title {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #1c1c1c;
  padding: 0%;
  margin: 0%;
  margin-right: 30px;
  /* min-width: 30%; */
}

.content {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 0px;
  text-align: right;
  color: #636365;
  padding: 0% 0% !important;
  margin: 0% 0% !important;
}

.detail_earning {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1% 4% 1% 4%;
  width: 60%;
}
@media (min-width: 768px) and (max-width: 992px) {
  .odlist span{
    max-width: 40%;
    width: 40%;
  }
  .odlist span + span{
    max-width: 60%;
    width: 60%;
  }
}
@media (max-width: 767px) {
  .odlist{
    display: block;
  }
  .odlist span,  .odlist span + span{
    max-width: 100%;
    width: 100%;
  }
  .detail {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0% 2%;
    margin: 0%;
  }
  .detail_content_div {
    width: 100%;
  }
  .detail_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0%;
    /* margin: 5% 0%; */
    width: 100%;
  }

  .detail_earning {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1% 2% 3% 2%;
    width: auto;
  }
  .top_div {
    padding: 2% 2% 2% 2%;
  }
  .del_img {
    margin-top: -29px;
    padding: 0px 5px;
  }
}