.asterisk{
  color: red;
}

.modal_exhibition {
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  width: 30%; 
  padding: 30px;
  overflow-y: auto;
  max-height: 90%;
}
.mpophndg{
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  text-align: center; 
  text-transform: capitalize;
}
.clobtn{
  position: absolute;
  right: 10px;
  top: 10px;
}
.clobtn a{
  cursor: pointer;
}
.iconru{
  font-size: 30px;
  color: #1C1C1C;
}
.action_btn{
  padding: 10px 25px;
  border-radius: 10px;
  background: #000;
  border: 1px solid #000;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  cursor: pointer;
}
.action_btn:hover{
  background: transparent;
  color: #000;
}
.modal_campaigns {
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  width: 30%; 
  padding: 30px;
  overflow-y: auto;
  max-height: 90%;
}
.actionc{
  text-align: center;
} 
.cancel_img {
  text-align: right;
  margin-right: -5px;
  margin-top: 10px;
}

.cancel_img > img {
  cursor: pointer;
}

.modal_heading {
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 34px;
  text-align: center;
  color: #1c1c1c;
  margin: 0px;
}

.textarea_div {
  width: 99%;
}

.textarea_div > label {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;

  /* Black */

  color: #1c1c1c;
}

.textarea_div > input {
  margin: 25px 0px;
}

.textarea {
  min-width: 100%;
  border: none;
  background: #f3f3f3;
  border-radius: 6px;
  /* padding-left: 10px; */
  margin-top: 8px;
}

.textarea_label {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;

  /* Black */

  color: #1c1c1c;
}

.inputs_div {
  border: 1px solid red;
}

.input_title {
  width: 100%;
  margin: 15px 0px;
}

.input_title > label {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #1c1c1c;
}

/* .input_title>input {
    height: 40px;
    padding-left: 10px;
    background: #F3F3F3;
    border-radius: 6px;
    border: none;
    margin: 5px 0px;
} */

.radio_active {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #636365;
}

.radio_notactive {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #636365;
  opacity: 0.5;
  border: none;
}
 

.action {
  text-align: center; 

}
.action_career {
  text-align: center;
  margin: 90px 0px 0;
} 

.inputs_gird {
  margin: 0px 0px;
}

.inputs_gird > label {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #1c1c1c;
}

.inputs_gird > input {
  background: #f3f3f3;
  height: 40px;
  padding-left: 10px;
  border-radius: 6px;
  border: none;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #636365;
  width: 92%;
  margin: 5px 0px 0px 0px;
}

.preview_img {
  width: 100%;
  background-color: #f3f3f3;
  margin: auto;
  height: 150px;
  border-radius: 5px;
  /* opacity: 0.5; */
  border: none;
  margin-top: 10px;
}

.img_box {
  width: 100%;
  height: 100%;
  background-color: #f3f3f3;
  /* background-color: white; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* opacity: 0.5; */
  border-radius: 5px;
  position: relative;
}

.label {
  /* margin: 5px 0px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.label > p {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #665d6f;
  margin: 5px 0px;
  /* opacity: 0.5; */
}

.lebless {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #1c1c1c;
}

.img_view {
  width: 100%;
  height: 150px;

}

.img_view_hide {
  border: none;
  width: 100%;
  background-color: #f3f3f3;
}

.image_div {
  width: 46px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.textarea_div {
  margin-top: 15px;
}
.img_main {
  margin-top: 15px;
}

.textarea {
  width: 96%;
  min-width: 96%;
  max-width: 96%;
  max-height: 100px;
  background-color: #f3f3f3;
  color: #636365;
  outline: none;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #636365;
  border: none;
  padding: 2%;
  /* margin-top: 8px; */
}
.textarea:focus {
  outline: none !important;
}

.textarea_blog {
  min-width: 100%;
  height: 150px;
  border: none;
  border-radius: 6px;
  margin-top: 8px;
}
.customEditor {
  height: 150px;
  .ql-editor {
    min-height: 250px;
  }
}
@media(min-width:993px) and (max-width:1025px){
  .modal_exhibition, .modal_campaigns{
    width: 50%;
  }
}
@media(min-width:768px) and (max-width:992px){
  .modal_exhibition {
    width: 60%;
  }
  .modal_campaigns {
    width: 60%;
  }
}
@media (max-width: 767px) {
  .modal_exhibition, .modal_campaigns {
    width: 95%; 
  }
}
