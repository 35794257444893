.components {
    background-color: #F6F6F6;
    border-radius: 10px;
    padding-bottom: 20px;
}

@media(max-width:600px) {
    .mains {
        width: 100%;
    }
    .tableheader{
        margin-bottom: 8px;
    }
}