.modal_del {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #FFFFFF;
    background-color: white;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    font-weight: 700;
    font-size: 35px;
    color: #1C1C1C;
    border: none;
    padding:10px 10px;
    width: 500px;
}

.card_main>h2 {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 34px;
    text-align: center;
    text-transform: capitalize;
    color: #1C1C1C;

}

.cancel_img {
    display: flex;
    justify-content: flex-end;
    /* margin-right: 10px; */
    /* margin-top: -24px; */
}

.cancel_img>img {
    cursor: pointer;
}
.radios_div{
    /* margin:auto; */
    display: flex;
    justify-content: center;
}
.radio_group{
    /* width:max-content; */
    display: flex;
    /* justify-content:center; */
    align-items: center;
}
.radio_des{
    
font-family: "Roboto", sans-serif;
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 23px;

/* Secondary */
margin:0px;

color: #636365;

}
.btn_div{
    display: flex;
    justify-content: center;
    align-items: center;
}

@media(max-width:600px) {
    .modal_del {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #FFFFFF;
        background-color: white;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        font-weight: 700;
        font-size: 35px;
        color: #1C1C1C;
        max-height: 90%;
        overflow-x: scroll;
        border: none;
        width: 95%;
    }

    .card_main {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }
}