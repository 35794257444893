.header {
  background: url("Rectangle 5372.png");
  background-size: cover;
  margin-top: 125px;
  padding: 50px 0;
}
.pageitle{
  text-align: center;
}
.pageitle h1{
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  font-weight: 500;
  line-height: 1.235;
  letter-spacing: 0;
  margin-bottom: 10px;
  margin-top: 0;
  color: #fff;
}
.pageitle ul{
  padding: 0;
  list-style-type: none;
  margin: 0;
}
.pageitle ul li{
  display: inline-block;
  margin-top: 0;
  color: #fff;
}
.pageitle ul li:last-child{
  padding-left: 5px;
}
.pageitle ul li a{
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #fff;
  text-decoration: none !important;
}

.bold {
  font-weight: bold;
  text-decoration: none;
  color: white;
}

.verticalLine {
  border-left: 2px solid #aeaeaf;
  height: 10vw;
  margin-right: 5%;
}

.artist__imgDiv {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}

.member__benefits {
  background-color: #f7f7f8;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
}

.artistImg {
  max-width: 100%;
  height: auto;
}

.imgcontainer {
  width: 140px;
  height: 140px;
  border: 9px solid rgb(196 196 196 / 26%);
  border-radius: 50%;
  margin: 0 auto;
}
.benefit__text{
  border-left: 2px solid #e2e2e2;
  padding-left: 15px;
}
.benefit__text h3{
  font-family: "Roboto", sans-serif;
    font-size: 30px;
    font-weight: 400;
    line-height: 38px;
}
.benefit__text p{
  color: #636365;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
}
.benefits__grid {
  width: 72.5%;
  margin-top: 3.5%;
  margin-bottom: 3.5%;
  margin-left: 20%;
}
.sec_sc{
  padding: 50px 0;
}
.info__div h3{
  font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 35px;
    text-transform: capitalize;
    color: #1c1c1c;
    margin: 0px;
    margin-bottom: 8px;
}
.info__div ul li{
  font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    text-align: left;
    color: #636365;
    opacity: .8;
    margin: 0;
    margin-bottom: 10px;
}
.apply_btn{
  padding: 10px 35px;
  border-radius: 10px;
  background:  #1c1c1c;
  border: 1px solid  #1c1c1c;
  color: #fff !important;
  font-family: DM Sans !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 26px !important;
  cursor: pointer; 
  text-decoration: none !important;
  display: block;
}
.apply_btn:hover{
  background: #fff;
  color:  #1c1c1c !important;
}
.divider {
  color: #636365;
}

.leftImage {
  width: 60%;
  margin-right: 2%;
}

.leftcard {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.curatorcards {
  margin-top: 1.5%;
}

.cardInfo__right {
  margin-left: 3%;
  /* margin-top: 1%; */
}

.logos_flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 2%;
}

.loc__logo {
  width: 3%;
}

.rightcard {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 7%;
}

.rightImage {
  width: 50%;
  margin-left: 15%;
}

.main__content {
  padding: 2% 18.5%;
}


li {
  color: #636365;
  margin-top: 2%;
}

.image__flex {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.rightImages {
  width: 96%;
  margin-bottom: 3%;
}

@media (max-width: 767px) {
  .header {
    margin-top: 57px;
  }
  .benefit__text{
    padding-left: 0;
    border-left: 0;
  }
  .verticalLine {
    display: none;
  }

  .benefits__grid {
    margin: none;
    margin-right: 12%;
  }

  .leftcard {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    flex-wrap: wrap;
  }

  .rightcard {
    display: flex;
    flex-direction: column-reverse;
    align-items: baseline;
    flex-wrap: wrap;
    margin-top: 7%;
  }

  .cardInfo__right {
    margin-left: 0;
    /* margin-top: 1%; */
  }

  .info__div {
    text-align: justify;
  }
}