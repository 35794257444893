

.detail_id {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 15px 20px 5px 20px;
}

.profiler_name {
    padding: 15px 20px 5px 20px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 46px;
    color: #1C1C1C;
}

.detail_id>h1 {
    margin: 0px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #1C1C1C;
}

.detail_id>p {
    margin: 0px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    text-align: right;
    color: #636365;
    text-align: right;
}

.status {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 25px 10px 15px;
}

.status>h1 {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 39px;
    color: #1C1C1C;
    margin: 0px;
}
.action_button{
    display: flex;
    justify-content: space-between;
    column-gap: 15px;
}
/* 
.status>p {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 29px;
    text-align: right;
    color: #636365;
    margin: 0px;

} */


.more_detail {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 50px 25px 40px 15px;
}

.more_detail_child {
    display: flex;
    /* justify-content: space-between; */
    align-items: flex-start;
    margin-top: 10px;
}

.title {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #1C1C1C;
    min-width: 150px;
}

.content {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #636365;
}

.action_btn {
    width: 156px;
    height: 54px;
    border-radius: 10px;
}

@media(max-width:600px) {

    .more_detail {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        column-gap: 10px;
        padding: 0px 5px 40px 5px;
    }.status {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        justify-content: space-between;
        align-items: stretch;
        padding: 10px 25px 10px 15px;
    }

    /* .action {
        width: 100%;
        margin: 10px 0px;
    } */

    /* .action_btn {
        width: 100%;
        height: 54px;
        border-radius: 10px;
    } */
}