.mains {
    padding: 0px 0px 0px 20px;
}
.more_categories_btn {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 12px;
    text-align: center;
    color: #1C1C1C;
    width: 151px;
}

.more_categories_btn_notactive {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 12px;
    text-align: center;
    text-transform: capitalize;
    color: #FFFFFF;
}

@media(max-width:767px) {
    .mains {
        display: flex;
        flex-wrap: wrap;
        padding: 0px 10px;
    }
}