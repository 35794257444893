.sidebar_main {
  color: black;
  background-color: #665d6f;
 
  box-shadow: 0px 0px 6.86721px rgba(0, 0, 0, 0.1);
  border-radius: 4.12032px;
 
  /* height: 1522.46px; */
  /* padding: 30px 0px; */
  /* margin: 20px 50px; */
}

.sidebar_main > h2 {
  padding: 0px 0px;
  margin: 0px 26px;
  font-family: "Roboto", sans-serif; 
  font-weight: 500;
  text-transform: capitalize;
  font-size: 16px;
  line-height: 24px;
}

.sidebar_main > div {
  background-color: white;
  margin: 0px 8px 0px 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  padding: 10px 0px;
}

.sidebar_main > div > Button {
  /* background: #1C1C1C; */
  height: 41.2px;
  border-radius: 2.06016px 0px 0px 2.06016px;
}

.demo {
  background-color: #f6f6f6;
  color: #1C1C1C;
  /* opacity: 0.71; */
  /* border: 0.549377px solid #1C1C1C; */
  box-shadow: 0px 0px 6.86721px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
   padding: 20px 0;
}

.demo_child_div {
  display: flex;
  flex-direction: column;
}

.demo > h2 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 1.8px;
  margin-top: 1.8px;
  padding: 6px 16px;
}

.flex_start {
  min-width: 3px;
  background-color: #1c1c1c;
  border-radius: 6.86721px;
}

.button_div_side {
  width: 3px;
  height: 44px;
}

.flex_btn {
  /* background-color: #1C1C1C; */
  width: 172.28px;
  height: 41.2px;
  margin: -4px 0px 0px 0px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* padding:-2px 0px; */
} 
@media (min-width: 768px) and (max-width: 1025px) {

}
@media (max-width: 767px) {
  .mobileDropdown {
    display: block;
    margin-bottom: 0px;
  }
  .sidebarContent {
    display: none;
  }
  .sidebarContent.open {
    display: block;
  }
  .sidebarContent.closed {
    display: none;
  }
  .demo {
    padding: 10px;
  }
  .demo > h2{
    margin-right: 0;
    margin-left: 0;
  }
  .demo .mobileDropdown button{
    width: 100%;
    justify-content: space-between;
  }
}

@media (min-width: 768px) {
  .mobileDropdown {
    display: none;
  }
  .sidebarContent {
    display: block;
  }
}
.submenuContainer {
  overflow: hidden; 
  height: 0;
  opacity: 0;
  transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.submenuOpen {
  /* height: 100px; */
  opacity: 1;
  transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out;
}



