

.end_col_auction{
    display: flex;
    justify-content: space-between;
}
.hyperlink_title{
    text-decoration: underline;
    color: #636365;
  }
.buyer_flex{
    display: flex;
    justify-content: space-between;
}
.loader_box {
    display: flex;
    justify-content: center;
    align-items: center;
}
.editicon{
    color: #665D6F;
    font-size: 22px;
  }
  .deleicon{
    color: #1C1C1C;
    font-size: 22px;
  }

  .pagination_box {
    display: flex;
    justify-content: flex-end;
    background-color: #F6F6F6;
    padding-top: 25px;
    padding-right: 25px;
    /* margin-bottom: 20px; */
  }
  
  .pagination_box>div {
    margin-bottom: 25px;
  }