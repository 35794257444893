.components {
  background-color: #F6F6F6;
  padding: 20px;
  border-radius: 10px;
}

.search_input_div {
  position: relative;
  width: 100%;
  margin: auto 13px;
  /* display: flex; */
  /* justify-content: center; */
}

.search_input_div > input {
  height: 44px;
  width: 95%;
  border: 0.6px solid #665d6f;
  border-radius: 6px;
  padding-left: 15px;
  background-color: #f6f6f6;
}

.search_input_div > img {
  position: absolute;
  bottom: 28%;
  right: 5%;
  height: 23px;
  width: 23px;
}

.checkbox_threedots {
  display: flex;
  justify-content: space-between;
  column-gap: 50px;
  align-items: center;
  padding: 10px 10px 10px 20px;
}

.card {
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
  align-items: center;
  margin: 10px 10px 10px 20px;
  padding: 15px 15px 15px 15px;
  background-color: white;
  border-radius: 6px;
}

.card_flex {
  display: flex;
  justify-content: space-between;
  column-gap: 15px;
  /* align-items: fcentert; */
}

.btn_div {
  display: flex;
  column-gap: 10px;
}

.blackdot {
  width: 10px;
  height: 10px;
  background-color: black;
  column-gap: 10px;
  border: none;
  border-radius: 50%;
  display: flex;
  margin-top: 3px;
  /* align-self: center; */
}

.img_name {
  display: flex;
  align-items: center;
  height: max-content;
  column-gap: 10px;
}

.card_description_div {
  margin-top: 4px;
}

.card_description {
  display: flex;

  align-items: flex-start;
  column-gap: 3px;
}

.card_btn {
  width: 96px;
  height: 27px;
  background: #ffffff;
  border: 1px solid #c6c6c6;
  border-radius: 30px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  color: #1c1c1c;
}

.name {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.title {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #1c1c1c;
  white-space: nowrap;
}

.content {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  letter-spacing: 0.4992px;

  /* Black */

  color: #1c1c1c;
}

.time {
  margin-top: -35px;
}

@media (max-width: 912px) {
  .search_input_div {
    position: relative;
    width: 96%;
  }
}

@media (max-width: 600px) {
  .mains {
    width: 100%;
  }

  .card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    column-gap: 10px;
  }

  .time {
    width: 100%;
    margin-top: 0px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .card_flex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 15px;
    width: 100%;
    /* align-items: fcentert; */
  }

  .card_description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    column-gap: 3px;
  }

  .search_input_div {
    position: relative;
    width: 93%;
    margin: auto 13px;
  }
}

@media (max-width: 600px) {
  .search_input_div {
    position: relative;
    width: 90%;
  }
}
