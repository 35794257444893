.toggle_main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 15px;
    padding-left: 10px;
    flex-wrap: wrap;
    height: 70px;
}

.font_style {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #1C1C1C;
    padding-left: 5px
}

.button_flex {
    display: flex;
    flex-wrap: wrap;
}

.upload_btn {
    display: flex;
    justify-content: center;
    column-gap: 15px;
    align-items: center;
    height: 45px;
    width: 139px;
    border-radius: 5px;
}

.textarea {
    border: none;
    min-height: 200px;
    /* max-height: 200px;
    max-width: 99.3%; */
    min-width: 97.8%;
    width: cover;
    resize: none;
    outline: none;
    padding: 10px 10px;
    margin: 10px 0px;
}

@media(max-width:600px) {
    .toggle_main {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 15px;
        padding-left: 10px;
        flex-wrap: wrap;
        height: fit-content;
        /* height: 70px; */
    }

    .upload_btn {
        display: flex;
        justify-content: center;
        column-gap: 15px;
        align-items: center;
        height: 45px;
        width: 139px;
        border-radius: 5px;
        margin: 10px auto;
    }

    .textarea {
        /* border: none; */
        min-height: 100px;
        /* max-height: 200px; */
        max-width: 93.3%;
        min-width: 93.3%;
        resize: none;
        outline: none;
        margin: 10px 0px;
    }
}

@media(max-width:600px) {
    .textarea {
        /* border: none; */
        min-height: 100px;
        /* max-height: 200px; */
        max-width: 93.3%;
        min-width: 93.3%;
    }
}