.order-success-container {
    max-width: 400px;
  
    padding: 20px;
    background-color: #f3f3f3;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  
  .order-success-container h2 {
    color: #4CAF50; /* Green color for success message */
  }
  
  .order-success-container p {
    margin: 10px 0;
  }
  
  .order-success-container strong {
    font-weight: bold;
  }
  