.modal_del {
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  width: 30%;
  padding: 30px;
  overflow-y: auto;
  max-height: 90%;
}

.mpophndg{
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  text-align: center; 
  text-transform: capitalize;
}
.clobtn{
  position: absolute;
  right: 10px;
  top: 10px;
}
.clobtn a{
  cursor: pointer;
}
.iconru{
  font-size: 30px;
  color: #1C1C1C;
}
.action{
  text-align: center;
}
.action_btn{
  padding: 10px 25px;
  border-radius: 10px;
  background: #000;
  border: 1px solid #000;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  cursor: pointer;
}
.action_btn:hover{
  background: transparent;
  color: #000;
}
.labelv{
  text-align: center !important;
  color: #1c1c1c !important;
  opacity: .6;
}
.labelv p{
  font-size: 14px;
    margin-bottom: 0;
    word-break: break-all;
}
.upload_img > p {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #1c1c1c;
  opacity: 0.5;
  margin-bottom: 30px;
  /* border */
}
.textarea_label {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;

  /* Black */

  color: #1c1c1c;
}
.preview_img {
  width: 100%;
  height: 100%;
}

.asterisk{
  color: red;
}
.img_box {
  width: 100%;
  height: 150px;
  background-color: #f6f6f6;
  display: flex
;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
} 
.img_view {
  width: 100%;
  height: 150px;
}
.img_view_hide {
  border: 1px solid red;
  width: 100%;
  background-color: #f6f6f6;
}
@media(min-width:993px) and (max-width:1025px){
  .modal_del{
    width: 50%;
  }
}
@media(min-width:768px) and (max-width:992px){
  .modal_del{
    width: 60%;
  }
}
@media (max-width: 767px) {
  .modal_del {
    width: 95%;
  }
}