.headercat{
  margin-top: 125px;
  padding: 50px 0;
}
.text_center {
  text-align: center;
}

.title_image {
  max-width: 110px;
}

.img_fluid {
  max-width: 100%;
  height: auto;
}

.max_width {
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
}

.max_width .sub_title {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  color: #636365;

}

.whitext {
  color: #fff !important;
}

.mains {
  box-shadow: 0 0 6px 2px #e7e9ec;
  border-radius: 22px;
  padding: 25px;
  margin-top: 20px;
}
  
.main_divs {
  margin: 13px 0px;
}
.textarea {
  border: 1px solid #665d6f;
  background-color: #f6f6f6;
  max-width: 100%;
  border-radius: 5px;
  padding: 10px;
  resize: none;
}
textarea:focus {
  outline: none;
}
.personal_gallery_textarea {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.input_label {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #1c1c1c;
}
.multi_inputs {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  column-gap: 5%;
}
.input_multi {
  margin: 10px 0px;
  width: 45%;
}
.btn_div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; 
}
.upload_img {
  /* margin: 0px 100px; */
  margin: 13px 0px;
}
.preview_img {
  width: 100%;
  height: 100%;
  /* margin-top: 6.5px;
  border: 1px solid rgb(214, 212, 212);
  border-radius: 5px;
  background-color: #f6f6f6; */
}
.preview_img_choose {
  padding: 17px 15px 17px 15px !important;
}
.img_box {
  background-color: #F6F6F6;
  display: flex
;
  align-items: center;
  justify-content: center;
  border: 1px solid #ced4da;
  text-align: center;
  border-radius: .25rem;
}
 
.label > p {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #665d6f;
  margin: 5px 0px;
  opacity: 0.5;
}
.img_view {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: .25rem;
  overflow: hidden;
}

.img_view_hide {
  border: 1px solid red;
  width: 100%;
  background-color: #f6f6f6;
}
.labelv{
  text-align: center !important;
  color: #1c1c1c !important;
  opacity: .6;
}
.labelv p{
  font-size: 14px;
    margin-bottom: 0;
    word-break: break-all;
}
.subbtn{
  padding: 10px 35px;
    border-radius: 10px;
    background: #1c1c1c;
    border: 1px solid #1c1c1c;
    color: #fff;
    font-family: DM Sans !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 26px !important;
    cursor: pointer;
    text-decoration: none !important;
    display: inline-block;
}
.subbtn:hover{
  background: #fff;
    color: #1c1c1c !important;
}
.select__btn {
  width: 100%;
}
.select {
  width: 100%;
}
.select__options {
  width: 100%;
}

@media (max-width: 767px) {
  .headercat{
    margin-top: 57px;
  }
  .input_multi {
    margin: 10px 0px;
    width: 100%;
  }
}
