.components {
    background-color: #f6f6f6;
    height: 100%;
    border-radius: 10px;
    padding-bottom: 20px;
    /* text-align: center; */
    /* font-size: 50px;
    padding-top: 50px; */
}

.category_btn {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
}

.card_main {
    display: flex;
        padding: 0 20px 20px;
}

.card {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
}

.heading_main {
    justify-content: space-between;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    color: #1C1C1C;
    padding: 20px 0px; 
}

.hrline {
    opacity: 0.5;
    height: 0.5px;
    background-color: #000000;
    margin-left: 2px;
    /* border: 0.5px solid #000000; */
    width: 100%;
}

.upload_img {
    margin: 0px 100px;
}

.upload_img>p {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #1C1C1C;
    opacity: 0.5;
}

.subheading {
    min-width: 118px;
    white-space: nowrap;
}

.input_detail_div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
}

.label {
    /* margin: 5px 0px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.label>p {

    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #665D6F;
    margin: 5px 0px;
    opacity: 0.5;
}

.input_detail_div>.subheading_head {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #1C1C1C;
}

.img_text_add {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.preview_img {
    width: 100%;
    height: 100%;
}

.img_box {
    width: 100%;
    height: 150px;
    background-color: #f6f6f6;
    display: flex;
    align-items: center;
    justify-content: center;
}

.img_view {
    width: 100%;
    height: 150px;
}

.img_view_hide {
    border: 1px solid red;
    width: 100%;
    background-color: #f6f6f6;
}
.img_heading{
    
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #1C1C1C;
} 
.labtn{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.adbtn{
    text-align: right;
}

.input {

    background: #F3F3F3;
    border-radius: 6px;
    border: none;
    /* width: 88%; */
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    padding: 5px 20px;
    color: #636365;
}

.img_text_add>p {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #1C1C1C;
    margin: 0px 0px;
    margin-left: 5px;
}

.save_btn {
    display: flex;
    justify-content: center;
    padding: 20px 0px;
}

@media(max-width:900px) {
    .mains {
        width: 100%;
    }

    

    .card {
        background-color: white;
        width: 100%;
        padding: 0px;
        border-radius: 10px;
        margin:0px
    }

    .heading_main {
        margin: 0px;
        padding: 20px 15px
    }

    .upload_img {
        margin: 0px;
        padding: 0px 15px
    }

    .detail_inputs {
        margin: 10px 0px;
        padding: 0px 15px
    }
}