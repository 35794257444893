.inputs_div_margin {
  margin-bottom: 5px;
}

.password_label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.new_div_inputs {
  display: flex;
  align-items: center;
  /* justify-content: cente; */
  column-gap: 10px;
}

.textarea {
  width: 95%;
  min-width: 95%;
  max-width: 95%;
  max-height: 100px;
  padding: 2.5%;
  background-color: white;
  border: none;
  border-radius: 6px;
  outline: none !important;
  margin-top: 5px;
}
.textarea:focus {
  outline: none !important;
  /* box-shadow: 0 0 10px #719ECE; */
}
.new_div_inputs > h2 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 33px;
  color: #1c1c1c;
  white-space: nowrap;
}

.inputs_div_second {
  margin: 10px 0px;
}

.input_label {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #1c1c1c;

}

.input_edit_label {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #1c1c1c;
  margin: 0px;
}

.forgot {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #1c1c1c;
  cursor: pointer;
  margin: 0px;
}

.hrs {
  width: 100%;
  opacity: 0.3;
  border: 1px solid #665d6f;
}

.edit_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.img_box {
  width: 100%;
  height: 200px;
  /* background-color: #f6f6f6; */
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  /* opacity: 0.5; */
  border-radius: 5px;
  margin-top: 10px;
}

.img_box_img {
  width: 100%;
  height: 200px;
  /* background-color: #f6f6f6; */
 
  display: flex;
  align-items: center;
  justify-content: center;
  /* opacity: 0.5; */
  border-radius: 5px;
  margin-top: 10px;
}
.label {
  /* margin: 5px 0px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.label > p {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #665d6f;
  margin: 5px 0px;
  opacity: 0.5;
}

.img_view {
  width: 100%;
  height: 150px;
}

.img_view_hide {
  /* border: 1px solid red; */
  width: 100%;
  background-color: #f6f6f6;
}

.image_div {
  width: 46px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
}
