.artistdetails {
  margin-top: 125px;
}

.artistInfo h1 {
  font-family: "Roboto", sans-serif;
  font-size: 27px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  margin: 5px 0 10px;
  text-transform: capitalize;
}

.artistImgDiv {
  margin: 0 auto;
  text-align: center;
}

.arstlist {
  list-style-type: none;
  padding: 0;
  margin: 10px auto;
  text-align: center;
}

.arstlist li {
  position: relative;
  text-align: center;
  display: inline-block;
  padding-right: 15px;
  margin-right: 15px;
  margin-top: 0;
  color: #636365;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  opacity: .8;
}
.mlt{
  margin-right: 5px;
}
.arstlist li span {
  display: block;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 23px;
  text-align: center;
  color: #1C1C1C;
}

.arstlist li::after {
  content: '';
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  border-right: 1px solid #bab4b4;
}

.arstlist li:last-child {
  padding-right: 0;
  margin-right: 0;
}

.arstlist li:last-child::after {
  display: none;
}

.apply_btn {
  padding: 10px 35px;
  border-radius: 10px;
  background: #1c1c1c;
  border: 1px solid #1c1c1c;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}

.apply_btn:hover {
  background: #fff;
  color: #1c1c1c;
}

.aboarts h3 {
  color: #1c1c1c;
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  font-weight: 600;
  line-height: 24px;
  margin: 0 0 5px;
  text-align: left;
  text-transform: capitalize;
}

.aboarts p {
  color: #636365;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  margin: 0;
  opacity: .8;
  text-align: left;
  margin-bottom: 15px;
}

.sec_sc {
  padding: 50px 0;
}

.abncr {
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  text-align: left;
  margin: 0;
  margin-bottom: 10px;
  font-family: "Roboto", sans-serif;
  border-bottom: 1px solid #E7EBED;
  padding-bottom: 10px;
}
.preview_img{
  width: 100%;
  background-color: white;
  margin: 0 auto;
  height: 200px;
  border-radius: 5px;
  overflow: hidden;
  text-align: center;
}
.artistImg {
  max-width: 100%;
  height: 200px;
  border-radius: 5px;
}

.artwork__div {
  padding-top: 25px;
}

.verticalLine3 {
  border-left: 1.5px solid black;
  height: 4.5vw;
  margin-left: 0.5%;
  margin-right: 0.5%;
}

.artistStats {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -1.5%;
}

.artistShare {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-top: 2%;
}

.sharelogo {
  margin-left: 1%;
  background-color: #EFEFEF;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shareimage {
  cursor: pointer;
  width: 45%;
}

.artist__description {
  padding: 2% 9%;
}

.artistInfo {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.react-share__ShareButton {
  display: flex;
  align-items: center;
  gap: 5px;
}
 

.colorcheckbox {
  background-color: red;
}

.orientation {
  margin-right: 7%;
}

.color__image {
  margin-right: 2%;
  width: 10%;
}

.cardImage {
  width: 100%;
  object-fit: contain;
}

.card__middle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card__main {
  width: 100%;
  cursor: pointer;
}

.card__top {
  position: relative;
  height: 350px;
  max-height: 350px;
  min-height: 350px;
  overflow: hidden;
  border: 1px solid #f1f7fd;
}

.card__top img {
  object-fit: fill;
  height: 100%;
}

.pic_info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 7px;
  text-align: center;
  overflow: hidden;
}

.pic_info .card__middle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  display: block;
}

.price_sec {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin-top: 8px;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 7px;
  text-align: center;
}

.like_count {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  color: #1c1c1c;
}

.hrs {
  height: 50px;
  width: 0.1px;
  border-right: 1px solid #bdbdbd;
}

.buys {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #636365;
  opacity: 0.6;
  margin-bottom: 5px;
  display: block;
}

.prices {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #1c1c1c;
}

.titles {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  text-transform: capitalize;
  color: #1c1c1c;
}

.names {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #636365;
  opacity: 0.5;
  margin: 0;
  padding-top: 1px;
  padding-bottom: 6px;
  border-bottom: 1px solid #BDBDBD;
}

.info_like {
  display: flex;
  justify-content: center;
  column-gap: 8px;
  align-items: center;
}

.wishlst {
  width: 25px;
  height: 25px;
  background: #f3f3f3;
  border-radius: 50%;
  line-height: 25px;
  text-align: center;
}

.card__middleLeft {
  margin-top: 12px;
  margin-bottom: 5px;
}

.card__middleRight {
  display: flex;
  margin-top: 12px;
  /* margin-bottom: 6.5%; */
  align-items: center;
}

.otherartist__div {
  margin-top: 3%;
  padding: 0% 10%;
  margin-bottom: 5%;
}

.designimage {
  width: 50%;
}

.otherartistimg {
  cursor: pointer;
  max-width: 100%;
  height: 100%;
  width: 100%;
}

.Other_otherartist {
  margin-top: 40px;
}

.Other_otherartist h4 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: #1c1c1c;
  text-transform: capitalize;
  margin: 0;
}

.Other_otherartist p {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #636365;
  opacity: 0.6;
  margin: 0;
}

.exprlobtn {
  padding: 10px 35px;
  border-radius: 10px;
  background: #fff;
  border: 1px solid #1c1c1c;
  color: #1c1c1c;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  display: block;
  width: 100%;
  margin-top: 15px;
}

.exprlobtn:hover {
  background: #1c1c1c;
  color: #fff;
}

.otherartistnamerating {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 27px;
}

.otherartistcards {
  background-color: white;
  padding: 13px;
  box-shadow: 0px 0px 7.24px 0px #0000000F;
  border: 0.9px solid #63636533;
  position: relative;
}

.Other_otheim {
  height: 200px;
  max-height: 200px;
  min-height: 200px;
  overflow: hidden;
}

.otherartimg {
  width: 100%;
  object-fit: fill;
  height: 100%;
}

.otherartistimaged {
  position: absolute;
  top: 53%;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid #fff;
}

.ratingdiv {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.art_de_rating_img {
  margin-right: 5px;
}

.latestartworks {
  background-color: #f2f2f2;
  padding: 50px 0;
}

.designImage {
  width: 35%;
}

.latestartworksheading {
  margin-top: 45%;
  margin-bottom: 45%;
}

.price_explore h3 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 45px;
  text-transform: capitalize;
  color: #1c1c1c;
  margin: 0px;
  margin-bottom: 10px;
}
.widthfull{
  width: 100%;
}
.mt_2{
  margin-top: 20px;
}
.price_explore p {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #636365;
  margin: 0px;
  opacity: 0.6;
  margin-bottom: 20px;
}

.text_center {
  text-align: center;
}

.title_image {
  max-width: 110px;
}

.sec_title {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 45px;
  text-transform: capitalize;
  color: #1c1c1c;
  margin: 0px;
  margin-bottom: 10px;
}
.nodata{
  font-family: "Roboto", sans-serif;
    font-weight: 600;
    font-size: 17px;
    line-height: 23px;
    color: #1c1c1c;
    margin-bottom: 0;
}
.filter__maindiv {
  display: flex;
      align-items: center;
      justify-content: flex-end;
}
.sub_title {
  letter-spacing: 0.00938em;
  color: #1C1C1C;
  z-index: 10;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  line-height: 26px;
  margin-bottom: 20px;
  text-transform: capitalize;
  font-size: 20px;
  margin-top: 0;
}

.img_fluid {
  max-width: 100%;
  height: auto;
}

.bannerbigimg {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  max-height: 300px;
  overflow: hidden;
}

.bannerbigimg img {
  width: 100%;
}

.moreart_heading {
  padding: 2% 9%;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  line-height: 44px;
  /* identical to box height */

  /* Black */

  color: #1c1c1c;
}

.moreart_heading>hr {
  border: 1px solid #e7ebed;
}

.filterButtonsStyles {
  color: rgb(51, 51, 51);
    font-size: 15px;
    background: rgb(243, 243, 243);
    width: max-content;
    white-space: nowrap;
    display: flex
;
    flex-direction: row;
    align-items: center;
    border-radius: .25rem;
    font-family: "Roboto", sans-serif;
    padding: .45rem .75rem;
    border: 0;
    text-transform: capitalize;
}

.filterButtonsStyles:hover .filterButtonsStylesIcon {
  text-decoration: line-through;
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
}

.filterButtonsStylesIcon {
  color: gray;
  font-size: 16px !important;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40vh;
}

.art_de_sear_con {
  width: 100%;
  display: flex;
  align-items: end;
  flex-wrap: wrap;
  justify-content: end;
  gap: 15px;
}

.art_de_sear_label {
  font-size: 15px;
  font-weight: bold;
  color: #1c1c1c;
}
.profilesec{
  box-shadow: 0 8px 10px 0px #edebeb;
  border-radius: 10px;
  padding: 20px;
  background: #fff;
  position: relative;
  top: -30px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.artnme{
  font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    color: #1C1C1C;
    margin-bottom: 5px;
    margin-top: 8px;
    text-transform: capitalize;
}
.prodlf{
  position: relative;
}

.tablist {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.tablist li {
  margin-top: 0;
  display: inline-block;
  margin-right: 5px;
}

.tablist li a {
  text-decoration: none;
  padding: 8px 20px;
  border-radius: 10px;
  background: #fff;
  border: 1px solid #fff;
  color: #1c1c1c;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  display: block;
  cursor: pointer;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.tab_content {
  border-radius: 22px;
  border-top-left-radius: 0;
  padding: 10px;
  background: #efefef;
}

@media(max-width:992px){
  .sidebar_container {
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    height: 70vh;
    background: white;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
    transition: bottom 0.4s ease-in-out;
    z-index: 1000;
  }
  
  /* Mobile: Show When Open */
  .sidebar_container.open {
    bottom: 0;
  }
  
  /* Desktop: Always Visible */
  .sidebar_container.desktop {
    position: static;
    width: 100%;
    height: auto;
    box-shadow: none;
    transition: none;
  }
  
  .sidebar_content { 
    position: relative;
    height: 100%;
    padding-top:35px
  }
  .filter__span{
    margin-right: 5px;
  }
  /* Close Button - Mobile Only */
  .close_btn {
        position: absolute;
  top: 6px;
  right: 10px;
  background: none;
  border: none;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid #ddd;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  line-height: 23px;
  }
}
@media (min-width:768px) and (max-width:991px) {
  .price_explore {
    text-align: center;
  }

  .bannerbigimg {
    max-height: 150px;
  }

  .artistInfo {
    bottom: 50px;
  }

  .artistImg {
    bottom: 50px;
  }
}

@media(max-width:767px) {
  .artistShare{
    justify-content: center;
    margin-top: 4%;
  }
  .price_explore {
    text-align: center;
  }

  .artistdetails {
    margin-top: 57px;
  }

  .artistImg {
    width: 80px;
    height: 80px;
    bottom: 50px;
  }

  .artistInfo {
    bottom: 50px;
  }
  .card__main{
    margin-bottom: 0;
  }
  .searchbar{
    display: block;
    text-align: center;
  }
  .searchbanner{
    margin-top: 57px;
  }
  .filter__maindiv{
    justify-content: space-between;
  }
  .tosearch{
    padding-bottom: 0;
  }
  .price_sec{
    margin-bottom: 0;
  }
  .preview_img{
    height: auto;
  }
  .artnme{
    text-transform: capitalize;
  }
  .tablist li a{
    padding: 8px 9px;
    font-size: 15px;
  }
  .profilesec{
    top: -10px;
  }
}
@media (max-width: 600px) {
  .searchresults__div {
    flex-direction: column;
  }
  .searchheading {
    padding-left: 0%;
  }
}
