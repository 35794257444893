.components {
  background-color: #f6f6f6;
  border-radius: 10px;
  padding-bottom: 20px;
}

.add_btn_div {
  display: flex;
  justify-content: flex-end;
  padding: 20px 20px 20px 20px;
  border-radius: 6px;
}

.add_btn {
  width: 100px;
  height: 44px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
}
.card_div{
  padding: 20px;
  padding-top: 0;
  color: #ffffff;
}
.card {    
  height: 100%;
  background-color: white;
  box-shadow: 0px 3.6682px 18.341px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 20px;
}

.card_dark { 
  height: auto;
  background: #1c1c1c;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 20px;
}

.edit_more {
  /* width: ; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 5px;
  /* padding: 10px 20px; */
}

.plan_price {
  display: flex; 
  align-items: center; 
  padding: 0px;
  margin-bottom: 5px;
}

.plan_price > .heading {
  font-family: "Roboto", sans-serif;
  font-size: 26px;
  font-weight: 700;
  color: #1c1c1c;
}

.plan_price > .heading_dark {
  font-family: "Roboto", sans-serif;
  font-size: 26px;
  font-weight: 700;
  color: #ffffff;
}

.plan_price > .times {
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  color: #1c1c1c;
}

.detail_main .heading {
  color: #1c1c1c;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 34px;
  line-height: 40px;
  text-transform: capitalize;
  margin: 0;
  text-align: left;
  margin-bottom: 15px;
}
.subs_edit {
  cursor: pointer;
}
.inputField {
  max-width: 60px;
  text-align: left;
  border: 1px solid #ced4da;
  border-radius: 4px;
  background: #fff;
  padding: 4px 8px;
  font-weight: 500;
}

.plan_subs {
  display: flex;
}
.textFieldSubs {
  width: 20px;
}

.detail_main .para {
  text-transform: capitalize;
  color: #636365;
  opacity: .8;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 23px;
}

.detail_main .heading_dark {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 34px;
  line-height: 30px;
  text-transform: capitalize;
  margin: 0;
  text-align: left;
  margin-bottom: 10px;
  color: #ffffff; 
}

.detail_main .para_dark { 
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 23px;
  color: #ffffff;
  text-transform: capitalize;
}

.detail_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
  margin: 10px 0px;
}

.right_icon {
  width: 21px;
  height: 21px;
  border: none;
  border-radius: 50%;
  background-color: #c9c9c9;
  /* opacity: 0.1; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.right_icon_dark {
  width: 21px;
  height: 21px;
  border: none;
  border-radius: 50%;
  /* opacity: 0.1; */
  display: flex;
  justify-content: center;
  align-items: center;
  background: #333333;
  mix-blend-mode: normal;
  /* opacity: 0.1; */
}

.flex_content {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #1c1c1c;
}
.editicon{
  color: #665D6F;
  font-size: 22px;
}
.deleicon{
  color: #1C1C1C;
  font-size: 22px;
}

@media (max-width: 767px) {
  .mains {
    width: 100%;
  }
}