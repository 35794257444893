.components{
    background-color: #f6f6f6;
    border-radius: 10px;
    padding-bottom: 20px;
}
.table_header{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px;
    gap: 5px;
}
@media(max-width:600px){
    .mains{
        width: 100%;
    }
}