.headerCategory {
  margin-top: 125px;
  width: 100%;
  background: url("./banner bg img.png");
  background-size: cover;
  padding: 50px 0;
}
.pageitle{
  text-align: center;
}
.pageitle h1{
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: 0;
  margin-bottom: 10px;
  margin-top: 0;
}
.pageitle ul{
  padding: 0;
  list-style-type: none;
  margin: 0;
}
.pageitle ul li{
  display: inline-block;
  margin-top: 0;
}
.pageitle ul li:last-child{
  padding-left: 5px;
}
.pageitle ul li a{
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #14142B;
  text-decoration: none !important;
}

.headerSubTitle {
  margin-left: -100%;
}
.content__image {
  width: 100%;
} 
.sec_sc{
  padding: 50px 0;
}
.blgcont h3{
  font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 22px;
    margin: 5px 0 5px;
    text-transform: capitalize;
}
.blgcont p{
  margin-top: 0;
  margin-bottom: 0;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  text-align: left;
  color: #665D6F; 
  opacity: .8;
}
@media (max-width: 767px) {
  .headerCategory {
    margin-top: 57px;
  }
  .headerSubTitle {
    margin-left: -100%;
  }
  .headerTitle {
    margin-left: 17.5%;
  }
  .pageitle h1{
    font-size: 25px;
        line-height: 26px;
        margin-bottom: 5px;
  }
}
