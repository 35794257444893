.popup_main {
  background-color: red;
}

.action {
  text-align: center;
  margin-top: 90px;
}
.modal_box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 700px;
  /* padding-bottom: 80px; */
  /* background-color: white; */
}

.input_action_div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* column-gap: 60px; */
  margin-bottom: 10px;
}

.input_div {
  position: relative;
  width: 77%;
}

.search_icon {
  position: absolute;
  right: -6%;
  top: 30%;
}

.search_icons {
  position: absolute;
  right: -6%;
  top: 30%;
}

.search_input {
  height: 44px;
  width: 100%;
  background: white;
  border: 0.6px solid #665d6f;
  border-radius: 6px;
  padding: 0px 25px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #636365;
}

.cancel_btn {
  background-color: white;
  color: black;
  width: "144px";
  height: "44px";
  font-family: "Roboto", sans-serif;
}

.save_btn {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
}

/* second modal  */
.modal_boxs {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
}

.modal_data {
  display: flex;
  justify-content: space-between;
  padding: 0px 25px;
}

/* .input_divs {
    position: relative;
    width: 81%;
} */

.input_actions {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  /* column-gap: 60px; */
  margin-bottom: 10px;
}

.input_actions > div {
  width: 100%;
  background-color: white;
  border-radius: 6px;
  margin-right: 10px;
}

.close_btn {
  width: 144px;
  margin: 0px 0px;
}

.input_divs {
  position: relative;
  width: 91%;
}

.search_inputs {
  border: none;
  height: 44px;
  width: 100%;
  background: white;
  border-radius: 6px;
  padding: 0px 0px 0px 25px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #636365;
  /* border-bottom: 0.6px solid #665D6F; */
  /* border-width: 200%; */
}

.hr {
  height: 1px;
  margin-top: 0px;
  padding: 0px;
  color: #665d6f;
  background-color: #665d6f;
}

/* third (assign) modal  */
.cancel_img {
  /* float:right; */
  text-align: end;
}

.assign_heading {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 34px;
  text-align: center;
  color: #1c1c1c;
  margin: 20px 0px;
}
 

.search_label {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #1c1c1c;
  margin-bottom: 40px;
}

.search_select {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #636365;
  margin: 10px 0px 50px 0px;
}

.search_modal {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  background-color: white;
  box-shadow: 24;
  border-radius: 6px;
  padding: 10px 10px;
  /* p: 2 */
}
.addnew_modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  background-color: white;
  box-shadow: 24;
  border-radius: 6px;
  padding: 10px 30px;
  /* p: 2 */
}
.submit_btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* subscription modal */
.textarea_blog {
  min-width: 100%;
  height: 100px;
  border: none;
  /* background: #f3f3f3; */
  border-radius: 6px;
  /* padding-left: 10px; */
  margin-top: 8px;
}
.customEditor {
  height: 100px; /* Adjust height as needed */
  .ql-editor {
    min-height: 150px; /* Ensure the editable area has a specific height */
  }
}
.modal_del{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  max-height: 95%;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 30px;
}
.mpophndg{
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  text-align: center;
}
.clobtn{
  position: absolute;
  right: 10px;
  top: 10px;
}
.clobtn a{
  cursor: pointer;
}
.iconru{
  font-size: 30px;
  color: #1C1C1C;
}
.action_btn{
  padding: 10px 25px;
  border-radius: 10px;
  background: #000;
  border: 1px solid #000;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  cursor: pointer;
}
.action_btn:hover{
  background: transparent;
  color: #000;
}
.actionc{
  text-align: center;
}

@media(min-width:993px) and (max-width:1025px){
  .modal_del{
    width: 50%;
  }
}
@media(min-width:768px) and (max-width:992px){
  .modal_del{
    width: 60%;
  }
}

@media (max-width: 767px) {
  .modal_del{
    width: 95%;
  }
  .action {
    margin-top: 120px;
  }
  .modal_box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
  }

  .input_div {
    position: relative;
    width: 70%;
  }

  .search_icon {
    position: absolute;
    right: -12%;
    top: 30%;
  }

  .cancel_btn {
    background-color: white;
    color: black;
    width: "144px";
    height: "44px";
    font-family: "Roboto", sans-serif;
  }
}

@media (max-width: 540px) {
  .modal_boxs {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
  }

  .input_div {
    position: relative;
    width: 68%;
  }

  .search_modal {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    background-color: white;
    box-shadow: 24;
    border-radius: 6px;
    padding: 10px 10px;
    /* p: 2 */
  }
  .addnew_modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    background-color: white;
    box-shadow: 24;
    border-radius: 6px;
    padding: 10px 10px;
    /* p: 2 */
  }

  .search_action {
    padding: 0px 10px;
  }

  .input_actions {
    width: 100%;
    display: flex;
    /* flex-wrap: wrap; */
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .input_actions > div {
    width: 100%;
    background-color: white;
    border-radius: 6px;
    margin-bottom: 10px;
  }

  .close_btn {
    width: 100%;
    margin: 0px 0px;
  }
}

@media (max-width: 520px) {
  .modal_box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
  }

  .input_div {
    position: relative;
    width: 54%;
  }

  .search_icon {
    position: absolute;
    right: -20%;
    top: 30%;
  }

  .cancel_btn {
    background-color: white;
    color: black;
    width: "144px";
    height: "44px";
    font-family: "Roboto", sans-serif;
  }

  .modal_boxs {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
  }

  .input_actions {
    width: 100%;
    display: flex;
    /* flex-wrap: wrap; */
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .input_actions > div {
    width: 100%;
    background-color: white;
    border-radius: 6px;
    margin-bottom: 10px;
  }

  .close_btn {
    width: 100%;
    margin: 0px 0px;
  }
}

@media (max-width: 280px) {
  .input_div {
    position: relative;
    width: 40%;
  }

  .search_icon {
    position: absolute;
    right: -30%;
    top: 30%;
  }

  .input_action_div {
    /* width: 100%; */
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  .cancel_btn {
    background-color: white;
    color: black;
    width: "100px";
    height: "44px";
    font-family: "Roboto", sans-serif;
  }
}
