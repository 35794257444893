.mains { 
    background-color: #F6F6F6;
    padding: 20px;
    border-radius: 10px;
}

.table_heading {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 39px;
    color: #1C1C1C;
    padding: 0% 0% 0% 2%;
    margin: 1% 0%;
}

@media(max-width:600px) {
    .mains {
        width: 100%;
    }
} 

