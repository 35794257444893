.modal_box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 900px;
  max-width: 900px;
  max-height: 90%;
  /* overflow-x: scroll; */
  overflow-y: scroll;
}

.input_action_div {
  width: 100%;
  display: flex;
  column-gap: 20px;
  justify-content: space-between;
  align-items: flex-start;
  /* border: 2px solid red; */
  margin-bottom: 10px;
}

.input_div {
  width: 100%;
  /* border:2px solid green; */
  /* height: 44px; */
}

.cancel_btn {
  background-color: white;
  color: black;
  width: "144px";
  height: "44px";
  font-family: "Roboto", sans-serif;
}

.search_icon {
  position: absolute;
  left: 83%;
  top: 12px;
}

.selector {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: -20px;
}

.main_content {
  background-color: white;
  padding: 0px 30px;
}

.details_div {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
}

.detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #1c1c1c;
  margin: 0px;
}

.content {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #636365;
}

.hrverticle {
  height: 65px;
  width: 1px;
  opacity: 0.07;
  background-color: #1c1c1c;
}

.hori_hrs {
  width: 100%;
  opacity: 0.3;
  border: 1px solid #665d6f;
  margin: 20px 0px;
  /* padding:0px 30px; */
}

.img_detail_div {
  display: flex;
  column-gap: 30px;
  justify-content: space-between;
  align-items: center;
}

.button_detail_div {
  display: flex;
  column-gap: 30px;
  justify-content: space-between;
  align-items: center;
}
.input_div{
    display: flex;
}
.input_div input{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
}
.sericon{
    padding: .375rem .75rem;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.4;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
}

@media (max-width: 600px) {
  .modal_box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: auto;
    max-height: 90%;
    overflow-y: scroll;
    overflow-x: scroll;
  }
}
