.component {
    background-color: #F6F6F6;
    border-radius: 10px;
}
.header{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-end;
}
.rightheader{
    padding: 20px 20px 0px 20px;
} 

@media(max-width:600px) {
    .mains {
        width: 100%;
    }
}