.modal_del {
 position: absolute;
 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%);
 background-color: #ffffff;
 background-color: white;
 font-family: "Roboto", sans-serif;
 font-style: normal;
 box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
 border-radius: 10px;
 font-weight: 700;
 font-size: 35px;
 color: #1c1c1c;
 border: none;
 text-align: center;
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 padding: 30px;
}

.card_main {
 height: 50%;
 width: 50%;
}
.login_text {
 color: rgb(109, 109, 227);
 font-family: "Roboto", sans-serif;
 font-style: normal;
 border-radius: 10px;
 font-weight: 700;
 font-size: 35px;
 cursor: pointer;
}
.success_txt {
 color: #1c1c1c;
 font-family: "Roboto", sans-serif;
 font-style: normal;
 border-radius: 10px;
 font-weight: 700;
 font-size: 35px;
}
.notify_txt {
 color: #1c1c1c;
 font-family: "Roboto", sans-serif;
 font-style: normal;
 border-radius: 10px;
 font-weight: 700;
 font-size: 25px;
}
@media (max-width: 600px) {
 .modal_del {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  background-color: white;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-weight: 700;
  font-size: 35px;
  color: #1c1c1c;
  max-height: 90%;
  overflow-x: scroll;
  border: none;
  width: 95%;
  padding: 0px;
 }
}
