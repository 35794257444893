.searchbar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3%;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.07);
  padding-bottom: 3%;
  padding-top: 3%;
}
.artistimage{
    width: 100%;
}
.artistimgDiv{
    width: 30%;
}
.artistinfo{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 5%;
}
.main__content{
    width: 75%;
    margin: auto;
}
.divider{
    margin-top: 3%;
    color: #c4c4ce;
}
.artworkcard{
    width: 90%;
    margin-top: 5%;
    margin-bottom: 5%;
}
.artworkimg{
    width: 100%;
}
.searchresult{
    margin-bottom: 5%;
}