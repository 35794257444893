.mains {
    /* width: 69%; */
}

.components {
    background-color: #f6f6f6;
    padding: 20px 2px 20px 30px;
}

.card {
    background-color: white;
    padding: 10px 0px;
    padding-bottom: 300px;
    border: 1px solid #D2D2D2;
}

.header {
    padding: 0px 20px;
}

.upload_img {
    margin: 20px 0px;
    padding: 0px 20px;
}

.upload_img>p {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #1C1C1C;
    opacity: 0.5;
}

.textarea_label {

    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #1C1C1C;

}

.preview_img {
    width: 100%;
    height: 100%;
}

.img_box {
    width: 100%;
    height: 150px;
    background-color: #f6f6f6;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}

.label {
    /* margin: 5px 0px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.label>p {

    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #665D6F;
    margin: 5px 0px;
    opacity: 0.5;
}

.img_view {
    width: 100%;
    height: 150px;
}

.img_view_hide {
    border: 1px solid red;
    width: 100%;
    background-color: #f6f6f6;
}


@media(max-width:600px) {
    .mains {
        width: 100%;
    }

    .components {
        background-color: #f6f6f6;
        padding: 10px 2px 10px 2px;
    }
}