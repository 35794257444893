
.components {
    background-color: #f6f6f6;
    border-radius: 10px;
}
.cardsc{
    padding: 0 20px 20px;
}
.selector_div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding: 20px;
}

.card { 
    background-image: url('../../.././assets/img/dashboard_card_bg.png');
    box-shadow: 0px 19.1765px 47.9412px rgba(181, 188, 194, 0.2);
    border-radius: 3px;
    padding: 20px;
    background-size: cover;
    background-position: center;
}

.card>p {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 19px;
    line-height: 25px;
    color: #1C1C1C;
    padding: 10px 0;
    margin-bottom: 0;
}

.card>h1 {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    margin-bottom: 0;
    font-size: 40px;
    line-height: 41px;
    text-align: left;
}

@media(max-width:600px) {
    .mains {
        width: 100%;
    }
}