.mains {
  background-color: #F6F6F6;
  padding: 20px;
  border-radius: 10px;
}
.back_button {
    border-bottom: 1px solid #ced4da;
    margin-bottom: 30px;
  }
  .back_button a{
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 10px;
    margin-bottom: 0;
    color: #665D6F !important;
    opacity: .8;
    text-decoration: none !important;
    cursor: pointer;
    display: block;
  }
  .bsrd{
    padding-bottom: 15px;
    border-bottom: 1px solid #ced4da;
    margin-bottom: 15px;
  }
 .arhdng {
    font-weight: 400;
    font-size: 22px;
    line-height: 27px;
    color: #1c1c1c;
    text-align: left;
    text-transform: capitalize;
    margin-bottom: 0px;
    font-family: "Roboto", sans-serif;
  }
  .bdngr{
    padding-bottom: 15px;
    border-bottom: 1px solid #ced4da;
    margin-bottom: 15px;
  }
  .sethng{
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    color: #1c1c1c;
    text-align: left;
    text-transform: capitalize;
    margin-bottom: 7px;
  }
  .odlist{
    color: #1C1C1C;
font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    margin-bottom: 10px;
;
  }
  .odlist span{
    color: #636365;
    opacity: .8;
   }
  .art_namms{
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 29px;
    color: #1c1c1c;
    margin: 0;
    text-transform: capitalize;
    text-align: left;
  }
  .productLink_text{
    color: #1c1c1c;
    text-decoration: none;
  }
   .quoterequest {
    display: flex;
    text-align: right;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
  }
   .main_heading {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 46px;
    color: gray;
    display: flex;
    align-items: center;
    cursor: pointer;
   padding-left: 10px;
   }
   .remark_text {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #1c1c1c;
   }
   .detail_id > h1 {
    margin: 0px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #1c1c1c;
   }
   .detail_id > h3 {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 46px;
    color: #1c1c1c;
    margin: 0px;
   }
   
   .schedule_time {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #636365;
   }
   
   .detail_id > h6 {
    margin: 0px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #1c1c1c;
   }
   
   .detail_id > p {
    margin: 0px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #636365;
   }
   
   .status {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0px 25px 10px 15px;
   }
   
   .status > h1 {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 39px;
    color: #1c1c1c;
    margin: 0px;
   }
   
   .status > p {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 29px;
    text-align: right;
    color: #636365;
    margin: 0px;
   }
   
   .more_detail {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0px 25px 40px 15px;
   }
   
   .more_detail_child {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    gap: 10px;
    margin-top: 10px;
   }
   
   .title {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #1c1c1c;
   
   }
   
   .content {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #636365;
   }
   
   .item_acc_dec_div {
    display: flex;
    justify-content: center;
    column-gap: 15px;
    align-items: center;
   }
   
   .action_btn {
    width: 156px;
    height: 54px;
    border-radius: 10px;
   }
   .art_name {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 39px;
    color: #1c1c1c;
    margin: 5px 0px;
   }
   @media (max-width: 767px) {
    .quoterequest{
      text-align: center;
      justify-content: center;
    }
   }
   