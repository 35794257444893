.mains {
  width: 100%;
}
.editicon{
  color: #665D6F;
  font-size: 22px;
}
.deleicon{
  color: #1C1C1C;
  font-size: 22px;
}
.table_head {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px 20px 30px;
}
.loader_box {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .table_column {
    font-family: "DM Sans", sans-serif !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    color: #636365 !important;
    white-space: nowrap !important;
    cursor: pointer !important;
  }
  .hyperlink_title{
    text-decoration: underline;
    color: #636365;
  }
  .action {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 6px;
  }
  .action img {
    cursor: pointer;
  }