.modalBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30%;
    background-color: #fff;
    border-radius: 10px;
    padding: 30px; 
  }
  
  .modalBox h2 {
    margin-bottom: 16px;
  }
  
  .modalBox input[type="file"] {
    margin-top: 16px;
  }
  .mpophndg{
font-family: "Roboto", sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    text-align: center;
  }
  .clobtn{
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .clobtn a{
    cursor: pointer;
  }
  .iconru{
    font-size: 30px;
    color: #1C1C1C;
  }
  .actionc{
    text-align: center;
  }
  @media(min-width:993px) and (max-width:1025px){
    .modalBox {
      width: 60%;
    }
   
  }
  
  @media (min-width: 768px) and (max-width:992px) {
    .modalBox {
      width: 60%;
    }
   
  }
  @media (max-width: 767px) {
    .modalBox {
      width: 95%;
    }
  }