.headerCategory {
  width: 100%;
  background: url("./banner bg img.png");
  background-size: cover;
  padding-top: 5%;
  padding-bottom: 5%;
  margin-top: 125px;
}

.pageitle {
  text-align: center;
}

.pageitle h1 {
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: 0;
  margin-bottom: 10px;
  margin-top: 0;
}

.pageitle ul {
  padding: 0;
  list-style-type: none;
  margin: 0;
}

.pageitle ul li {
  display: inline-block;
  margin-top: 0;
}

.pageitle ul li:last-child {
  padding-left: 5px;
}

.pageitle ul li a {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #14142B;
  text-decoration: none !important;
}

li {
  font-family: 'DM Sans', sans-serif;
}

.headerSubTitle {
  margin-left: 15%;
}

.tandc__content {
  padding: 50px 0;
  font-family: "Roboto", sans-serif;
}

.tandc__content h3 {
  margin: 0 0 5px;
  display: flex;
  gap: 5px;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: #1c1c1c;
}

.tandc__content h4 {
  margin: 15px 0 3px;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #1c1c1c;
  text-transform: capitalize;
}

.tandc__content h5 {
  margin: 15px 0 3px;
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  font-weight: 600;
  color: #1c1c1c;
  text-transform: capitalize;
}

.tandc__content h6 {
  margin: 15px 0 3px;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: #1c1c1c;
  text-transform: capitalize;
}

.tandc__content p {
  margin: 0 0 10px;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: #636365;
  opacity: .8;
}

.tandc__content ul {
  margin-top: 0;
  margin-bottom: 10px;
}

.tandc__content ul li {
  margin-top: 0;
  padding-bottom: 5px;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: #636365;
  opacity: .8;
}

.tandc__content b {
  color: #1c1c1c;
}

.privacy__buyer {
  padding-top: 50px;
}

@media (max-width: 767px) {
  .headerCategory {
    margin-top: 57px;
  }
  .pageitle h1 {
    font-size: 25px;
        line-height: 26px;
        margin-bottom: 5px;
  }
}