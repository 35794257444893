.table_row {
  /* min-height: 30px; */
}
.table_column {
  /* height: 50px; */
}
.table_columns {
  display: flex;
  justify-content: space-between;
  /* min-height: 30px; */
  border-collapse: separate;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #636365;
}
.hyperlink_title{
  text-decoration: underline;
  color: #636365;
}
.table_head {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #1c1c1c;
}
.hover_text {
  text-decoration: underline;
  font-weight: normal;
  color: inherit;
  transition: color 0.3s ease, font-weight 0.3s ease;
}

.hover_text:hover {
  color: black;
  font-weight: bold;
}

.table_column {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #636365; 
}
.table_campaign_col {
  display: flex;
  flex-direction: column;
  width: 200px;
  text-align: justify;
}

.description_text {
  margin: 0;
  white-space: normal;
  word-break: break-word;
  
}
.description_text img{
width: 200px;
  
}

.read_more_button {
  margin-top: 5px;
  font-weight: 500;
  font-size: 14px;
}
.read_more_button:hover{
  color: blue;
}

.status_active {
  /* width: 57px;
  height: 31px; */
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 30px;
  color: #219653;
  margin-left: 10px;
  padding: 5px 15px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  border-radius: 30px;
  gap:5px;
}
.nested_span {
  width: 6px;
  height: 6px;
  background-color: #219653;
}
.status_inactive { 
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 30px;
  color: #eb5757;
  margin-left: 10px;
  padding: 5px 15px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  gap: 5px;
}
/* .table_campaign_col {
  display: flex;
  justify-content: space-between;
} */
/* .status_inactive */
.flex_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.loader_box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.title {
  text-decoration: underline;
  text-decoration-color: transparent;
  color: inherit;
  cursor: pointer;
  transition: color 0.3s, text-decoration-color 0.3s;
}

.title:hover {
  color: black;
  text-decoration-color: black;
}
.editicon{
  color: #665D6F;
  font-size: 22px;
}
.deleicon{
  color: #1C1C1C;
  font-size: 22px;
}

.pagination_box {
  display: flex;
  justify-content: flex-end;
  background-color: #F6F6F6;
  padding-top: 25px;
  padding-right: 25px;
  /* margin-bottom: 20px; */
}

.pagination_box>div {
  margin-bottom: 25px;
}