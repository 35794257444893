.mains {
    /* width: 69%; */
}

.components {
    background-color: #f6f6f6;
}

.tab_label {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    text-transform: capitalize;
    color: #1C1C1C;
}

@media(max-width:912px) {
    .mains {
        width: 100%;
    }
}