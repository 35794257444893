.brlist{
    list-style-type: none;
    padding: 0;
}
.brlist li{
    display: inline-block;
    margin-top: 0;
}
.brlist li a{
    text-decoration: none !important;
    cursor: pointer;
}
.odlist{
    color: #1C1C1C;
font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    margin-bottom: 0px;
  }
  .odlist span{
    color: #636365;
    opacity: .7;
  }
.order_main {
    width: 71%;
   }
   
   .img_view {
     width: 100%;
     height: 150px;
   }
   
   .del_img_div {
     position: absolute;
     height: 26px;
     width: 26px;
     right: 9px;
     top: 18px;
     display: flex;
     justify-content: center;
     align-items: center;
     background-color: white;
     border-radius: 5px;
   }
   .img_view_hide {
     border: none;
     width: 100%;
     background-color: #f3f3f3;
   }
   .textarea {
     width: 90%;
     min-width: 90%;
     max-width: 90%;
     max-height: 100px;
     background-color: #f3f3f3;
     color: #636365;
     outline: none;
     font-family: "Roboto", sans-serif;
     font-style: normal;
     font-weight: 400;
     margin-left: 5%;
     font-size: 16px;
     color: #636365;
     border: none;
    
     /* margin-top: 8px; */
   }
   .textarea:focus {
     outline: none !important;
   }
   
   .makeofferModal {
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
     width: 40%;
     max-height: 95%;
     background-color: #ffffff;
     border-radius: 10px;
     padding: 20px;
     overflow-y: auto !important;
   }
   
   .preview_img { 
     background-color: #f3f3f3;
     margin: auto;
     height: 150px;
     border-radius: 5px;
     /* opacity: 0.5; */
     border: none; 
     position: relative;
   
   }
   .img_main {
     margin-top: 15px;
   }
   .lebless {
     font-family: "Roboto", sans-serif;
     font-style: normal;
     font-weight: 500;
     font-size: 18px;
     line-height: 23px;
     color: #1c1c1c;
   }
   
   .img_box {
     width: 100%;
     height: 100%;
     background-color: #f3f3f3;
     /* background-color: white; */
     display: flex;
     align-items: center;
     justify-content: center;
     /* opacity: 0.5; */
     border-radius: 5px;
   }
   
   .label {
     /* margin: 5px 0px; */
     display: flex;
     flex-direction: column;
     align-items: center;
     cursor: pointer;
   }
   
   .emailInfo {
     padding: 4%;
   }
   
   .first__lastname {
     display: flex;
     align-items: center;
     gap: 2.5%;
   }
   .nameInfo {
     padding: 2% 4%;
   }
   
   .offer__number {
     display: flex;
     align-items: center;
     gap: 2.5%;
   }
   .order_main_details {
    width: 85%;
   }
   .order_cart {
    padding: 20px 20px;
    /* height: 200px;   */
    background-color: #f6f6f6;
   }.order_img{
       width: 100%;
       height: 100%;
   }
   .order_detail {
    display: flex;
    column-gap: 5px;
    align-items: center;
    margin: 13px 0px;
   }
   .heading {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 21px;
    color: #1c1c1c;
    margin: 0px;
    padding: 0px;
   }.content{
   font-family: "Roboto", sans-serif;
   font-style: normal;
   font-weight: 500;
   font-size: 18px;
   line-height: 21px;
   color: #636365;
   margin: 0px;
   padding: 0px;
   }
   .img_div{
       height: 100%;
       width: 100%;
   }
   .transit_div{
       display: flex;
       justify-content: flex-end;
       align-items: center;
       column-gap: 10px;
   }.trasit_dot{
       height: 15px;
       width: 15px;
       border-radius: 50%;
       background-color: #665D6F;
   }.transit_text{
       font-family: "Roboto", sans-serif;
       font-style: normal;
       font-weight: 500;
       font-size: 18px;
       line-height: 23px;
       color: #665D6F;
   }
  
.hrs {
    opacity: 0.2;
    border: 1px solid #000000;
    margin:50px 0px;
}

.flex_img {
    width: 100%;
}

.img_detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.img_detail>div {
    display: flex;
    column-gap: 15px;
    justify-content: space-between;
    align-items: center;
}

.img_detail>div>h4 {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #1C1C1C;
    margin: 0px;
}

.img_detail>div>h6 {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #1C1C1C;
    margin: 0px;
}

.img_ids {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #636365;
    margin: 0px;
}

.dates {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #636365;
    margin: 0px;
}

.img_div {
    width: 100%;
    height: 420px;
}

.flex_description {
    padding: 0px 20px;
}

.main_heading {
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    font-size: 23px;
    line-height: 27px;
    color: #1c1c1c;
    text-align: left;
    text-transform: capitalize;
    margin-bottom: 7px;
}

.present_by {
    color: #636365;
    opacity: .7;
font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    margin-bottom: 10px;
    text-transform: capitalize;
}
.present_by span{
    text-decoration: underline;
}
.price_detail {
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin: 8px 0px 8px 0px;
}

.main_detail { 
    margin: 4px 0px;
}

.price_detail>h4 {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 29px;
    color: #1C1C1C;
    margin: 0px;
}

.mrp {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 29px;
    color: #665D6F;
    margin: 0px;
}

.tax_detail {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #665D6F;
    margin: 0px;
}

.rating {
    display: flex;
    column-gap: 10px;
    align-items: center;
    margin-bottom: 0px;
}

.rating>p {
    margin: 0px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    text-decoration-line: underline;
    color: #636365;
}

.para_description {
font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #636365;
    opacity: .8;
}
.main_detail p{
    margin-bottom: 5px;
}
.aply_btn{
    padding: 10px 35px;
      border-radius: 10px;
      background: #1c1c1c;
      border: 1px solid #1c1c1c;
      color: #fff;
  font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
      cursor: pointer;
      text-decoration: none !important;
      display: block;
  }
  .aply_btn:hover{
    background: #fff;
    color: #1c1c1c;
  }
  .cancl_btn{
    padding: 10px 35px;
      border-radius: 10px;
      background: #fff;
      border: 1px solid #1c1c1c;
      color: #1c1c1c;
  font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
      cursor: pointer;
      text-decoration: none !important;
      display: block;
  }
  .cancl_btn:hover{
    background: #1c1c1c;
    color: #fff;
  }
.btngrd{
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    gap: 10px;
}
.track_btn {
    width: fit-content;
    height: 30px;
}
.odrdtlb{
    border-bottom: 1px solid #ced4da;
    padding-bottom: 30px;
    margin-bottom: 30px;
}
.cancel_img {
    display: flex;
        justify-content: flex-end;
        position: absolute;
        right: 10px;
        z-index: 9999999;
        cursor: pointer;
        top: 10px;
  }
  .closicon{
    font-size: 35px;
      color: #1C1C1C;
  }
  .modal_heading {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  }
.text_center{
    text-align: center;
}
.labelv{
    text-align: center !important;
    color: #1c1c1c !important;
    opacity: .6;
  }
  .labelv p{
    font-size: 14px;
      margin-bottom: 0;
      word-break: break-all;
  }
  @media(min-width:768px) and (max-width:991px){
    .flex_description{
        padding: 10px 0px;
    }
    .btngrd{
        position: relative; 
    }
    .odrdtlb{
        padding-bottom: 10px;
    }
    .main_detail{
        margin-bottom: 14px;
        margin-top: 0;
    }
  }
  @media(max-width:767px){
   .makeofferModal {
       width: 100%;
   }
      .order_main {
          width: 100%;
         }
           .order_main_details{
          width: 100%;
         }
         
  }
@media(max-width:767px){
    .odrdtlb{
        padding-bottom: 10px;
        margin-bottom: 10px;
    }
    .main_detail{
        margin-bottom: 14px;
        margin-top: 0;
    }
    .mains {
        width: 100%; 
    }
    .btngrd{
        position: relative;
        display: grid;
    }
    .aply_btn{
        width: 100%;
        margin-bottom: 10px;
    }
    .cancl_btn{
        width: 100%;
        margin-bottom: 10px;
    }
    .flex_description{
        padding: 10px 0px;
    }
    .img_detail {
        padding:0px;
        display: block;
    }
    .img_div { 
        height: 300px;
    }
}