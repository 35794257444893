.mains {
    /* width: 69%;
    margin: auto; */
    background-color: #f6f6f6;
    /* background-color: #f6f6f6; */
}

.components { width: 69%;
    margin: auto;
}

.inputs_div {
    margin-bottom: 20px;
}

.input_label {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #1C1C1C;

}

.files_div {
    margin:20px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.new_heading {
    margin-top:10px;
    display: flex;
    align-items: center;
    column-gap: 10px;
    justify-content: space-between;
}
.btn_div{
    display: flex;
    justify-content: center;
    align-items: center;
}

.new_heading>h2 {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 33px;
    color: #1C1C1C;
    margin: 0px;
}

.new_heading>p {
    margin:0px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.377143px;
    color: #636365;
}

.hrs {
    width: 100%;
    opacity: 0.3;
    border: 1px solid #665D6F;
}
@media(max-width:600px){
    .mains {
        width: 100%;
    }
    
.files_div {
    margin:20px 0px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    justify-content: space-between;
    align-items: center;
}
}