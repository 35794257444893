.headerCategory {
  margin-top: 125px;
  width: 100%;
  background: url("./banner bg img.png");
  background-size: cover; 
  padding: 50px 0;
  display: flex;
  justify-content: center;
}
.flex_align{
 
  width: 100%;
  justify-content: center;
  text-align: center;
}
.no_result{
  margin-top: 50px;
  justify-content: center;
  text-align: center;
  display: flex;
  width: 100%;
}
.no_result h3{
  font-weight: 600;
}
.pageitle{
  text-align: center;
}
.pageitle h1{
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  font-weight: 500;
  line-height: 1.235;
  letter-spacing: 0;
  margin-bottom: 10px;
  margin-top: 0; 
}
.pageitle ul{
  padding: 0;
  list-style-type: none;
  margin: 0;
}
.pageitle ul li{
  display: inline-block;
  margin-top: 0; 
}
.pageitle ul li:last-child{
  padding-left: 5px;
}
.pageitle ul li a{
  font-family: "Roboto", sans-serif;
  font-size: 16px; 
  text-decoration: none !important;
  color: #1c1c1c;
}
.sec_sc{
  padding: 50px 0;
}
.link__div ul{
  list-style-type: none;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #b1b1b2;
  padding-bottom: 7px;
}
.link__div ul li{
  display: inline-block;
  padding-right: 12px;
  margin-right: 10px;
  position: relative;
  margin-top: 0;
}
.link__div ul li:last-child{
  margin-right: 0;
  padding-right: 0;
}
.link__div ul li::after{
  content: '|';
  color: #636365;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 22px;
  font-weight: 200;
  line-height: 20px;
}
.link__div ul li:last-child::after{
  display: none;
}
.link__div ul li a{
  font-family: "Roboto", sans-serif;
} 
.dtitle{
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: #1c1c1c;
  margin: 0;
  padding-bottom: 5px;
}
.atdec{
  font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #636365;
    opacity: 0.5;
    margin: 0;
    padding-top: 1px;
    padding-bottom: 6px;
    border-bottom: 1px solid #BDBDBD;
}

.divider {
  color: #636365;
}

.main__container {
  padding: 2% 12.5%;
}

.bid__timing {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.verticalLine {
  height: 50px;
  width: 0.1px;
  border-right: 1px solid #bdbdbd;
}

.auction__cards {
  margin-top: 6%;
}

.auctionImg {
  width: 100%;
}

.auction__timing {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2%;
}

.auction__price {
  display: flex
  ;
      justify-content: space-around;
      align-items: flex-start;
      margin-top: 8px;
      flex-wrap: wrap;
      width: 100%;
      margin-bottom: 7px;
      text-align: center;
}
.buys{
  font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #636365;
    opacity: 0.6;
    margin-bottom: 5px;
    display: block;
}
.picrs{
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #1c1c1c;
}
.auction__history {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5%;
}

.verticalLine2 {
  border-left: 1.5px solid grey;
  height: 1vw;
  /* margin-left: 4%;
  margin-right: 4%; */
}

@media (max-width: 767px) {
  .headerCategory {
    margin-top: 57px;
  }
}
