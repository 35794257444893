.mains{
    width:100%
}
    
.components{
    background-color: #f6f6f6;
    padding-bottom: 20px;
    border-radius: 10px;
}
@media(max-width:600px){
    .mains{
        width: 100%;
    }
}