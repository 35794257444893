.proddets__top {
  margin-top: 125px;
  padding-top: 2%;
}
.artplacing__div{
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  align-items: center;
}
.makeofferModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  max-height: 95%;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 10px;
  overflow-y: auto !important;
}
.widthfull{
  width: 100%;
}
.emailInfo {
  padding: 4%;
}

.first__lastname {
  display: flex;
  align-items: center;
  gap: 2.5%;
}

.nameInfo {
  padding: 2% 4%;
}

/* frame class names */
.whitecanvas {
  border-radius: 0px 10px;
  border-right: 13px solid rgb(240, 240, 240);
  border-bottom: 13px solid rgb(240, 240, 240);
  border-top-color: transparent;
  border-left-color: transparent;
  padding: 0px;
  max-width: 100%;
  max-height: 100%;
  box-shadow: rgb(204, 204, 204) 0px 1px 5px 2px;
  background: #fff;
}

.blackcanvas {
  border-radius: 0px 10px;
  border-right: 13px solid rgb(0, 0, 0);
  border-bottom: 13px solid rgb(0, 0, 0);
  border-top-color: transparent;
  border-left-color: transparent;
  padding: 0px;
  max-width: 100%;
  max-height: 100%;
  box-shadow: rgb(204, 204, 204) 0px 1px 5px 2px;
}

.noframe {
  background: white;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  box-shadow: rgb(204, 204, 204) 0px 1px 5px 2px;
  border: 1px solid rgb(237, 237, 237);
}

.artpaperblack {
  background: white;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  border: 13px solid rgb(0, 0, 0);
  box-shadow: rgb(204, 204, 204) 0px 1px 5px 2px,
    rgb(204, 204, 204) 2px 2px 2px 0px inset;
}

.artpaperwhite {
  background: white;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  border: 13px solid rgb(240, 240, 240);
  box-shadow: rgb(204, 204, 204) 0px 1px 5px 2px,
    rgb(204, 204, 204) 2px 2px 2px 0px inset;
}

.artpapernaturalwood {
  background: white;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  border: 13px solid rgb(239, 227, 210);
  box-shadow: rgb(204, 204, 204) 0px 1px 5px 2px,
    rgb(204, 204, 204) 2px 2px 2px 0px inset;
}

.artpapermetllightpewter {
  background: white;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  border-image: initial;
  box-shadow: rgb(204, 204, 204) 0px 1px 5px 2px,
    rgb(204, 204, 204) 2px 2px 2px 0px inset;
  border-width: 13px;
  border-style: solid;
  border-color: rgba(192, 183, 165, 0.698) rgb(192, 183, 165);
}

.artpapermetldarkpewter {
  background: white;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  border-image: initial;
  box-shadow: rgb(204, 204, 204) 0px 1px 5px 2px,
    rgb(204, 204, 204) 2px 2px 2px 0px inset;
  border-width: 13px;
  border-style: solid;
  border-color: rgba(121, 118, 111, 0.698) rgb(121, 118, 111);
}

/* frame class names end */
.prod__img {
  max-width: 100%;
  position: relative;
  /* max-height: 50vh; */
}

.main__img {
  max-width: 100%;
  height: auto;
  position: relative;
  /* max-height: 50vh; */
}

.verticalImage {
  content: "";
  background: url(../../../../public/img/vertical-frame.png);
  width: 93%;
  height: 100%;
  display: block;
  position: absolute;
  z-index: 99;
  left: 0;
  right: 0;
  top: 0;
  background-size: contain;
  bottom: 0;
  background-repeat: no-repeat;
}

.horizontalImage {
  content: "";
  background: url(../../../../public/img/horizontal-frame.png);
  width: 93%;
  height: 100%;
  display: block;
  position: absolute;
  z-index: 99;
  left: 0;
  right: 0;
  top: 0;
  background-size: contain;
  bottom: 0;
  background-repeat: no-repeat;
}

.small_img {
  margin-right: 2%;
  width: 14%;
  width: 10%;
}

.proddets_frame {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 98%;
}

.previewimg__div {
  /* margin-left: 7.5%; */
  margin-top: 1.5%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.outlineimg {
  margin-left: -10%;
  width: 6.5%;
}

.prodDetails {
  width: 100%;
  height: auto;
}

.prodDetails h1 {
  text-transform: capitalize;
  margin: 0;
  padding-bottom: 8px;
  font-family: "Roboto", sans-serif;
  font-size: 38px;
  font-weight: 500;
  line-height: 40px;
  text-align: left;
}

.prodDetails a {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  text-decoration-line: underline;
  text-decoration-style: solid;
  color: #636365;
  display: block;
  margin-bottom: 5px;
  cursor: pointer;
}

.mprice {
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  font-weight: 500;
  line-height: 33.35px;
  text-align: left;
  margin: 0;
  margin-right: 20px;
}

.prodDetails_top_section {
  display: block !important;
  width: 100%;
  flex-direction: row !important;
}

.pricing__section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 20px;
}

.sharediv,
.pricediv {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
}

.stock {
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 22.35px;
  text-align: right;
  margin: 0;
}

.ellipseshare {
  background: #f3f3f3;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
  text-align: center;
}

.ellipseheart {
  cursor: pointer;
  width: 30px;
  height: 30px;
  background: #f3f3f3;
  border-radius: 50%;
  line-height: 30px;
  text-align: center;
}

.ellipseheart .likeIcon {
  font-size: 18px;
  font-weight: 500;
  margin-right: 0;
}

.shricn {
  font-size: 18px;
  font-weight: 500;
}

.ratings__reactions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stratinglst {
  list-style-type: none;
  padding: 0;
  margin: 0 0;
}

.stratinglst li {
  display: inline-block;
  margin-right: 3px;
}

.stratinglst li:last-child {
  margin-right: 0;
}

.sticon {
  color: #2C2844;
  opacity: .2;
  font-size: 20px;
}

.starrating {
  margin-right: 0.5%;
  margin-top: 1.5%;
}

.viewIcon {
  color: #1C1C1C;
  margin-right: 5px;
  font-size: 16px;
}

.likeIcon {
  color: #1C1C1C;
  margin-right: 5px;
  font-size: 16px;
}

.proddets_dets {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 13px;
  border-bottom: 1px solid #d5d4da;
  padding-bottom: 13px;
}

.catgr {
  font-weight: 500;
  color: #1C1C1C;
  margin: 0;
}

.reslte {
  margin: 0;
  color: #1C1C1C;
  opacity: .5;
}

.greydescr {
  margin-right: 16%;
}

/* .greydescr_rent {
  margin-right: 18%;
} */
.para_description {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  color: #665d6f;
  opacity: 0.8;
  margin: 0px;
}

.para_description_remove {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 32px;
  color: #665d6f;
  opacity: 0.8;
  margin: 0px;
  margin-right: 7px;
  border-bottom: 1px solid #665d6f;
  cursor: pointer;
}

.dets__divider {
  width: 81%;
  margin-right: 18%;
  margin-top: 2%;
}

.prints__customization {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.buttonstyl {
  padding: 10px 25px;
  border-radius: 10px;
  background: #000;
  border: 1px solid #000;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  cursor: pointer;
}

.buttonstyl:hover {
  background: transparent;
  color: #000;
}

.buttonstylw {
  padding: 10px 25px;
  border-radius: 10px;
  background: #000;
  border: 1px solid #000;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  cursor: pointer;
  display: block;
  width: 100%;
}

.buttonstylw:hover {
  background: transparent;
  color: #000;
}

.bntbdr {
  padding: 10px 25px;
  border-radius: 10px;
  background: #fff;
  border: 1px solid #000;
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  cursor: pointer;
  display: block;
  width: 100%;
}

.bntbdr:hover {
  background: #000;
  color: #fff;
}

.custLink {
  margin-right: 18.5%;
  color: black;
}

.offerSection {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.offserhdng {
  display: flex;
  align-items: center;
  gap: 8px;
}

.offserhdng p {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 25px;
  text-align: left;
  color: #1c1c1c;
}

.ofrresn p {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: #1c1c1c;
  opacity: .4;
}

.vectorheart {
  width: 60%;
}

.vectorimages {
  width: 60%;
}

.description {
  margin-top: 2%;
  width: 100%;
}

.cfflex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.choose__frame {
  background-color: #f7f7f8;
  border-radius: 6px;
  border: 0.8px solid #ededf1;
  padding: 20px;
  margin-bottom: 15px;
}

.chosbtn {
  padding: 5px 10px;
  border-radius: 10px;
  background: #000;
  border: 1px solid #000;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 26px;
  cursor: pointer;
}

.sidePanel {
  width: 100%;
}

.verticalLine {
  border-left: 1.5px solid grey;
  height: 2.75vw;
}

.general__information {
  background-color: #f7f7f8;
  border-radius: 6px;
  border: 0.8px solid #ededf1;
  padding: 20px;
}

.descriptiongrid {
  padding: 50px 0;
}

.descriptiongrid .description h3 {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 26.04px;
  text-align: left;
  margin: 0;
  margin-bottom: 10px;
}

.descriptiongrid .description p {
  font-family: "Roboto", sans-serif;
  text-align: left;
  font-size: 15px;
  font-weight: 400;
  color: #636365;
  margin-top: 0;
  line-height: 26px;
}

.specicationlis {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.specicationlis li {
  display: flex;
  align-items: center;
  margin-top: 0;
  justify-content: space-between;
  position: relative;
  padding-left: 12px;
  margin-bottom: 18px;
}

.specicationlis li:last-child {
  margin-bottom: 0;
}

.specicationlis li span {
  color: #000000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  text-align: left;
  z-index: 99;
}

.specicationlis li span+span {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  color: #636365;
  z-index: 99;
}

.specicationlis li::before {
  content: '';
  border-left: 3px solid #000;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.oraimg {
  text-align: right !important;
  line-height: 0 !important;
}

.orientationmain {
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-right: 7.5%; */
}

.orientation1 {
  width: 40%;
}

.orientation2 {
  margin-right: 2%;
  width: 40%;
}

.orientation3 {
  width: 40%;
}

.features {
  padding: 50px 0;
  background-color: #f7f7f8;
}

.feturcont h4 {
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  font-weight: 600;
  line-height: 26px;
  text-align: left;
  color: #020A18;
  margin: 0 0 2px
}

.feturcont p {
  font-family: "Roboto", sans-serif;
  text-align: left;
  font-size: 15px;
  font-weight: 400;
  color: #636365;
  margin: 0;
  line-height: 25px;
}

.mini__truck {
  width: 25%;
  height: 25%;
}

.safe__shipping {
  display: flex;
}

.package {
  width: 25%;
  height: 25%;
}

.secure {
  width: 25%;
  height: 25%;
}

.proddets__middle {
  padding: 50px 0 50px;
}

.design__headingImage {
  display: flex;
  justify-content: center;
  align-items: center;
}

.design__image {
  width: 7.5%;
}

.design__heading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardImage {
  width: 100%;
  object-fit: contain;
}

.card__middle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card__main {
  /* background-color: #ccc; */
  width: 85%;
}

.card__middleRight {
  display: flex;
  margin-bottom: 6.5%;
}

.card__bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.verticalLine {
  border-left: 1.5px solid grey;
  height: 2.75vw;
}

.buy__text {
  margin-top: -8%;
}

.rent__text {
  margin-top: -8%;
}

.print__text {
  margin-top: -8%;
}

.ProductCards {
  margin-left: 1%;
}

.card__main {
  margin-bottom: 20%;
}

.ProductCards {
  margin-top: 1%;
}

.productrating {
  display: flex;
  gap: 15px;
  align-items: center;
  font-size: 14px;
}

.like {
  display: flex;
  align-items: center;
}
.makeofferModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  max-height: 95%;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 30px;
}

.clobtn {
  position: absolute;
  right: 10px;
  top: 10px;
}

.clobtn a {
  cursor: pointer;
}
.iconru {
  font-size: 30px;
  color: #1C1C1C;
}

.mpophndg {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  text-align: center;
}


@media(max-width:767px) {
  .makeofferModal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    max-height: 70%;
    overflow-y: scroll;
  }
  .proddets__top {
    margin-top: 57px;
  }
  .prodDetails>* {
    width: 100%;
  }

  .greydescr {
    margin-right: 0;
  }

  .productrating {
    justify-content: space-around;
  }

  .main__img {
    width: 100%;
  }

  .custLink {
    margin-right: 0;
  }
  .prodDetails h1{
    font-size: 25px;
    line-height: 26px;
  }
  .mprice{
    font-size: 22px;
    line-height: 25px;
    margin-right: 5px;
  }
  .proddets_dets{
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
 


