.img_view {
  width: 100%;
  height: 150px;
}
.otiobtn{
  display: flex;
      flex-direction: column;
      gap: 10px;
}

.del_img_div {
  position: absolute;
  height: 26px;
  width: 26px;
  right: 9px;
  top: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 5px;
}
.img_view_hide {
  border: none;
  width: 100%;
  background-color: #f3f3f3;
}
.textarea {
  width: 90%;
  min-width: 90%;
  max-width: 90%;
  max-height: 100px;
  background-color: #f3f3f3;
  color: #636365;
  outline: none;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  margin-left: 4%;
  font-size: 16px;
  color: #636365;
  border: none;
 
  /* margin-top: 8px; */
}
.textarea:focus {
  outline: none !important;
}

.makeofferModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  max-height: 95%;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 10px;
  overflow-y: auto !important;
}

.preview_img {
  width: 90%;
  background-color: #f3f3f3;
  margin: auto;
  height: 150px;
  border-radius: 5px;
  /* opacity: 0.5; */
  border: none;
  margin-top: 10px;
  position: relative;

}
.img_main {
  margin-top: 15px;
}
.lebless {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #1c1c1c;
}

.img_box {
  width: 100%;
  height: 100%;
  background-color: #f3f3f3;
  /* background-color: white; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* opacity: 0.5; */
  border-radius: 5px;
}

.label {
  /* margin: 5px 0px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.emailInfo {
  padding: 4%;
}

.first__lastname {
  display: flex;
  align-items: center;
  gap: 2.5%;
}
.nameInfo {
  padding: 2% 4%;
}

.offer__number {
  display: flex;
  align-items: center;
  gap: 2.5%;
}
.order_main_details {
 width: 85%;
}
.order_cart {
  padding: 20px 20px;
  background-color: #f6f6f6;
  margin-bottom: 15px;
  border-radius: 10px;
}.order_img{
    width: 100%;
    height: 100%;
}
.order_detail {
 display: flex;
 column-gap: 5px;
 align-items: center;
 margin: 13px 0px;
}
.heading {
 font-family: "Roboto", sans-serif;
 font-style: normal;
 font-weight: 700;
 font-size: 19px;
 line-height: 21px;
 color: #1c1c1c;
 margin: 0px;
 padding: 0px;
}.content{
font-family: "Roboto", sans-serif;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 21px;
color: #636365;
margin: 0px;
padding: 0px;
}
.img_div{
    height: 100%;
    width: 100%;
}
.transit_div{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 10px;
    margin-bottom: 15px;
}.trasit_dot{
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: #665D6F;
}.transit_text{
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #665D6F;
}
.odlist{
  color: #1C1C1C;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  margin-bottom: 10px;
}
.odlist span{
  color: #636365;
}
.aply_btn{
  padding: 10px 35px;
    border-radius: 10px;
    background: #1c1c1c;
    border: 1px solid #1c1c1c;
    color: #fff;
font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    cursor: pointer;
    text-decoration: none !important;
    display: block;
    width: 100%;
    margin-bottom: 10px;
}
.aply_btn:hover{
  background: #fff;
  color: #1c1c1c;
}
.cancl_btn{
  padding: 10px 35px;
    border-radius: 10px;
    background: #fff;
    border: 1px solid #1c1c1c;
    color: #1c1c1c;
font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    cursor: pointer;
    text-decoration: none !important;
    display: block;
    width: 100%;
    margin-bottom: 10px;
}
.cancl_btn:hover{
  background: #1c1c1c;
  color: #fff;
}
.buttonlist{
  display: flex;
}
@media(max-width:767px){
    .order_main {
        width: 100%;
       }
         .order_main_details{
        width: 100%;
       }
       .buttonlist{
        display: block;
       }
       .transit_div{
        justify-content: center;
       }
}