.hero_main {
  background-image: url(../../../assets/img/Banner.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-top: 125px;
  padding: 5% 0px;
}

.max_width {
  max-width: 600px;
  margin: 0 auto 10px;
  text-align: center;
}

.max_width .sub_title {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  color: #636365;

}

.stcgbox {
  box-shadow: 0 0 6px 2px #e7e9ec;
  border-radius: 22px;
  padding: 25px;
}

.whitext {
  color: #fff !important;
}

.pageitle h1 {
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: 0;
  margin-bottom: 10px;
  margin-top: 0;
  color: #ffffff;
}

a.apply_btnwht {
  text-decoration: none !important;
  display: inline-block;
}

.apply_btnwht {
  padding: 10px 35px;
  border-radius: 10px;
  background: #fff;
  border: 1px solid #fff;
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  cursor: pointer;
}

.apply_btnwht:hover {
  background: #1c1c1c;
  color: #fff;
}

.abttitle {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  text-transform: capitalize;
  color: #1c1c1c;
  margin: 0px;
  margin-bottom: 8px;
}

.abttitlefr {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 35px;
  text-transform: capitalize;
  color: #1c1c1c;
  margin: 0px;
  margin-bottom: 8px;
}

.prtra {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: #636365;
  opacity: .8;
  margin: 0;
  margin-bottom: 10px;

}

.apply_btn {
  padding: 10px 35px;
  border-radius: 10px;
  background: #1c1c1c;
  border: 1px solid #1c1c1c;
  color: #fff !important;
  font-family: DM Sans !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 26px !important;
  cursor: pointer;
  text-decoration: none !important;
  display: inline-block;
}

.apply_btn:hover {
  background: #fff;
  color: #1c1c1c !important;
}

.sec_title {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  text-transform: capitalize;
  color: #1c1c1c;
  margin: 0px; 
}

.sub_title {
  letter-spacing: 0.00938em;
  color: #1C1C1C;
  z-index: 10;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  line-height: 26px;
  margin-bottom: 20px;
  text-transform: capitalize;
  font-size: 20px;
  margin-top: 0;
}

.withor {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  color: #636365;
}

.text_center {
  text-align: center;
}

.title_image {
  max-width: 110px;
}

.img_fluid {
  max-width: 100%;
  height: auto;
}

.sec_sc {
  padding: 50px 0;
}

.stboxh {
  padding-top: 30px;
  height: 100%;
}

.stvob {
  border-radius: 10px;
  padding: 20px 25px;
  background: #F6F6F6;
  position: relative;
  height: 100%;
}

.sticon {
  top: -30px;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  position: absolute;
  box-shadow: 0px 10px 30px rgba(28, 28, 28, 0.3);
  background: #1c1c1c;
  padding: 12px;
}

.stvob h4 {
  margin: 0 0 5px;
  text-transform: capitalize;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  padding-top: 25px;
}

.stvob p {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: #636365;
  opacity: .8;
  margin: 0;
}

.hgrd {
  text-align: center !important;
  font-size: 18px !important;
  color: #fff !important;
  opacity: 1 !important;
  font-weight: 600 !important;
  line-height: 30px !important;
}

.withor {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  color: #636365;
}

.accordsc {
  box-shadow: 0px 6.14px 19.66px 0px #080F340F;
  border-radius: 22px;
  padding: 25px;
  background: #fff;
  height: 100%;
}

.benfhdgn {
  margin: 0;
  text-transform: capitalize;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #1C1C1C;
}

.bendesc {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: #636365;
  opacity: .8;
  margin: 0;
}

.sec_scexcl {
  background: #F6F6F6;
  padding: 50px 0;
}

.stboxh {
  padding-top: 30px;
  height: 100%;
}

.rechhbox {
  box-shadow: 0px 6.14px 19.66px 0px #080F340F;
  border-radius: 22px;
  padding: 25px;
  background: #fff;
  position: relative;
  height: 100%;
}

.rechhbox h4 {
  margin: 0 0 5px;
  text-transform: capitalize;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  padding-top: 25px;
}

.rechhbox p {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: #636365;
  opacity: .8;
  margin: 0;
}

.talhg {
  padding-bottom: 25px;
  height: 100%;
}

.talgbx {
  box-shadow: 0 0 6px 2px #f8f2f2;
  border-radius: 22px;
  padding: 20px;
  background: #fff;
  height: 100%;
}

.taldartist .talnartst {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  overflow: hidden;
  zoom: 1;
}

.talenimg img {
  border-radius: 20px;
  padding: 0;
}

.talentcont {
  margin-top: 10px;
}

.talentcont h4 {
  margin: 0 0 5px;
  text-transform: capitalize;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #1C1C1C;
}

.talentcont p {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: #636365;
  opacity: .8;
  margin: 0;
}

.testmibox {
  padding: 25px;
  background: url(/public/img/testimon-bg.png);
  background-size: cover;
  background-position: center;
  padding-bottom: 35px;
}

.tecont {
  padding: 0 20px;
}

.test_metainfo {
  padding-bottom: 18px;
}

.test_cont {
  padding: 0 25px;
  position: relative;
  min-height: 96px;
}

.test_cont::before {
  content: '';
  background: url(/public/img/test-quote.png);
  position: absolute;
  left: 0;
  background-size: cover;
  width: 15px;
  height: 10px;
}

.test_cont::after {
  content: '';
  background: url(/public/img/test-quote.png);
  position: absolute;
  right: 0;
  background-size: cover;
  width: 15px;
  height: 10px;
}

.test_card_heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 180%;
  color: #ededed;
  margin: 0px;
}

.test_card_subhead {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 180%;
  color: #b6b6b6;
  margin: 0px;
}

.test_card_des {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  color: #f6f6f6;
  margin: 0px;
}
.mt_1{
  margin-top: 10px;
}
.mt_2 {
  margin-top: 20px !important;
}

.mt_5 {
  margin-top: 60px;
}

.hders {
  display: flex;
  gap: 15px;
  align-items: center;
  margin-bottom: 15px;
}

.test_cont h4 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #ffff;
}

.faq_box {
  align-items: center;
}

.faqhdr {
  margin: 0;
  text-transform: capitalize;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #1C1C1C;
}

.faqhdrExpanded {
  color: #1C1C1C;
}

.faqx p {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: #636365;
  opacity: .8;
  margin: 0;
}

.readMoreBtn {
  background: none;
  cursor: pointer;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 25px;
  text-align: left;
  color: #1c1c1c;
  padding-top: 6px;
  border: 0;
}

.feabc {
  display: flex;
  gap: 10px;
}

.feabc h4 {
  margin: 0 0;
  text-transform: capitalize;
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

.feabc p {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: #636365;
  opacity: .8;
  margin: 0;
}

.iconst {
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  background: #fff;
  text-align: center;
  min-width: 30px;
  box-shadow: 0px 3.14px 5.66px -1px #080f344d;
}

.usicon {
  color: #1C1C1C;
  font-size: 20px;
}

.tekd {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  text-transform: capitalize;
  color: #1c1c1c;
  margin: 0px;
  margin-bottom: 20px;
  text-align: center;
}

.spcebtnm {
  margin-top: 35px;
}

.arconsec {
  padding: 50px 0;
  background: url(/public/img/testimon-bg.png);
  background-size: cover;
  background-position: center;
}

.arconsec h3 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: #fff;
}

.pytrg {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  color: #fff;
}

.termlistnum {
  list-style-type: decimal;
}

.termlist li,
.termlistnum li {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: #636365;
  margin: 0;
  margin-bottom: 15px;
}

.termlist li span,
.termlistnum li span {
  color: #1C1C1C;
  font-weight: 600;
}

.termlist li ol {
  list-style-type: circle;
}

.faqx li {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: #848485;
  margin: 0;
  margin-bottom: 10px;
}

.faqx li span {
  color: #1C1C1C;
  font-weight: 600;
}

.linkdf {
  padding: 10px 35px;
  border-radius: 10px;
  background: #1c1c1c;
  border: 1px solid #1c1c1c;
  color: #fff !important;
  font-family: DM Sans !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  cursor: pointer;
  text-decoration: none !important;
  display: block;
  text-align: center;

}

.linkdf:hover {
  background: #fff;
  color: #1C1C1C !important;
}

.hdrng {
  margin-bottom: 10px;
}

.rechhbox2 {
  box-shadow: 0px 6.14px 19.66px 0px #080F340F;
  border-radius: 22px;
  padding: 25px;
  background: #fff;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.rechhbox2 h4 {
  margin: 0 0 5px;
  text-transform: capitalize;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  padding-top: 25px;
}

.rechhbox2 p {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: #636365;
  opacity: .8;
  margin: 0;
}
.sec_shgn .tab_content {
  box-shadow: 0px 6.14px 19.66px 0px #080F340F;
  border-radius: 22px;
  border-top-left-radius: 0;
  padding: 25px;
  background: #fff;
}

.sec_shgn .tab_content p {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: #636365;
  opacity: .8;
  margin: 0;
}
.tablist {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.tablist li {
  margin-top: 0;
  display: inline-block;
  margin-right: 5px;
}

.tablist li a {
  text-decoration: none;
  padding: 8px 20px;
  border-radius: 10px;
  background: #fff;
  border: 1px solid #fff;
  color: #1c1c1c;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  display: block;
  cursor: pointer;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.nedhlp{
  text-align: center;
  padding: 40px 0 0px;
}

.ourartistcard {
  width: 100%;
}

.ourartistinfo {
  background: #fff;
  display: block;
  position: relative;
  padding: 10px;
  padding-bottom: 15px;
}

.ourartistinfo h4 {
  margin: 0px 0 5px;
  text-transform: capitalize;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
}

.tdr {
  color: #1c1c1c !important;
  opacity: .7;
}

.ourartistinfo p {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
  color: #636365;
  opacity: .8;
  margin: 0;
} 
.hbox1 h4{
  margin: 0 0 5px;
  text-transform: capitalize;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}
.hbox1 p{
  font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    text-align: left;
    color: #636365;
    opacity: .8;
    margin: 0;
}
.idnumbr{
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background: #1c1c1c;
  text-align: center;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 25px;
  font-weight: 600;
  line-height: 55px;
  margin-bottom: 10px;
}
.timeline{
  padding: 0;
  margin-bottom: 0;
  list-style-type: none;
  position: relative;
  counter-reset: timeline-counter;
}
.timeline li{
  padding-left: 75px;
  margin-top: 0;
  counter-increment: timeline-counter;
  position: relative;
}
.timeline li::before{
  content: counter(timeline-counter);
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background: #fff;
  text-align: center;
  color: #1c1c1c;
  font-family: "Roboto", sans-serif;
  font-size: 23px;
  font-weight: 600;
  line-height: 55px;
  position: absolute;
  left: 0;
  top: -15px;
  z-index: 99;
  box-shadow: 0px 6.14px 19.66px 0px #080F340F;
}
/* .timeline li::after{
  content: '';
  height: 100%;
  width: 1px;
  background: #1c1c1c;
  position: absolute;
  top: 20%;
  left: 4.4%;
} */
.timeline li:last-child:after{
  display: none;
}
.whibg .timeline li::before{
  box-shadow: 0px 6.14px 19.66px 0px #080f3426;
}
.stepli{
  margin-bottom: 20px;
}
.stepli h4{
  margin: 0 0 5px;
  text-transform: capitalize;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #1C1C1C;
}
.stepli p{
  font-family: "Roboto", sans-serif;
   font-size: 15px;
   font-weight: 400;
   line-height: 25px;
   text-align: left;
   color: #636365;
   opacity: .8;
   margin: 0;
}
.stepli .faqx li{
  padding-left:0
}
.stepli .faqx li::before{
  display: none;
}
.mtb_2{
  margin-top: 35px;
}
@media(max-width:767px) {
  .hero_main {
    margin-top: 57px;
  }
.pageitle h1{
  font-size: 25px;
        line-height: 26px;
        margin-bottom: 5px;
}
  .mobcenter {
    text-align: center;
  }

  .prtra {
    text-align: center;
  }
.arconsec{
  text-align: center;
}
  .abttitle, .sec_title, .arconsec h3 {
    text-align: center;
    font-size: 23px;
        line-height: 26px;
  }

  .leftText {
    text-align: center;
    font-size: 23px;
        line-height: 26px;
  }

  .spcebtnm {
    margin-top: 0px;
  }
  .tekd{
    font-size: 21px;
        line-height: 24px;
  }
  .mt_5{
    margin-top: 50px;
  }
  .talentcont h4{
    text-align: center;
  }
  .talentcont p{
    text-align: center;
  }
  .talentcont{
    text-align: center;
  }
  .tecont{
    padding: 0;
  }
  .tesarrow{
    margin-top: 0;
  }
  .tablist li{
    display: block;
    margin-right: 0;
  }
  .tablist li a{
    margin-bottom: 10px;
    border-radius: 10px;
  }
  .sec_shgn .tab_content{
    border-radius: 22px;
  }
  .mt_2{
    margin-top: 0 !important;
    text-align: center;
  }
  .tab_content p{
    text-align: center !important;
  }
}