.mains {
  margin-top: 125px;
  padding: 50px 0;
}
.title_image {
  max-width: 110px;
}
.text_center{
  text-align: center;
}
.components {
  box-shadow: 0 0 6px 2px #e7e9ec;
  border-radius: 22px;
  padding: 25px;
  margin-top: 20px;
}
.components > h1 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 39px;
  color: #1c1c1c;
}

.input_div {
  margin: 15px 0px;
}

.input_label {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: #1c1c1c;
}
.labelv{
  color: #1c1c1c !important;
  opacity: .6;
  text-align: center !important;
}
.labelv p{
  font-size: 14px;
  margin-bottom: 0;
  word-break: break-all;
} 
.upload_img {
  /* margin: 0px 100px; */
  margin: 10px 0px;
  
}

.preview_img {
  width: 100%;
  height: 100%;

}


.img_box {
  width: 100%;
  height: 150px;
  background-color: #f6f6f6;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f6f6f6;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  overflow: hidden;
}

.label {
  /* margin: 5px 0px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.label > p {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #665d6f;
  margin: 5px 0px;
  opacity: 0.5;
}

.img_view {
  width: 100%;
  object-fit: cover;
  border-radius: .25rem;
  overflow: hidden;
  height: 250px;
}

.img_view_hide {
  border: 1px solid red;
  width: 100%;
  background-color: #f6f6f6;
}

.submit_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px 0px 0;
}

@media (max-width: 600px) {
  .mains {
    width: 100%;
    /* background-color: red; */
    background-color: #f6f6f6;
    /* padding:25px; */
    margin-top: 20px;
  }
}
