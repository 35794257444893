
.rbent{    border-bottom: 1px solid #ced4da;
    margin-bottom: 30px;
}
.back_button {
    border-bottom: 1px solid #ced4da;
    margin-bottom: 30px;
  }
  .back_button a{
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 10px;
    margin-bottom: 0;
    color: #665D6F !important;
    opacity: .8;
    text-decoration: none !important;
    cursor: pointer;
    display: block;
  }
 .sethng {
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    color: #1c1c1c;
    text-align: left;
    text-transform: capitalize;
    margin-bottom: 7px;
  }
.main_heading {
    font-family: "Roboto", sans-serif;
    color: #333333;
    font-size: 21px;
    font-weight: 700;
    padding-bottom: 10px;
    margin-bottom: 0;
}
.art_namms{
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 29px;
    color: #1c1c1c;
    margin: 0;
    text-transform: capitalize;
    text-align: left;
}
.bdngr{
    padding-bottom: 15px;
    border-bottom: 1px solid #ced4da;
    margin-bottom: 15px;
}

.remark_text {
 font-family: "Roboto", sans-serif;
 font-style: normal;
 font-weight: 500;
 font-size: 18px;
 line-height: 23px;
 color: #1c1c1c;
}

.schedule_time {
 font-family: "Roboto", sans-serif;
 font-style: normal;
 font-weight: 500;
 font-size: 18px;
 line-height: 23px;
 color: #636365;
}

.detail_id > h6 {
 margin: 0px;
 font-family: "Roboto", sans-serif;
 font-style: normal;
 font-weight: 500;
 font-size: 18px;
 line-height: 23px;
 color: #1c1c1c;
}

.detail_id > p {
 margin: 0px;
 font-family: "Roboto", sans-serif;
 font-style: normal;
 font-weight: 400;
 font-size: 18px;
 line-height: 23px;
 color: #636365;
}

.status {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
}

.status > h1 {
 font-family: "Roboto", sans-serif;
 font-style: normal;
 font-weight: 500;
 font-size: 30px;
 line-height: 39px;
 color: #1c1c1c;
 margin: 0px;
}

.status > p {
 font-family: "Roboto", sans-serif;
 font-style: normal;
 font-weight: 400;
 font-size: 22px;
 line-height: 29px;
 text-align: right;
 color: #636365;
 margin: 0px;
}

.title {
 font-family: "Roboto", sans-serif;
 font-style: normal;
 font-weight: 500;
 font-size: 20px;
 line-height: 26px;
 color: #1c1c1c;

}

.content {
    margin-right: 5px;
    display: inline-block;
    margin-bottom: 0;
}

.item_acc_dec_div {
    text-align: right;
}

.action_btn {
 width: 156px;
 height: 54px;
 border-radius: 10px;
}
.bsrd{
    padding-bottom: 15px;
    border-bottom: 1px solid #ced4da;
    margin-bottom: 15px;
}
.art_name {
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    color: #1c1c1c;
    text-align: left;
    text-transform: capitalize;
    margin-bottom: 7px;
}
.odlist{
    color: #1C1C1C;
font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    margin-bottom: 10px;
}
.odlist span{
    color: #636365;
    opacity: .8;
}
.textright{
    text-align: right;
}
@media (max-width: 767px) {
    .item_acc_dec_div{
        text-align: center;
    } 

 .status {
  justify-content: center;
 }  
}
