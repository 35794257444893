.sections {
  position: relative;
  width: 100%;
  /* display: flex; */
}

/*  */
.leftAligned {
  display: flex;
  flex-direction: row-reverse;
}

.rightAligned {
  display: flex;
  flex-direction: row;
}

.plans {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  column-gap: 20px;
  padding: 0px;
  margin-bottom: 20px;
}

.rating_plans {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  column-gap: 20px;
  padding: 0px;
  margin-bottom: 20px;
  height: 100%;
}

.rating_div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 2px;
}

.right_actions {
  position: absolute;
  bottom: 100%;
  left: 8%;
}

.left_actions_rating {
  position: absolute;
  bottom: 50%;
  left: -5%;
}

.right_actions_rating {
  position: absolute;
  bottom: 50%;
  right: -11%;
}

.left_actions_price {
  position: absolute;
  bottom: 50%;
  left: 5%;
  z-index: 1;
}

.right_actions_price {
  position: absolute;
  bottom: 50%;
  right: 2%;
  z-index: 1;
}

.trianglebtn {
  /*width: 50px;
  height: 50px;
   margin-left: 10%; */
  /* margin-top: 5%; */
  cursor: pointer;
  font-size: 25px;
}

.left_active {
  border: 1px solid black;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f6f6f6;
  z-index: 1;
}

.left_inactive {
  position: relative;
  cursor: not-allowed;
  background-color: white;
  height: 50px;
  width: 50px;
  border: 1px solid black;
  border-radius: 50%;
  z-index: 1;
}

.category_card {
  min-width: 100%;
  position: relative;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  transition-duration: 3s;
  scroll-behavior: smooth;
  margin-bottom: 20px;
}

.category_img_div {
  height: 330px;
  min-height: 330px;
  max-height: 330px;
  /* width: 260px; */
  overflow: hidden;
}

.category_img_div img {
  transition: transform 0.5s ease;
  width: 100%;
}

.category_img_div:hover img {
  transform: scale(1.2);
  /* border: 1px solid black;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.7); */
}

.category_img_div:hover {
  border: 2px solid #000000;
  box-shadow: 0px 7px 10px 0px #0000004D;
}

.category_text {
  position: absolute;
  bottom: 8%;
  /* height: 20px; */
  width: 100%;
  margin: auto;
  /* align-self: center; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.category_text_child {
  background-color: white;
  margin: 0px auto;
  width: 85%;
  /* display: flex;
   */
  text-align: center;
}

.category_text_child>h4 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  margin: 10px 0px;
  text-align: center;
  text-transform: capitalize;
  color: #1c1c1c;
}

.pic_info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 7px;
  text-align: center;
  overflow: hidden;
}

.pic_info .card__middle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  display: block;
}

.price_sec {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin-top: 8px;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 7px;
  text-align: center;
}

.hr_line {
  margin-top: 0px;
}

.card__main {
  width: 100%;
  cursor: pointer;
}

.card__top {
  position: relative;
  height: 350px;
  max-height: 350px;
  min-height: 350px;
  overflow: hidden;
  border: 1px solid #f1f7fd;
}

.card__top img {
  object-fit: fill;
  height: 100%;
}

.card__main:hover .card__top {
  /* box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.7); */
  box-shadow: 0px -8px 16px -5px rgba(0, 0, 0, 0.301),
    -10px -2px 16px -10px rgba(0, 0, 0, 0.301),
    10px -2px 16px -10px rgba(0, 0, 0, 0.301);
  /* }.card__top {
    height: 220px; */
}

.info_like {
  display: flex;
  justify-content: center;
  column-gap: 8px;
  align-items: center;
}

.wishlst {
  width: 25px;
  height: 25px;
  background: #f3f3f3;
  border-radius: 50%;
  line-height: 25px;
  text-align: center;
}

.like_count {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  color: #1c1c1c;
}

.hrs {
  height: 50px;
  width: 0.1px;
  border-right: 1px solid #bdbdbd;
}

.buys {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #636365;
  opacity: 0.6;
  margin-bottom: 5px;
  display: block;
}

.prices {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #1c1c1c;
}

.titles {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  text-transform: capitalize;
  color: #1c1c1c;
}

.names {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #636365;
  opacity: 0.5;
  margin: 0;
  padding-top: 1px;
  padding-bottom: 6px;
  border-bottom: 1px solid #BDBDBD;
  text-transform: capitalize;
}

.price_img_div {
  position: relative;
  /* width: 150px; */
  height: 280px;
}

.price_img {
  object-fit: fill;
}

.price_plans {
  width: 75%;
  /* heigt: max-content; */
  margin: auto auto 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  column-gap: 20px;
  padding: 0px;

  /* margin-left: 10%; */
}

.price_card {
  border: 1px solid #000000b7;
  padding: 12px;
  margin-bottom: 20px;
  /* max-width: 20%; */
}

.price_card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.7);
}

.price_action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Roboto", sans-serif;
  margin: 0;
}

.feature_action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;
}

.feature_card {
  border: 1px solid rgba(99, 99, 101, 0.2);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.06);
  padding: 10px;
  /* width: 85%; */
}

.featured_img_div {
  position: relative;
}

.main_img_div {
  height: 150px;
}

.featured_img_div>div {
  display: flex;
  justify-content: space-between;
  column-gap: 5px;
  margin-bottom: 7px;
}

.featured_img_div>div>div {
  width: 100%;
}

.artist_img {
  width: 70px;
  height: 70px;
  border: 3px solid white;
  border-radius: 50%;
  position: absolute;
  top: 43%;
  /* modified */
  left: 38%;
  cursor: pointer;
}

.ratings {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ratings>h5 {
  white-space: nowrap;
  word-spacing: 0;
  letter-spacing: 0px;
  color: rgba(0, 0, 0, .6);
  font-size: 13px;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
}


.rating_main>h2 {
  font-family: "Roboto", sans-serif;
  line-height: 19px;
  color: #1c1c1c;
  font-size: 14px;
  font-weight: 500;
  height: 16px;
  margin: 0 0 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.ratings {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #0000005a;
  margin-bottom: 10px;
}

.img_type {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  margin: 10px 0px;
}

.rating_main>h6 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 14px;
  margin: 10px 0px;
  color: #1c1c1c;
}

.rating_main>p {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin: 0 0 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  color: #636365;
  opacity: .8;
}

.card_div {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 30px;
  width: 100%;
  height: 100%;
}

.card_testimonials {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 500px;
  column-gap: 30px;
  background-color: black;
  padding: 20px 30px;
  box-shadow: 0px 20px 50px rgba(18, 17, 39, 0.08);
}

.testimonial_img_div>img {
  border-radius: 0px 50% 50% 50%;
}

.test_card_heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 180%;
  color: #ededed;
  margin: 0px;
}

.test_card_subhead {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 180%;
  color: #b6b6b6;
  margin: 0px;
}

.test_card_des {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 180%;
  color: #f6f6f6;
  margin: 0px;
}

.tesimonial_btns {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 50px;
}

.left_arrow {
  width: 100px;
  height: 40px;
  /* transform: scale(2.8); */
  cursor: pointer;
}

.right_arrow {
  width: 100px;
  height: 40px;
  /* transform: scale(2.8); */
  cursor: pointer;
}

.left_arrow_notactive {
  color: #000000;
  opacity: 0.2;
  cursor: not-allowed;
}

.right_arrow_notactive {
  color: #000000;
  opacity: 0.2;
  cursor: not-allowed;
}

.by_price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.by_price>p {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  text-transform: capitalize;
  color: #1c1c1c;
  margin: 0px;
  margin-left: 2%;
}

.carousel_btn {
  height: 50px;
  position: relative;
  top: 50%;
  border-radius: 50%;
}

.right_active {
  position: absolute;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.right_inactive {
  cursor: not-allowed;
  position: absolute;
  height: 50px;
  width: 50px;
  background-color: white;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  color: #000000;
}

.left_actions {
  position: absolute;
  bottom: 50%;
  left: 8%;
}

.art_img_div {
  height: 100px;
}

/* hero */
.homepage__LandingPage {
  background: url("../../../../assets/img/Background\ Image.png");
  background-color: #f7f7f7;
  width: 100%;
  background-size: cover;
  position: relative;
  padding: 13% 0;
  background-position: center;
}

.landingpage__Images {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* Center the image vertically */
  width: 50%;
  /* Adjust this based on how much space you want the image to take */
  height: 500px;
  padding-right: 3%;
}

.images {
  position: relative;
}

.image__item1 {
  z-index: 2;
  left: 0%;
  position: relative;
  width: 60%;
  height: 60%;
}

/* .image__item1 {
  z-index: 1;
  position: relative;
  width: 100%; 
  height: auto;
} */
.top__Image {
  width: 100%;
  height: 100%;
  /* object-fit: contain; */
  /* margin-left: 10%;
  margin-top: -5%; */
}

/* .top__Image {
  width: 100%;
  height: auto;
  object-fit: contain;
} */
.image__item2 {
  z-index: 1;
  position: absolute;
  top: 20%;
  left: 12%;
  width: 59%;
}

.top__Image2 {
  width: 100%;
  height: 75%;
  /* position: a; */
  /* margin-left: -47%;
  margin-top: 37.5%; */
}

.image__item3 {
  z-index: 3;
  position: absolute;
  left: 39%;
  top: 36%;
  height: 90%;
  width: 40%;
}

.top__Image3 {
  width: 100%;
  height: 100%;
  object-fit: contain;
  /* margin-left: -128%;
  margin-top: 55%; */
}

.actnicons {
  position: absolute;
  top: 10px;
  right: 10px;
}

.actnicons ul {
  padding: 0;
  list-style-type: none;
}

.actnicons ul li {
  margin-bottom: 5px;
}

.actnicons ul li:last-child {
  margin-bottom: 0;
}

.actnicons ul li a {
  background: #1c1c1c;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: block;
  text-align: center;
  line-height: 35px;
  color: #fff;
}

.landingpage__Text {
  text-align: center;
}

.landingpage__Text h5 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #fff;
  text-align: center;
  font-size: 35px;
  margin-top: 0;
  margin-bottom: 15px;
}

.landingpage__Text h3 {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  color: #fff;
  text-align: center;
  font-size: 60px;
  margin-top: 0;
  margin-bottom: 30px;
  line-height: 70px
}
.btnme{
  text-align: right;
}
.feature_action h4{
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  text-transform: capitalize;
  color: #1c1c1c;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}
/* 
.landingpage__Text button {
  padding: 10px 25px;
  border: 1px solid #fff;
  font-family: 'Poppins';
  text-align: center;
  text-transform: capitalize;
  background: #fff;
  border-radius: 10px;
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 26px;
  margin: 0 auto;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: 0.3s;
  display: flex;
    align-items: center;
    justify-content: center;z-index: 9999;
}
.landingpage__Text button:before {
  content: '';
  display: inline-block;
  width: 0;
  height: 100%;
  background-color: #000000;
  position: absolute;
  z-index: -1;
  transition: 0.3s;
  width: 0;
    height: 0;
    border-radius: 10px;
}

.landingpage__Text button:hover:before {
  width: 100%;
  height: 100%;
}

.landingpage__Text button:hover {
  border-color: #000000;
  color: #fff;
} */
@media(max-width:767px) {
  .landingpage__Text h3 {
    font-size: 25px;
    line-height: 26px;
  }
  .feature_action h4{
    text-align: center;
  }
  .landingpage__Text h5 {
    font-size: 18px;
  }
.btnme{
  text-align: center;
}
.rating_main {
  width: 65%;
  text-align: center;
  margin: 0 auto;
}
.ratings{
  flex-direction: column;
}
}

@media (max-width: 540px) {
  .sections {
    position: relative;
    width: 100%;
    margin: auto;
  }

  .card_testimonials {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 90%;
    column-gap: 30px;
    background-color: black;
    padding: 20px 30px;
    box-shadow: 0px 20px 50px rgba(18, 17, 39, 0.08);
  }

  

  .left_actions {
    left: -0%;
  }

  .right_actions {
    right: -0%;
  }

  .left_actions_rating {
    position: absolute;
    bottom: 50%;
    left: -11%;
  }

  .category_img_div {
    width: 100%;
  }

  .category_text_child {
    width: 72%;
  }

  .left_actions_price {
    position: absolute;
    bottom: 50%;
    left: -13%;
    z-index: 1;
  }

  .right_actions_price {
    position: absolute;
    bottom: 50%;
    right: -13%;
    z-index: 1;
  }

  .price_plans {
    width: 95%;
  }

  .left_active {
    height: 40px;
    width: 40px;
  }

  .left_inactive {
    height: 40px;
    width: 40px;
  }

  .right_active {
    height: 40px;
    width: 40px;
  }

  .right_inactive {
    height: 40px;
    width: 40px;
  }

  .card__top>img {
    height: 100%;
  }

  .card__mai {
    width: 100%;
    width: 100%;
    cursor: pointer;
  }

  .category_card {
    width: 85%;
  }

  .price_img_div {
    position: relative;
    height: 210px;
    /* height: 210px; */
  }
}

@media (max-width: 300px) {
  .category_card {
    height: 200px;
  }

  .category_img_div {
    height: 200px;
  }

  .feature_card {
    width: 200px;
  }
}