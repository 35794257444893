.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
  
  .modalContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #FFFFFF;
    font-family: "Roboto", sans-serif;
    border-radius: 10px;
    font-weight: 700;
    max-height:95%;
    overflow-y: auto;
    color: #1C1C1C;
    border: none;
    padding:30px;
    width: 40%;
  }
  .mpophndg{
font-family: "Roboto", sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    text-align: center;
  }
  .clobtn{
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .clobtn a{
    cursor: pointer;
  }
  .iconru{
    font-size: 30px;
    color: #1C1C1C;
  } 
  .warningText {
    color: #ff0000;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .description {
    text-align: left;
    margin-bottom: 20px;
  }
  .cancel_img {
    display: flex;
    justify-content: flex-end;
    /* margin-right: 10px; */
    cursor: pointer;
    /* margin-top: -24px; */
}
  .buttonGroup {
    display: flex;
        justify-content: center;
        margin-top: 20px;
        gap: 10px;
        margin-bottom: 10px;
  }
  .description p{
font-family: "Roboto", sans-serif;
    font-size: 17px;
    line-height: 23px;
    font-weight: 600;
    margin-bottom: 0;

  }
  .description ul{
    color: #636365;
    opacity: .8;
font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
  }
  @media(min-width:993px) and (max-width:1025px){
    .modalContent{
      width: 60%;
    }
  }
  @media(min-width:768px) and (max-width:992px){
    .modalContent{
      width: 80%;
    }
  }
  @media(max-width:767px){
    .modalContent{
      width: 95%;
    }
    .buttonGroup{
      flex-direction:column
    }
  }