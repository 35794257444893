.mains{
    background-color: #F6F6F6;
    padding: 20px;
    border-radius: 10px;
}
.action_div{
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 5%;
    background-color: #f6f6f6;
    padding-top: 20px;
    margin-top:-20px;
}
.action_div .btn{
    width: 140px;
    height: 54px;
    border-radius: 10px;
}