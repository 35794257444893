.mains {
    background-color: #f6f6f6;
    border-radius: 10px;
    padding: 20px;
}

.content {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #636365;
    margin-bottom: 0;
}

.action_btns {
    display: flex;
    justify-content: center;
    column-gap: 15px;
    align-items: center;
}
@media(max-width:767px){
    
    .action_btns{
        padding: 10px 10px;
        display: flex;
        text-align: center;
        flex-direction: column;
        gap: 13px;
    }
}