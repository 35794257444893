.headerCategory {
  width: 100%;
  background: url("./banner bg img.png");
  background-size: cover;
  padding-top: 5%;
  padding-bottom: 5%;
  margin-top: 125px;
}

.pageitle {
  text-align: center;
}

.pageitle h1 {
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  font-weight: 500;
  line-height: 1.235;
  letter-spacing: 0;
  margin-bottom: 10px;
  margin-top: 0;
}

.pageitle ul {
  padding: 0;
  list-style-type: none;
  margin: 0;
}

.pageitle ul li {
  display: inline-block;
  margin-top: 0;
}

.pageitle ul li:last-child {
  padding-left: 5px;
}

.pageitle ul li a {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #14142B;
  text-decoration: none !important;
}

.formsec {
  padding: 50px 0;
}

.mainForm h3 {
  font-family: "Roboto", sans-serif;
  font-size: 45px;
  font-weight: 400;
  line-height: 50px;
  text-align: left;
  color: #1c1c11;
  margin: 0 0 13px;
}

.img_fluid {
  max-width: 100%;
  height: auto;
}

.headerTitle {
  margin-left: 38%;
}
 

.headerSubTitle {
  margin-left: 35.2%;
}

.header__div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 8%;
  margin-bottom: 1.5%;
  margin-top: 1.5%;
}

.frameImg {
  width: 90%;
  margin-left: 8%;
}

.framedImg {
  width: 100%;
  margin-left: 16.5%;
}

.framedImg__div {
  width: 70%;
  margin-left: 3.7%;
  margin-top: -110%;
  margin-left: 6.6%;
}

.formDiv {
  margin-bottom: 5%;
  padding: 0% 16%;
  margin-top: 3%;
  background-color: transparent !important;
}
   .mainForm {    box-shadow: 0 0 6px 2px #e7e9ec;
      border-radius: 22px;
      padding: 25px;}

@media(max-width:767px) {
  .headerCategory {
    margin-top: 57px;
  }
}