.mains {
    width: 100%;
    margin: auto;
}
.back_img2{
    position: relative;
    top: 50px;
    right: 170px;
    cursor: pointer;
}
@media(max-width:767px){
   .back_img2 {
        position: relative;
    top: 10px;
    right: 0;
    left: 0;
    cursor: pointer;
    padding: 20px;
    padding-bottom: 0;
    text-align: center;
    }
}