.mains {
    margin-top: 125px;
    padding: 50px 0;
}
.text_center{
    text-align: center;
}
.title_image{
    max-width: 110px;
}
.components {
    box-shadow: 0 0 6px 2px #e7e9ec;
    border-radius: 22px;
    padding: 25px;
    margin-top: 20px;
}

.result {
    /* min-height: 100%; */
    max-height: auto;
    width: 100%;
    /* background-color: #272c34; */
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: left;
    position: relative;
  
  }
  .del_img_div {
    position: absolute;
    height: 26px;
    width: 26px;
   top: 0;
  left: 6;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }
  .imgs {
    width: 100px;
    height: 100px;
    /* object-fit: cover; */
    padding: 0.75rem;
  
  }
 .main_divs_category {
       /* margin: 13px 0px; */
       display: flex;
       align-items: center;
       flex-wrap: wrap;
       padding: 10px 0px;
 }
.components>h1 {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 39px;
    color: #1C1C1C;
}

.input_div {
    margin: 15px 0px;
}

.input_label {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #1C1C1C;
}

.input_top {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #665D6F;
    /* opacity: 0.6; */
    /* margin-top:10px; */
}

.upload_img {
    /* margin: 0px 100px; */
    margin: 10px 0px;
}

.preview_img {
    width: 100%;
    height: 100%;
}

.img_box {
    align-items: center;
    background-color: #f6f6f6;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    display: flex;
    height: 150px;
    justify-content: center;
    text-align: center;
    width: 100%;
}

.labelv{
    color: #1c1c1c !important;
    opacity: .6;
    text-align: center !important;
  }
  .labelv p{
    font-size: 14px;
    margin-bottom: 0;
    word-break: break-all;
  }

.img_view {
    width: 100%;
    height: 150px;
}

.img_view_hide {
    border: 1px solid red;
    width: 100%;
    background-color: #f6f6f6;
}

.submit_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px 0px 0;
}

.multi_inputs {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    column-gap: 5%;
}

.input_multi {
    margin: 10px 0px;
    width: 45%;
}

.checkbox_div {
    /* display: flex; */
}
.checkbox_div_container{
    margin:10px 0px;
}
.checkbox_range_div {
    width: 450px;
    display: flex;
    flex-wrap: wrap;
}
@media(max-width:767px){
    .mains {
        margin-top: 57px;
    }
}