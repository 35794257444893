.modal_exhibition {
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  width: 30%; 
  padding: 30px;
  overflow-y: auto;
  max-height: 90%;
}
.textarea_div {
  width: 98%;
}
.textarea_div > input {
  margin: 25px 0px;
}

.textarea {
  min-width: 99%;
  border: none;
  background: #f3f3f3;
  border-radius: 6px;
  padding-left: 10px;
  margin: 5px 0px;
}

.inputs_div {
  border: 1px solid red;
}

.input_title {
  width: 100%;
  margin: 10px 0px;
}

.input_title > label {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #1c1c1c;
}

.input_title > input {
  width: 97%;
  height: 40px;
  padding-left: 10px;
  background: #f3f3f3;
  border-radius: 6px;
  border: none;
  margin: 5px 0px;
}

.radio_active {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #636365;
}

.radio_notactive {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #636365;
  opacity: 0.5;
  border: none;
}

.mpophndg{
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  text-align: center;
}
.clobtn{
  position: absolute;
  right: 10px;
  top: 10px;
}
.clobtn a{
  cursor: pointer;
}
.iconru{
  font-size: 30px;
  color: #1C1C1C;
}
.downloadcv_btn{
  text-align: center;
}
.download_btn{
  padding: 10px 25px;
  border-radius: 10px;
  background: #000;
  border: 1px solid #000;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  cursor: pointer;
}
.download_btn:hover{
  background: transparent;
  color: #000;
}

.inputs_gird {
  margin: 10px 0px;
}

.inputs_gird > label {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #1c1c1c;
}

.inputs_gird > input {
  background: #f3f3f3;
  height: 40px;
  padding-left: 10px;
  border-radius: 6px;
  border: none;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #636365;
  width: 92%;
  margin: 5px 0px;
}
@media(min-width:993px) and (max-width:1025px){
  .modal_exhibition{
    width: 50%;
  }
}
@media(min-width:768px) and (max-width:992px){
  .modal_exhibition {
    width: 60%;
  }
}
@media (max-width: 767px) {
  .modal_exhibition {
    width: 95%;
  }

  .inputs_gird > input {
    background: #f3f3f3;
    height: 40px;
    padding-left: 10px;
    border-radius: 6px;
    border: none;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #636365;
    width: 97%;
    margin: 5px 0px;
  }
}
