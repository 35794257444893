.searchbanner {
  width: 100%;
  background: url("./banner bg img.png");
  background-size: cover;
  padding-top: 5%;
  padding-bottom: 5%;
  margin-top: 125px;
}
.search {
  margin: 50px 0;
}
.searchbar {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.tosearch{
  padding-bottom: 20px;
}
.ProductContent {
  display: flex;
  /* justify-content: center; */
  align-items: flex-start;
  margin-left: 7%;
}

.colorcheckbox {
  background-color: red;
}
.orientation {
  margin-right: 7%;
}
.color__image {
  margin-right: 2%;
  width: 10%;
}
.card__top img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.card__middle {white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  display: block;
}
.titles{
  font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #1c1c1c;
    text-transform: capitalize;
}
.info_like{
  display: flex;
    justify-content: center;
    column-gap: 8px;
    align-items: center;
}
.wishlst{
  width: 25px;
    height: 25px;
    background: #f3f3f3;
    border-radius: 50%;
    line-height: 25px;
    text-align: center;
}
.like_count{
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  color: #1c1c1c;
}
.names{
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #636365;
  opacity: 0.5;
  margin: 0;
  padding-top: 1px;
  padding-bottom: 6px;
  border-bottom: 1px solid #BDBDBD;
}
.price_sec{
  display: flex;
    justify-content: space-around;
    align-items: flex-start;
    margin-top: 8px;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 7px;
    text-align: center;
}
.buys{
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #636365;
  opacity: 0.6;
  margin-bottom: 5px;
  display: block;
}
.prices{
  font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #1c1c1c;
}
.hrs{
  height: 50px;
  width: 0.1px;
  border-right: 1px solid #bdbdbd;
}
.rent_price{

}
.card__middleRight {
  display: flex;
  margin-top: 12px;
  /* margin-bottom: 6.5%; */
  align-items: center;
}

.verticalLine {
  border-left: 1.5px solid grey;
  height: 3vw;
}
.buy__text {
  margin-top: -8%;
}
.rent__text {
  margin-top: -8%;
}
.print__text {
  margin-top: -8%;
}
.ProductCards {
  margin-left: 1%;
  width: 100%;
}
.card__main {
  cursor: pointer;
  width: 100%;
  margin-bottom: 18px;
}
.card__top {
  position: relative;
  height: 300px;
  max-height: 300px;
  min-height: 300px;
  overflow: hidden;
  border: 1px solid #f1f7fd;
}
.pic_info{
  display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
    margin-bottom: 7px;
    text-align: center;
    overflow: hidden;
}

.filter__maindiv {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.MuiSvgIcon-root {
  font-size: 1rem;
}
.nodata{
  font-family: "Roboto", sans-serif;
    font-weight: 600;
    font-size: 17px;
    line-height: 23px;
    color: #1c1c1c;
}
.filterContainer{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.clearAllButton{
  border: 0;font-size: 14px;
  margin: 4px;
  background: transparent;
}
.filterButtonsStyles {
  color: rgb(51, 51, 51);
    font-size: 14px;
    background: rgb(243, 243, 243);
    width: max-content;
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: .25rem;
    font-family: "Roboto", sans-serif;
    padding: 4px 8px;
    border: 0;
    text-transform: capitalize;
}
.filterButtonsStyles:hover .filterButtonsStylesIcon {
  text-decoration: line-through;
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
}
.filterButtonsStylesIcon {
  color: gray;
  font-size: 16px !important;
}

.buttonFilter {
  cursor: pointer;
  font-size: 12px;
  background-color: #e0e0e0;
  box-shadow: 0 2px 4px 0 hsla(0, 0%, 100%, 0.5);
  border-radius: 3px;
  margin: 2px 4px;
  overflow: hidden;
  transition: background-color 0.1s;
  max-width: 200px;
  padding: 8px;
}
.searchresults__div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.searchheading {
  display: flex;
  align-items: center;
  gap: 100px;
  flex-wrap: wrap;
}
.searchheading h3{
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: #1c1c1c;
  margin: 0;
}
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40vh;
}
.sractnbtn{
  display: flex;
  align-items: center;
}
.rficon{
  color: #1c1c1c;
  font-size: 22px;
}
@media(max-width:992px){
  .sidebar_container {
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    height: 70vh;
    background: white;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
    transition: bottom 0.4s ease-in-out;
    z-index: 1000;
  }
  
  /* Mobile: Show When Open */
  .sidebar_container.open {
    bottom: 0;
  }
  
  /* Desktop: Always Visible */
  .sidebar_container.desktop {
    position: static;
    width: 100%;
    height: auto;
    box-shadow: none;
    transition: none;
  }
  
  .sidebar_content { 
    position: relative;
    height: 100%;
    padding-top:35px
  }
  .filter__span{
    margin-right: 5px;
  }
  /* Close Button - Mobile Only */
  .close_btn {
        position: absolute;
  top: 6px;
  right: 10px;
  background: none;
  border: none;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid #ddd;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  line-height: 23px;
  }
}
@media(max-width:767px){
  .filter__span{
    display: none;
  }
  .card__main{
    margin-bottom: 0;
  }
  .searchbar{
    display: block;
    text-align: center;
  }
  .searchbanner{
    margin-top: 57px;
  }
  .filter__maindiv{
    justify-content: space-between;
  }
  .tosearch{
    padding-bottom: 0;
  }
  .price_sec{
    margin-bottom: 0;
  }
}
@media (max-width: 600px) {
  .searchresults__div {
    flex-direction: column;
  }
  .searchheading {
    padding-left: 0%;
  }
}
