.loader_box {
    display: flex;
    justify-content: center;
    align-items: center;
}

.end_col_auction {
    display: flex;
    /* color: ; */
    justify-content: space-around;
}