.components {
    padding: 20px;
    background-color: #f6f6f6;
    border-radius: 10px;
}
.headerdsc{
    border-bottom: 1px solid #ced4da;
    margin-bottom: 30px;
}
.headerdsc h3{
    font-family: "Roboto", sans-serif;
    color: #333333;
    font-size: 21px;
    font-weight: 700;
    padding-bottom: 10px;
    margin-bottom: 0;
}
.add_btn_div p {
    color: #333333;
    font-size: 22px;
    font-weight: 700;
}
.addres_span{
    font-weight: 500;
    color: #333333;
}
.btn_div {
    display: flex;
    justify-content: center;
    padding: 20px 20px 20px 20px;
    border-radius: 6px;
}
.add_btn {
    width: 200px;
    height: 44px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #FFFFFF;

}

.card_div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    color: white;
}

.card {
    box-shadow: 0 0 6px 2px #edebeb;
    border-radius: 22px;
    padding: 20px;
    background: #fff;
    height: 100%;
}

.edit_more {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.heading {
    margin: 0px;
    padding: 0px;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    font-size: 19px;
    line-height: 27px;
    color: #1C1C1C;
}
.detail_main{
    margin-top: 0px;
}

.detail_main .heading {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 17px;
    line-height: 23px;
    color: #636365;
    margin: 0px;
    padding: 0px;
}

.detail_main .para {
    font-family: "Roboto", sans-serif; 
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    color: #665D6F; 
    opacity: 0.8;
    text-transform: capitalize;
    margin-bottom: 10px;
}
 .para + .para{
    margin-bottom: 0;
}
.aply_btn{
    padding: 10px 35px;
      border-radius: 10px;
      background: #1c1c1c;
      border: 1px solid #1c1c1c;
      color: #fff;
  font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
      cursor: pointer;
      text-decoration: none !important;
      display: inline-block;
  }
  .aply_btn:hover{
    background: #fff;
    color: #1c1c1c;
  }

@media(max-width:600px) {
    .mains {
        width: 100%;
    }

    .card_div {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        row-gap: 15px;
    } 
    .card_dark {
        width: 90%;
        height: 322px;
        background-color: black;
        box-shadow: 0px 3.6682px 18.341px rgba(0, 0, 0, 0.15);
        border-radius: 18.341px;
        padding: 10px 10px 10px 20px;
    }
}
 