.headerCategory {
  width: 100%;
  background: url("./banner bg img.png");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  padding-top: 5%;
  padding-bottom: 5%;
  margin-top: 125px;
}
.pageitle{
  text-align: center;
}
.pageitle h1{
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  font-weight: 500;
  line-height: 1.235;
  letter-spacing: 0;
  margin-bottom: 10px;
  margin-top: 0;
}
.pageitle ul{
  padding: 0;
  list-style-type: none;
  margin: 0;
}
.pageitle ul li{
  display: inline-block;
  margin-top: 0;
}
.pageitle ul li:last-child{
  padding-left: 5px;
}
.pageitle ul li a{
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #14142B;
  text-decoration: none !important;
}
.formsec{
  padding: 50px 0;
}
.proddets__middle {
  margin-top: 5%;
}
.design__headingImage {
  display: flex;
  justify-content: center;
  align-items: center;
}
.design__image {
  width: 6.5%;
}
.design__heading {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cardImage {
  width: 100%;
  object-fit: contain;
}
.card__middle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card__main {
  /* background-color: #ccc; */
  width: 100%;
}
.card__top {
  position: relative;
  height: 300px;
}

.card__top img {
  object-fit: fill;
  height: 100%;
}
.card__middleRight {
  display: flex;
  margin-bottom: 6.5%;
}
.verticalLine {
  border-left: 1.5px solid grey;
  height: 2.75vw;
}
.buy__text {
  margin-top: -8%;
}
.rent__text {
  margin-top: -8%;
}
.print__text {
  margin-top: -8%;
}
.ProductCards {
  margin-left: 10%;
  width: 80%;
}
.card__main {
  margin-bottom: 20%;
}
/* .product__info {
  margin-top: 3%;
  margin-left: 10%;
} */
 .productimg{
  width: 70px;
  height: 70px;
  overflow: hidden;
  border-radius: 10px;
 }
.product_nme{
  font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #1C1C1C;
    text-transform: capitalize;
    margin: 0;
    display: block;
 }
 .frdimns{
  font-family: "Roboto", sans-serif;
font-size: 14px;
font-weight: 400;
line-height: 19.53px;
text-align: left;
color: #1C1C1C;
opacity: .4;
margin: 0;
padding-top: 5px;
}
.counterDiv {
  display: flex;
      justify-content: center;
      align-items: center;
}
.decrease {
  border: 1px solid #cdcdcd;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
  cursor: pointer;
}
.increase {
  border: 1px solid #cdcdcd;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
  cursor: pointer;
}
.counterValue {
  border: 1px solid #cdcdcd;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1%;
  margin-right: 1%;
}
.totalvalue__div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3%;
}
.itemnm{
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #1C1C1C;
  text-transform: capitalize;
}
.iprisubt{
  font-family: "Roboto", sans-serif;
font-size: 16px;
font-weight: 600;
line-height: 24px;
text-align: right;
color: #1C1C1C;
}
.cartitdtl{
  padding-top: 15px;
  padding-bottom: 5px;
  border-bottom: 1px solid #111111;
}
.totalsub__div{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  margin: 0;
}
.fillbtn{ 
  width: 100%;
}
.fillbtn:hover{
  background: transparent;
  color: #000;
}
.wotfillbtn{
  width: 100%;
  margin-top: 15px;
}
.wotfillbtn:hover{
  background: #000;
  color: #fff;
}
.wisliic{
  color: #1C1C1C;
  font-size: 18px;
}
.remic{
  color: #BDBDBD;
  font-size: 18px;
}

.totalvalue__div {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 3%;
}
.TopDivider {
  margin-top: 16px;
  opacity: 1;
  /* color: black; */
  border-color: black;
  /* margin-bottom: 0; */
}
.Divider {
  margin-top: -2px;
  border-color: black;
}
.cartprodimg {
  max-width: 100%;
  min-height: 100%;
}

.cart__grid {
  margin-top: 40px;
}
.carhndg{
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #111111;    
  padding-bottom: 7px;
  border-bottom: 1px solid #111111;
}
.newpaintngs{
  padding: 50px 0;
}
.sec_title{
  font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 50px;
    text-transform: capitalize;
    color: #1c1c1c;
    margin: 0px;
    margin-bottom: 10px;
}
.sub_title{
  letter-spacing: 0.00938em;
    color: #1C1C1C;
    z-index: 10;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    line-height: 26px;
    margin-bottom: 20px;
    text-transform: capitalize;
    font-size: 20px;
    margin-top: 0;
}
.text_center{
  text-align: center;
}
.title_image{
  max-width: 110px;
}
.card__top {
  position: relative;
  height: 350px;
  max-height: 350px;
  min-height: 350px;
  overflow: hidden;
  border: 1px solid #f1f7fd;
}
.card__top img {
  object-fit: fill;
  height: 100%;
}
.pic_info {
  display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 8px;
      margin-bottom: 7px;
      text-align: center;
      overflow: hidden;
}
.pic_info  .card__middle{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  display: block;
}
.titles {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: #1c1c1c;
  text-transform: capitalize;
}
.info_like {
  display: flex;
  justify-content: center;
  column-gap: 8px;
  align-items: center;
}
.wishlst{
  width: 25px;
  height: 25px;
  background: #f3f3f3;
  border-radius: 50%;
  line-height: 25px;
  text-align: center;
}
.like_count {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  color: #1c1c1c;
}
.names {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #636365;
  opacity: 0.5;
  margin: 0;
  padding-top: 1px;
  padding-bottom: 6px;
  border-bottom: 1px solid #BDBDBD;
  text-transform: capitalize;
}
.price_sec{
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin-top: 8px;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 7px;
  text-align: center;
}
.buys {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #636365;
  opacity: 0.6;
  margin-bottom: 5px;
  display: block;
}

.prices {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #1c1c1c;
}
.hrs {
  height: 50px;
  width: 0.1px;
  border-right: 1px solid #bdbdbd;
}
.loader_box {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 767px) {
  .headerCategory{
    margin-top: 57px;
  }
  .total__div {
    margin-left: 0%;
  }
   
}
