.compnat{
  background-color: #f6f6f6;
  padding: 20px;
  border-radius: 10px;
}
.backimage {
  cursor: pointer;
}

.artdetails_main {
  /* background-color: red; */
  margin: auto -50px;
  padding: 0px 50px;
}

.back_button {
  border-bottom: 1px solid #ced4da;
  margin-bottom: 30px;
}
.back_button a{
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 10px;
  margin-bottom: 0;
  color: #665D6F !important;
  opacity: .8;
  text-decoration: none !important;
  cursor: pointer;
  display: block;
} 
.previewimg {
  height: 300px;
  width: 333px;
}
.bsrd{
  padding-bottom: 30px;
  border-bottom: 1px solid #ced4da; 
  margin-bottom: 30px;
} 
.details_main > h2 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 39px;
  color: #1c1c1c;
}

.description_details {
  display: flex;
  align-items: flex-start;
}

.description_details_title {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
  color: #1c1c1c;
  /* width:280px; */
  min-width: 25%;
}

.description_details_content {
  padding: 5px 0px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #636365;
}

.horizontal {
  border: 1px solid #665d6f;
  opacity: 0.3;
}

.hr_short {
  width: 40%;
  text-align: left;
  margin-left: 0px;
  border: 1px solid #665d6f;
  opacity: 0.3;
}

.description_details_print_sizing {
  display: flex;
  align-items: flex-start;
  column-gap: 30px;
}

.price_details {
  display: flex;
  align-items: center;
  /* column-gap: 150px; */
}
.price_details_print {
  display: flex;
  align-items: flex-start;
  margin-top: 5px;
}

.price_details .title {
  margin-right: 5px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
  color: #1c1c1c;
}

.details_subheading {
  font-family: "Roboto", sans-serif; 
  font-weight: 600;
  font-size: 19px;
  line-height: 25px;
  color: #1c1c1c;
  margin-bottom: 7px;
}

.price_input {
  width: 97px;
  height: 41px;
  left: 1164px;
  /* background: #ffffff; */
  border-radius: 10px;
  border: none;
  padding: 0px 10px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #636365;
}
.sethng{
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #1c1c1c;
  text-align: left;
  text-transform: capitalize;
  margin-bottom: 7px;
}
.odlist{
  color: #1C1C1C;
font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    margin-bottom: 10px;
    display: flex;
}
.odlist span{
  max-width: 20%;
  width: 20%;
  display: block;
}
.odlist span + span{
  color: #636365;
  opacity: .8;
  max-width: initial;
  width: 80%;
}
.bdngr{
  padding-bottom: 15px;
  border-bottom: 1px solid #ced4da; 
  margin-bottom: 15px;
}
.slider_images {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  overflow-x: auto; 
  white-space: nowrap; 
  scrollbar-width: thin; 
  scrollbar-color: #ccc transparent; 
}

.slider_images img {
  width: auto;
  height: 243px;
  flex-shrink: 0; 
}

@media (max-width: 767px) {
  .odlist span{
    max-width: 100%;
    width: 100%;
  }
  .odlist span + span{
    width: 100%;
  }
  .odlist{
    display: block;
  }
  .description_details {
    display: flex;
    align-items: flex-start;
    padding: 0px 2%;
  }

  .previewimg_img_div {
    /* display: flex;
        justify-content: center; */
    width: 100%;
    margin: auto auto;
    text-align: center;
  }

  .previewimg {
    height: 300px;
    width: 95%;
  }
 

  .details_main > h2 {
    padding: 0px 2%;
  }

  .description_details_title {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
    color: #1c1c1c;
    /* width:280px; */
    min-width: 150px;
  }

  .description_details_print_sizing {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    column-gap: 30px;
  }

  .description_details_print_sizing {
    display: flex;
    /* align-items: center; */
    /* column-gap: 30px; */
  }

  .price_details {
    display: flex;
    align-items: flex-start;
    padding: 0px 2%;
    /* column-gap: 150px; */
  }

  .artdetails_main {
    /* background-color: red; */
    margin: 10px 0px;
    padding: 0px 0px;
  }
  .slider_images {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    overflow-x: auto; /* Enables scrolling only when necessary */
    white-space: nowrap; /* Prevents images from wrapping to the next line */
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: #ccc transparent; /* Customize scrollbar color */
  }
  .slider_images img {
    width: 295px;
    height: 243px;
    flex-shrink: 0; /* Prevents images from shrinking */
  }
 
}

@media (max-width: 280px) {
  .price_details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 2%;
    /* column-gap: 150px; */
  }

  .description_details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 2%;
  }
}
