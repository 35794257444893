.mains {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f6f6f6;
    width: 69%;
    height: 75vh;
}

.content {
    width: 450px;
    text-align: center;
}

.content>h2 {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 190%;
    text-align: center;
    color: #1C1C1C;
}

.content>p {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.4992px;
    color: #1C1C1C;
}

@media(max-width:600px) {
    .mains {
        width: 100%;
    }
}