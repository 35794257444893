.compoents {
 background-color: #f6f6f6;
border-radius: 10px;
padding-bottom: 20px
}
.more_download {
 display: flex;
 align-items: flex-end;
 justify-content: space-between;
}

.pagination_box {
    display: flex;
    justify-content: flex-end;
    background-color: #F6F6F6;
    padding-top: 25px;
    padding-right: 25px;
    /* margin-bottom: 20px; */
  }
  
.pagination_box>div {
    margin-bottom: 25px;
  }
@media (max-width: 767px) {
.more_download {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 10px;
        margin-top: 10px;
   }
}
