.loader_box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.end_col_auction {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.actionedit {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}
.buyer_flex {
  display: flex;
  justify-content: space-between;
}
.editicon{
  color: #665D6F;
  font-size: 22px;
}
.deleicon{
  color: #1C1C1C;
  font-size: 22px;
}