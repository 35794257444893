.components {
  background-color: #f6f6f6;
  border-radius: 10px;
  padding-bottom: 20px;
}

.faq_header {
  display: flex;
  justify-content: flex-end;
  /* margin-right: 20px; */
  padding: 20px;
  padding-right: 0;
}

.card {
  background-color: #fff;
  padding: 15px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.card_title {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  text-transform: capitalize;
  color: #1c1c1c;
  cursor: pointer;
  margin-bottom: 0;
}

.open_arrow {
  height: 30px;
  width: 30px;
  background-color: #efefef;
  border: none;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
}
.arico{
  font-size: 20px;
}

@media (max-width: 600px) {
  .mains {
    width: 100%;
  }
}
