
.login_main {
    height: 100vh;
    width: 100vw;
    display: flex;
}
.left_login_main {
    height: 100vh;
    background-color: #EBEBEB;
    display: flex;
    margin: auto;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.right_main_l {
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0px auto;
    text-align: center;
}

@media(max-width:912px){
    
    .login_main {
        display: flex;
        flex-direction: column;
    }
}