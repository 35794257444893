.mains {
  padding: 50px 0;
}
.components {
  box-shadow: 0 0 6px 2px #e7e9ec;
  border-radius: 22px;
  padding: 25px;
  margin-top: 20px;
}
.main_heading {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 39px;
  color: #1c1c1c;
}

/* test */
.mainHead {
  display: flex;
  justify-content: space-between;
}

.access_roles {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  width: 100%;
}

.access_roles > div {
  font-family: "Roboto", sans-serif;
  font-style: normal;.mains {
  width: 69%;
  margin: auto;
}

.multi_inputs {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  column-gap: 3%;
}

.components {
  background-color: #f6f6f6;
  padding: 20px 30px;
}

.main_heading {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 39px;
  color: #1c1c1c;
}

.mainHead {
  display: flex;
  justify-content: space-between;
}

.access_roles {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  width: 100%;
}

.access_roles > div {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #636365;
}

.toggle_btn {
  text-align: left;
}

.switch {
  position: relative;
  display: inline-block;
  width: 43px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider.round {
  border-radius: 35px;
}

.slider.round:before {
  border-radius: 50%;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  transition: 0.2s;
  border: 0.5px solid #e2e2e2;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.07);
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 4px;
  bottom: 2.5px;
  background-color: #1c1c1c;
  transition: 0.3s;
}

input:checked + .slider {
  background-color: #ffffff;
}

input:focus + .slider {
  box-shadow: 0 0 1px #ffffff;
}

input:checked + .slider:before {
  transform: translateX(17px);
}
 
.child_div {
  width: 47%;
  margin: 10px 0px;
}

.child {
  width: 100%;
  margin: 10px 0px;
}

.other_input_container {
  margin-top: 1rem;
}

.other_input_container_influence {
  margin-top: 60px;
}

.input_label {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #1c1c1c;
}

.input_multi {
  margin: 10px 0px;
  width: 100%;
}

.input_multi_hear_other {
  margin-bottom: 20px;
  width: 100%;
}

.textarea {
  width: 96%;
  min-width: 96%;
  max-width: 96%;
  max-height: 100px;
  padding: 2%;
  background-color: #f6f6f6;
  border-radius: 5px;
  margin-top: 5px;
}

.textarea:focus {
  outline: none !important;
}
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #636365;
}
.toggle_btn {
  text-align: left;
}

.switch {
  position: relative;
  display: inline-block;
  width: 43px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider.round {
  border-radius: 35px;
}

.slider.round:before {
  border-radius: 50%;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  border: 0.5px solid #e2e2e2;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.07);
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 4px;
  bottom: 2.5px;
  background-color: #1c1c1c;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

input:checked + .slider {
  background-color: #ffffff;
}

input:focus + .slider {
  box-shadow: 0 0 1px #ffffff;
}

input:checked + .slider:before {
  -webkit-transform: translateX(17px);
  -ms-transform: translateX(17px);
  transform: translateX(17px);
}

.btn_div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px 0px 0;
}

.child_div {
  width: 47%;
  margin: 10px 0px;
}

.child {
  width: 100%;
  margin: 10px 0px;
}
.other_input_container {
  margin-top: 1rem;
}
.other_input_container_influence {
  margin-top: 60px;
}
.input_label {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #1c1c1c;
}

.input_multi {
  margin: 10px 0px;
  width: 100%;
}

.input_multi_hear_other {
  margin-bottom: 20px;
  width: 100%;
}

.textarea {
  width: 96%;
  min-width: 96%;
  max-width: 96%;
  max-height: 100px;
  padding: 2%;
  background-color: #f6f6f6;
  border-radius: 5px;
  margin-top: 5px;
}

.textarea:focus {
  outline: none !important;
  /* box-shadow: 0 0 10px #719ECE; */
}
