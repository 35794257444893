.modal_uploaded {
 position: absolute;
 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%);
 background-color: white;
 width: 60%;
 max-height: 95%;
 overflow-y: auto;
 border-radius: 10px;
    padding: 30px;
}
.mpophndg{
font-family: "Roboto", sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    text-align: center;
  }
  .clobtn{
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .clobtn a{
    cursor: pointer;
  }
  .iconru{
    font-size: 30px;
    color: #1C1C1C;
  } 
.pagination_box {
    display: flex;
    justify-content: flex-end;
    background-color: #F6F6F6;
    padding-top: 25px;
    padding-right: 25px;
    /* margin-bottom: 20px; */
}

.pagination_box>div {
    margin-bottom: 25px;
}

.page_btn {
    height: 29.47px;
    width: 35px;
    margin-left: 8px;
    min-width: 20px;
    height: 29.47px;
    border: 0.841897px solid #d5d5d5;
    box-shadow: none;
}
.cancel_btn_div > img {
 cursor: pointer;
}
.mains > h2 {
 text-align: center;
 font-family: "Roboto", sans-serif;
 font-style: normal;
 font-weight: 700;
 font-size: 30px;
 line-height: 39px;
 color: #1c1c1c;
 margin: 0px;
 margin-bottom: 10px;
}

.textarea {
 width: 96%;
 min-width: 96%;
 max-width: 96%;
 max-height: 100px;
 background-color: #f3f3f3;
 color: #636365;
 outline: none;
 font-family: "Roboto", sans-serif;
 font-style: normal;
 font-weight: 400;
 font-size: 16px;
 color: #636365;
 border: none;
 padding: 2%;
}

.textarea:focus {
 outline: none !important;
 /* box-shadow: 0 0 10px #719ECE; */
}

.input_label {
 font-family: "Roboto", sans-serif;
 font-style: normal;
 font-weight: 500;
 font-size: 18px;
 line-height: 23px;
 color: #1c1c1c;
}

.input_outline {
 font-family: "Roboto", sans-serif;
 font-style: normal;
 font-weight: 400;
 font-size: 16px;
 line-height: 21px;
 color: #636365;
}
.menu_item{display: block;}
.details_div {
 display: flex;
 justify-content: space-between;
 align-items: center;
 column-gap: 10px;
}
.img_detail_div {
 display: flex;
 column-gap: 30px;
 justify-content: space-between;
 align-items: center;
}
.detail {
 display: flex;
 flex-direction: column;
 align-items: start;
 justify-content: center;
}

.title {
 font-family: "Roboto", sans-serif;
 text-transform: capitalize;
 font-weight: 500;
 font-size: 16px;
 line-height: 21px;
 color: #1c1c1c;
 margin: 0px;
}
.content {
 font-family: "Roboto", sans-serif;
 text-transform: capitalize;
 font-weight: 500;
 font-size: 15px;
 line-height: 21px;
 color: #636365;
 margin-bottom: 0;
}
.hrverticle {
 height: 65px;
 width: 1px;
 opacity: 0.07;
 background-color: #1c1c1c;
}

.add_btn_div_hide {
 visibility: hidden;
}
.hori_hrs {
 width: 100%;
 opacity: 0.3;
 border: 1px solid #665d6f;
 margin: 20px 0px;
 /* padding:0px 30px; */
}

.btn_div {
 display: flex;
 justify-content: center;
 align-items: center;
 margin: 10px 0px 0;
}

@media (min-width: 993px) and (max-width: 1025px) {
    .modal_uploaded {
        width: 90%;
       }
}
@media (min-width: 768px) and (max-width: 992px) {
    .modal_uploaded {
        width: 95%;
       }
}
@media (max-width: 767px) {
 .modal_uploaded {
  width: 95%;
 }
 .details_div{
  flex-direction: column;
 }
 .img_detail_div{
  flex-direction: column;
 }
 .detail{
  flex-direction: column;
 }
.hrverticle {
  height: 1px;
    width: 100%;
    margin: 5px 0;
 }
 .content{
  margin-bottom: 0;
 }
 /* li{
  border-bottom: 1px solid #e0e0e0 !important;
 } */
}
