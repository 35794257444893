.mains {
    margin-top: 125px;
    padding: 50px 0;
}
.title_image {
    max-width: 110px;
  }
  .text_center{
    text-align: center;
  }
.components {
    box-shadow: 0 0 6px 2px #e7e9ec;
    border-radius: 22px;
    padding: 25px;
    margin-top: 20px;
}

.components>h1 {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 39px;
    color: #1C1C1C;
}

.main_divs {
    margin: 13px 0px;
}
.main_divs_category {
    /* margin: 13px 0px; */
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px 0px;
}
.result {
    /* min-height: 100%; */
    max-height: auto;
    width: 100%;
    /* background-color: #272c34; */
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: left;
    position: relative;
  
  }
  .imgs {
    width: 100px;
    height: 100px;
    /* object-fit: cover; */
    padding: 0.75rem;
  
  }
  .del_img_div {
    position: absolute;
    height: 26px;
    width: 26px;
   top: 0;
  left: 5;
    background-color: white;
    border-radius: 5px;
  }
  .preview_img {
    width: 100%;
    height: 100%; 
  }
  .imguplod{
    align-items: center;
    background-color: #f6f6f6;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    display: flex;
    height: 150px;
    justify-content: center;
    text-align: center;
    width: 100%;
  }
.input_label {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #1C1C1C;
}
.multi_inputs {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    column-gap: 5%;
    padding-bottom: 10px;
}
.input_multi {
    margin: 10px 0px;
    width: 45%;
}
.btn_div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px 0px 0;
}
.input_text{
    -webkit-appearance: none;
    appearance: none;
    background-clip: padding-box;
    background-color: #fff;
    border: 0;
    border-bottom: 1px solid #ced4da;
    border-radius: 0;
    box-shadow: none !important;
    color: #212529;
    display: block;
font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    height: auto;
    line-height: 1.5;
    max-width: -webkit-fill-available;
    outline: none !important;
    padding: .375rem .75rem;
    position: relative;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 100%;
  }
  .labelv{
    color: #1c1c1c !important;
    opacity: .6;
    text-align: center !important;
    
  }
  .labelv p{
    font-size: 14px;
    margin-bottom: 0;
    word-break: break-all;
  }
  @media (max-width: 767px) {
    .mains{
      margin-top: 57px;
    }
    .input_multi {
      margin: 10px 0px;
      width: 100%;
    }
  }
  