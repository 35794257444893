.modal_del {
    padding: 50px 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* // height:700px, */
    background-color: #FFFFFF;
    width: 445px;
    /* bgcolor: 'background.paper', */
    border: 0px solid #000;
    padding:10px 50px;
}

.cancel_img {
    text-align: right;
    margin-right: -35px;
}

.modal_heading {
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 34px;
    color: #1C1C1C;
    margin-top: 20px;
    padding: 0px;
}

.textarea {
    margin: 20px 0px;
    display: flex;
    justify-content: center;
    margin: 30px 0px;
    /* width: 97%; */
    /* width: 420px; */
}

.textareas {
    min-width: 100%;
    border: 1px solid #665D6F;
    border-radius: 5px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #636365;
    padding:15px 15px;
    /* min-row:10; */
    opacity: 0.6;
}

.action {
    text-align: center;
    margin-bottom:20px;
}

.action_btn {
    width: 156px;
    height: 54px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    text-transform: capitalize;
    color: #FFFFFF;
}

@media(max-width:600px){
    .modal_del {
        /* padding: 50px 20px; */
        border-radius: 10px;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        /* // height:700px, */
        background-color: #FFFFFF;
        width: 70%;
        /* bgcolor: 'background.paper', */
        border: 0px solid #000;
        /* padding:10px 50px; */
    }
}
@media(max-width:280px){
    .modal_del {
        /* padding: 50px 20px; */
        border-radius: 10px;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        /* // height:700px, */
        background-color: #FFFFFF;
        width: 75%;
        /* bgcolor: 'background.paper', */
        border: 0px solid #000;
        padding:10px 30px;
    }
    
.cancel_img {
    text-align: right;
    margin-right: -20px;
}
}