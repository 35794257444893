.modal_del {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #FFFFFF;
    background-color: white;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    font-weight: 700;
    max-height:92vh;
    overflow-y: auto;
    color: #1C1C1C;
    border: none;
    padding:20px;
    width: 70%;
} 
.modal_uploaded {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #f6f6f6;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    font-weight: 700;
    font-size: 35px;
    width: 500px;
    color: #1C1C1C;
    border: none;
}
 
.done_icon {
    text-align: center;
    margin-top:20px;
}
.done_icon>h1 {
    font-family: 'Marcellus';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 50px;
    text-transform: capitalize;
    color: #1C1C1C;
    margin:0px;

}

.upload_done {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    color: #665D6F;
    opacity: 0.7;
    padding:0px 50px;
    margin-bottom: 50px;
}



.main_div>h2 {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    color: #1C1C1C;
}

.btn_div {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
    margin-top: 30px;
}

.cancel_btn_div {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-right: 20px;
    margin-top: 20px;
}
.cancel_btn_div>img{
    cursor: pointer;
}

.mains_common_subs_card {
    display: flex;
    align-items: flex-start;
    column-gap: 20px;
    /* margin: 20px; */
    flex-wrap: wrap;
    row-gap: 20px;
}

.card { 
    height: 100%;
    background-color: white;
    box-shadow: 0px 3.6682px 18.341px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 20px;
}

.card_dark {
    height: auto;
    background: #1c1c1c;
    box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 20px;
}
 

.most_popular_div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 20px 0px 0px 0px;
}

.most_popular {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: white;
    border-radius: 13.5;
    width: 121px;
    height: 27px;
    text-transform: uppercase;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    text-align: center;
    letter-spacing: 0.833333px;
    color: #1C1C1C;
    border-radius: 14px;
}

.content {
    display: flex;
    column-gap: 15px;
    align-items: center;
    padding: 5px 0px;
}

.content_dark {
    display: flex;
    column-gap: 15px;
    align-items: center;
    padding: 5px 0px;
}

.pricing {
    display: flex;
    align-items: center;
    padding: 0px;
    margin-bottom: 5px;
}
.subsprice p{
    font-family: "Roboto", sans-serif;
    font-size: 19px;
    font-weight: 700;
    color: #1c1c1c;
}
.pricv{
    font-family: "Roboto", sans-serif;
    font-size: 26px;
    font-weight: 700;
}
.pricing_dark {
    display: flex;
    align-items: center;
    padding: 0px;
    margin-bottom: 5px;
}
.subsprice{
    margin-bottom: 0;
}
.pricing_dark p{
    color: #FFFFFF;
}
.pricing>h3 {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 45px;
    line-height: 60px;
    color: #1C1C1C;
    margin: 0px;
    white-space: nowrap;
}

.pricing_dark>h3 {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 45px;
    line-height: 60px;
    color: white;
    margin: 0px;
}

.pricing>h6 {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 29px;
    white-space: nowrap;
    color: #1C1C1C;
    margin: 0px;
}

.pricing_dark>h6 {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 29px;
    color: white;
    margin: 0px;
}

.card_child>h6 {
    color: #1c1c1c;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    text-transform: capitalize;
    margin: 0;
    text-align: left;
    margin-bottom: 10px;
    font-family: "Roboto", sans-serif;
}

.card_child_dark>h6 {
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    text-transform: capitalize;
    margin: 0;
    text-align: left;
    margin-bottom: 10px;
    font-family: "Roboto", sans-serif;
    color: white; 
}

.card_child>p {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #1C1C1C;
    text-align: left;
    opacity: 0.5;
    margin: 5px 0px 2px 0px;
    
}

.card_child_dark>p {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    text-transform: capitalize;
    color: white;
    margin: 10px 0px;
    text-align: left;
}
 

.content>h2 {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    color: #1C1C1C;
    margin: 0px;
}

.content_dark>h2 {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    color: white;
    margin: 0px;
}

.btn_div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 0px 10px 0px;
}

.clobtn{
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .clobtn a{
    cursor: pointer;
  }
  .iconru{
    font-size: 30px;
    color: #1C1C1C;
  }
  .whtbx{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 23px;
    background: #edecec;
    text-align: center;
    min-width: 25px;
  }
  .darkd{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 23px;
    background: #fff;
    text-align: center;
    min-width: 25px;
  }
  .checkion{
    font-size: 18px;
    color: #1C1C1C;
  }
  .vpra{
    color: #1C1C1C;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 23px;
    text-transform: capitalize;
    margin-bottom: 0;
  }
  .vpra span{
    color: #636365;
    opacity: .8;
  }
  .content_dark .vpra{
    color: #fff;
  }
  .content_dark .vpra span{
    color: #fff;
    opacity: .8;
  }
  .mpophndg{
    font-family: "Roboto", sans-serif;
        font-size: 20px;
        font-weight: 700;
        line-height: 23px;
        text-align: center;
      }
@media(max-width:600px) {
    .mains_common_subs_card {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 20px;
        margin: 0px 20px;
    }

    .card {
        width: 95%;
        padding: 0px 20px 20px 10px;

    }

    .card_dark {
        width: 95%;
        padding: 0px 20px 20px 10px;
    }
}


@media(max-width:600px) {
    .modal_del {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #FFFFFF;
        background-color: white;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        font-weight: 700;
        font-size: 35px;
        color: #1C1C1C;
        max-height: 90%;
        overflow-x: scroll;
        border: none;
        width: 95%;
    }

    .modal_uploaded {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #f6f6f6;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        font-weight: 700;
        font-size: 35px;
        color: #1C1C1C;
        max-height: 90%;
        overflow-x: scroll;
        border: none;
        width: 95%;
    }

    .main_div {
        padding: 20px 10px;
    }
}