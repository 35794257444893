.sections {
    position: relative;
   }
   
   .plans {
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    column-gap: 20px;
    padding: 0px;
    margin-bottom: 20px;
   }
   
   .rating_plans {
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    column-gap: 20px;
    padding: 0px;
    margin-bottom: 20px;
    height: 100%;
   }
   
   .rating_div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 2px;
   }
   
   .left_actions {
    position: absolute;
    bottom: 50%;
    left: 2%;
   }
   
   .right_actions {
    position: absolute;
    bottom: 50%;
    right: 2%;
   }
   
   .left_actions_rating {
    position: absolute;
    bottom: 50%;
    left: -5%;
   }
   
   .right_actions_rating {
    position: absolute;
    bottom: 50%;
    right: -11%;
   }
   
   .left_actions_price {
    position: absolute;
    bottom: 50%;
    left: 5%;
    z-index: 1;
   }
   
   .right_actions_price {
    position: absolute;
    bottom: 50%;
    right: 2%;
    z-index: 1;
   }
   .trianglebtn {
    width: 50px;
    height: 50px;
    /* margin-left: 10%; */
    /* margin-top: 5%; */
    cursor: pointer;
   }
   
   .left_active {
    border: 1px solid black;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f6f6f6;
    z-index: 1;
   }
   
   .left_inactive {
    position: relative;
    cursor: not-allowed;
    background-color: white;
    height: 50px;
    width: 50px;
    border: 1px solid black;
    border-radius: 50%;
    z-index: 1;
   }
   
   .right_inactive {
    cursor: not-allowed;
    background-color: white;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    color: #000000;
   }
   
   .right_active {
    /* border: 1px solid black; */
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
   }
   
   .category_card {
    min-width: 18.1%;
    height: 300px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    transition-duration: 3s;
    scroll-behavior: smooth;
   }
   
   .category_img_div {
    height: 300px;
   }
   
   .category_img_div:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.7);
   }
   
   .category_text {
    position: absolute;
    bottom: 8%;
    /* height: 20px; */
    width: 100%;
    margin: auto;
    /* align-self: center; */
    display: flex;
    justify-content: center;
    align-items: center;
   }
   
   .category_text_child {
    background-color: white;
    margin: 0px auto;
    width: 85%;
    /* display: flex;
      */
    text-align: center;
   }
   
   .category_text_child > h4 {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    margin: 10px 0px;
    text-align: center;
    text-transform: capitalize;
    color: #1c1c1c;
   }
   
   .pic_info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 8px;
   }
   .hr_line{
       margin-top: 0px;
   }
   .card__main {
    width: 25%;
    cursor: pointer;
   }
   .card__top{
       position: relative;
       height: 350px;
   }
   .card__top  img{
       object-fit: fill;
       height: 100%;
   }
   .card__main:hover .card__top {
    /* box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.7); */
    box-shadow: 0px -8px 16px -5px rgba(0, 0, 0, 0.301), -10px -2px 16px -10px rgba(0, 0, 0, 0.301),
     10px -2px 16px -10px rgba(0, 0, 0, 0.301);
   /* }.card__top {
       height: 220px; */
   }
   
   .info_like {
    display: flex;
    justify-content: center;
    column-gap: 8px;
   }
   
   .like_count {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #1c1c1c;
   }
   
   .hrs {
    height: 50px;
    width: 0.1px;
    border-right: 1px solid #bdbdbd;
   }
   
   .buys {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #636365;
    opacity: 0.6;
   }
   
   .prices {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #1c1c1c;
   }
   
   .titles {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    color: #1c1c1c;
   }
   
   .names {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    color: #636365;
    opacity: 0.5;
   }
   
   .price_img_div {
    position: relative;
    /* width: 150px; */
    height: 280px;
   }
   
   .price_img {
    object-fit: fill;
   }
   
   .price_plans {
    width: 75%;
    /* heigt: max-content; */
    margin: auto auto 20px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    column-gap: 20px;
    padding: 0px;
   
    /* margin-left: 10%; */
   }
   
   .price_card {
    border: 1px solid #000000b7;
    padding: 12px;
    /* max-width: 20%; */
   }
   
   .price_card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.7);
   }
   
   .price_action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Roboto", sans-serif;
    margin: 0;
   }
   
   .feature_action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
   }
   
   .feature_card {
    border: 1px solid rgba(99, 99, 101, 0.2);
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.06);
    padding: 10px;
   }
   
   .featured_img_div {
    position: relative;
   }
   
   .main_img_div {
    height: 180px;
   }
   
   .featured_img_div > div {
    display: flex;
    justify-content: space-between;
    column-gap: 0px;
   }
   
   .featured_img_div > div > div {
    width: 63px;
   }
   
   .artist_img {
    width: 40px;
    height: 40px;
    border: 3px solid white;
    border-radius: 50%;
    position: absolute;
    top: 67%;
    left: 40%;
    cursor: pointer;
   }
   
   .ratings {
    display: flex;
    justify-content: space-between;
    align-items: center;
   }
   
   .ratings > h5 {
    white-space: nowrap;
    word-spacing: -2px;
    letter-spacing: 0px;
   }
   
   .rating_main {
    width: 170px;
   }
   
   .rating_main > h2 {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    text-transform: capitalize;
    margin: 0px;
   }
   
   .ratings {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    text-transform: capitalize;
    margin: 0px;
    color: #0000005a;
   }
   
   .img_type {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    margin: 10px 0px;
   }
   
   .rating_main > h6 {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-transform: capitalize;
    margin: 10px 0px;
    color: #1c1c1c94;
   }
   
   .rating_main > p {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-transform: capitalize;
    margin: 0px;
   }
   
   .card_div {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 30px;
    width: 100%;
    height: 100%;
   }
   
   .card_testimonials {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 500px;
    column-gap: 30px;
    background-color: black;
    padding: 20px 30px;
    box-shadow: 0px 20px 50px rgba(18, 17, 39, 0.08);
   }
   
   .testimonial_img_div > img {
    border-radius:   50%  ;
   }
   
   .test_card_heading {
       font-family: "Poppins";
       font-style: normal;
       font-weight: 600;
       font-size: 15px;
       line-height: 23px;
       color: #ededed;
    margin: 0px;
   }
   
   .test_card_subhead {
       font-family: "Poppins";
       font-weight: 400;
       font-size: 13px;
       line-height: 22px;
       color: #B6B6B6;
       margin: 0px;
   }
   
   .test_card_des {
       font-family: "Roboto", sans-serif;
       font-weight: 400;
       font-size: 16px;
       line-height: 24px;
       color: #F6F6F6;
       margin: 0px;
   }
   
   .tesimonial_btns {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 50px;
   }
   
   .left_arrow {
    width: 100px;
    height: 40px;
    /* transform: scale(2.8); */
    cursor: pointer;
   }
   
   .right_arrow {
    width: 100px;
    height: 40px;
    /* transform: scale(2.8); */
    cursor: pointer;
   }
   
   .left_arrow_notactive {
    color: #000000;
    opacity: 0.2;
    cursor: not-allowed;
   }
   
   .right_arrow_notactive {
    color: #000000;
    opacity: 0.2;
    cursor: not-allowed;
   }
   
   .by_price {
    display: flex;
    justify-content: space-between;
    align-items: center;
   }
   
   .by_price > p {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    text-align: center;
    text-transform: capitalize;
    color: #1c1c1c;
    margin: 0px;
    margin-left: 2%;
   }
   .testmibox{
       padding: 25px;
       background: url(/public/img/testimon-bg.png);
       background-size: cover;
       background-position: center;
       padding-bottom: 35px;
       height: 100%;
   }
   .tecont{
       padding: 0;
   }
   
.test_cont {
    padding: 0 25px;
    position: relative;
    min-height: 80px;
  }
  
  .test_cont::before {
    content: '';
    background: url(/public/img/test-quote.png);
    position: absolute;
    left: 0;
    background-size: cover;
    width: 15px;
    height: 10px;
  }
  
  .test_cont::after {
    content: '';
    background: url(/public/img/test-quote.png);
    position: absolute;
    right: 0;
    background-size: cover;
    width: 15px;
    height: 10px;
  }
  
  .test_card_heading {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 180%;
    color: #ededed;
    margin: 0px;
  }
  
  .test_card_subhead {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 180%;
    color: #b6b6b6;
    margin: 0px;
  }
  
  .test_card_des {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    color: #f6f6f6;
    margin: 0px;
  }
  .hders {
    display: flex;
    gap: 15px;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .tehgt {
    height: 100%;
  }
   
   @media (max-width: 540px) {
    .sections {
     position: relative;
     width: 95%;
     margin: auto;
    }
   
    .card__main {
     width: 86%;
     cursor: pointer;
     /* height: 250px; */
    }
   
    .card_testimonials {
     display: flex;
     justify-content: center;
     align-items: flex-start;
     width: 90%;
     column-gap: 30px;
     background-color: black;
     padding: 20px 30px;
     box-shadow: 0px 20px 50px rgba(18, 17, 39, 0.08);
    }
    .price_img_div {
       position: relative;
       height: 210px;
      }
    .rating_main {
     width: 90%;
    }
   
    .left_actions {
     left: -0%;
    }
   
    .right_actions {
     right: -0%;
    }
   
    .price_card {
     border: 1px solid #000000b7;
     padding: 12px;
     width: 85%;
    }
   
    .left_actions_rating {
     position: absolute;
     bottom: 50%;
     left: -11%;
    }
    .category_img_div {
     width: 100%;
    }
    .category_text_child {
     width: 72%;
    }
    .left_actions_price {
     position: absolute;
     bottom: 50%;
     left: -13%;
     z-index: 1;
    }
   
    .right_actions_price {
     position: absolute;
     bottom: 50%;
     right: -13%;
     z-index: 1;
    }.price_plans {
       width: 95%;
   
   }
   .left_active {
       height: 40px;
       width: 40px;
   }
   .left_inactive {
       height: 40px;
       width: 40px;
   }
   .right_active{
       height: 40px;
       width: 40px;
   }.right_inactive{
       height: 40px;
       width: 40px;
   }
   .card__top >img{
       height:40%;
   }
   }
   
   @media (max-width: 300px) {
    .category_card {
     height: 200px;
    }
   
    .category_img_div {
     height: 200px;
    }
   
    .feature_card {
     width: 200px;
    }
   }
   