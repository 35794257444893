.shadow {
  box-shadow: none;
}
.table_head {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #1c1c1c;
}

.table_column {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #636365;
  white-space: nowrap;
}

.auction_last_col {
  display: flex;
  justify-content: space-between;
}

.action {
  text-align: center;
  margin: 20px 0px;
}
