.upload_pic_div {
  height: 200px;
  width: 100%;
  border-radius: 10px;
}
.password_label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.components {
  padding: 20px;
  background-color: #f6f6f6;
  border-radius: 10px;
}
.inputs_div_margin {
  margin-bottom: 5px;
}
.input_label {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #1c1c1c;
}
.btn_div {
  width: 200px;
  margin-bottom: 5%;
}
.btn_div1 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px 20px 0px;
}
.editicon {
  display: flex;
  flex-direction: row-reverse;
}
.inputs {
  width: 80%;
  padding: 0;
}
@media (min-width:768px) and (max-width:991px){
  .upload_pic_div{
    height: auto;
    width: auto;
  }
}
@media (max-width: 767px) {
  .mains {
    width: 100%;
  }
  .inputs{
    width: 100%;
  }
}
