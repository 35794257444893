.cancel_img {
  display: flex;
  justify-content: flex-end;
}

.modal_heading {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 46px;
}

.horizontal_line {
  height: 0.01rem;
  width: 100%;
}

.data_div {
  margin: 15px 10px 15px 0px;
  /* background-color: red; */
}

.user_detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 0px;
}

.user_detail>p {
  margin: 5px 0px;
}

.user_detail>h5 {
  margin: 5px 0px;
}

.action {
  text-align: center;
}

.user_title {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #1C1C1C;
}

.user_value {
  color: #636365;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
}
.modal_del{
  position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
width: 30%;
max-height: 95%;
background-color: #ffffff;
border-radius: 10px;
padding: 30px;
}
.mpophndg{
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  text-align: center;
  border-bottom: 1px solid #ced4da;
  padding-bottom: 20px;
  text-transform: capitalize;
}
.clobtn{
  position: absolute;
  right: 10px;
  top: 10px;
}
.clobtn a{
  cursor: pointer;
}
.iconru{
  font-size: 30px;
  color: #1C1C1C;
}
.action{
  display: flex;
  gap: 15px;
  justify-content: center;
}
.odlist{
  color: #1C1C1C;
font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    margin-bottom: 10px; 
} 
.odlist span + span{
  color: #636365;
  opacity: .8;
  max-width: initial;
}
@media(min-width:768px) and (max-width:992px) {
  .modal_del {
    max-width: 60%;
    width: 60%; 
}
}
@media (max-width:767px) {
  .modal_del {
      max-width: 95%;
      width: 95%;
      max-height: 90%;
  }
}