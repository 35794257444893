.mains {
    background-color: #f6f6f6;
    border-radius: 10px;
    padding: 20px;
}

.back_button {
    border-bottom: 1px solid #ced4da;
    margin-bottom: 30px;
  }
  .back_button a{
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 10px;
    margin-bottom: 0;
    color: #665D6F !important;
    opacity: .8;
    text-decoration: none !important;
    cursor: pointer;
    display: block;
  }
  .hrdngofv{ 
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    font-size: 22px;
    line-height: 25px;
    color: #1c1c1c;
    padding-bottom: 5px;
  }
  .title{
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    font-size: 19px;
    line-height: 23px;
    color: #1c1c1c;
    text-align: center;
  }

.editicon{
    color: #665D6F;
    font-size: 22px;
  }
  .deleicon{
    color: #1C1C1C;
    font-size: 22px;
  }
  .detail_para {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin-bottom: 5px;
  }
  .menpr{
font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    color: #636365;
    opacity: .8;
    max-width: 90px;
    min-width: 90px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -moz-line-clamp:1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.boxsc{
    background: #fff;
    padding: 10px;
    border-radius: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
}