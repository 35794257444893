.modal_details {
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  width: 30%; 
  padding: 30px;
  overflow-y: auto;
  max-height: 90%;
}
.astricksymb{
  color: red;
}
.modal_decline {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  max-height: 95%;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 30px;
}

.cancel_img {
  text-align: right;
  margin-right: -5px;
  margin-top: 10px;
}

.cancel_img > img {
  cursor: pointer;
}

.modal_heading {
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 34px;
  text-align: center;
  color: #1c1c1c;
  margin: 10px 0px 18px 0px;
}

.input_label {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #1c1c1c;
}

.action {
  text-align: center; 
}
.actionPolicy {
  text-align: center;
  margin: 50px 0px;
}
 
.action_btnFAQ {
  width: fit-content;
  height: 54px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  text-transform: capitalize;
  color: #ffffff;
  border-radius: 10px;
  margin-top: 30px;
}
.textarea_div {
  width: 99%;
  margin-top: 15px;
}
.textarea_div > label {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #1c1c1c;
}
.textarea_label {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #1c1c1c;
}
.textarea_blog {
  min-width: 100%;
  height: 150px;
  border: none;
  /* background: #f3f3f3; */
  border-radius: 6px;
  /* padding-left: 10px; */
  margin-top: 8px;
  font-style: normal !important;
}


.customEditor {
  height: 150px; /* Adjust height as needed */
}

.quill > .ql-container > .ql-editor.ql-blank::before{
  font-size: 20px;
  color: red;
}
.ql-editor::before {
  font-style: normal !important;
  font-weight: 400 !important;
}



.textarea {
  min-width: 100%;
  max-width: 100%;
  /* border: none; */
  /* background: #F3F3F3; */
  border-radius: 6px;
  /* padding-left: 10px; */
  margin-top: 8px;
  /* Grey */

  border: 1px solid #665d6f;
  color: black;
  border-radius: 5px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;

  /* Secondary */
  border: "1px solid #665D6F";
  /* opacity: 0.5; */

  /* color: #636365; */

  /* opacity: 0.6; */
}

.mpophndg{
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  text-align: center;
}
.clobtn{
  position: absolute;
  right: 10px;
  top: 10px;
}
.clobtn a{
  cursor: pointer;
}
.iconru{
  font-size: 30px;
  color: #1C1C1C;
}
.action_btn{
  padding: 10px 25px;
  border-radius: 10px;
  background: #000;
  border: 1px solid #000;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  cursor: pointer;
}
.action_btn:hover{
  background: transparent;
  color: #000;
}

.action {
  text-align: center; 

}
.action_career {
  text-align: center;
  margin: 90px 0px 0;
} 
@media(min-width:993px) and (max-width:1025px){
  .modal_details {
    width: 60%;
  }

  .modal_decline {
    width: 60%;
  }
}

@media (min-width: 768px) and (max-width:992px) {
  .modal_details {
    width: 60%;
  }

  .modal_decline {
    width: 60%;
  }
}
@media (max-width: 767px) {
  .modal_details {
    width: 95%;
  }

  .modal_decline {
    width: 95%;
  }
  .textarea {
    min-width: 99%;
    /* border: none; */
    /* background: #F3F3F3; */
    border-radius: 6px;
    /* padding-left: 10px; */
    margin-top: 8px;
  }
}
