.headerCategory {
  /* margin-top: 5%; */
  width: 100%;
  background: url("./banner bg img.png");
  background-size: cover;
 padding:50px 0 ;
 margin-top: 125px;
}
.pageitle{
  text-align: center;
}
.pageitle h1{
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  font-weight: 500;
  line-height: 1.235;
  letter-spacing: 0;
  margin-bottom: 10px;
  margin-top: 0; 
}
.pageitle ul{
  padding: 0;
  list-style-type: none;
  margin: 0;
}
.pageitle ul li{
  display: inline-block;
  margin-top: 0; 
}
.pageitle ul li:last-child{
  padding-left: 5px;
}
.pageitle ul li a{
  font-family: "Roboto", sans-serif;
  font-size: 16px; 
  text-decoration: none !important;
  color: #1c1c1c;
}
.sec_sc{
  padding: 50px 0;
}
.catitle{
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  margin: 0;
  font-size: 18px;
  text-transform: capitalize;
}
.headerSubTitle {
  margin-left: 12%;
  text-transform: capitalize;
}

.painting__Categories {
  width: 80%;
  padding-left: 12%;
}

.CategoryImages {
  width: 30%;
  padding-right: 3%;
  margin-top: 3%;
}

.CategoryButton {
  margin-top: -5%;
}

.card__top {
  position: relative;
}

.imgText {
  /* width: 80%; */
  position: absolute;
  bottom: 1%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-left: 6%; */
  padding: 2.75%;
}

.card__Image {
  width: 100%;
  height: 400px;
  object-fit: fill;
}

.card__main {
  /* border: 1px solid black; */
  overflow: hidden; 
  cursor: default;
}

.container__div {
  margin-bottom: 5%;
  margin-top: -1%;
  margin-left: -2%;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;
}

@media (max-width: 767px) {
  .headerCategory {
    margin-top: 57px;
  }
  .card__Image {
    height: 200px;
  }
}
