.modal_campaigns {
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* // height:700px, */
  background-color: #ffffff;
  width: 70%;
  /* bgcolor: 'background.paper', */
  border: 0px solid #000;
  padding: 30px;
  overflow-y: auto;
  max-height: 90%;
}
.clobtn{
  position: absolute;
  right: 10px;
  top: 10px;
}

.mpophndg{
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  text-align: center; 
  text-transform: capitalize;
} 
.clobtn a{
  cursor: pointer;
}
.iconru{
  font-size: 30px;
  color: #1C1C1C;
}
.cancel_img {
  text-align: right;
  margin-right: -5px;
  margin-top: 10px;
}

.cancel_img > img {
  cursor: pointer;
}

.input_title {
  width: 100%;
  margin: 10px 0px;
}
.input_title > label {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #1c1c1c;
}

.input_fifty {
  width: 50%;
  margin: 10px 0px;
}

.input_fifty > label {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #1c1c1c;
}

.inputs_gird {
  margin: 0px 0px;
}

.inputs_gird > label {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #1c1c1c;
}

.inputs_gird > input {
  background: #f3f3f3;
  height: 40px;
  padding-left: 10px;
  border-radius: 6px;
  border: none;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #636365;
  width: 92%;
  margin: 5px 0px 0px 0px;
}

.textarea_div {
  width: 99%;
}

.textarea_div > input {
  margin: 25px 0px;
}

.textarea {
  min-width: 100%;
  border: none;
  background: #f3f3f3;
  border-radius: 6px;
  /* padding-left: 10px; */
  margin-top: 8px;
}

.textarea_label {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;

  /* Black */

  color: #1c1c1c;
}

.add_new_feature {
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin: 10px 0px;
  /* justify-content: space-between; */
}

.add_new_feature > span {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;

  /* Black */

  color: #1c1c1c;
}

.action {
  text-align: center;
  margin: 20px 0px 0;
}
.frelist{
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
  text-transform: capitalize;
}

.new_feature_heading {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #1c1c1c;
  margin: 0px;
  margin-bottom: 10px;
}

.checkboxs_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkbox_width {
  width: 50%;
}
@media(min-width:993px) and (max-width:1025px){
  .modal_del{
    width: 80%;
  }
}
@media(min-width:768px) and (max-width:992px){
  .modal_del{
    width: 80%;
  }
}
@media (max-width: 767px) {
  .modal_campaigns {
    width: 95%;
  }
}