.header {
  margin-top: 125px;
  background: url("Rectangle 5265.png");
  background-size: cover;
 padding: 50px 0;
}
.max_width{
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
}
.max_width .sub_title{
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  color: #636365;
  
}
.pageitle h1{
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: 0;
  margin-bottom: 15px;
  margin-top: 0;
  color: #ffffff;
  text-transform: capitalize;
}
.pageitle h2{
  font-family: "Roboto", sans-serif;
    font-size: 40px;
    font-weight: 500;
    line-height: 50px;
    letter-spacing: 0;
    margin-bottom: 15px;
    margin-top: 0;
    color: #ffffff;
}
.withor{
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  color: #636365;
}
.whitext{
  color: #fff !important;
 }
 .exlist{
  list-style-type: none;
  padding: 0;
  margin: 0 auto;
  margin-bottom: 15px;
  display: flex;
  gap: 15px;
  text-align: center;
  justify-content: center;
}
.exlist li{
  display: flex;
      align-items: center; 
      margin-top: 0;
  font-family: "Roboto", sans-serif;
font-size: 16px;
font-weight: 400;
line-height: 26px;
gap: 4px;
text-align: left;
text-transform: capitalize;
color: #fff;
}
.exlist li span{
  color: #fff;
  font-weight: 600;
  padding-right: 5px;
}

.icns{
  font-size: 20px;
  color: #fff;
  margin-right: 8px;
}
.apply_btn{
  padding: 10px 35px;
  border-radius: 10px;
  background:  #fff;
  border: 1px solid  #fff;
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  cursor: pointer;
}
.apply_btn:hover{
  background: #1c1c1c;
  color:  #fff;
}
.sec_sc{
  padding: 50px 0;
}
.styhf{
  padding-bottom: 30px;
}
.styhf h3{
  color: #1c1c1c;
font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    margin: 0 0 5px;
    text-align: left;
    text-transform: capitalize;
}
.styhf p{
  color: #636365;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  margin: 0;
  opacity: .8;
  text-align: left;
}
.exhibitiondate ul{
  display: block;
    text-align: left;
    margin: 0;
}
.exhibitiondate ul li{
  color: #1C1C1C;
  font-size: 15px;
}
.exhibition__card h4{
  font-family: "Roboto", sans-serif;
  font-size: 25px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
  margin: 0;
  margin-bottom: 10px;
}
.exhibition__card p{
  color: #636365;
font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    margin: 0;
    opacity: .8;
    text-align: left;
}
.header__content {
  margin-top: 6.25%;
  margin-bottom: 5%;
}
.location__date {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5%;
}
.loc {
  display: flex;
  width: 200px;
}
.date {
  margin-left: 4%;
  display: flex;
  width: 200px;
}
.top__content {
  padding: 2% 15%;
}
.headingart {
  margin-left: 25.5%;
}
.headingdiscover {
  margin-left: 26%;
}
.headingthis {
  margin-left: 42%;
}

.cardContainer {
  padding: 2% 20%;
}
.exhibition__card { 
  margin-bottom: 4%;
}
.card__img {
  width: 100%;
}
.exhibitiondate {
  margin-top: 3%;
  margin-bottom: 5px;
}

.discover_con_btns {
  display: flex;
  justify-content: center; 
}
.discover_con_btn {
  justify-content: space-between;
}
.apply_btnbl{
  padding: 10px 25px;
  border-radius: 10px;
  background: #000;
  border: 1px solid #000;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  cursor: pointer;
}
.apply_btnbl:hover{
  background: transparent;
  color: #1c1c1c;
}

@media (max-width: 767px) {
   
  .header {
    margin-top: 57px;
  }
  .exlist{
    display: block;
  }
  .exlist li{
    justify-content: center;
  }
  .exhibitiondate ul li{
    justify-content: left;
  }
  .pageitle h1{
    font-size: 25px;
        line-height: 26px;
        margin-bottom: 5px;
  }
}
