.login_main {
 display: flex;
 justify-content: center;
 align-items: center;
}
.para_span_otp {
 font-size: 12px;
 margin: 5px 0px;
 color: #1c1c1c;
 font-family: "Roboto", sans-serif;
 font-style: normal;
 font-weight: 700;
 font-size: 15px;
}

.submit_otp_btn {
    border-radius: 10px;
    height: 45px;
}
.heading_art_varify {
    font-family: 'Marcellus';
    font-weight: 100;
    margin: 0px;
    font-size: 35px;
}
.right_main_o {
 width: 50vw;
 padding: 10% 0% 20% 7%;
}
.left_main_varify {
 width: 50vw;
 height: 100vh;
 background-color: #ebebeb;
 display: flex;
 justify-content: center;
 align-items: center;
}

.opt_text {
 color: #636365;
 font-size: 13.8px;
 line-height: 5px;
 letter-spacing: 0.05em;
 font-family: "Roboto", sans-serif;
 font-style: normal;
 font-weight: 400;
}

.opt_box_div {
 /* width: 100%; */
 margin-top: 10px;
}
.opt_all input[type="text"] {
 font-size: 20px;
 font-weight: 500;
 font-family: "Roboto", sans-serif;
 font-style: normal;
 text-align: center;
}
input:focus {
 outline: none !important;
}

.otp_box {
 width: 52px;
 height: 52px;
 margin: 10px 15px auto 0px;
 background-color: #e9e9e9;
 border: none;
 border-radius: 5px;
}

.opt_all {
 /* width: 68%; */
}

.resend {
 width: 339px;
 display: flex;
 justify-content: end;
 margin: 17px 0px;
 /* float: right; */
 color: #1c1c1c;
 font-family: "Roboto", sans-serif;
 font-style: normal;
 font-weight: 500;
 letter-spacing: 0.02em;
}

.submit_otp {
 margin-top: 20px;
 width: 339px;
}


.form {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
}

@media (max-width: 912px) {
 .left_main_varify {
  width: 100vw;
  height: 60vh;
  background-color: #ebebeb;
  display: flex;
  justify-content: center;
  align-items: center;
 }
 .right_main_o {
  width: 100%;
  padding: 20px 0px;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  /* background-color: red; */
 }
 .resend {
  width: 305px;
  display: flex;
  justify-content: end;
  margin: 15px 0px;
  /* float: right; */
  color: #1c1c1c;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
 }

 .opt_box_div {
  width: 100%;
 }

 .otp_box {
  width: 45px;
  height: 45px;
  margin: 10px 15px 0px 0px;
  background-color: #e9e9e9;
  border: none;
  border-radius: 5px;
 }

 .submit_otp {
  margin-top: 20px;
  width: 305px;
 }
 .opt_all {
  /* width: 68%; */
 }
}
@media (max-width: 600px) {
 .right_main_o {
  width: 100%;
  padding: 20px 0px;
  display: flex;
  justify-content: center;
  /* background-color: red; */
 }
 .resend {
  width: 305px;
  display: flex;
  justify-content: end;
  margin: 15px 0px;
  /* float: right; */
  color: #1c1c1c;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
 }

 .opt_box_div {
  width: 100%;
 }

 .otp_box {
  width: 45px;
  height: 45px;
  margin: 10px 15px 0px 0px;
  background-color: #e9e9e9;
  border: none;
  border-radius: 5px;
 }

 .submit_otp {
  margin-top: 20px;
  width: 305px;
 }
 .opt_all {
  /* width: 68%; */
 }
}
@media (max-width: 280px) {
 .right_main_o {
  width: 100%;
  display: block;
  padding: 50px 20px 50px 25px;
  /* background-color: red; */
 }
 .resend {
  width: 227px;
  display: flex;
  justify-content: end;
  margin: 15px 0px;
  /* float: right; */
  color: #1c1c1c;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
 }

 .opt_box_div {
  width: 100%;
 }

 .otp_box {
  width: 30px;
  height: 30px;
  margin: 10px 15px 0px 0px;
  background-color: #e9e9e9;
  border: none;
  border-radius: 5px;
 }

 .submit_otp {
  margin-top: 20px;
  width: 82%;
 }
 .opt_all {
  /* width: 68%; */
 }
}
/* @media(min-width:1025px){
    .right_main_o {
        width: 100%;
        padding: 20px 0px;
        display:flex;
        justify-content: center;
    }
    .resend {
        width: 305px;
        display: flex;
        justify-content: end;
        margin: 15px 0px;
        color: #1C1C1C;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 500;
    }

    .opt_box_div {
        width: 100%;
    }

    .otp_box {
        width: 55px;
        height: 55px;
        margin: 10px 15px 0px 0px;
        background-color: #E9E9E9;
        border: none;
        border-radius: 5px;
    }

    .submit_otp {
        margin-top: 20px;
        width: 305px;
    }
} */
