.headerCategory {
  width: 100%;
  background: url("./banner bg img.png");
  background-size: cover; 
  padding-top: 5%;
  padding-bottom: 5%;
  margin-top: 125px;
}
.pageitle{
  text-align: center;
}
.pageitle h1{
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: 0;
  margin-bottom: 10px;
  margin-top: 0;
}
.pageitle ul{
  padding: 0;
  list-style-type: none;
  margin: 0;
}
.pageitle ul li{
  display: inline-block;
  margin-top: 0;
}
.pageitle ul li:last-child{
  padding-left: 5px;
}
.pageitle ul li a{
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #14142B;
  text-decoration: none !important;
}
.tandc__content {
  padding: 50px 0;
  font-family: "Roboto", sans-serif;
}
.tandc__content h3{
  margin: 0 0 5px;
  display: flex;
    gap: 5px;
font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: #1c1c1c;
  text-transform: capitalize;
}
.tandc__content h4{
  margin: 15px 0 3px;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #1c1c1c;
  text-transform: capitalize;
}
.tandc__content p{
  margin: 0 0 10px;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: #636365;
  opacity: .8;
}
.tandc__content ul{
  margin-top: 0;
  margin-bottom: 10px;
}
.tandc__content ul li{
  margin-top: 0;
  padding-bottom: 5px;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: #636365;
  opacity: .8;
}
.tandc__content b{
  color: #1c1c1c;
}
.sidebar_main {
  color: black;
  background-color: #665d6f;
  opacity: 0.05;
  box-shadow: 0px 0px 6.86721px rgba(0, 0, 0, 0.1);
  border-radius: 4.12032px;
  width: 211.51px;
  /* height: 1522.46px; */
  /* padding: 30px 0px; */
  /* margin: 20px 50px; */
}

.sidebar_main > h2 {
  padding: 0px 0px;
  margin: 0px 26px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
}

.sidebar_main > div {
  background-color: white;
  margin: 0px 8px 0px 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  padding: 10px 0px;
}

.sidebar_main > div > Button {
  /* background: #1C1C1C; */
  height: 41.2px;
  border-radius: 2.06016px 0px 0px 2.06016px;
}

.demo {
  border-radius: 6px;
  border: 0.8px solid #f7f7f8;
  box-shadow: 0px 0px 10px 0px #0000001A;
  background: #f7f7f8;
  padding-top: 25px;
  padding-bottom: 15px;
  padding-left: 10px;
}

.sidebar_flex {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 90%;
  margin-right: 3%;
}

.demo_child_div {
  display: flex;
}

.demo > h2 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 1.8px;
  margin-top: 1.8px;
  padding: 6px 26px;
}

.flex_start {
  min-width: 3px;
  background-color: #1c1c1c;
  border-radius: 6.86721px;
}

.button_div_side {
  width: 3px;
  height: 44px;
}

.flex_btn {
  /* background-color: #1C1C1C; */
  width: 172.28px;
  height: 41.2px;
  margin: -4px 0px 0px 0px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* padding:-2px 0px; */
}

.shipping__policy,
.return__policy,
.faqs {
  background-color: #f7f7f8;
  padding: 20px;
  border-radius: 3px;
}

.listitem {
  color: black;
}
.calendarHeading h4{
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 26.04px;
  text-align: left;
  margin: 0;
  margin-bottom: 10px;
}
.information__use p{
  font-family: "Roboto", sans-serif;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  color: #636365;
  margin: 0;
  line-height: 25px;
  margin-bottom: 15px;
}
.information__use p b{
  color: #1c1c1c;
  font-weight: 600;
}
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
}
@media(max-width:767px){
  .headerCategory {
    margin-top: 57px;
  }
  .pageitle h1{
    font-size: 25px;
        line-height: 26px;
        margin-bottom: 5px;
  }
}
@media (max-width: 400px) {
  .sidebar_flex {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    /* margin-right: 3%; */
    height: 200px;
    width: 100%;
  }
  .demo {
    margin-left: 0;
    height: 67%;
  }
  .shipping__policy,
  .return__policy,
  .faqs {
    height: 87%;
    margin-bottom: 0;
    padding: 3% 2%;
  }
}
