.buyer_header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center; 
  padding: 20px;
}

.input_div{
  display: flex;
}
.input_div input{
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
}
.sericon{
  padding: .375rem .75rem;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.search_input_full {
  /* position: relative; */
  width: 230%;
  border: 0.6px solid #665D6F;
  border-radius: 6px;
  height: 37px;
  background-color: #F6F6F6;
  padding: 0px 15px;
}

.search_icon {
  position: absolute;
  left: 108%;
  /* right: -139%; */
  top: 23%;
}

.search_image {
  position: relative;
  left: -33px;
  top: 6px;

}

.header_action {
  display: flex;
  align-items: center;
  column-gap: 10px;
  flex-wrap: wrap;
}

.header_action_filter {
  margin: 5px 16px;

}

.selects {
  width: 150px;
}

.add_btn {
  margin-top: 0px;
  min-width: 150px;
}


@media(max-width:767px) {
  .search_image {
    position: relative;
    left: 279px;
    top: -32px;

  }

  .buyer_header {
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: column; */
    justify-content: space-between;
    align-items: center;
    /* margin-left: 30px; */
    padding: 20px 10px 0px 10px;
  }

  .header_action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 10px;

  }
}

@media(max-width:540px) {
  .search_image {
    position: relative;
    left: 279px;
    top: -32px;
  }

  .header_action_filter {
    margin: 8px 0px;
  }

  .input_div {
    position: relative;
    width: 100%;
  }


  .search_icon {
    position: absolute;
    left: 90%;
    top: 23%;
  }

  .add_btn {
    margin-top: 10px;
    min-width: 150px;
  }
}

@media(max-width:280px) {

  .selects {
    width: 120px;
  }
}