
.main_heading {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 39px;
    color: #1C1C1C;
    margin: 0px;
    padding: 0px;
}

.hrs {
    opacity: 0.3;
    border: 1px solid #665D6F;
}

.selector {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    height: fit-content;
}
.textfeild_color{
    color:red
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{color:'red'}
.details {
    display: flex;
    /* justify-content: space-between; */
    column-gap: 10px;
    align-items: flex-start;
    /* margin-bottom: 20px 0px; */
}

.title {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #1C1C1C;
    width: 150px;
    height: 40px;
}

.content {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #636365;

}

.image_div {
    display: flex;
    column-gap: 10px;
    align-items: center;
}
.gallery_status{
    display: flex;
    justify-content: center;
    column-gap: 5px;
    align-items: center;
}
.gallery_status>h6{
    margin:0px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #1C1C1C;
}
.gallery_status>p{
    margin:0px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: #EE0909;
}
@media(max-width:600px){
    .selector {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        /* width: 100%; */
        margin: 20px 0px;
        margin-bottom: 0px;
        margin-top: -45px;
        margin-right: 10px;
    }
    .title{
        width: 99px;
    }
    .details{
        padding:0px 10px;
    }
    .image_div{
        display: flex;
        row-gap: 10px;
        padding: 0px 10px;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    
.main_heading {
    margin-left: 10px;
}
}