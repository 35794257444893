.ProductSidebar {
  margin-bottom: 10%;
  box-shadow: 0 0 6px 2px #edebeb;
  border-radius: 10px;
  padding: 20px;
  background: #fff;
}
.ctfilist{
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}
.ctfilist1{
  list-style-type: none;
  padding-left: 0px;
  margin-bottom: 0;
  max-height: 160px;
  overflow-y: scroll;
}
.ctfilist li{
  margin-top: 0;
  padding-bottom: 10px;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  text-transform: capitalize;
}
.ctfilist li label, .ctfilist1 li label{
  margin-top: 0 !important;
  font-family: "Roboto", sans-serif;
  line-height: 21px;
  color: hsl(0deg 1% 39% / 86%);
  font-weight: 400;
}
.ctfilist li:last-child, .ctfilist1 li:last-child{
  padding-bottom: 0;
}
.checkbox_selected{
  cursor: pointer;
  margin-right: 16px;
  height: 100%;
}
.searchInput{
  width: 100%;
  height: 35px;
  margin-bottom: 16px;
}
.searchStyleInput{
  width: 100%;
  height: 35px;
  margin-bottom: 30px;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 3px;
}
.checkbox_animated {
  cursor: pointer;
  position: relative;
  margin-right: 13px;
  height: 100%;
  left: 5px;
}
.checkbox_animated:before {
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  transition: -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75), -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  -webkit-transform: rotate(-45deg) scale(0, 0);
  transform: rotate(-45deg) scale(0, 0);
  content: "";
  position: absolute;
  left: 0.5px;
  top: 3px;
  z-index: 1;
  width: 9px;
  height: 5px;
  border: 2px solid #000;
  border-top-style: none;
  border-right-style: none;
}
.checkbox_animated:after {
  content: "";
  position: absolute;
  top: -3px;
  left: -5px;
  width: 19px;
  height: 19px;
  background-color: #fff;
  border: 1px solid #bdbdbd;
  cursor: pointer;
}
.checkbox_animated:checked:before {
  -webkit-transform: rotate(-45deg) scale(1, 1);
  transform: rotate(-45deg) scale(1, 1);
}

.price__slider {
  margin: 1px;
  /* padding-top: 5px; */
  margin-top: 10px;
  padding: 5px 0px;
  margin-bottom: 10px;
  padding-left: 0px;
    padding-right: 16px;
    border-bottom: 1px solid #D9DBE9;
}
.labels {
  display: flex;
  justify-content: space-between;
  
  margin-top: -5px;
  font-weight: 500;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
}
 

.divider {
  opacity: 0.4;
}

.radio__button {
  margin-bottom: 5%;
  border-radius: 50%;
}
/* 
.radio__div {
  margin-top: -10%;
  margin-left: -2%;
  margin-bottom: -5%;
  padding-left: 10px;
} */
.mb1{
  margin-bottom: 10px;
}
.orientation {
  margin-right: 5%;
}
.orientation_select {
  border: 1px solid #665d6f;
}

.color__image {
  margin-right: 3%;
  width: 8%;
  height: 8%;
}

.radio__label {
  margin-left: 0.5%;
}
 .a_palette_color {
    width: 24px;
    height: 24px;
  margin: 4px 7px;
    display: inline-block;
    /* margin: 7px; */
    border: 1px solid transparent;
    border-radius: 5px;
  /* padding: 4px; */
    cursor: pointer;
    transition: padding 0.2s ease, border-radius 0.2s ease, box-shadow 0.2s ease, border-color 0.2s ease;
  }
  .a_palette_color:hover {
    border: 1px solid #adadad;
    /* padding: 4px; */
    border-radius: 5px;
    box-shadow: inset 0 0 0 .2rem #fff;
  }
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
}
@media (max-width: 767px) {
  .ProductSidebar {
    padding-top: 10px;
    max-height: 95%;
    overflow-y: auto;
    box-shadow: none;
  }
}
