.modal_box {
  border-radius: 10px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  width: 65%;
  border: 0px solid #000;
  overflow-y: auto;
  max-height: 90%;
}

.main_div {
  display: flex;
  width: 100%;
}

.left_div {
  background-color: #f3f3f3;
  padding-bottom: 20px;
  width: 45%;
}

.second_left_div {
  padding-bottom: 20px;
  width: 50%;
  margin-top: 10px;
}

.left_div_second {
  background-color: #f3f3f3;
  padding-bottom: 20px;
  padding-top: 30px;
  width: 50%;
}

.left_div_third {
  background-color: #f3f3f3;
  padding-bottom: 20px;
  padding-top: 30px;
  width: 50%;
}

.left_div>div:first-child {
  width: 85%;
  margin: auto;
}

.second_left_div>div:first-child {
  width: 85%;
  margin: auto;
}

.left_second_div {
  width: 92%;
  border-top: 1px solid #d1cece;
  text-align: right;
  margin-left: auto;
  padding-top: 15px;
}

.order_summary {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 135.89%;
  text-transform: capitalize;
  color: #1c1c1c;
  margin: 0px;
  padding: 0px;
}

.streching {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #636365;
  margin: 5px 0;
  padding: 0;
}

.view_price {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #1c1c1c;
  margin: 0px;
  padding: 0px;
  padding-bottom: 5px;
}

.selected {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #636365;
  margin: 0;
  padding: 0;
  margin-right: 10px;
  display: flex;
  justify-content: end;
  gap: 10px;
  align-items: center;
}

.selected .view_price {
  padding-bottom: 0;
}

.img_div {
  height: 50%;
  position: relative;
  width: 100%;
  /* text-align: center; */
  /* margin:  auto; */
  margin-top: 30px;
  margin-bottom: 10px;
}

.border_image {
  z-index: -1;
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.border_img {
  background-color: red;
  width: 90%;
  height: 100%;
}

.img_right_div {
  width: 100%;
  height: 50vh;
  /* position: absolute; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.img_right_null {
  width: 90%;
  height: 100%;
}

.img_right_small {
  width: 85%;
  height: 95%;
}

.img_right_mid {
  width: 80%;
  height: 90%;
}

.img_right_large {
  width: 75%;
  height: 85%;
}

.right_div {
  width: 55%;
  padding-top: 10px;
  padding: 20px;
}

.texcbox {
  padding: 0 30px;
}

h1 {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 36px;
  text-align: center;
  margin-bottom: 11px;
}

.right_div_second {
  width: 50%;
  /* margin-top: 10px; */
  padding: 10px 20px;
}

.close_icon {
  /* float: right; */
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin-bottom: 20px;
}

.right_div>h1 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  line-height: 115.89%;
  text-transform: capitalize;
  color: #1c1c1c;
  margin: 0px;
  padding: 0px;
  text-align: center;
  margin-bottom: 20px;
}

.para_first {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #636365;
  padding: 0px;
  margin: 0px;
}

.para_second {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #636365;
  padding: 0px;
  margin: 10px 0px;
}

.select_frame_div {
  display: flex;
  margin: auto;
  justify-content: space-around;
  margin: 30px auto;
}

.card_div {
  background-color: #efefef;
  text-align: center;
  padding: 0px 10px;
  padding-top: 10px;
}

.price {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  color: #636365;
  margin: 0px;
  padding: 0px;
  margin-left: -34px;
  margin-top: -13px;
}

.textarea_div {
  width: 99%;
  margin: 10px 0px;
}

.textarea_div>label {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #1c1c1c;
}

.textarea_div>input {
  margin: 25px 0px;
}

.textarea_label {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #1c1c1c;
}

.textarea {
  min-width: 100%;
  border: none;
  background: #f3f3f3;
  border-radius: 6px;
  margin-top: 8px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #1c1c1c;
  padding: 5px;
}

.framing_second_heading {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 135.89%;
  text-align: center;
  text-transform: capitalize;
  color: #1c1c1c;
}

.frame_div {
  width: 80px;
}

.customframe_div {
  width: 80px;
}

.customframe_div>div {
  background: #ffffff;
  width: 74px;
  height: 74px;
  border: 3px solid #c1c1c1;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.frame_flex {
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 10px;
  margin: auto;
  justify-content: center;
}

.frame_div>h6 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #636365;
  padding: 0px;
  margin: 0px;
}

.customframe_div>h6 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #636365;
  padding: 0px;
  margin: 0px;
}

.thickness_text {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #1c1c1c;
}

.action_btns {
  display: flex;
  justify-content: center;
  margin-top: 45px;
  column-gap: 20px;
}

.checked_div {
  position: relative;
  cursor: pointer;
}

.checked_icon {
  position: absolute;
  top: 5px;
  left: 5px;
  font-size: 15px;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
  background-color: white;
}

.loader_stepper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loaders {
  /* display: flex; */
  align-items: center;
  justify-content: center;
  margin: 0px 10px;
  margin-bottom: 35px;
  /* background-color: white; */
}

.buttonstyl {
  padding: 10px 25px;
  border-radius: 10px;
  background: #000;
  border: 1px solid #000;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  cursor: pointer;
  display: block;
  width: 100%;
}

.buttonstyl:hover {
  background: transparent;
  color: #000;
}

@media (max-width: 912px) {
  .modal_box {
    border-radius: 10px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    width: 85%;
    border: 0px solid #000;
    overflow-y: scroll;
    max-height: 90%;
  }
}

@media (max-width: 767px) {
  .close_icon {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .img_div {
    margin-top: 40px;
  }

  .modal_box {
    border-radius: 10px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    width: 95%;
    border: 0px solid #000;
    overflow-y: scroll;
    max-height: 90%;
  }

  .modalclosebutton {
    display: block;
  }

  .main_div {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .second_left_div {
    width: 100%;
  }

  .left_div_third {

    width: 100%;
  }

  .left_div_second {
    width: 100%;
  }

  .right_div_second {
    width: 95%;
    padding: 2%;
  }

  .left_div {
    background-color: #f3f3f3;
    padding-bottom: 20px;
    width: 100%;
  }

  .right_div {
    width: 100%;
    padding: 10px 0px 25px;
  }

  .right_div>h1 {
    white-space: pre-wrap;
  }
}