.mains {
    width: 100%
}

.components {
    background-color: #f6f6f6;
}

.moreoption_div {
    display: flex;
    justify-content: space-between;
    margin-right: 30px;
}
.pagination_box {
    display: flex;
    justify-content: flex-end;
    background-color: #F6F6F6;
    padding-top: 25px;
    padding-right: 25px;
    /* margin-bottom: 20px; */
  }
  
.pagination_box>div {
    margin-bottom: 25px;
  }


@media(max-width:600px) {
    .mains {
        width: 100%
    }

    .moreoption_div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        margin:0px;
    }
    .invite_btn {
        /* width: 100%; */
        /* margin:0px; */
        padding:0px 10px;
    }
}