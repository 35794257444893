.headerCategory {
  width: 100%;
  background: url("./banner bg img.png");
  background-size: cover;
  padding-top: 5%;
  padding-bottom: 5%;
  margin-top: 125px;
}
.pageitle{
  text-align: center;
}
.pageitle h1{
  font-family: "Roboto", sans-serif;
  font-size: 40px;
    font-weight: 500;
    line-height: 50px;
  letter-spacing: 0;
  margin-bottom: 10px;
  margin-top: 0;
}
.pageitle ul{
  padding: 0;
  list-style-type: none;
  margin: 0;
}
.pageitle ul li{
  display: inline-block;
  margin-top: 0;
}
.pageitle ul li:last-child{
  padding-left: 5px;
}
.pageitle ul li a{
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #14142B;
  text-decoration: none !important;
}
.sec_sc{
  padding: 50px 0;
}
.metgtilt{
  display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 8px;
      margin-bottom: 10px;
      text-align: center;
      overflow: hidden;
}
.hftitle{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  display: block;
}
.hftitle h4{
  font-family: "Roboto", sans-serif; 
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #1c1c1c;
    text-transform: capitalize;
    margin-bottom: 0;
}
.ofdate{
  color: #636365;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  opacity: .8;
  display: flex
;
  align-items: center;
  gap: 5px;
}
.calicon{
  font-size: 19px;
}
.offercont h5{
  padding-bottom: 7px;
    color: #636365;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    opacity: .8;
    border-bottom: 1px solid #BDBDBD;
    margin-bottom: 5px;
    padding-top: 5px;
}
.offercont p{
  color: #636365;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    opacity: .8;
    margin-bottom: 0;
}
.link__div {
  display: flex;
  align-items: flex-start;

  padding-left: 7.5%;
  margin-top: 2.5%;

}
.divider {
  width: 85%;
  margin: 0px auto;
  margin-top: 1%;
}
.headerSubTitle {
  margin-left: -65%;
}

.productCards {
  width: 100%;
  position: relative;
}

.offerCardImg {
  width: 100%;
  height: 250px;
  /* object-fit: contain; */
}
.offerCardImgExhibition {
  width: 250px;
  height: 330px;
  /* object-fit: contain; */
}
.discountDiv {
  background-color: black;
    color: white;
    padding: 5px 11px;
    position: absolute;
    top: 0px;
    right: 0px;
    font-weight: 600;
    font-size: 17px;
    text-align: center;
    box-sizing: border-box;
    font-family: "Roboto", sans-serif; /* Ensures padding doesn’t affect width */
}


.card__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.calendar {
  display: flex;
  align-items: center;
  color: #636365;
  width: 100%;
  justify-content: flex-end;
}

.calendarImg {
  width: 5.5%;
}

.card__divider {
  color: #636365;
} 
.productCards__main {
  margin: 10px;
  /* padding-right: 15%;
  padding-left: 1.5%; */
  width: 100%;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
}

@media (max-width: 767px) {
  .headerTitle {
    margin-left: 20%;
  }
  .headerCategory {
    margin-top: 57px;
  }
  .metgtilt{
    display: block;
    text-align: left;
  }
  
.hftitle h4{
  margin-bottom: 5px;
}
.pageitle h1{
  font-size: 25px;
        line-height: 26px;
        margin-bottom: 5px;
}
}
