.components {
    background-color: #f6f6f6;
    border-radius: 10px;
    padding-bottom: 20px;
   }
   .mains{
    background-color: #f6f6f6;
    border-radius: 10px;
    padding: 20px;
   }
.card_title {
 font-family: "Roboto", sans-serif;
 font-style: normal;
 font-weight: 500;
 font-size: 18px;
 line-height: 23px;
 color: #1c1c1c;
 margin: 0px 0px 5px 0px;
}

.country_label_div {
 display: flex;
 column-gap: 10px;
 row-gap: 10px;
 flex-wrap: wrap;
 justify-content: start;
 align-items: center;
 margin-bottom: 15px;
}
.labels {
    border: 1px solid #ced4da;
    padding: 5px 6px 5px 8px;
font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #636365;
    opacity: .8;
    border-radius: 6px;
    background: #fff;
}
.save_btn {
 display: flex;
 justify-content: center;
 border-radius: 10px;
}

.btn {
 font-family: "Roboto", sans-serif;
 font-style: normal;
 font-weight: 500;
 font-size: 18px;
 line-height: 26px;
 text-transform: capitalize;
 color: #ffffff;
}
.loader {
 display: flex;
 justify-content: center;
 align-items: center;
 width: 100%;
}