.headrtop{
  margin-top: 125px;
  padding: 50px 0;
}
.layout_main {
  margin: 0px 0px;
  margin-top: 50px;
  padding: 0px;
  display: flex;
  justify-content: center;
  column-gap: 25px;
}

.no_navbar {
  min-height: 150px;
  background-color: red;
}
.curator_childrens {
  margin: 0px 0px;
  margin-top: 5px;
  /* display: flex;
  justify-content: center; */
  /* column-gap: 25px; */
  padding: 0px;
  width: 100%;
  padding-top:50px;
}

@media (max-width: 600px) {
  .headrtop{
    margin-top: 57px;
  }
  .layout_main {
    margin-top: 50px;
    padding: 0px;
    display: block;
  }

  .curator_children {
    margin: 0px 0px;
    margin-top: 50px;
    display: block;
    padding: 0px;
  }
}