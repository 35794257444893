.input_text::placeholder {
  font-family: "Roboto", sans-serif;
 
}
.input_text{
  -webkit-appearance: none;
  appearance: none;
  background-clip: padding-box;
  background-color: #fff;
  border: 0;
  border-bottom: 1px solid #ced4da;
  border-radius: 0;
  box-shadow: none !important;
  color: #212529;
  display: block;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  height: auto;
  line-height: 1.5;
  max-width: -webkit-fill-available;
  outline: none !important;
  padding: .375rem .75rem;
  position: relative;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  width: 100%;
}
.gallery_color{
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #1C1C1C;
  margin-bottom: 20px;
}
.result {
  /* min-height: 100%; */
  max-height: auto;
  width: 100%;
  /* background-color: #272c34; */
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: left;
  position: relative;

}
.imgs {
  width: 100px;
  height: 100px;
  /* object-fit: cover; */
  padding: 0.75rem;

}
.del_img_div {
  position: absolute;
  height: 26px;
  width: 26px;
 top: 0;
left: 5;
  background-color: white;
  border-radius: 5px;
}
/* styles.css */
.ql-toolbar.ql-snow {
  border: 1px solid #ccc !important;
  box-sizing: border-box !important;
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  padding: 8px;
  height: 100px !important;
}

.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6 {
  text-align: left;
}
.reactQuilStle .ql-editor {
  height: 100%; /* Ensures that the editor takes up the full height of the container */
}

.mains {
  width: 100%;
  margin: 20px 0px; 
  display: flex;
  justify-content: center;
  align-content: center;
}
.preview_img {
  width: 100%;
  height: 100%; 
}
.imguplod, .img_box{
  align-items: center;
  background-color: #f6f6f6;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  display: flex;
  height: 150px;
  justify-content: center;
  text-align: center;
  width: 100%;
}
.img_box_new {
  width: 100%;
  height: auto;
   background-color: #f6f6f6; 
  display: flex;
  align-items: center;
  justify-content: center;
   border: 1px solid #665d6f; 
  opacity: 0.5;
  border-radius: 5px;
  padding: 10px;
}

.card {
  background: #ffffff;
  margin-top: 20px;
  box-shadow: 0 0 9px 1px #e7e9ec;
  border-radius: 10px;
}

.question_no_div {
  background-color: #1c1c1c;
  text-align: center;
  color: #fff;
  border-radius: 50%;
  min-height: 40px;
  min-width: 40px;
  max-height: 40px;
  max-width: 40px;
  line-height: 40px;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 20px;
}
.main_heading_title{
 margin-top: 10px;
  display: flex;
  column-gap: 30px;
  align-items: center;
  text-align: left;
  
}
.main_heading_title > h2 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 25px;
  color: #1c1c1c;
}

.main_heading_text {
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 25px;
  color: #1c1c1c;
}
.main_heading {
  display: flex;
      column-gap: 15px;
      align-items: center;
      background: #F6F6F6;
      padding: 20px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
}

.main_heading > h2 {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #1C1C1C;
  margin-bottom: 0;
}

.inputs_fields{
  width: 100%;
  text-align: right;
  margin: auto;
  margin-top: 0px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 34px;
}
.inputs_company{
 
  text-align: left;
  margin: auto;
  margin-top: 30px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 34px;
} 

.numbers {
  text-align: left;
}

.inputs > h6 {
  text-align: start;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #1c1c1c;
  margin: 0px;
}
.checkbox_div {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* width: 180px; */
}
.logo_img{
  margin: 0 auto;
  max-width: 150px;
  margin-bottom: 20px;
}
.img_div{ 
  max-width: 150px;
  margin: 0 auto;
  margin-bottom: 20px;
}
.form_sec{
  padding: 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.labelv{
  color: #1c1c1c !important;
  opacity: .6;
  text-align: center !important;
}
.labelv p{
  font-size: 14px;
  margin-bottom: 0;
  word-break: break-all;
}

 
.btn_div_new {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0px;
  width: 80%;
  margin: auto;
}
.btn_div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 0px 0;
}

.btn_div > :nth-child(2) {
  margin-left: auto;
}

@media(max-width:767px){
  .main_heading{
    display: block;
    text-align: center;
  }
  .question_no_div{
    margin: 0 auto;
  }
  .mains{
    margin: 20px 0;
    display: block;
    height: auto;
  }
  .inputs{
    width: 100%;
  }
}