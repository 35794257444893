@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Marcellus&display=swap'); 
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100..900;1,100..900&display=swap');

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  /* font-family: 'DM Sans', sans-serif !important; */
  font-family: "Roboto", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.disabled-input {
  background-color: #a9a9a9;
  color: #a9a9a9;          
  cursor: not-allowed;     
}
.error_enquiry{
  font-size: 13px;
  color: red;
  font-weight: bold;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Toastify__toast-container--top-center {
  width: 450px !important;
}

.prod-slider .swiper-button-prev:after,
.prod-slider .swiper-button-next:after {
  font-size: 16px !important;
  color: #fff !important;
}

/* Make sure the container is relative to position navigation outside */
.prod-slider .swiper-button-prev,
.prod-slider .swiper-button-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  z-index: 10;
  background: #1c1c1c;
  width: 50px !important;
  height: 50px !important;
}

.prod-slider .swiper-button-prev {
  left: -75px !important;
  /* Adjust the position to the left */
}

.prod-slider .swiper-button-next {
  right: -75px !important;
  /* Adjust the position to the right */
}

.hero-slider .swiper-button-prev:after,
.hero-slider .swiper-button-next:after {
  color: #fff;
  font-size: 38px;
}

.prod-slider1 .swiper-button-prev {
  left: 0;
}

.prod-slider1 .swiper-button-next {
  right: 0;
}

.prod-slider1 .swiper-button-prev,
.prod-slider1 .swiper-button-next {
  position: absolute;
  top: 50%;
  border-radius: 50%;
  z-index: 10;
  background: #1c1c1c;
  width: 50px !important;
  height: 50px !important;
}

.prod-slider1 .swiper-button-prev:after,
.prod-slider1 .swiper-button-next:after {
  font-size: 16px !important;
  color: #fff !important;
}

.ratingsc .swiper-button-prev,
.ratingsc .swiper-button-next {
  position: absolute;
  top: 50%;
  border-radius: 50%;
  z-index: 10;
  background: transparent;
  width: 30px !important;
  height: 30px !important;
  border: 1px solid #1c1c1c;
}

.ratingsc .swiper-button-prev:after,
.ratingsc .swiper-button-next:after {
  font-size: 13px !important;
  color: #1c1c1c !important;
}

.tesarrow {
  position: relative;
  margin: 20px auto 0;
  text-align: center;
}

.testmo-slider .swiper-button-prev {
  right: 50px;
  margin: 0 auto;
  font-size: 20px;
  color: #1c1c1c;
}

.testmo-slider .swiper-button-next {
  left: 50px;
  margin: 0 auto;
  font-size: 20px;
  color: #1c1c1c;
}

.testmo-slider .swiper-button-prev:after,
.testmo-slider .swiper-button-next:after {
  display: none;
}

.acchdr .MuiAccordionSummary-contentGutters {
  align-items: center;
  margin: 25px 0;
}

.acchdr .css-1086bdv-MuiPaper-root-MuiAccordion-root {
  box-shadow: none;
  border-bottom: 1px solid #D9DBE9;
}

.acchdr .css-1086bdv-MuiPaper-root-MuiAccordion-root::before {
  display: none;
}

.acchdr .css-eqpfi5-MuiAccordionSummary-content.Mui-expanded {
  margin: 0 0 10px 0;
}

.acchdr .css-1086bdv-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin-top: 10px;
  margin-bottom: 0;
}

.acchdr .css-yw020d-MuiAccordionSummary-expandIconWrapper {
  background: #B7B7B7;
  opacity: .3;
  border-radius: 8px;
  text-align: center;
}

.acchdr .css-yw020d-MuiAccordionSummary-expandIconWrapper.Mui-expanded {
  background: #1C1C1C;
  opacity: 1;
  color: #fff;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.filtersidebar .MuiPaper-root {
  box-shadow: none;
  background-color: transparent;
  border-bottom: 0.4px solid #E0DFE2
}

.filtersidebar .css-eqpfi5-MuiAccordionSummary-content.Mui-expanded {
  margin: 10px 0;
}

.filtersidebar .css-6hq2k4-MuiPaper-root-MuiAccordion-root {
  background-color: transparent;
}

.filtersidebar .MuiPaper-elevation::before {
  display: none;
}

.filtersidebar h3 {
  text-transform: capitalize;
font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #1C1C1C;
  margin: 0;
}

#root .ql-container {
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: #fefcfc;
}

/* Snow Theme */
#root .ql-snow.ql-toolbar {
  display: block;
  background: #eaecec;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

#root .ql-editor {
  min-height: 18em;
}

.dropdown {
  display: flex;
  flex-direction: column;
}

.category {
  margin-bottom: 10px;
}
.dropdown-toggle{
  margin-top: 0;
}
.dropdown-toggle .mincateg{
  padding: 8px 10px;
  background-color: transparent;
  cursor: pointer;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
  border-bottom: 1px solid rgb(240 234 234);
  margin-top: 0;
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 23.44px;
  outline: none;
  background: transparent;
  text-decoration: none;
}
.submenu-open ul{
  padding-left: 7px !important;
}
.submenu-open ul li a{
  text-decoration: none;
    color: #1c1c1c;
    font-size: 15px;
    font-weight: 400;
    margin-top: 0;
    opacity: .6;
    text-transform: capitalize;
    display: block;
    padding-bottom: 5px;
}
.icon {
  font-size: 18px;
  margin-left: 10px;
}
.icon {
  transition: transform 0.3s ease;
}

.icon.rotate {
  transform: rotate(180deg);
}
.dropdown-menuv,  .submenu {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}
.slide-downv, .submenu-open {
  max-height: 200px;
  opacity: 1;
  border: 0;
  border-bottom: 1px solid rgb(240 234 234);
  box-shadow: none;
  background-color: transparent;
  overflow-y: auto;
}

.dropdown-menuv ul li {
  margin-top: 0;
  font-size: 15px;
  font-weight: 400;
  text-transform: capitalize;
  color: #1c1c1c;
  opacity: 0.6;
}
.slide-upv {
  max-height: 0;
  opacity: 0;
  padding: 0;
}

.dropdown-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.dropdown-menu li:hover {
  background-color: #ddd;
}

@media(min-width:991px) and (max-width:1025px) {
  .prod-slider .swiper-button-prev {
    left: 0 !important;
  }

  .prod-slider .swiper-button-next {
    right: 0 !important;
  }
}

@media(max-width:991px) {
  .prod-slider .swiper-button-prev {
    left: 0px !important;
    /* Adjust the position to the left */
  }

  .prod-slider .swiper-button-next {
    right: 0px !important;
    /* Adjust the position to the right */
  }
}

@media(max-width:767px) {
  .acchdr .css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root {
    padding: 0 0;
  }

  .acchdr .css-eqpfi5-MuiAccordionSummary-content.Mui-expanded {
    display: block;
  }

  .prod-slider .swiper-button-prev {
    left: 0px !important;
    /* Adjust the position to the left */
  }

  .prod-slider .swiper-button-next {
    right: 0px !important;
    /* Adjust the position to the right */
  }

  .hero-slider .swiper-button-prev:after,
  .hero-slider .swiper-button-next:after {
    color: #fff;
    font-size: 22px;
  }

  .prod-slider .swiper-button-prev,
  .prod-slider .swiper-button-next,
  .prod-slider1 .swiper-button-prev,
  .prod-slider1 .swiper-button-next {
    width: 40px !important;
    height: 40px !important;
  }
}