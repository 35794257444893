.headerCategory {
  width: 100%;
  background: url("./banner bg img.png");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  padding-top: 5%;
  padding-bottom: 5%;
  margin-top: 125px;
}
.pageitle{
  text-align: center;
}
.pageitle h1{
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: 0;
  margin-bottom: 10px;
  margin-top: 0;
}
.pageitle ul{
  padding: 0;
  list-style-type: none;
  margin: 0;
}
.pageitle ul li{
  display: inline-block;
  margin-top: 0;
}
.pageitle ul li:last-child{
  padding-left: 5px;
}
.pageitle ul li a{
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #14142B;
  text-decoration: none !important;
}
.press_list{
  padding: 50px 0;
}
.press_box{
  cursor: pointer;
}
.press_img{
  min-height: 249px;
  max-height: 249px;
  overflow: hidden;
}
.img_fluid{
  max-width: 100%;
  height: auto;
}
.press_cont{
  padding: 10px 0;
}
.press_cont h4{
  margin: 0;
  text-transform: capitalize;
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
}
.press_meta{
  display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 8px;
      margin-bottom: 7px;
      text-align: center;
      overflow: hidden;
}
.press_meta h5{
  margin: 0;
  color: #636365;
  font-family: "Roboto", sans-serif;
font-size: 15px;
font-weight: 500;
line-height: 20.83px;
text-align: left;
}
.press_descpr{
  font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 23px;
    text-align: left;
    color: #665D6F;
    min-height: 70px;
    overflow: hidden;
    max-height: 70px;
}
.press_descpr p{
  display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-top: 0;
    margin-bottom: 0;
}
.prsmto{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  display: block;
}
.headerSubTitle {
  margin-left: -34%;
}
.card__main {
  width: 90%;
  margin-bottom: 10%;
}
.card__image {
  width: 100%;
}
.card__prof {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pressreleases__grid {
  padding: 2% 13%;
}
@media (max-width: 767px) {
  .headerCategory {
    margin-top: 57px;
  }
  .headerSubTitle {
    margin-left: -150%;
  }
  .pageitle h1{
    font-size: 25px;
        line-height: 26px;
        margin-bottom: 5px;
  }
}
