.header {
  background: url("Rectangle 5372.png");
  background-size: cover;
  margin-top: 125px;
  padding-top: 5%;
  padding-bottom: 5%;
}
.pageitle{
  text-align: center;
}
.pageitle h1{
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: 0;
  margin-bottom: 15px;
  margin-top: 0;
  color: #ffffff;
}
.pageitle ul{
  padding: 0;
  list-style-type: none;
  margin: 0;
}
.pageitle ul li{
  display: inline-block;
  margin-top: 0;
  color: #ffffff;
}
.pageitle ul li:last-child{
  padding-left: 5px;
}
.pageitle ul li a{
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #ffffff;
  text-decoration: none !important;
}
.sec_sc{
  padding: 50px 0;
}
.apply_btn{
  padding: 10px 35px;
  border-radius: 10px;
  background:  #1c1c1c;
  border: 1px solid  #1c1c1c;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.apply_btn:hover{
  background: #fff;
  color:  #1c1c1c;
}
.abttitle{
  font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 45px;
    text-transform: capitalize;
    color: #1c1c1c;
    margin: 0px;
    margin-bottom: 8px;
}
.abttitlefr{
  font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 35px;
    text-transform: capitalize;
    color: #1c1c1c;
    margin: 0px;
    margin-bottom: 8px;
}
.prtra{
  font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    text-align: left;
    color: #636365;
    opacity: .8;
    margin: 0;
    margin-bottom: 10px;

}
.swithry{
  padding-bottom: 50px;
}
.ariconb{
  font-size: 19px;
  padding-left: 5px;
}
.bannerheading {
  margin-left: -18%;
}
.bannersubheading {
  margin-left: -10%;
}
.top__flex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2%;
  margin-top: 2%;  
}
.iconimg {
  width: 80%;
}
.greybg {
  width: 100%;
}
.greybgDiv {
  width: 80%;
}
.vintageframe {
  width: 100%;
} 
.aboutuscontainer {
  margin-bottom: 5%;
  padding: 2%;
}
.aboutusTop {
  margin-bottom: 7.5%;
}
.welcomeheading {
  display: flex;
  justify-content: center;
  align-items: center;
}
.leftImage {
  width: 60%;
  margin-right: 2%;
}
.leftcard {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 7%;
}

.curatorcards {
  margin-top: 1.5%;
}
.cardInfo__right {
  margin-left: 6%;
  /* margin-top: 1%; */
}
.logos_flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 2%;
}
.loc__logo {
  width: 3%;
}
/* .leftText {
  margin-right: 10%;
} */

.rightcard {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 7%;
}
.rightImage {
  width: 50%;
  margin-left: 15%;
}

.info__div {
  width: 80%;
  margin-left: 10%;
  text-align: justify;
}
li {
  color: #636365;
  margin-top: 2%;
}
.image__flex {
  display: flex;
  justify-content: center;
  align-items: flex-start;  
}
.image__flex2 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-left: 10%;
}
.image__flex3 {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-left: 9%;
  }
.rightImages {
  width: 96%;
  margin-bottom: 3%;
}
.rightImages2 {
  width: 100%;
  margin-bottom: 3%;
}
.leftImage2 {
  width: 60.5%;
  margin-right: 2%;
}
.rightImages3 {
  width: 82%;
  margin-bottom: 3%;
}
.leftImage3 {
  width: 56%;
  margin-right: 2%;
}
.aboutusTop {
  margin-left: 12%;
}
.maingrid__container {
  padding: 0% 10%;
}
.info__div2 {
  width: 80%;
  margin-left: 9%;
}
.ourartistcard{
    width: 100%;
    background-color: white;
}
.ourartistinfo h4{
  margin: 8px 0 5px;
    text-transform: capitalize;
font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
}
.ourartistinfo p{
  font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    text-align: center;
    color: #636365;
    opacity: .8;
    margin: 0;
}
.socilist{
  list-style-type: none;
  padding: 0;
  margin: 7px auto 0;
  text-align: center;
}
.socilist li{
  display: inline-block;
  margin-right: 5px;
}
.socilist li:last-child{
  margin-right: 0;
}
.socialmediaflex{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 8%;
    margin-left: 45%;
    margin-bottom: 4%;
}
.sociallogo{
    width: 100%;
    margin-right: 25%;
}
.ourartistimg{
  width: 100%;
}
.text__flex{
  display: flex;
  justify-content: center;
  align-items: center;
}
.ourartistdiv{
  padding: 2% 13.5%;
  background-color: #F9F9F9;
  margin-top: 5%;
}
.artistTypo{
  display: flex;justify-content: center;align-items: center;
}
.sec_shgn{
  background: #F6F6F6;
  padding: 50px 0;
}
.tablist{
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.tablist li{
  margin-top: 0;
  display: inline-block;
  margin-right: 5px;
}
.tablist li a{
  text-decoration: none;
  padding: 8px 20px;
    border-radius: 10px;
    background: #fff;
    border: 1px solid #fff;
    color: #1c1c1c;
font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    display: block;
    cursor: pointer;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.sec_shgn .tab_content{
  box-shadow: 0px 6.14px 19.66px 0px #080F340F;
  border-radius: 22px;
  border-top-left-radius: 0;
  padding: 25px;
  background: #fff;
}
.sec_shgn .tab_content p{
  font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    text-align: left;
    color: #636365;
    opacity: .8;
    margin: 0;
}
.stboxh{
  padding-top: 30px;
    height: 100%;
}
.stvobx{
  border-radius: 10px;
    padding: 20px;
    background: #F6F6F6;
    position: relative;
    height: 100%;
}
.stvobx h4{
  margin: 0 0 5px;
  text-transform: capitalize;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}
.stvobx p{
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: #636365;
  opacity: .8;
  margin: 0;
}
.accordsc{
  box-shadow: 0px 6.14px 19.66px 0px #080F340F;
   border-radius: 22px;
   padding: 25px;
   background: #fff;
   height: 100%;
}
.benfhdgn{
  margin: 0;
   text-transform: capitalize;
   font-family: "Roboto", sans-serif;
   font-size: 18px;
   font-weight: 600;
   line-height: 24px;
   text-align: left;
   color: #1C1C1C;
}
.bendesc{
  font-family: "Roboto", sans-serif;
   font-size: 15px;
   font-weight: 400;
   line-height: 25px;
   text-align: left;
   color: #636365;
   opacity: .8;
   margin: 0;
}
.sec_title{
  font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 45px;
    text-transform: capitalize;
    color: #1c1c1c;
    margin: 0px;
    margin-bottom: 10px;
}
.sub_title{
  letter-spacing: 0.00938em;
    color: #1C1C1C;
    z-index: 10;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    line-height: 26px;
    margin-bottom: 20px;
    text-transform: capitalize;
    font-size: 20px;
    margin-top: 0;
}
.withor{
   font-family: "Roboto", sans-serif;
   font-size: 16px;
   font-weight: 400;
   line-height: 27px;
   text-align: center;
   color: #636365;
}
.text_center{
  text-align: center;
}
.title_image{
  max-width: 110px;
}
.img_fluid{
  max-width: 100%;
  height: auto;
}
.max_width{
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
}
.max_width .sub_title{
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  color: #636365;
  
}
.whitext{
color: #fff !important;
}
.mt_2{
  margin-top: 10px;
}
.readMoreBtn{
  background: none;
  cursor: pointer;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 25px;
  text-align: left;
  color: #1c1c1c;
  padding-top: 6px;
  border: 0;
}

@media(max-width:767px){
  .pageitle h1{
    font-size: 25px;
        line-height: 26px;
        margin-bottom: 5px;
    text-align: center;
  }
.abttitle, .sec_title{
    font-size: 23px;
    line-height: 26px;
    text-align: center;
  }
  .abttitlefr{
    font-size: 21px;
    text-align: center;
  }
  .leftText{
    text-align: center;
  }
  .leftText p{
    text-align: center;
  }
  .stvobx{
    text-align: center;
  }
  .stvobx h4, .stvobx p{
    text-align: center;
  }
  .header{
    margin-top: 57px;
  }
  .tablist li{
    display: block;
    margin-bottom: 5px;
    margin-right: 0;
  }
  .tablist li a{
    border-radius: 10px;
    text-align: center;
  }
  .sec_shgn .tab_content{
    border-radius: 22px;
    margin-top: 15px;
    text-align: center;
  }
  .sec_shgn .tab_content p{
    text-align: center;
  }
  .stboxh{
    padding-top: 0px;}
}
@media (max-width: 600px) {
  /* .iconimg {
    width: 40%;
  } */
  .leftImage{
    width: 51%;
  }
  .rightImages{
    width: 65%;
  }
  .image__flex{
    margin-left: 7%;
  } 
  .image__flex2{
    margin-right: 3%;
  } 
}
