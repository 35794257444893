.mains {
    background-color: #F6F6F6;
    padding: 20px;
    border-radius: 10px;
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0px;
}

.footer>span {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #636365;
}

@media(max-width:600px) {
    .mains {
        width: 100%;
    }
}