.compoents{
    background-color: #f6f6f6;
    border-radius: 10px;
    padding-bottom: 20px;
}

@media(max-width:600px){
    .mains{
        width: 100%;
    }
}