
.sucrhndg{
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 25px;
    line-height: 33px;
    text-transform: capitalize;
    margin: 0;
    text-align: center;
    margin-bottom: 15px;
}
.icdvn{
    width: 100px;
    height: 100px;
    background: #f6f6f6;
    text-align: center;
    line-height: 100px;
    border-radius: 50%;
    margin: 0 auto;
}
.icvn{
    color:#1C1C1C;
    font-size: 35px;
}
.orpra{
font-family: "Roboto", sans-serif;
    font-size: 17px;
    font-weight: 400;
    line-height: 26px;
    color: #636365;
    opacity: .8;
}
.cancel_img {
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
    margin-top: -24px;
}
.apply_btn{
    padding: 10px 35px;
    border-radius: 10px;
    background:  #1c1c1c;
    border: 1px solid  #1c1c1c;
    color: #fff !important;
    font-family: DM Sans !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 26px !important;
    cursor: pointer; 
    text-decoration: none !important;
    display: inline-block;
  }
  .apply_btn:hover{
    background: #fff;
    color:  #1c1c1c !important;
  }
.cancel_imgweb {
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
    margin-top: -64px;
}

.cancel_img_hide_web {
    visibility: hide;
    display: none;
    justify-content: flex-end;
    margin-right: 10px;
    /* margin-top:-64px; */
}

.cancel_img_hide {
    visibility: hide;
    display: none;
    justify-content: flex-end;
    margin-right: 10px;
    margin-top: -24px;
}

.card {
    /* height: 350px; */
    width: 350px;
    background-color: #f6f6f6;
}

.card_content {
    text-align: center;
    margin: auto;
    border: none;
    outline: none;
}

.radius_image {
    width: 100px;
    height: 100px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card_content>h3 {
    font-family: 'Marcellus';
    font-style: normal;
    font-weight: 400;
    font-size: 33px;
    line-height: 45px;
    text-align: center;
    text-transform: capitalize;
    color: #1C1C1C;
    margin: 0px;
    width: 270px;
    margin: auto;
}

.card_content>h6 {
    margin: 0px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: #636365;
    width: 300px;
    margin: auto;
    margin: 8px auto;
    margin-bottom: 20px;
}
.signboc{
    display: flex;
    flex-direction: column;
    flex: 1 1;
    justify-content: space-between;
}
.ml_auto{
    margin: 0 auto;
}
@media(max-width:600px) {
}