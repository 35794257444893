.table_head {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #1C1C1C;
}

.table_column {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #636365;
}

.table_columnss {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #636365;
    max-width: 100px;
}

.career_lastcol {
    display: flex;
    justify-content: space-between;
}

.closed {
    color: #D00000;
}

.loader_box {
    display: flex;
    justify-content: center;
    align-items: center;
}