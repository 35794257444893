.headerCategory {
  width: 100%;
  background: url("./banner bg img.png");
  background-size: cover; 
  padding-top: 50px;
  padding-bottom: 50px;
  margin-top: 125px;
}

.dropdown_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70%;
}

.ddflexDiv {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.career__cards {
  box-shadow: 0px 0px 30px 0px #0000001A;
  border-radius: 10px;
  margin-bottom: 5%;
  padding: 20px;
  cursor: pointer;
}
.career__cards h4{
  font-family: "Roboto", sans-serif;
font-size: 20px;
font-weight: 600;
line-height: 26px;
text-align: left;
margin: 0 0 8px;
text-transform: capitalize;
}
.car_meta{
  margin-bottom: 10px;;
}
.car_meta p{
  margin: 0 0 3px;
  font-family: "Roboto", sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 23px;
text-align: left;
text-transform: capitalize;
color: #636365;
}
.car_descr h5{
  font-family: "Roboto", sans-serif;
font-size: 18px;
font-weight: 500;
line-height: 24px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
margin: 0 0 5px;
}
.car_descr p{
  margin: 0 ;
  font-family: "Roboto", sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 23px;
text-align: left;
color: #636365;
}
.car_descr span{
  min-height: 46px;
  max-height: 46px;
  text-overflow: ellipsis;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.filtersec{
  padding: 40px 0 10px;
}
.car_descr{
  margin-bottom: 15px;
}
.apply_btn{
  padding: 10px 25px;
  border-radius: 10px;
  background: #000;
  border: 1px solid #000;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  cursor: pointer;
}
.apply_btn:hover{
  background: transparent;
  color: #1c1c1c;
}
.cardContainer {
  padding: 4% 10%;
}

.modalclosebutton {
  margin-left: 93%;
  margin-top: 2%;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
}
.pageitle{
  text-align: center;
}
.pageitle h1{
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: 0;
  margin-bottom: 10px;
  margin-top: 0;
}
.pageitle ul{
  padding: 0;
  list-style-type: none;
  margin: 0;
}
.pageitle ul li{
  display: inline-block;
  margin-top: 0;
}
.pageitle ul li:last-child{
  padding-left: 5px;
}
.pageitle ul li a{
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #14142B;
  text-decoration: none !important;
}
.listsec{
  padding: 50px 0;
}

@media(max-width:767px){
  .car_descr span{
    min-height: auto;
  }
  .headerCategory {
    margin-top: 57px;
  }
  .pageitle h1{
    font-size: 25px;
        line-height: 26px;
        margin-bottom: 5px;
  }
}
@media(max-width:600px) {
  

  .headerSubTitle {
    margin-left: -150%;
  }
  .ddflexDiv{
    display: none;
  }
}