.table_head {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #1c1c1c;
}
.editicon{
  color: #665D6F;
  font-size: 22px;
}
.deleicon{
  color: #1C1C1C;
  font-size: 22px;
}
.hyperlink_title{
  text-decoration: underline;
  color: #636365;
}
.table_columns {
  color: #636365;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  /* height: 100px; */
  /* max-width: 40px; */
}

.table_column {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #636365;
}

.table_columnss {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #636365;
  max-width: 100px;
}

.career_lastcol {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.closed {
  color: #d00000;
}

.pagination_box {
  display: flex;
  justify-content: flex-end;
  background-color: #F6F6F6;
  padding-top: 25px;
  padding-right: 25px;
  /* margin-bottom: 20px; */
}

.pagination_box>div {
  margin-bottom: 25px;
}

.loader_box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.action {
  display: flex;
  align-items: center;
  gap: 10px;
}
.action img {
  cursor: pointer;
}
.title {
  text-decoration: underline;
  text-decoration-color: transparent;
  color: inherit;
  cursor: pointer;
  transition: color 0.3s, text-decoration-color 0.3s;
}

.title:hover {
  color: black;
  text-decoration-color: black;
}
