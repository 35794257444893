
.preview_img {
    width: 100%;
    background-color: white;
    margin: auto;
    height: 150px;
    border-radius: 5px;
    opacity: 0.5;
    border: 1px solid #665D6F;
    margin-top: 10px;

}

.img_box {
    width: 100%;
    height: 100%;
    /* background-color: #f6f6f6; */
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    /* opacity: 0.5; */
    border-radius: 5px;
}

.label {
    /* margin: 5px 0px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    margin-top: 10px;
}

.label>p {

    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #665D6F;
    margin: 5px 0px;
    opacity: 0.5;
}

.img_view {
    width: 100%;
    height: 150px;
}

.img_view_hide {
    border: 1px solid red;
    width: 100%;
    background-color: #f6f6f6;
}

.image_div {
    width: 46px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
}
