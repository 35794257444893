.upload_pic_div{
    height: 200px;
    width: 200px;
    border-radius: 10px;
    margin: 0 auto;
}
.components {
    background-color: #F6F6F6;
    padding: 20px;
    border-radius: 10px;
}

.btn_div {  
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0px 0 0px;
}

@media(max-width:600px) {
    .mains {
        width: 100%
    }
}