
.left_main{
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.right_main_reset {
    width: 60%;
    padding: 23% 0% 20% 0%;
    /* margin-right: 15%; */
}

.reset_btn {
    margin: 15px 0px;
}
.reset_main {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}
.reset_center {
    width: 60%;
    margin: 0px auto;
}

.span_input_reset {
    font-weight: 500;
    font-size: 18px;
    line-height: 40px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.02em;
}

.heading_reset {
    text-transform: capitalize;
    font-family: 'Marcellus';
    font-style: normal;
    font-weight: 400;
    margin: 0px 0px 10px 0px;
    font-size: 30px;
}

.opt_text_reset {
    letter-spacing: 0.05em;
    color: #636365;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
}


@media(max-width:912px) {
    
.reset_main {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
}
}

@media(max-width:540px) {
    .reset_center{
        width: 90%;
        margin:0px auto;
    }
}