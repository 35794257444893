.cancel_img {
  display: flex;
      justify-content: flex-end;
      position: absolute;
      right: 10px;
      z-index: 9999999;
      cursor: pointer;
      top: 10px;
}

.modal_heading {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 46px;
}

.horizontal_line {
  height: 0.01rem;
  width: 100%;
}

.data_div {
  margin: 0;
  padding-right: 20px;
}
.modalImg {
  width: 100%;
  height: auto;
}

.user_detail {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  margin: 0px 0px;
}

.user_detail > p {
  margin: 5px 0px;
}

.user_detail > h5 {
  margin: 5px 0px;
}

.action {
  text-align: center;
}

.user_title {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #1c1c1c;
}
.requestpop {
  margin: 0px;
  font-size: 18px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  color: #1c1c1c;
}
.reactext p{
  font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #636365;
    opacity: .8;
    margin-bottom: 0;
}

.user_value {
  color: #636365;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
}
.user_value1 {
  color: #636365;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  padding-top: 1px;
}
.closicon{
  font-size: 35px;
    color: #1C1C1C;
}
.inputs_img{
  max-height: 180px;
  overflow: hidden;
  border-radius: 10px;
}
.inputs_img img{
  border-radius: 10px;
}
.odlist{
  color: #1C1C1C;
font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    margin-bottom: 5px;
}
.odlist span{
  color: #636365;
}
.reactext{
  padding-top: 15px;
}

@media (max-width: 767px) {
  .modal_del {
    max-width: 100%;
    background-color: white;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    color: #1c1c1c;
  }
  .cancel_img{
    position: relative;
    top: 0;
    right: 0;
  }
}
