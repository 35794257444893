.mains {
    background-color: #F6F6F6;
    padding: 20px;
    border-radius: 10px;
}
.previewimg_img_div {
    /* display: flex;
    justify-content: center; */
    width: 100%;
}
.previewimg {
    height: 300px;
    width: 333px;
}


@media(max-width:600px) {
   
    .previewimg_img_div {
        /* display: flex;
        justify-content: center; */
        width: 100%;
        margin: auto auto;
        text-align: center;
    }
    
    .previewimg {
        height: 300px;
        width: 95%;
    }
}