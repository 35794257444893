.modal_exhibition {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  max-height: 95%;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 30px;
  overflow-y: auto;
}

.mpophndg{
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  text-align: center;
}
.clobtn{
  position: absolute;
  right: 10px;
  top: 10px;
}
.clobtn a{
  cursor: pointer;
}
.iconru{
  font-size: 30px;
  color: #1C1C1C;
}
 

.inputs_div {
  border: 1px solid red;
}
 
.radio_active {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #636365;
}

.radio_notactive {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #636365;
  opacity: 0.5;
  border: none;
}

.action {
  text-align: center; 
}.action_btn{
  padding: 10px 25px;
  border-radius: 10px;
  background: #000;
  border: 1px solid #000;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  cursor: pointer;
}
.action_btn:hover{
  background: transparent;
  color: #000;
}
.actionc{
  text-align: center;
}
@media(min-width:993px) and (max-width:1025px){
  .modal_exhibition{
    width: 50%;
  }
}
@media(min-width:768px) and (max-width:992px){
  .modal_exhibition { 
    width: 60%; 
  }
}
@media (max-width: 767px) {
  .modal_exhibition { 
    width: 95%; 
  }
}