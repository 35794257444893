.dashboard_main {
    padding: 20px;
    background-color: #f6f6f6;
    border-radius: 10px;
}.flex_cards {
    display: flex;
    flex-wrap: wrap;
}
.left_align{
    text-align: left;
}
 

.card {
    box-shadow: 0px 19.1765px 47.9412px rgba(181, 188, 194, 0.2);
    border-radius: 3px;
    padding: 20px;
    background-image: url('../../../../../assets/img/dashboard_card_bg.png');
    /* background-image: url('../../../../../../public/img/dashboard_card_bg.png'}); */
    background-size: cover;
    background-repeat: no-repeat;
}

.img_div {
    background-color: #F1F1F1;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    line-height: 55px;
    text-align: center;
}

.img_div>img {
    text-align: center;
    width: 30px;
}

.card>h3 {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 19px;
    line-height: 25px;
    color: #1C1C1C;
    padding: 10px 0 0;
    min-height: 65px;
    margin-bottom: 0;
}
.card h4{
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    margin-bottom: 0;
    font-size: 40px;
    line-height: 41px;
}

.card>h1 {
    margin: 20px auto auto 20px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 59px;
}

@media(max-width:767px){
    .card>h3{
        min-height: auto;
    }
    .img_div {
        margin: 0 auto;
    }
    .card{
        text-align: center;
    }
    .card h4{
        text-align: center;
    }
}