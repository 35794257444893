.login_main {
  height: 100vh;
  width: 100vw;
  display: flex;
  overflow-x: hidden;
}
.login_buttons {
  margin-bottom: 10px;
}
.img_login {
  height: 60%;
  width: 50%;
}

.left_login_main {
  background-color: #ebebeb;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  height: 100%;
  width: 100%;
}

.right_main_l {
  display: flex;
  justify-content: center;
  padding: 3% 0% 0% 0%;
  width: 100%;
}
.heading_art_s {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  margin: 0px;
  font-size: 32px;
  margin-top: 10px;
}
.img_art {
  height: auto;
  width: 25%;
  cursor: pointer;
}

.heading_art {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  margin: 0px;
  font-size: 35px;
}

.para_note {
  color: #636365;
  margin: 15px 0px 15px 0px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 15px;
  letter-spacing: 0.05em;
}

.para {
  color: #000000;
  margin-right: 20px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  /* opacity: 0.4; */
}

.para_span {
  color: #1c1c1c;
  margin-left: 10px;
  font-weight: 500;
}

.login_button {
  width: 105%;
  margin: 30px 0px;
}
.policy {
  display: flex;
  align-items: center;
}

.span_inputs {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 40px;
  letter-spacing: 0.02em;
  color: #000000;
  margin: 5px 0px;
  cursor: pointer;
}

.forgot {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: auto auto;
}

.forgot > div {
  margin: auto 0px;
}

.login_center {
  width: 60%;
}

.checks[type="checkbox"] {
  accent-color: black;
  width: 20px;
  height: 20px;
}
.policy .para{
  padding-left: 5px;
}
.apply_btn{
  padding: 10px 35px;
  border-radius: 10px;
  background:  #1c1c1c;
  border: 1px solid  #1c1c1c;
  color: #fff !important;
  font-family: DM Sans !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 26px !important;
  cursor: pointer; 
  text-decoration: none !important;
  display: inline-block;
}
.apply_btn:hover{
  background: #fff;
  color:  #1c1c1c !important;
}
.full_width{
  width: 100%;
}
.apply_btnwht{
  padding: 10px 35px;
  border-radius: 10px;
  background: #fff;
  border: 1px solid #000;
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  cursor: pointer;
}
.apply_btnwht:hover{
  background: #1c1c1c;
    color: #fff;
}

@media (min-width: 993px) and (max-width:1025px) {
.login_center{
  width: 80%;
}
.img_login{
  width: 50%;
  height: auto;
}
}
@media (max-width: 992px) {
  .login_main {
    display: flex; 
  }
  .login_center{
    width: 90%;
  }
  .img_login{
    width: 80%;
    height: auto;
  }
} 
@media(max-width:767px){
  .left_login_main{
    display: none;
  }
  .text_center{
    text-align: center;
  }
}
@media (max-width: 600px) {
  .login_center {
    width: 90%;
  }
  .img_login {
    height: 70%;
    width: 50%;
  }
}
