.footer_main {
    display: flex;
    justify-content: space-between;
    background-color: #F3F3F3;
    /* height: 50vh; */
    flex-wrap: wrap;
    padding: 50px 0 30px;
}

.footer_main>div {
    /* background-color: red; */
    min-width: 150px;
}

.footer_heading {
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #636365;
    margin-top: 0;
    margin-bottom: 0px;
}

.footer_links {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #665D6F;
    opacity: 0.8;
    cursor: pointer;
    margin: 0;
    padding-bottom: 9px;
}

.ftlinks {
    padding: 0;
    list-style-type: none;
    margin: 0;
}

.ftlinks li {
    margin-top: 0;
}

.ftlinks li a {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #665D6F;
    opacity: 0.8;
    cursor: pointer;
    margin: 0;
    padding-bottom: 10px;
    text-decoration: none;
    display: block;
}
.footer_cards {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.footer_cards>img {
    width: 40px;
    height: 24px;
}

.footer_socialmedia {
    display: flex;
    margin: 15px 0px 0;
}

.footer_socialmedia>img {
    width: 40px;
    height: 20px;
    margin-right: 35px
}

.footer_socialmedia .facebook {
    width: 10px;
    height: 20px;
}

.footer_socialmedia ul {
    list-style-type: none;
    padding: 0;
}

.footer_socialmedia ul li:first-child {
    padding-left: 0;
}

.footer_socialmedia ul li:last-child {
    padding-right: 0;
}

.footer_socialmedia ul li {
    display: inline-block;
    padding: 0 8px;
    margin-top: 0;
}

.soclicn {
    font-size: 20px;
}

.socln_sec {
    background: #1C1C1C;
    padding: 30px 0;
}

.scrvtext {
    display: flex;
    align-items: center;
    gap: 20px;
}

.scrvtext h5 {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
    text-transform: capitalize;
    color: #fff;
}

.fomsc {
    text-align: right;
}

.frsvc {
    display: flex;
    gap: 15px;
    justify-content: end;
}
@media(min-width:768px){
.mobileview{
    display: none;
}
.onlmob{
    display: none;
}
}
@media(min-width:768px) and (max-width:991px) {
    .fomsc {
        text-align: center;
    }

    .frsvc {
        justify-content: center;
    }
}

@media(max-width:767px) {
    .mobnone{
        display: none;
    }
    .socln_sec{
        border-bottom: 1px solid #fff;
        padding: 20px 0;
    }
    .mobileview .footer_heading{
        color: #fff;
        font-weight: 500;
        font-size: 16px;
        line-height: 23px    
    }
    .desktopview{
        display: none;
    }
    .scrvtext h5 {
        font-size: 23px;
        line-height: 26px;
    }

    .fomsc {
        text-align: center;
    }

    .mte {
        margin-bottom: 10px;
    }

    .frsvc {
        display: block;
    }

    .ftlinks {
        column-count: 2;
    }
.mobileview .ftlinks {
    column-count: 1;
}
.mobileview .ftlinks li:last-child a{
    padding-bottom: 0;
}
.mobileview .ftlinks li  a{
   color: #fff;
   opacity: 1;
   font-size: 15px;
}
    .footer_heading {
        margin-bottom: 0;
    }

    .footer_main {
        padding: 15px 0 0;
        background: #1C1C1C;
    }

    .mbtextcenter {
        text-align: center;
        margin-top: 5px;
    }

    .footer_socialmedia {
        justify-content: center;
    }
    .footer_socialmedia li a{
        color: #fff;
    }
    .footer_links{
        font-size: 13px;
        line-height: 19px;
        color: #fcfbf9;
        opacity: 1;
        padding-bottom: 7px;
    }
    .footer_cards{
        padding: 8px;
        background: #fff;
        margin-top: 5px;
    }
}