.mains {
    width: 100%;
}
.components{
    background-color: #f6f6f6;
    padding-bottom: 20px;
    border-radius: 10px;
}
@media(max-width:600px) {
    .mains {
        width: 100%;
    }
}

.pagination_box {
    display: flex;
    justify-content: flex-end;
    background-color: #F6F6F6;
    padding-top: 25px;
    padding-right: 25px;
    /* margin-bottom: 20px; */
}