.proddets__top {
  margin-top: 125px;
  padding-top: 2%
}

.main__img {
  max-width: 100%;
  height: auto;
  /* max-height: 50vh; */
}
.widthfull{
  width: 100%;
}
.small_img {
  margin-right: 2%;
  height: 100%;
  width: 10%;
}

.previewimg__div {
  margin-top: 1.5%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.outlineimg {
  width: 6.5%;
}

.prodDetails {
  width: 100%;
  height: auto;
}

.prodDetails h1 {
  text-transform: capitalize;
  margin: 0;
  padding-bottom: 8px;
  font-family: "Roboto", sans-serif;
  font-size: 38px;
  font-weight: 500;
  line-height: 40px;
  text-align: left;
}

.prodDetails a {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  text-decoration-line: underline;
  text-decoration-style: solid;
  color: #636365;
  display: block;
  margin-bottom: 5px;
}

.pricing__section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 20px;
}

.mprice {
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  font-weight: 500;
  line-height: 33.35px;
  text-align: left;
  margin: 0;
  margin-right: 20px;
}

.stock {
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 22.35px;
  text-align: right;
  margin: 0;
}

.ellipseshare {
  background: #f3f3f3;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  line-height: 38px;
  cursor: pointer;
  text-align: center;
}

.ellipseheart {
  cursor: pointer;
  width: 30px;
  height: 30px;
  background: #f3f3f3;
  border-radius: 50%;
  line-height: 38px;
  text-align: center;
}

.ellipseheart .likeIcon {
  font-size: 18px;
  font-weight: 500;
  margin-right: 0;
}

.shricn {
  font-size: 18px;
  font-weight: 500;
}

.ratings__reactions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stratinglst {
  list-style-type: none;
  padding: 0;
  margin: 0 0;
}

.stratinglst li {
  display: inline-block;
  margin-right: 3px;
}

.stratinglst li:last-child {
  margin-right: 0;
}

.sticon {
  color: #2C2844;
  opacity: .2;
  font-size: 20px;
}

.starrating {
  margin-right: 0.5%;
  margin-top: 1.5%;
}

.viewIcon {
  color: #1C1C1C;
  margin-right: 5px;
  font-size: 16px;
}

.likeIcon {
  color: #1C1C1C;
  margin-right: 5px;
  font-size: 16px;
}

.proddets_dets {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 13px;
  border-bottom: 1px solid #d5d4da;
  padding-bottom: 13px;
}

.catgr {
  font-weight: 500;
  color: #1C1C1C;
  margin: 0;
}

.reslte {
  margin: 0;
  color: #1C1C1C;
  opacity: .5;
}

.proddets_frame {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 13px;
  border-bottom: 1px solid #d5d4da;
  padding-bottom: 13px;
}

.greydescr {
  text-align: right;
}

.search_select {
  height: 27px;
  width: 160px;
}

.dets__divider {
  width: 81%;
  margin-right: 18%;
  margin-top: 2%;
}

.prints__customization {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.print_label {
  color: #000 !important;
  background: transparent;
  border: 0;
  text-decoration: underline;
  font-family: "Roboto", sans-serif;
  font-size: 16px !important;
  font-weight: 500 !important;
  text-decoration-color: #000 !important;
  line-height: 26px !important;
  cursor: pointer;
  padding: 0;
}

.buttonstyl {
  padding: 10px 25px;
  border-radius: 10px;
  background: #000;
  border: 1px solid #000;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  cursor: pointer;
}

.buttonstyl:hover {
  background: transparent;
  color: #000;
}

.buttonstylw {
  padding: 10px 25px;
  border-radius: 10px;
  background: #000;
  border: 1px solid #000;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  cursor: pointer;
  display: block;
  width: 100%;
}

.buttonstylw:hover {
  background: transparent;
  color: #000;
}

.bntbdr {
  padding: 10px 25px;
  border-radius: 10px;
  background: #fff;
  border: 1px solid #000;
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  cursor: pointer;
  display: block;
  width: 100%;
}

.bntbdr:hover {
  background: #000;
  color: #fff;
}

.mb_2 {
  margin-bottom: 15px;
}

.custLink {
  margin-right: 18.5%;
  color: black;
}

.offerSection {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.offserhdng {
  display: flex;
  align-items: center;
  gap: 8px;
}

.offserhdng p {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 25px;
  text-align: left;
  color: #1c1c1c;
}

.ofrresn p {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: #1c1c1c;
  opacity: .4;
}

.vectorheart {
  width: 60%;
}

.vectorimages {
  width: 60%;
}

.description {
  margin-top: 2%;
  width: 100%;
}

.sidePanel {
  width: 100%;
}

.choose__frame {
  background-color: #f7f7f8;
  border-radius: 6px;
  border: 0.8px solid #ededf1;
  padding: 20px;
  margin-bottom: 15px;
}

.chosbtn {
  padding: 5px 10px;
  border-radius: 10px;
  background: #000;
  border: 1px solid #000;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 26px;
  cursor: pointer;
}

.general__information {
  background-color: #f7f7f8;
  border-radius: 6px;
  border: 0.8px solid #ededf1;
  padding: 20px;
}

.specicationlis {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.specicationlis li {
  display: flex;
  align-items: center;
  margin-top: 0;
  justify-content: space-between;
  position: relative;
  padding-left: 12px;
  margin-bottom: 18px;
}

.specicationlis li:last-child {
  margin-bottom: 0;
}

.specicationlis li span {
  color: #000000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  text-align: left;
  z-index: 99;
}

.specicationlis li span+span {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  color: #636365;
  z-index: 99;
}

.specicationlis li::before {
  content: '';
  border-left: 3px solid #000;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.oraimg {
  text-align: right !important;
  line-height: 0 !important;
}

.orientation1 {
  width: 40%;
}

.orientation2 {
  margin-right: 2%;
  width: 40%;
}

.orientation3 {
  width: 40%;
}

.features {
  padding: 50px 0;
  background-color: #f7f7f8;
}

.feturcont h4 {
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  font-weight: 600;
  line-height: 26px;
  text-align: left;
  color: #020A18;
  margin: 0 0 2px
}

.feturcont p {
  font-family: "Roboto", sans-serif;
  text-align: left;
  font-size: 15px;
  font-weight: 400;
  color: #636365;
  margin: 0;
  line-height: 25px;
}


.sec_title {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 45px;
  text-transform: capitalize;
  color: #1c1c1c;
  margin: 0px;
  margin-bottom: 10px;
}

.latesprosec {
  padding: 50px 0;
}

.sub_title {
  letter-spacing: 0.00938em;
  color: #1C1C1C;
  z-index: 10;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  line-height: 26px;
  margin-bottom: 20px;
  text-transform: capitalize;
  font-size: 20px;
  margin-top: 0;
}

.text_center {
  text-align: center;
}

.title_image {
  max-width: 110px;
}

.mini__truck {
  width: 25%;
  height: 25%;
}

.safe__shipping {
  display: flex;
}

.package {
  width: 25%;
  height: 25%;
}

.secure {
  width: 25%;
  height: 25%;
}

.proddets__middle {
  padding: 30px 0 50px;
}

.design__headingImage {
  display: flex;
  justify-content: center;
  align-items: center;
}

.design__image {
  width: 7.5%;
}

.design__heading {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  padding: 15px 0px;
}

.cardImage {
  width: 100%;
  object-fit: fill;
}

.card__top img {
  object-fit: fill;
  height: 100%;
}

.pic_info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 7px;
  text-align: center;
  overflow: hidden;
}

.card__top {
  position: relative;
  height: 350px;
}

.card__main {
  /* background-color: #ccc; */
  cursor: pointer;
  width: 100%;
}

.card__middle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  display: block;
}

.titles {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: #1c1c1c;
  text-transform: capitalize;
}

.info_like {
  display: flex;
  justify-content: center;
  column-gap: 8px;
  align-items: center;
}

.wishlst {
  width: 25px;
  height: 25px;
  background: #f3f3f3;
  border-radius: 50%;
  line-height: 31px;
  text-align: center;
}

.like_count {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  color: #1c1c1c;
}

.names {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #636365;
  opacity: 0.5;
  margin: 0;
  padding-top: 1px;
  padding-bottom: 6px;
  border-bottom: 1px solid #BDBDBD;
  text-transform: capitalize;
}

.price_sec {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin-top: 8px;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 7px;
  text-align: center;
}

.buys {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #636365;
  opacity: 0.6;
  margin-bottom: 5px;
  display: block;
}

.prices {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #1c1c1c;
}

.hrs {
  height: 50px;
  width: 0.1px;
  border-right: 1px solid #bdbdbd;
}

.card__middleLeft {
  margin-top: 12px;
}

.card__middleRight {
  display: flex;
  align-items: center;
  margin-bottom: 6.5%;
}

.bottom__text {
  font-size: 0.6rem;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
}

.verticalLine {
  border-left: 1.5px solid grey;
  height: 2.75vw;
}

.buy__text {
  margin-top: -8%;
}

.rent__text {
  margin-top: -8%;
}

.print__text {
  margin-top: -8%;
}

.card__main {
  margin-bottom: 20%;
}

.modalclosebutton {
  margin-left: 90%;
  margin-top: 5%;
}

.makeofferModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  max-height: 95%;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 30px;
}

.clobtn {
  position: absolute;
  right: 10px;
  top: 10px;
}

.clobtn a {
  cursor: pointer;
}

.iconru {
  font-size: 30px;
  color: #1C1C1C;
}

.mpophndg {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  text-align: center;
}

.emailInfo {
  padding: 4%;
}

.first__lastname {
  display: flex;
  align-items: center;
  gap: 2.5%;
}

.nameInfo {
  padding: 2% 4%;
}

.offer__number {
  display: flex;
  align-items: center;
  gap: 2.5%;
}

.sharediv,
.pricediv {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
}

.starflex {
  display: flex;
  justify-content: center;
  align-items: center; 
}

.productrating {
  display: flex;
  gap: 15px;
  align-items: center;
  font-size: 14px;
}

.like {
  display: flex;
  align-items: center;
}

.descriptiongrid {
  padding: 50px 0;
}

.descriptiongrid .description h3 {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 26.04px;
  text-align: left;
  margin: 0;
  margin-bottom: 10px;
}

.descriptiongrid .description p {
  font-family: "Roboto", sans-serif;
  text-align: left;
  font-size: 15px;
  font-weight: 400;
  color: #636365;
  margin-top: 0;
  line-height: 26px;
}

.cfflex {
  display: flex;
  justify-content: center;
  align-items: center;
}


.orientationmain {
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-right: 7.5%; */
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
}

@media (min-width:768px) and (max-width:991px) {
  .previewimg__div {
    display: block;
  }

  .makeofferModal {
    width: 50%;
  }
}

@media(max-width:767px) {
  .prodDetails h1{
    font-size: 25px;
    line-height: 26px;
  }
  .pricing__section {
    margin-bottom: 15px;
  }
.mprice{
    font-size: 22px;
    line-height: 25px;
    margin-right: 5px;
}
.proddets_dets{
  padding-top: 8px;
  padding-bottom: 8px;
}
.search_select{
  margin-top: 0 !important;
}
  .proddets__top {
    margin-top: 57px;
  }
  .sec_title{
    font-size: 23px;
    line-height: 26px;
  }

  .makeofferModal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    max-height: 70%;
    overflow-y: scroll;
  }
  

  .greydescr {
    margin-right: 0;
  }

  .productrating {
    justify-content: space-around;
  }

  .main__img {
    width: 100%;
  }

  .sidePanel>* {
    width: 100%;
  }

  .custLink {
    margin-right: 0;
  }
}
 