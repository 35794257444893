.back_button {
  border-bottom: 1px solid #ced4da;
  margin-bottom: 30px;
}
.back_button a{
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 10px;
  margin-bottom: 0;
  color: #665D6F !important;
  opacity: .8;
  text-decoration: none !important;
  cursor: pointer;
  display: block;
} 
.del_img {
  text-align: right;
}
.bsrd{
  padding-bottom: 30px;
  border-bottom: 1px solid #ced4da; 
  margin-bottom: 30px;
}
.arndng{
  font-weight: 400;
    font-size: 22px;
    line-height: 27px;
    color: #1c1c1c;
    text-align: left;
    text-transform: capitalize;
    margin-bottom: 0px;
    font-family: "Roboto", sans-serif;
}
.sethng{
  font-family: "Roboto", sans-serif;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    color: #1c1c1c;
    text-align: left;
    text-transform: capitalize;
    margin-bottom: 7px;
}
.odlist{
  color: #1C1C1C;
font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    margin-bottom: 10px;
    display: flex;
    gap: 5px;
}
.odlist span{
  max-width: 35%;
  width: 37%;
  display: block;
}
.odlist span + span{
  color: #636365;
  opacity: .8;
  max-width: initial;
  width: 63%;
}
.backimage {
  cursor: pointer;
}
 
.details_main > h2 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 39px;
  color: #1c1c1c;
}

.description_details {
  display: flex;
  align-items: flex-start;
}

.description_details_title {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
  color: #1c1c1c;
  /* width:280px; */
  min-width: 50%;
}

.description_details_content {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 36px;
  color: #636365;
}

.horizontal {
  border: 1px solid #665d6f;
  opacity: 0.3;
}

.hr_short {
  width: 40%;
  text-align: left;
  margin-left: 0px;
  border: 1px solid #665d6f;
  opacity: 0.3;
}

.description_details_print_sizing {
  display: flex;
  align-items: flex-start;
  column-gap: 30px;
}

.price_details {
  display: flex;
  align-items: center;
  /* column-gap: 150px; */
}
.price_details_print {
  display: flex;
  align-items: flex-start;
}

.price_details .title {
  margin-right: 5px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
  color: #1c1c1c;
}

.details_subheading {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: #1c1c1c;
}

.price_input {
  width: 97px;
  height: 41px;
  left: 1164px;
  /* background: #ffffff; */
  border-radius: 10px;
  border: none;
  padding: 0px 10px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #636365;
}
.prfimg{
  width: 200px;
    height: 200px;
    border-radius: 10px;
}
.preview_img{
  width: 100%;
    background-color: white;
    margin: auto;
    height: 200px;
    border-radius: 5px;
    overflow: hidden;
}
.tablist {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.tablist li {
  margin-top: 0;
  display: inline-block;
  margin-right: 5px;
}

.tablist li a {
  text-decoration: none;
  padding: 8px 20px;
  border-radius: 10px;
  background: #fff;
  border: 1px solid #fff;
  color: #1c1c1c;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  display: block;
  cursor: pointer;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.tab_content {
  box-shadow: 0px 6.14px 19.66px 0px #080F340F;
  border-radius: 22px;
  border-top-left-radius: 0;
  padding: 25px;
  background: #fff;
}

@media (max-width: 767px) {
  .odlist{
    display: block;
  }
  .odlist span, .odlist span + span{
    max-width: initial;
    width: 100%;
  }
}
