.mains{
    background-color: #F6F6F6;
    padding: 20px;
    border-radius: 10px;
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0px;
}
@media(max-width:600px){
    .mains{
        width: 100%;
    }
}