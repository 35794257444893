.headerCategory {
  width: 100%;
  background: url("./banner bg img.png");
  background-size: cover; 
  padding-top: 5%;
  padding-bottom: 5%;
  margin-top: 125px;
}
.pageitle{
  text-align: center;
}
.pageitle h1{
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  font-weight: 500;
  line-height: 1.235;
  letter-spacing: 0;
  margin-bottom: 10px;
  margin-top: 0;
}
.pageitle ul{
  padding: 0;
  list-style-type: none;
  margin: 0;
}
.pageitle ul li{
  display: inline-block;
  margin-top: 0;
}
.pageitle ul li:last-child{
  padding-left: 5px;
}
.pageitle ul li a{
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #14142B;
  text-decoration: none !important;
}
.secs_tcv{
  padding: 50px 0;
}
.hrndg{
  margin-bottom: 20px;
}
.hrndg h2{
  text-transform: capitalize;
font-family: "Roboto", sans-serif;
    font-size: 23px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: #1C1C1C;
    margin: 0 0 10px;
}
.hrndg ul{
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.hrndg ul li{
  margin-top: 0;
  margin-bottom: 10px;
  font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    text-align: left;
    color: #636365;
    opacity: .8;
}
.manulsc h3{
  text-transform: capitalize;
font-family: "Roboto", sans-serif;
    font-size: 19px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: #1C1C1C;
    margin: 0 0 10px;
}
.manulsc ul{  
  margin: 0;
}
.manulsc ul li{
  font-family: "Roboto", sans-serif;
  margin-top: 0;
  margin-bottom: 25px;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    color: #636365; 
}
.manulsc ul li:last-child{
  margin-bottom: 0;
}
.manulsc ul li span{
  color: #1C1C1C;
  font-weight: 600;
}
.mnu_img{
  border-radius: 10px;
    padding: 25px;
    background: #fff;
    border: 1px solid #D9DBE9;
    margin: 13px 0;
}
.manulsc ul li h5{
  font-family: "Roboto", sans-serif;
  color: #1C1C1C;
  font-weight: 600;
  margin: 0 0 6px;
  font-size: 17px;
  line-height: 24px;

}
.manulsc ul li p{
  margin: 0 0 10px;
  font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    color: #636365;
    opacity: .8;
}
.quilinks{
  border-radius: 10px;
  padding: 20px 25px;
  background: #F6F6F6;
  position: relative;
}
.quilinks h4{
  text-transform: capitalize;
font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: #1C1C1C;
    margin: 0 0 10px;
}
.quilinks ul{
  margin: 0;
  padding-left: 22px;
}
.quilinks ul li{
  margin-top: 0;
}
.quilinks ul li a{
  padding-bottom: 12px;
  display: block;
  text-decoration: none;
  color: #636365;
}
.secs_tcv {
  display: flex;
}

.staticLinks {
  position: sticky;
  top: 100px; /* Adjust for your fixed header */
  height: max-content;
}

@media(max-width:767px){
.headerCategory {
  margin-top: 57px;
}
.staticLinks{
  position: relative;
  top: 0;
}
}