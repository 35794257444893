.headerCategory {
  margin-top: 125px;
  width: 100%;
  background: url("./banner bg img.png");
  background-size: cover;
  padding: 50px 0;
}
.pageitle{
  text-align: center;
}
.pageitle h1{
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: 0;
  margin-bottom: 10px;
  margin-top: 0;
}
.pageitle ul{
  padding: 0;
  list-style-type: none;
  margin: 0;
}
.pageitle ul li{
  display: inline-block;
  margin-top: 0;
}
.pageitle ul li:last-child{
  padding-left: 5px;
}
.pageitle ul li a{
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #14142B;
  text-decoration: none !important;
}

.headerSubTitle {
  margin-left: -100%;
}
.card__main { 
  margin-bottom: 20px;
}
.card__image {
  width: 100%;
}
.card__prof {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pressreleases__grid {
  padding: 2% 13%;
}
.blocnt h3{
  margin: 0;
  text-transform: capitalize;
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 8px;
}
.blocntpra{
  display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-top: 0;
    margin-bottom: 0;
font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 23px;
    text-align: left;
    color: #665D6F;
    opacity: .8;
}
.blocntpra p{
  margin: 0;
}
.metap{
  margin-top: 0;
    margin-bottom: 5px;
font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-weight: 600;
    line-height: 23px;
    text-align: left;
    color: #665D6F;
}
.sec_sc{
  padding: 50px 0;
}

@media (max-width: 767px) {
  .headerCategory {
    margin-top: 57px;
  }
  .headerSubTitle {
    margin-left: -150%;
  }
  .pageitle h1{
    font-size: 25px;
        line-height: 26px;
        margin-bottom: 5px;
  }
}
