.pagination_box {
    display: flex;
    justify-content: flex-end;
    background-color: #F6F6F6;
    padding-top: 25px;
    padding-right: 25px;
    /* margin-bottom: 20px; */
}

.pagination_box>div {
    margin-bottom: 25px;
}

.page_btn {
    height: 29.47px;
    width: 35px;
    margin-left: 8px;
    min-width: 20px;
    height: 29.47px;
    border: 0.841897px solid #d5d5d5;
    box-shadow: none;
}