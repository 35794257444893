.mains {
  /* background-color: red; */
}
.deleicon{
  color: #1C1C1C;
  font-size: 22px;
}
.table_column {
  color: #636365;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
}
.hyperlink_title{
  text-decoration: underline;
  color: #636365;
}
.table_columns {
  display: inline;
  white-space: nowrap;
}

.table_status_active {
  color: #219653;
}

.table_status_inactive {
  color: #d00000;
}

.desctiption_enquiry {
  width: 300px;
  overflow-x: hidden;
}

.flex_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.loader_box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.title {
  text-decoration: underline;
  text-decoration-color: transparent;
  color: inherit;
  cursor: pointer;
  transition: color 0.3s, text-decoration-color 0.3s;
}

.title:hover {
  color: black;
  text-decoration-color: black;
}

.pagination_box {
  display: flex;
  justify-content: flex-end;
  background-color: #F6F6F6;
  padding-top: 25px;
  padding-right: 25px;
  /* margin-bottom: 20px; */
}

.pagination_box>div {
  margin-bottom: 25px;
}



.hover_text {
  text-decoration: underline;
  font-weight: normal;
  color: inherit;
  transition: color 0.3s ease, font-weight 0.3s ease;
}

.hover_text:hover {
  color: black;
  font-weight: bold;
}