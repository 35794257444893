.mains {
  margin-top: 125px;
  padding: 50px 0;
}
.title_image {
  max-width: 110px;
}
.text_center{
  text-align: center;
}
.components {
  box-shadow: 0 0 6px 2px #e7e9ec;
  border-radius: 22px;
  padding: 25px;
  margin-top: 20px;
}
.input_label {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #1c1c1c;
}
.multi_inputs {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  column-gap: 3%;
}
.child_div {
  width: 47%;
  margin: 10px 0px;
}
.input_multi {
  margin: 10px 0px;
  width: 100%;
}
.input_multi_phone {
  margin: 5px 0px;
  width: 100%;
}
.btn_div {
  display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 10px 0px 0;
}

/* .input_multi_phone .react-tel-input form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: 0.01rem;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0 !important;
  padding-top: 0px !important;
  margin-left: 0;
  background: #cacaca;
  border: 1px solid #cacaca;
  border-radius: 5px;
  line-height: 25px;
  height: 53px;
  width: 100%;
  outline: none;
  box-sizing: none;
  display: flex;
  flex-wrap: wrap;
}

.form-control {
  height: 54px !important;
  width: 100% !important ;
  background-color: #f6f6f6 !important;
} */

@media (max-width: 767px) {
  .mains {
    margin-top: 57px;
  }
}
