.cancel {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #EB5757;
    min-width: 80px;
    display: inline-block;
}

.pending {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #35BE43;
    min-width: 80px;
    display: inline-block;
}

.career_lastcol{
    display: flex;
    justify-content: space-between;
}
.column_gallery_artist_true {
    /* color:red; */
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: -40px;
}
.loader_box {
    display: flex;
    justify-content: center;
    align-items: center;
}