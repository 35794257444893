
.preview_img {
    width: 100%;
    background-color: white;
    margin: auto;
    height: 200px;
    border-radius: 5px;
    overflow: hidden;
    /* opacity: 0.5; */
    /* border: 1px solid #665D6F; */ 

}

.img_box {
    width: 100%;
    height: 100%;
    /* background-color: #f6f6f6; */
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    /* opacity: 0.5; */
    border-radius: 5px;
}

.label {
    /* margin: 5px 0px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.label>p {

    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #665D6F;
    margin: 5px 0px;
    opacity: 0.5;
}

.img_view {
    width: 100%;
    height: 200px;
    border-radius: 5px;
}
.img_fuild{
    max-width: 100%;
    height: 200px;
    cursor: pointer;
}
.img_view_hide {
    width: 100%;
    height: 100%;
    background-color: #f6f6f6;
    border-radius: 10px;
}
.profilemg img{
    width: 100%;
    height: 200px;
    border-radius: 5px;
}
.image_div {
    width: 46px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
}
@media (min-width:992px) and (max-width:1025px)
{
    .img_fuild{
        height: auto;
    }
    .preview_img{
        height: auto;
    }
}
@media (min-width:768px) and (max-width:991px)
{
    .preview_img {
        height: auto;
    }
    .img_fuild{
        height: auto;
    }
}

@media(max-width:767px){
    .img_fuild{
        height: auto;
      }
      .preview_img{
        height: auto;
      }
      
}