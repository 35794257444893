.table_head {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    color: #1C1C1C;
}

.table_column {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #636365;
}
.table_column_green {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #219653;
}
.table_column_red {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #FF0000;
}

.table_columnss {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #636365;
    max-width: 100px;
}

.career_lastcol {
    display: flex;
    justify-content: space-between;
}

.closed {
    color: #D00000;
}

.loader_box {
    display: flex;
    justify-content: center;
    align-items: center;
}
.mesdcrp{
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -moz-line-clamp:1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-width: 130px;
}
.table_column2{
    text-align: center;
}