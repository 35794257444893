.mains {
 padding: 20px 0;
  border-radius: 10px;
  background-color: #f6f6f6;
}

@media (max-width: 600px) {
  .mains {
    width: 100%;
  }
}
