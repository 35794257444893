.headerCategory {
  width: 100%;
  background: url("./banner bg img.png");
  background-size: cover;
  padding-top: 5%;
  padding-bottom: 5%;
  margin-top: 125px;
}
.careerdetailCard { 
  padding: 50px 0;
}
.careerdetailCard h4{
  font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 29px;
    text-align: left;
    margin: 0 0 10px;
}
.careerdetailCard p{
  font-family: "Roboto", sans-serif;
font-size: 16px;
font-weight: 400;
line-height: 23px;
text-align: left;
color: #636365;
margin: 0 0 10px;

}
.description {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
}
.jointeamModal{
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 25%;
  max-height: 85%;
  overflow-y: auto;
  background-color: #FFFFFF;
  box-Shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-Radius: 10px;
  left: 50%;
  }
  .jointeamModal input, .jointeamModal textarea{
    background: #F3F3F3;
      border-color: #F3F3F3;
  }
.modalInfo{
  padding: 2% 4%;
}
.modalclosebutton {
  margin-left: 90%;
  margin-top: 2%;
  cursor: pointer;
}
.formgroup{
  margin-bottom: 15px;
}
.form_control {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  height: auto !important;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
.pageitle{
  text-align: center;
}
.pageitle h1{
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: 0;
  margin-bottom: 10px;
  margin-top: 0;
  text-transform: capitalize;
}
.pageitle ul{
  padding: 0;
  list-style-type: none;
  margin: 0;
}
.pageitle ul li{
  display: inline-block;
  margin-top: 0;
}
.pageitle ul li:last-child{
  padding-left: 5px;
}
.pageitle ul li a{
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #14142B;
  text-decoration: none !important;
}
.apply_btn{
  padding: 10px 25px;
  border-radius: 10px;
  background: #000;
  border: 1px solid #000;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  cursor: pointer;
}
.apply_btn:hover{
  background: transparent;
  color: #1c1c1c;
}
.jointeamModal{
  padding: 25px;
}
.mdltitle{
  font-family: "Roboto", sans-serif;
  font-size: 25px;
  font-weight: 700;
  line-height: 29px;
  text-align: center;
  margin: 0 0 10px;
}
.modalclosebutton{
  font-size: 30px  !important;
  color: #1c1c1c !important;
}
.textright{
  text-align: right;
}
@media(max-width:767px){
  .careerdetailCard h4{
    margin-bottom: 0;
  }
  
  .headerCategory {
    margin-top: 57px;
  }
  .pageitle h1{
    font-size: 25px;
        line-height: 26px;
        margin-bottom: 5px;
  }
}
@media(max-width:600px) {
  .description {
    flex-direction: column;
    align-items: flex-start;
  }


  .headerSubTitle {
    margin-left: -150%;
  }
    .jointeamModal {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 80%;
      max-height: 75%;
      overflow-y: scroll;
      background-color: #FFFFFF;
      box-Shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
      border-Radius: 10px;
    }
}

