
.component{
    background-color: #F6F6F6 ;
    border-radius: 10px;
    padding-bottom: 20px;
}
@media(max-width:767px){
    .tableheader_enter{
        margin-bottom: 10px;
    }
}