.modal_subadmin {
  border-radius: 10px; 
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  width: 60%; 
  padding: 30px 30px;
  overflow-y: scroll;
  max-height: 90%;
}


.mpophndg{
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  text-align: center; 
  text-transform: capitalize;
}
.clobtn{
  position: absolute;
  right: 10px;
  top: 10px;
}
.clobtn a{
  cursor: pointer;
}
.iconru{
  font-size: 30px;
  color: #1C1C1C;
}
.action_btn{
  padding: 10px 25px;
  border-radius: 10px;
  background: #000;
  border: 1px solid #000;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  cursor: pointer;
}
.action_btn:hover{
  background: transparent;
  color: #000;
}

.role_heading {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #1c1c1c;
  margin-bottom: 0;
  margin-top: 10px;
}

.inputs_gird {
  margin: 0px 0px;
}

.inputs_gird > label {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #1c1c1c;
}

.inputs_gird > input {
  background: #f3f3f3;
  height: 40px;
  padding-left: 10px;
  border-radius: 6px;
  border: none;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #636365;
  width: 92%;
  margin: 5px 0px 0px 0px;
}
.loader_box {
  margin-left: 20px;
}
.action {
  text-align: center;
  margin: 20px 0px 0;
}
 

.access_roles {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.access_roles > div {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #636365;
}

.slider.round {
  border-radius: 35px;
}

.slider.round:before {
  border-radius: 50%;
}

.switch {
  position: relative;
  display: inline-block;
  width: 43px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  border: 0.5px solid #e2e2e2;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.07);
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 4px;
  bottom: 2.5px;
  background-color: #1c1c1c;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

input:checked + .slider {
  background-color: #ffffff;
}

input:focus + .slider {
  box-shadow: 0 0 1px #ffffff;
}

input:checked + .slider:before {
  -webkit-transform: translateX(17px);
  -ms-transform: translateX(17px);
  transform: translateX(17px);
}

.toggle_btn {
  text-align: left;
}
@media(min-width:993px) and (max-width:1025px){
  .modal_subadmin{
    width: 70%;
  }
}
@media(min-width:768px) and (max-width:992px){
  .modal_subadmin {
    width: 90%;
  }
}
@media (max-width: 767px) {
  .modal_subadmin {
    width: 95%;
  }

  .cancel_img {
    text-align: right;
    margin-right: 1px;
    margin-top: 10px;
  }
}


.capitalize{
  text-transform: capitalize;
}