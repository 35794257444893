.mains {
    /* background-color: red; */
}

.table_column {
    color: #636365;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    white-space: nowrap;
}

.table_columns {
    display: inline;
    white-space: nowrap;
}

.table_status_active {
    color: #219653;
}

.table_status_inactive {
    color: #D00000;
}

.desctiption_enquiry {
    width: 300px;
    overflow-x: hidden;
}

.flex_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.loader_box {
    display: flex;
    justify-content: center;
    align-items: center;
}
.interested{
    display: flex;
    justify-content: space-between;
    align-items: center;
}