.inputs_div_margin {
  margin-bottom: 5px;
}
.second_flex {
  width: 100%;
  padding: 0;
}
.forgot_pass {
  text-align: right;
  padding-bottom: 10px;
}

.forgot_pass a{
  text-decoration: none !important;
  color: #636365 !important;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  opacity: .8;
}
.password_label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.new_div_inputs {
  display: flex;
  align-items: center;
  /* justify-content: cente; */
  column-gap: 10px;
}

.textarea {
  width: 95%;
  min-width: 95%;
  max-width: 95%;
  max-height: 100px;
  padding: 2.5%;
  background-color: white;
  border: none;
  border-radius: 6px;
  outline: none !important;
  margin-top: 5px;
}
.textarea:focus {
  outline: none !important;
  /* box-shadow: 0 0 10px #719ECE; */
}
.new_div_inputs > h2 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 33px;
  color: #1c1c1c;
  white-space: nowrap;
}

.inputs_div_second {
  margin: 10px 0px;
}

.input_label {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #1c1c1c;

}

.input_edit_label {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #1c1c1c;
  margin: 0px;
}

.forgot {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #1c1c1c;
  cursor: pointer;
  margin: 0px;
}

.hrs {
  width: 100%;
  opacity: 0.3;
  border: 1px solid #665d6f;
}

.edit_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.img_box {
  width: 100%;
  height: 100%;
  display: flex
;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  padding: 6px;
}
.labelv{
  color: #1c1c1c !important;
  opacity: .6;
  text-align: center !important;
}
.labelv p{
  font-size: 14px;
  margin-bottom: 0;
  word-break: break-all;
}
.ckndr{
  font-family: "Roboto", sans-serif;
  color: #333333;
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 10px;
  margin-bottom: 0;
}
.img_box_img {
  align-items: center;
  background-color: #f6f6f6;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  display: flex;
  max-height: 150px;
  justify-content: center;
  text-align: center;
  width: 100%;
}
.label {
  /* margin: 5px 0px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.label > p {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #665d6f;
  margin: 5px 0px;
  opacity: 0.5;
}

.img_view {
  width: 100%;
  height: 150px;
}

.img_view_hide {
  /* border: 1px solid red; */
  width: 100%;
  background-color: #f6f6f6;
}

.image_div {
  width: 46px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form_sec{
  padding: 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.inputs{
  width: 100%;
}
.preview_img {
  width: 100%;
  height: 100%; 
}
.imguplod, .img_box{
  align-items: center;
  background-color: #f6f6f6;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  display: flex;
  height: 150px;
  justify-content: center;
  text-align: center;
  width: 100%;
  overflow: hidden;
}
.tablist {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.tablist li {
  margin-top: 0;
  display: inline-block;
  margin-right: 5px;
}

.tablist li a {
  text-decoration: none;
  padding: 8px 20px;
  border-radius: 10px;
  background: #fff;
  border: 1px solid #fff;
  color: #1c1c1c;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  display: block;
  cursor: pointer;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.tab_content {
  box-shadow: 0px 6.14px 19.66px 0px #080F340F;
  border-radius: 22px;
  border-top-left-radius: 0;
  padding: 25px;
  background: #fff;
}
 .tab_content p {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: #636365;
  opacity: .8;
  margin: 0;
}
.downbtn{
  width: 35px;
  height: 35px;
  line-height: 35px;
  background: #1c1c1c;
  border-radius: 50%;
  display: block; 
  text-align: center;
}
.iconwf{
  color: #fff;
}
.downlicon{
  display: flex;
  justify-content: space-between;
}
.docaction{
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}
.docaction li{
  display: inline-block;
  margin-top: 0;
  margin-right: 5px;
}
.docaction li:last-child{
  margin-right: 0;
}
.docaction{
  margin-bottom: 5px;
}
@media (min-width:992px) and (max-width:1025px){
.tablist li a{
  padding: 6px;
  font-size: 14px;
}
.tablist li{
  margin-right: 2px;
}
}
@media (min-width:768px) and (max-width:991px){
  .tablist li a{
    padding: 6px;
    font-size: 14px;
  }
  .tablist li{
    margin-right: 2px;
  }
}
@media(max-width:767px){
.downlicon{
  display: block;
}
  .second_flex{
    width: 100%;
  }
  .tablist li{
    display: block;
  }
  .tablist li a{
    margin-bottom: 4px;
        border-radius: 10px;
        text-align: center;
  }
  .tablist{
    margin-bottom: 20px;
  }
  .tab_content{
    border-radius: 22px;
  }
  .prfimg{
    margin: 0 auto;
  }
}