.table_column {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #636365;
}

.table_row {

    border-collapse: separate;
    /* min-height: 37px; */
}

.table_columns {
    /* display: flex; */
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* background-color: red; */
    /* height: auto; */
    /* margin: auto auto; */
}

.add_img {
    border-radius: 50%;
    height: 29px;
    width: 30px;
}

.add_img_second {
    /* border-radius: 50%; */
    height: 50px;
    width: 50px;
}

.add_btn {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
}

.auction_last_col {
    display: flex;
    justify-content: space-between;
}

.nested_flex_offer {
    margin-right: 16px;
}

.loader_box {
    display: flex;
    justify-content: center;
    align-items: center;
}