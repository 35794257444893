.steppers_step{
    text-align: left;
}
.steps{
    /* display: flex;
    justify-content: space-between; */

    /* max-width: max-content; */
}
.steppers{
    /* display: flex; */
    /* justify-content: flex-start; */
    /* display: flex;
    justify-content: space-between; */
}