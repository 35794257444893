.mains {
  background-color: #f6f6f6;
  border-radius: 10px;
  padding: 20px;
  }
  
.back_button {
  border-bottom: 1px solid #ced4da;
  margin-bottom: 30px;
}
.back_button a{
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 10px;
  margin-bottom: 0;
  color: #665D6F !important;
  opacity: .8;
  text-decoration: none !important;
  cursor: pointer;
  display: block;
}
.hrdngofv{ 
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 22px;
  line-height: 25px;
  color: #1c1c1c;
  padding-bottom: 5px;
}
  .fqalist_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #ced4da;
    padding-bottom: 20px;
  }
  
  .main_heading {
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    font-size: 22px;
    line-height: 25px;
    color: #1c1c1c;
    padding-bottom: 0px;
    text-transform: capitalize;
  }
  
  .hrs {
    border: 1px solid #a4a4a4;
    margin: 20px 0px;
  }
  
  .card {
    display: flex;
        justify-content: space-between;
        align-items: flex-start;
        column-gap: 20px;
        border-bottom: 1px solid #ced4da;
        padding-bottom: 20px;
        margin-bottom: 20px;
  }
  .details {
    display: flex;
    column-gap: 15px;
    align-items: flex-start;
  }
  
  .details > div > h2,  .details h4{
    margin: 0px;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    color: #1c1c1c;
    text-transform: capitalize;
  }
  .details  p {
    margin: 0px;
font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    color: #636365;
    opacity: .8;
  }
  
  .action_div {
    display: flex;
    align-items: flex-start;
    column-gap: 5px;
  }
  
  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .backstyl {
    cursor: pointer;
  }
  .editicon{
    color: #665D6F;
    font-size: 22px;
  }
  .deleicon{
    color: #1C1C1C;
    font-size: 22px;
  }
  @media (max-width: 600px) {
    .mains {
      width: 100%;
    }
    .card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      column-gap: 20px;
    }
  
    .action_div {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      column-gap: 5px;
    }
  }
  