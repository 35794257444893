.mains {
  width: 100%;
}

.components {
  background-color: #f6f6f6;
  height: 100%;
}

.category_btn {
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
  padding: 20px 0px;
}

.card_main {
  display: flex;
  justify-content: flex-end;
  justify-self: flex-end;
  padding: 20px;
}

.card {
  background-color: white;
  padding:20px;
  border-radius: 10px;
}

.input {
  background: #f3f3f3;
  border-radius: 6px;
  border: none;
  width: 100%;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  /* padding: 5px 20px; */
  color: #636365;
}

.heading_main { 
  font-family: "Roboto", sans-serif; 
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #1c1c1c;
  padding: 20px 0px; 
}
.subheading {
  min-width: 118px;
}

.subheading_head {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #1c1c1c;
}

.hrline {
  opacity: 0.5;
  height: 0.5px;
  background-color: #000000;
  margin-left: 2px;
  /* border: 0.5px solid #000000; */
  width: 100%;
}

.input_detail_div {
  display: flex;
  align-items: center;
  /* justify-content: flex-end; */
  margin-bottom: 5px;
}

.img_text_add {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.img_text_add > p {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #1c1c1c;
  margin: 0px 0px;
  margin-left: 5px;
}

.slider.round {
  border-radius: 35px;
}

.slider.round:before {
  border-radius: 50%;
}

.switch {
  position: relative;
  display: inline-block;
  width: 43px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  border: 0.5px solid #e2e2e2;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.07);
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 4px;
  bottom: 2.5px;
  background-color: #1c1c1c;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

input:checked + .slider {
  background-color: #ffffff;
}

input:focus + .slider {
  box-shadow: 0 0 1px #ffffff;
}

input:checked + .slider:before {
  -webkit-transform: translateX(17px);
  -ms-transform: translateX(17px);
  transform: translateX(17px);
}

.toggle_btn {
  text-align: left;
}

@media (max-width: 900px) {
  .heading_main {
    margin: 0px;
    padding: 20px 0px 10px 10px;
  }

  .card {
    background-color: white;
    /* width: 90%; */
    padding: 10px 0px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-left: 0px;
  }

  .inputs_div {
    margin-left: 0px;
    padding: 0px 10px;
  }
  .toggle_btn {
    text-align: end;
  }
}
