.headerCategory {
  margin-top: 1%;
  background-color: #ccc;
  width: 100%;
  background: url("./banner bg img.png");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  padding-top: 5%;
  padding-bottom: 5%;
}

.headerSubTitle {
  margin-left: -10%;
  text-transform: capitalize;
}

.header__div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 8%;
  margin-bottom: 1.5%;
  margin-top: 1.5%;
}

.filter__label {
  margin-right: 1%;
}

.ProductContent {
  display: flex;
  /* justify-content: space-between; */
  align-items: flex-start;
  margin-left: 7%;
}

.colorcheckbox {
  background-color: red;
}

.orientation {
  margin-right: 7%;
}

.color__image {
  margin-right: 2%;
  width: 10%;
}
.card__top {
  height: 300px;
}
.card__top img {
  object-fit: fill;
  height: 100%;
  width: 100%;
}
.card__top:hover .cardImage {
  opacity: 0.5;
}
.img_icon {
  opacity: 0;
  position: absolute;
  text-align: right;
  top: 5%;
  right: 5%;
}

.card__top:hover .img_icon {
  opacity: 1;
}
.card__middle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card__middleRight {
  display: flex;
  margin-bottom: 6.5%;
}

.card__bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.verticalLine {
  border-left: 1.5px solid grey;
  height: 2.75vw;
}

.buy__text {
  margin-top: -8%;
}

.rent__text {
  margin-top: -8%;
}

.print__text {
  margin-top: -8%;
}

.ProductCards {
  margin-left: 1%;
  width: 100%;
}

.card__main {
  cursor: pointer;
  /* margin-bottom: 20%;   */
  width: 100%;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40vh;
  /* margin: 20px auto; */
}
.trendingcategory {
  width: 100%;
}

@media (max-width: 600px) {
  .headerCategory {
    margin-top: -12.5%;
  }
}
