.headerCategory {
  margin-top: 125px;
  width: 100%;
  background: url("./banner bg img.png");
  background-size: cover;
  padding: 50px 0;
}
.pageitle{
  text-align: center;
}
.pageitle h1{
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: 0;
  margin-bottom: 10px;
  margin-top: 0; 
}
.pageitle ul{
  padding: 0;
  list-style-type: none;
  margin: 0;
}
.pageitle ul li{
  display: inline-block;
  margin-top: 0; 
}
.pageitle ul li:last-child{
  padding-left: 5px;
}
.pageitle ul li a{
  font-family: "Roboto", sans-serif;
  font-size: 16px; 
  text-decoration: none !important;
  color: #1c1c1c;
}
.sec_sc{
  padding: 50px 0;
}
.exlist{
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-bottom: 15px;
}
.exlist li{
  display: flex;
      align-items: center; 
      margin-top: 0;
      padding-bottom: 11px;
  font-family: "Roboto", sans-serif;
font-size: 16px;
font-weight: 400;
line-height: 26px;
text-align: left;
text-transform: capitalize;
}
.exlist li span{
  color: #1c1c1c;
  font-weight: 600;
  padding-right: 5px;
}

.icns{
  font-size: 20px;
  color: #636365;
  opacity: .7;
  margin-right: 8px;
}
.prthgd{
  font-family: "Roboto", sans-serif;
    font-size: 30px;
    font-weight: 400;
    line-height: 40px;
    text-align: left;
    margin: 0 0 10px;
    text-transform: capitalize;
    cursor: pointer;
}
.prta{
  font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #636365;
    margin-bottom: 10px;
    margin-top: 0;
}
.imgrc{
  max-height: 430px;
  overflow: hidden;
  width:100%;
  height: 100%;
 

}
.imgalign{
  object-fit: cover !important;
  width: 100%;
  height: 100%;
}

.apply_btn{
  padding: 10px 35px;
  border-radius: 10px;
  background:  #1c1c1c;
  border: 1px solid  #1c1c1c;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  cursor: pointer; 
}
.apply_btn:hover{
  background: #fff;
  color:  #1c1c1c;
}
.byathr{
  font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    text-transform: capitalize;
    color: #1c1c1c;
}
.lisbgm{
  margin-bottom: 40px;
}
.textvh{
  padding: 10px 15px;
}
.textvh h4{
  font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    margin: 0;
    margin-bottom: 5px;
}
.textvh p{
  font-family: "Roboto", sans-serif;
font-size: 14px;
font-weight: 400;
line-height: 28px;
text-align: left;
color: #636365;
margin: 0;
}
.price_explore h3{
  color: rgb(28, 28, 28);
  font-family: Marcellus, serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 45px;
  text-transform: capitalize;
  margin: 0;
}
.headerSubTitle {
  margin-left: -45%;
}
.verticalLine3 {
  border-left: 1.5px solid grey;
  height: 1vw;
  margin-left: 1%;
  margin-right: 1%;
}
.link__div ul{
  list-style-type: none;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #b1b1b2;
  padding-bottom: 7px;
}
.link__div ul li{
  display: inline-block;
  padding-right: 12px;
  margin-right: 10px;
  position: relative;
  margin-top: 0;
}
.link__div ul li:last-child{
  margin-right: 0;
  padding-right: 0;
}
.link__div ul li::after{
  content: '|';
  color: #636365;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 22px;
  font-weight: 200;
  line-height: 20px;
}
.link__div ul li:last-child::after{
  display: none;
}
.link__div ul li a{
  font-family: "Roboto", sans-serif;
}
.main__content {
  padding: 2% 12.5%;
}
.divider {
  color: #636365;
}
.leftImage {
  width: 50%;
  height: 300px;
  object-fit: cover;
}
.leftcard {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 7%;
  width: 100%;
}
/* .leftcard {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 35px;
} */
.designImage {
  width: 18%;
  height: 20px;
}
.exhibitioncards {
  margin-top: 1.5%;
}
.cardInfo__right {
  /* margin-left: 6%;
  width: 50%; */
  /* margin-top: 1%; */
}
.cardInfo__left {
  margin-left: 28%;
  width: 50%;
  margin-top: 1%;
}
.logos_flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 2%;
}
.loc__logo {
  width: 3%;
}
.interested__button {
  width: 35%;
  margin-top: 5%;
  padding: 3%;
  border-radius: 4px;
  color: white;
  background-color: black;
  margin-left: 1%;
}
.rightcard {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 7%;
  width: 100%;
}
.rightImage {
  width: 50%;
  margin-left: 15%;
}
.historycard__image {
  width: 100%;
}
.historycard__main { 
  background-color:#fff ;
}
.design__image {
  width: 100%;
}

.history__exhibitions {
  padding: 50px 0;
  background-color: #f2f2f2;  
}
.hh__Main {
  margin-top: 27.5%;
}
.design__imagediv {
  width: 30%;
}
@media(max-width:767px){
  .price_explore{
    text-align: center;
  }
  .headerCategory{
    margin-top: 57px;
  }
  .pageitle h1{
    font-size: 25px;
        line-height: 26px;
        margin-bottom: 5px;
  }
  .price_explore h3{
    font-size: 23px;
    line-height: 26px;
  }
}

