.cancel {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #eb5757;
  min-width: 80px;
  display: inline-block;
}
.deleicon{
  color: #1C1C1C;
  font-size: 22px;
}
.pending {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #f2c94c;
  min-width: 80px;
  display: inline-block;
}

.career_lastcol {
  display: flex;
  justify-content: space-between;
}

.loader_box {
  display: flex;
  justify-content: center;
  align-items: center;
}
