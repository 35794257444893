.headerCategory {
  width: 100%;
  background: url("./banner bg img.png");
  background-size: cover;
  padding-top: 5%;
  padding-bottom: 5%;
  margin-top: 125px;
}
.pageitle{
  text-align: center;
}
.pageitle h1{
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  font-weight: 500;
  line-height: 1.235;
  letter-spacing: 0;
  margin-bottom: 10px;
  margin-top: 0;
}
.pageitle ul{
  padding: 0;
  list-style-type: none;
  margin: 0;
}
.pageitle ul li{
  display: inline-block;
  margin-top: 0;
}
.pageitle ul li:last-child{
  padding-left: 5px;
}
.pageitle ul li a{
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #14142B;
  text-decoration: none !important;
}
.main__content {
  padding: 50px 0;
}
.main__content h3{
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 26.04px;
  text-align: left;
  margin: 0;
  margin-bottom: 5px;
}
.main__content ol{
  margin-top: 0;
}
.main__content ol li{
  padding-bottom: 5px;
}
.bullets {
  font-family: "Roboto", sans-serif;
  text-align: left;
  font-size: 15px;
  font-weight: 400;
  color: #636365;
  margin-top: 0;
  line-height: 26px;
}
@media (max-width: 767px) {
  .headerCategory {
    margin-top: 57px;
  }
}
