.headerCategory {
  width: 100%;
  background: url("./banner bg img.png");
  background-size: cover;
  padding-top: 5%;
  padding-bottom: 5%;
  margin-top: 125px;
}

.pageitle {
  text-align: center;
}

.pageitle h1 {
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: 0;
  margin-bottom: 10px;
  margin-top: 0;
}

.pageitle ul {
  padding: 0;
  list-style-type: none;
  margin: 0;
}

.pageitle ul li {
  display: inline-block;
  margin-top: 0;
}

.pageitle ul li:last-child {
  padding-left: 5px;
}

.pageitle ul li a {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #14142B;
  text-decoration: none !important;
}

.formsec {
  padding: 50px 0;
}

.mainForm h3 {
  font-family: "Roboto", sans-serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 45px;
  text-align: left;
  color: #1c1c11;
  margin: 0 0 13px;
}

.img_fluid {
  max-width: 100%;
  height: auto;
}

.headerTitle {
  margin-left: 38%;
}

textarea {
  width: 86%;
}

.headerSubTitle {
  margin-left: 35.2%;
}

.header__div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 8%;
  margin-bottom: 1.5%;
  margin-top: 1.5%;
}

.frameImg {
  width: 90%;
  margin-left: 8%;
}

.framedImg {
  width: 100%;
  margin-left: 16.5%;
}

.framedImg__div {
  width: 70%;
  margin-left: 3.7%;
  margin-top: -110%;
  margin-left: 6.6%;
}

.formDiv {
  margin-bottom: 5%;
  padding: 0% 16%;
  margin-top: 3%;
  background-color: transparent !important;
}

.apply_btn {
  padding: 10px 35px;
  border-radius: 10px;
  background: #000;
  border: 1px solid #000;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  cursor: pointer;
}

.apply_btn:hover {
  background: transparent;
  color: #1c1c1c;
}

.text_right {
  text-align: left;
}

.mapImage {
  width: 60%;
  margin-left: 19%;
}

.map__div {
  margin-bottom: 5%;
  position: relative;
}

.contactInfo {
  border-radius: 10px;
  width: 35%;
  background-color: #1C1C1C;
  position: absolute;
  top: 7%;
  left: 0;
  padding: 25px;
}

.contactInfo__details {
  margin-bottom: 5%;
  margin-top: 5%;
  display: flex;
  align-items: center;
  gap: 10px;
}

.contactdetails__Icon {
  line-height: 47px;
  background-color: #fff;
  height: 50px;
  width: 50px;
  min-width: 50px;
  border-radius: 50%;
  text-align: center;
  font-size: 22px;
  color: #636365;
}

.prdgf {
  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  margin: 0;
  text-decoration: none !important;
}

.frme {
  border-radius: 10px;
}

.mapdc {
  margin-left: 10%;
}

.contmp {
  position: relative;
}

@media(max-width:767px) {
  .headerCategory {
    margin-top: 57px;
  }
  .pageitle h1{
    font-size: 25px;
        line-height: 26px;
        margin-bottom: 5px;
  }
  .mainForm h3 {
    font-size: 23px;
        line-height: 26px;
  }
  .mapdc {
    margin-left: 0%;
  }
  .contactInfo{
    position: relative;
    width: 100%;    top: 0;
  }
  .text_right{
    text-align: center;
  }
}