.mains {
    width: 100%;
}

.stepper_div {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.imageContainer {
    position: relative;
    display: inline-block; /* Allows positioning of delete button */
    margin: 5px; /* Adjust as needed */
  }
  .checkboxContainer {
    display: flex;
    align-items: center;
    gap: 5px; /* Adjust the gap between checkbox and text */
  }
  
  .agreeText {
    font-size: 14px;  /* You can adjust the font size as needed */
    color: #000; /* Text color */
    margin-left: 5px;  /* Add space between the checkbox and text */
  }
  
  .imgs {
    width: 100px; /* Adjust image size as needed */
    height: 100px; /* Adjust image size as needed */
    object-fit: cover;
    border-radius: 5px; /* Optional for rounded corners */
  }
  
  .deleteButton {
    position: absolute;
    top: 5px;
    right: 5px;
    background: white; /* Optional for button background */
    border-radius: 50%;
    z-index: 10; /* Ensure it appears above the image */
  }
  
.back_img {
    margin-bottom: 15px;
    cursor: pointer
}
.back_img {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #665d6f;
    cursor: pointer;
   }
.back_img_non {
    padding-left: 40px;
    margin-bottom: 15px;
    cursor: pointer
}

.upload_pic {
    height: 200px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
    padding-left: 40px;
}

.upload_img {
    background-color: #f6f6f6;
    height: 300px;
    background-image: url('../../../../assets/img/upload_img.png');
    background-size: 100px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
}

.img_main_prev {
    width: 100%;
    height: 100%;
}

.atwork_div {
    background-color: #f6f6f6;
    padding: 20px;
    margin-top: 20px;
}

.main_heading {
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    color: #1c1c1c;
    text-align: left;
    text-transform: capitalize;
    margin-bottom: 7px;
}

.search_action {
    margin: 15px 0px;
}

.upload_images {
    display: flex;
    column-gap: 20px;
}

.upload_images>div {
    width: 150px;
}

.labels {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #1C1C1C;

}

.des {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #1C1C1C;
    opacity: 0.5;
}

.copyright_div {
    display: flex;
    column-gap: 25px;
    align-items: center;
}

.radio_label {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    text-transform: capitalize;
    color: #665D6F;
    opacity: 0.6;
}

.copyright_div>p {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #665D6F;
    opacity: 0.6;
    margin: 5px 0px;
}



.preview_img {
    align-items: center;
    background-color: #f6f6f6;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    display: flex;
    height: 150px;
    justify-content: center;
    text-align: center;
    width: 100%;
}

.preview_imgs {
    align-items: center;
    background-color: #f6f6f6;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    display: flex;
    height: 150px;
    justify-content: center;
    text-align: center;
    width: 100%;
}

.upload_div_main {
    position: relative;
    width: 100%;
    height: 100%;
}

.del_img_div {
    position: absolute;
    height: 26px;
    width: 26px;
    right: 9px;
    top: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 5px;
}

.img_box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* opacity: 0.5; */
    border-radius: 5px;
    object-fit: cover;
}

.img_boxs {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* opacity: 0.5; */
    /* border-radius: 5px; */
}

.label {
    /* margin: 5px 0px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.label>p {

    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #665D6F;
    margin: 5px 0px;
    opacity: 0.5;
}

.image_div {
    width: 46px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img_view {
    width: 280px;
    height: 142px;
  
}

.img_view_hide {
    width: 100%;
    /* background-color: #f6f6f6; */
}

.btn_div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0px 0;
}

.img_box_div {
    display: flex;
    justify-content: left;
    align-items: center;
    column-gap: 20px;
}

.result {
    /* min-height: 100%; */
    max-height: auto;
    width: 100%;
    /* background-color: #272c34; */
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: left;

}

.imgs {
    width: 100px;
    height: 100px;
    /* object-fit: cover; */
    padding: 0.75rem;

}

#file[type="file"] {
    display: none;
}

.labelholder {
    width: 100%;
    height: 50px;
    margin-top: 3em;
    display: grid;
    place-items: center;

}

.labelss {
    height: 50px;
    width: 200px;
    /* background-color:#2a3eb1; */
    color: white;
    display: grid;
    place-items: center;
    font-size: 2.5rem;
    cursor: pointer;

}
.labelv{
    color: #1c1c1c !important;
    opacity: .6;
    text-align: center !important;
  }
  .labelv p{
    font-size: 14px;
    margin-bottom: 0;
    word-break: break-all;
  }