.cancel {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #eb5757;
  min-width: 80px;
  display: inline-block;
}
.hyperlink_title{
  text-decoration: underline;
  color: #636365;
}
.pending {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #f2c94c;
  min-width: 80px;
  display: inline-block;
}

.career_lastcol {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.curator_order {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table_column {
  white-space: nowrap;
}

.column_gallery_artist_true {
  /* color:red; */
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.column_gallery_artist {
  color: red;
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.quoterequest {
  display: flex;
  column-gap: 20px;
  justify-content: center;
  align-items: center;
}

.rejected {
  color: #ff2222;
}

.loader_box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.action {
  display: flex;
  align-items: center;
  gap: 10px;
}
.action img {
  cursor: pointer;
}
.editicon{
  color: #665D6F;
  font-size: 22px;
}
.deleicon{
  color: #1C1C1C;
  font-size: 22px;
}


.auction_last_col {
  display: flex;
  justify-content: space-between;
  flex-direction: column; 
  width: 100%; 
  word-wrap: break-word; 
  white-space: pre-wrap; 
  overflow-wrap: break-word; 
  text-align: justify;
}

.read_more_button{
  cursor: pointer;
}
.read_more_button:hover{
  cursor: pointer;
  color: rgb(51, 51, 179);
}


.pagination_box {
  display: flex;
  justify-content: flex-end;
  background-color: #F6F6F6;
  padding-top: 25px;
  padding-right: 25px;
  /* margin-bottom: 20px; */
}

.pagination_box>div {
  margin-bottom: 25px;
}


.hover_text {
  text-decoration: underline;
  font-weight: normal;
  color: inherit;
  transition: color 0.3s ease, font-weight 0.3s ease;
}

.hover_text:hover {
  color: black;
  font-weight: bold;
}