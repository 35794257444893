.modal_details {
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #FFFFFF;
    width: 30%;
    padding: 30px;
    overflow-y: auto;
    max-height: 95%;
}
.mpophndg{
font-family: "Roboto", sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    text-align: center;
  }
  .clobtn{
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .clobtn a{
    cursor: pointer;
  }
  .iconru{
    font-size: 30px;
    color: #1C1C1C;
  }
.modal_heading {
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 34px;
    text-align: center;
    color: #1C1C1C;
    margin: 10px 0px 18px 0px;
}.input_label {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;   
    color: #1C1C1C;
    margin-bottom:10px;
}.action {
    text-align: center;
    margin: 20px 0px;
}

.action_btn {
    width: 120px;
    height: 54px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    text-transform: capitalize;
    color: #FFFFFF;
    border-radius: 10px;
}
.inputs_div{
    /* margin:10px 0px; */
}.cancel_img {
    text-align: right;
    margin-right: -5px;
    margin-top: 10px;
}

.cancel_img>img {
    cursor: pointer;
}
.date_picker{
    background-color: #f6f6f6;
    border: none;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #636365;
}
.input_date{
    
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #636365;
}

@media(max-width:993px) and (max-width:1025px) {
    .modal_details {
        width: 50%;
    }
}
@media(max-width:768px) and (max-width:992px) {
    .modal_details {
        width: 50%;
    }
}
@media(max-width:767px) {
    .modal_details {
        width: 95%;
    }
}