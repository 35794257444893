.table_column {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #636365;
}
.editicon{
  color: #665D6F;
  font-size: 22px;
}
.deleicon{
  color: #1C1C1C;
  font-size: 22px;
}
.table_row {
  border-collapse: separate;
  /* min-height: 37px; */
}

.table_columns {
  /* display: flex; */
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* background-color: red; */
  /* height: auto; */
  /* margin: auto auto; */
}

.add_img {
  border-radius: 50%;
  height: 29px;
  width: 30px;
}

.add_img_second {
  /* border-radius: 50%; */
  cursor: pointer;
  height: 50px;
  width: 60px;
}

.add_btn {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
}

.auction_last_col {
  display: flex;
  justify-content: space-between;
  flex-direction: column; /* Arrange items vertically */
  width: 100%; /* Ensure it takes the full width of its container */
  word-wrap: break-word; /* Break words if they're too long */
  white-space: pre-wrap; /* Preserve line breaks and allow text wrapping */
  overflow-wrap: break-word; 
  text-align: justify;
}
.auction_last_col img {
  max-width: 100%; /* Restrict image width to the container */
  height: auto; /* Maintain aspect ratio */
  display: block; /* Prevent inline display issues */
  margin: 0 auto; /* Center the image horizontally */
}

.read_more_button{
  cursor: pointer;
}
.read_more_button:hover{
  cursor: pointer;
  color: rgb(51, 51, 179);
}

.nested_flex_offer {
  margin-right: 16px;
}
.action {
  display: flex;
  align-items: center;
  gap: 10px;
}
.action img {
  cursor: pointer;
}

.loader_box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination_box {
  display: flex;
  justify-content: flex-end;
  background-color: #F6F6F6;
  padding-top: 25px;
  padding-right: 25px;
  /* margin-bottom: 20px; */
}

.pagination_box>div {
  margin-bottom: 25px;
}