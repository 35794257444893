.mains {
  background-color: #F6F6F6;
  padding: 20px;
  border-radius: 10px;
  }
  .notif_list{
    list-style-type: none;
    padding: 0;
    margin-bottom: 0;
  }
  .notif_list li{
    background: #fff;
    border-radius: 10px;
    padding: 15px;
    margin-top: 0;
    margin-bottom: 10px;
    color: #636365;
    opacity: .8;
font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 23px;
  }
  .notif_list li:last-child{
    margin-bottom: 0;
  }
  .notimeta{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .notiftl{
    width: 80%;
    color: #1C1C1C;
font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
  }
  .notifm{
    color: #636365;
    opacity: .8;
font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 23px;
  }
  .descript_text{
    width: 80%;
    font-weight: 500;
    color: #2a2a2b;
    opacity: .8;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    line-height: 23px;
  }
  .notification {
    display: flex;
    gap: 12px;
    padding: 12px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .notification.active {
    border-color: #007bff;
  }
  
  .avatar img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .content {
    flex: 1;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: #555;
  }
  
  .time {
    font-size: 12px;
    color: #999;
  }
  
  .title {
    margin: 8px 0;
    font-size: 14px;
    color: #333;
  }
  
  .actions {
    display: flex;
    gap: 8px;
  }
  
  .actionBtn {
    font-size: 12px;
    padding: 4px 8px;
  }
  .pagination_box {
    display: flex;
    justify-content: flex-end;
    background-color: #F6F6F6;
    padding-top: 25px;
    padding-right: 25px;
    /* margin-bottom: 20px; */
  }
  
.pagination_box>div {
    margin-bottom: 25px;
  }
@media(max-width:767px) {
    .mains {
        width: 100%;
    }
    .notimeta{
      flex-direction: column;
    }
    .no_result_text{
      font-size: 15px !important;
    }
}
.no_result_text{
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  color: #1C1C1C;
  margin: 30px 0;
  font-family: "Roboto", sans-serif;
}