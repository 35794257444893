

  .phoneinput{
    /* width:100%; */
    background-color: #f6f6f6;
    /* border:1px solid red; */ 
  }

  .disabled {
    pointer-events: none;
    
  }

  .react-tel-input .country-list .country {
    padding: 0px 9px;
    display: flex;
    align-items: center;
  }