.headerCategory {
  margin-top: 5%;
  width: 100%;
  background: url("./banner bg img.png");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  padding-top: 5%;
  padding-bottom: 5%;
}
.headerTitle {
  margin-left: 11%;
}
.headerSubTitle {
  margin-left: -38%;
}
.verticalLine3 {
  border-left: 1.5px solid grey;
  height: 1vw;
  margin-left: 1%;
  margin-right: 1%;
}
.link__div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.main__content {
  padding: 2% 12.5%;
}
.divider {
  color: #636365;
}
.leftImage {
  width: 50%;
}
.leftcard {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.designImage {
  width: 30%;
}
.exhibitioncards {
  margin-top: 1.5%;
}
.cardInfo__right {
  margin-left: 6%;
  /* margin-top: 1%; */
}
.logos_flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 2%;
}
.loc__logo {
  width: 3%;
}
.interested__button {
  width: 20%;
  margin-top: 2%;
  padding: 1.5%;
  border-radius: 4px;
  color: white;
  background-color: black;
  margin-left: 1%;
}
.rightcard {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 7%;
}
.rightImage {
  width: 50%;
  margin-left: 15%;
}

@media (max-width: 600px) {
  .headerCategory {
    margin-top: -12.5%;
  }
  .headerSubTitle {
    margin-left: -80%;
  }
  .headerTitle {
    margin-left: 27%;
  }
}
