
.loader_box {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cancel {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #eb5757;
    min-width: 80px;
    display: inline-block;
  }

  .pending {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #f2c94c;
    min-width: 80px;
    display: inline-block;
  }
  .table_column {
    white-space: nowrap;
  }


  .pagination_box {
    display: flex;
    justify-content: flex-end;
    background-color: #F6F6F6;
    padding-top: 25px;
    padding-right: 25px;
    /* margin-bottom: 20px; */
  }
  
  .pagination_box>div {
    margin-bottom: 25px;
  }

  .hover_text {
    text-decoration: underline;
    font-weight: normal;
    color: inherit;
    transition: color 0.3s ease, font-weight 0.3s ease;
  }
  
  .hover_text:hover {
    color: black;
    font-weight: bold;
  }