/* .mains {
    width: 65%;
    padding-top: 4%;
    background-color: #F6F6F6;
    margin-bottom: 50px;
} */

.main_artistprofile { 
  color: black;
  z-index: 1; 
}
.backimage {
  cursor: pointer;
}

.artImage {
  max-width: 100px;
  max-height: 100px;
  cursor: pointer;
  margin: 5px;
}

.table_heading {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 39px;
  color: #1c1c1c;
  padding: 0% 0% 0% 2%;
  margin: 1% 0%;
}

.profile_detail {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.profile_detail > div > h2 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 33px;
  text-transform: capitalize;
  color: #1c1c1c;
}

.profile_img {
  height: 300px;
  width: 333px;
} 
.more_details {
  display: flex;
  justify-content: space-between;
}

.more_details > div > h4 {
  color: #1C1C1C;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  margin-bottom: 0px;
}

.more_details > div > p { 
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  margin-bottom: 0px;color: #636365;
  opacity: .8;
}

.all_details > h1 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 39px;
  color: #1c1c1c;
}
.desc {
  display: flex;
  justify-content: space-between;
}

.description_deatils {
  display: flex;
  align-items: flex-start;
  margin: 10px 0px;
}

.des_title {
  min-width: 280px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: #1c1c1c;
}

.des_content {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #636365;
  margin: 0px 0px;
}
.table_heading {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 39px;
  color: #1c1c1c;
  padding: 0% 0% 0% 2%;
  margin: 1% 0%;
}
.back_button {
  border-bottom: 1px solid #ced4da;
  margin-bottom: 30px;
}
.back_button a{
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 10px;
  margin-bottom: 0;
  color: #665D6F !important;
  opacity: .8;
  text-decoration: none !important;
  cursor: pointer;
  display: block;
} 
.bsrd{
  padding-bottom: 30px;
  border-bottom: 1px solid #ced4da; 
  margin-bottom: 30px;
}
.edit_btn{
  text-align: right;
}
.action_btn{
  padding: 10px 25px;
  border-radius: 10px;
  background: #000;
  border: 1px solid #000;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  cursor: pointer;
}
.action_btn:hover{
  background: transparent;
  color: #000;
}
.arf_in{
  padding-top: 15px;
}
.arf_in h2{
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  color: #1c1c1c;
  text-align: left;
  text-transform: capitalize;
  margin-bottom: 10px;
  font-family: "Roboto", sans-serif;
}
.odlist{
  color: #1C1C1C;
font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    margin-bottom: 10px;
    display: flex;
}
.odlist span{
  max-width: 35%;
  width: 37%;
  display: block;
}
.odlist span + span{
  color: #636365;
  opacity: .8;
  max-width: initial;
  width: 63%;
}
.sethng{
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #1c1c1c;
  text-align: left;
  text-transform: capitalize;
  margin-bottom: 7px;
}
.bdngr{
  padding-bottom: 15px;
  border-bottom: 1px solid #ced4da; 
  margin-bottom: 15px;
}
.upload_pic_div{
  height: 200px;
  width: 100%;
  border-radius: 10px;
  /* margin: 0 auto; */
}
.prfimg{
  width: 200px;
  height: 200px;
}
.preview_img{
  width: 100%;
  background-color: white;
  margin: auto;
  height: 200px;
  border-radius: 5px;
  overflow: hidden;
}
.img_fuild{
  max-width: 100%;
  height: 200px;
}
.mb2{
  margin-bottom: 15px;
}
.cvhdng{
  color: #1C1C1C;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    margin-bottom: 5px;
}
.prfimg{
  width: 200px;
  height: 200px;
}
.profilemg img {
  width: 100%;
  height: 200px;
  border-radius: 5px;
}
.tablist {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.tablist li {
  margin-top: 0;
  display: inline-block;
  margin-right: 5px;
}

.tablist li a {
  text-decoration: none;
  padding: 8px 20px;
  border-radius: 10px;
  background: #fff;
  border: 1px solid #fff;
  color: #1c1c1c;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  display: block;
  cursor: pointer;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.tab_content {
  box-shadow: 0px 6.14px 19.66px 0px #080F340F;
  border-radius: 22px;
  border-top-left-radius: 0;
  padding: 25px;
  background: #fff;
}
.downbtn{
  width: 35px;
  height: 35px;
  line-height: 35px;
  background: #1c1c1c;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
}
.iconwf{
  color: #fff;
}
.artworkimg{
  text-align: center;
  margin: 0 auto;
  /* max-height: 500px;
  overflow: hidden; */
}
.docnme{
  margin-right: 5px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}
@media (max-width: 767px) {
  .edit_btn{
    text-align: center;
  }
  .odlist{
    display: block;
  }
  .odlist span{
    max-width: 100%;
    width: 100%;
  }
  .odlist span + span{
    max-width: 100%;
    width: 100%;
    word-wrap: break-word;
  }
  .mains {
    width: 100%;
    /* padding:4% 2%; */
  } 
  .profile_img {
    height: 300px;
    width: 100%;
  }

  .des_title {
    min-width: 197px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #1c1c1c;
  }

  .profile_detail {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 10px;
  }
  .tablist li{
    display: block;
  }
  .tablist li a{
    margin-bottom: 4px;
        border-radius: 10px;
        text-align: center;
  }
  .tablist{
    margin-bottom: 20px;
  }
  .tab_content{
    border-radius: 22px;
  }
  .prfimg{
    margin: 0 auto;
    height: auto;
  }
  .cvhdng{
    text-align: center;
  }
  .preview_img{
    height: auto;
  } 
  .upload_pic{
    height: auto;
  }
  .profilemg img{
    height: auto;
  }
  .upload_pic_div{
    height: auto;
  }
}
@media (max-width: 280px) {
  .description_deatils {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 10px 0px;
  }
}
