.headerCategory {
  width: 100%;
  background: url("./banner bg img.png");
  background-size: cover; 
  padding-top: 5%;
  padding-bottom: 5%;
  margin-top: 125px;
}
.pageitle{
  text-align: center;
}
.pageitle h1{
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  font-weight: 500;
  line-height: 1.235;
  letter-spacing: 0;
  margin-bottom: 10px;
  margin-top: 0;
}
.pageitle ul{
  padding: 0;
  list-style-type: none;
  margin: 0;
}
.pageitle ul li{
  display: inline-block;
  margin-top: 0;
}
.pageitle ul li:last-child{
  padding-left: 5px;
}
.pageitle ul li a{
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #14142B;
  text-decoration: none !important;
}

.wishlist__content {
  padding: 50px 0;
}
.product_wish_con {
  text-align: start;
  align-self: center;
  padding-left: 15px;
}
.wisliic{
  color: #1C1C1C;
  font-size: 18px;
}
.remic{
  color: #BDBDBD;
  font-size: 18px;
}
.features {
  padding: 50px 0;
  background-color: #f7f7f8;
}
.feturcont h4{
  font-family: "Roboto", sans-serif;
    font-size: 17px;
    font-weight: 600;
    line-height: 26px;
    text-align: left;
    color: #020A18;
    margin: 0 0 2px
}
.feturcont p{
  font-family: "Roboto", sans-serif;
    text-align: left;
    font-size: 15px;
    font-weight: 400;
    color: #636365;
    margin: 0;
    line-height: 25px;
}
.counterDiv {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: -20%;
}

.decrease {
  border: 1px solid #cdcdcd;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
}

.increase {
  border: 1px solid #cdcdcd;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
}


.counterValue {
  border: 1px solid #cdcdcd;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1%;
  margin-right: 1%;
}

.mini__truck {
  width: 25%;
  height: 25%;
}

.safe__shipping {
  display: flex;
}

.package {
  width: 25%;
  height: 25%;
}

.secure {
  width: 25%;
  height: 25%;
}

.proddets__middle {
  margin-top: 5%;
}

.design__headingImage {
  display: flex;
  justify-content: center;
  align-items: center;
}

.design__image {
  width: 6.5%;
}

.design__heading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardImage {
  width: 100%;
  object-fit: contain;
}

.card__middle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card__main {
  /* background-color: #ccc; */
  width: 100%;
}
.card__top {
  position: relative;
  height: 300px;
}
.card__top img {
  object-fit: fill;
  height: 100%;
}
.card__middleRight {
  display: flex;
  margin-bottom: 6.5%;
}

.verticalLine {
  border-left: 1.5px solid grey;
  height: 2.75vw;
}

.buy__text {
  margin-top: -8%;
}

.rent__text {
  margin-top: -8%;
}

.print__text {
  margin-top: -8%;
}

.ProductCards {
  margin-left: 10%;
  width: 80%;
}
.productimg{
  width: 110px;
  height: 110px;
  overflow: hidden;
  border-radius: 10px;
 }
.product_nme{
  font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #1C1C1C;
    text-transform: capitalize;
    margin: 0;
    display: block;
 }
.productimg img{
  max-width: 100%;
  min-height: 100%;
 }
 .frdimns{
  font-family: "Roboto", sans-serif;
font-size: 14px;
font-weight: 400;
line-height: 19.53px;
text-align: left;
color: #1C1C1C;
opacity: .4;
margin: 0;
padding-top: 5px;
}

.subscribe {
  background-color: black;
  padding: 3%;
}

.subscribe__logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.emailImage {
  width: 10%;
}
.newpaintngs{
  padding: 50px 0;
}
.sec_title{
  font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight:500;
    font-size: 32px;
    line-height: 40px;
    text-transform: capitalize;
    color: #1c1c1c;
    margin: 0px;
    margin-bottom: 10px;
}
.sub_title{
  letter-spacing: 0.00938em;
    color: #1C1C1C;
    z-index: 10;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    line-height: 26px;
    margin-bottom: 20px;
    text-transform: capitalize;
    font-size: 20px;
    margin-top: 0;
}
.text_center{
  text-align: center;
}
.title_image{
  max-width: 110px;
}
.card__top {
  position: relative;
  height: 350px;
  max-height: 350px;
  min-height: 350px;
  overflow: hidden;
  border: 1px solid #f1f7fd;
}
.card__top img {
  object-fit: fill;
  height: 100%;
}
.pic_info {
  display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 8px;
      margin-bottom: 7px;
      text-align: center;
      overflow: hidden;
}
.pic_info  .card__middle{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  display: block;
}
.titles {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: #1c1c1c;
  text-transform: capitalize;
}
.info_like {
  display: flex;
  justify-content: center;
  column-gap: 8px;
  align-items: center;
}
.wishlst{
  width: 25px;
  height: 25px;
  background: #f3f3f3;
  border-radius: 50%;
  line-height: 31px;
  text-align: center;
}
.like_count {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  color: #1c1c1c;
}
.names {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #636365;
  opacity: 0.5;
  margin: 0;
  padding-top: 1px;
  padding-bottom: 6px;
  border-bottom: 1px solid #BDBDBD;
  text-transform: capitalize;
}
.price_sec{
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin-top: 8px;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 7px;
  text-align: center;
}
.buys {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #636365;
  opacity: 0.6;
  margin-bottom: 5px;
  display: block;
}

.prices {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #1c1c1c;
}
.hrs {
  height: 50px;
  width: 0.1px;
  border-right: 1px solid #bdbdbd;
}
.loader_box {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media(max-width:767px){
  .headerCategory {
    margin-top: 57px;
  }
  .productimg{
    width: 100%;
  }
}