.modal_del {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #FFFFFF;
    background-color: white;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    font-weight: 700;
    font-size: 35px;
    color: #1C1C1C;
    border:none;
}

.card_main {
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding:30px 0px; */
}

.card {
    /* height: 450px; */
    width: 400px;
    padding: 30px 0px;
}

.card_odd {
    /* height: 450px; */
    width: 400px;
    padding: 30px 0px;
    background-color: #f6f6f6;
}

.hrs {
    width: 80%;
    text-align: center;
    margin: auto;
    border: 1.22893px solid #E4E4E4;
    margin-top: 25px;
    margin-bottom: 10px;
}

.already_signin {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: #636365;
    margin: 0px 0px 0px 0px;
}

.already_signin_btn {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: #1C1C1C;
    margin: 10px 0px;
}

.card_footer {
    display: flex;
    column-gap: 5px;
    justify-content: center;
    align-items: center;
}

@media(max-width:600px) {
    .modal_del {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #FFFFFF;
        background-color: white;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        font-weight: 700;
        font-size: 35px;
        color: #1C1C1C;
        max-height: 90%;
        overflow-x: scroll;
        border: none;
        width: 95%;
    }
    .card_main {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        /* padding:30px 0px; */
    }
}

@media (max-width:520px) {
    .modal_del {
        max-width: 88%;
        background-color: white;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 35px;
        color: #1C1C1C;
    }
}