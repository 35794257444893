.components {
  background-color: #f6f6f6;
}

.tab_label {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  text-transform: capitalize;
  color: #1c1c1c;
}

@media (max-width: 912px) {
  .mains {
    width: 100%;
  }
}
