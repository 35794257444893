.modal_del {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #FFFFFF;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    border-radius: 26px;
    color: #1C1C1C;
    border: none;
    outline: none;
    padding-bottom: 20px;
}
.yourNewClass{
    padding-bottom: 0;
}
.card_main {
    display: flex;
    justify-content: center;
    align-items: center;
    height:auto;
    position: relative;
    overflow-y: hidden;
   overflow-x: hidden;
}
.cancel_imgweb {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}

.closicon {
    font-size: 35px;
    color: #1C1C1C;
}
.card {
    padding: 20px;
   border-top-left-radius: 22px;
   height: 100%;
   text-align: center;
   display: flex;
   flex-direction: column;
 
}

.card_odd {
    background-color: #f6f6f6;
    padding: 20px;
    border-top-left-radius: 22px;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
}
.yourNewClass .card_odd{
    border-bottom-left-radius: 22px;
}
.hrs {
    width: 100%;

 
    border: 1.22893px solid #E4E4E4;
  
   margin: 0;
   padding: 0;
}

.yourNewClass  .hrs{
    display: none;
}
.already_signin {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: #636365;
    margin: 0px 0px 0px 0px;
}

.already_signin_btn {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #1C1C1C;
    margin: 0;
    cursor: pointer;
}

.card_footer {
    text-align: center;
    margin: 20px 0 0;
}

.card_footer>div {
    display: flex;
    align-items: center;
    column-gap: 5px;
    justify-content: center;
    margin-top: 5px;
}

.dcbn {
    font-size: 22px;
}
@media(max-width:600px){
    .modal_del {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #FFFFFF;
        background-color: white;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        font-weight: 700;
        font-size: 35px;
        color: #1C1C1C;
        max-height: 90%;
        overflow-x: scroll;
        border:none;
        width:95%;
    }
    .card_main {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }.card_footer {
        flex-direction: column;
    }

}