.hero_main_curator { 
    padding: 50px 0;
    margin-top: 125px;
 }
.card {
    background-color: #f6f6f6;
    padding:10px 30px;
    border-radius: 15px;
}
.Formsubmission {
     /* background-image: url(../../../../assets/img/Banner.png); */
 background-repeat: no-repeat;
 background-size: 100% 100%;
 display: flex;
 justify-content: center;
 align-items: center;
 flex-direction: column;
 width: 100%; 
}
.FormsubmissionGallery {
    /* background-image: url(../../../../assets/img/gallery-background.png); */
background-repeat: no-repeat;
background-size: 100% 100%;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
width: 100%; 
}
.FormsubmissionAffiliate {
    /* background-image: url(../../../../assets/img/affiliate-background.png); */
background-repeat: no-repeat;
background-size: 100% 100%;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
width: 100%; 
}
.FormsubmissionCurator {
    /* background-image: url(../../../../assets/img/curator-background.png); */
background-repeat: no-repeat;
background-size: 100% 100%;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
width: 100%; 
}

.modal_uploaded {
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: "Roboto", sans-serif;
    font-style: normal;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    font-weight: 700;
    font-size: 35px;
    width: 750px;
    color: #1C1C1C;
    border: none;
 
    
}

.top_heading {
    font-family: 'Marcellus';
    font-style: normal;
    font-weight: 400;
    font-size: 38px;
    line-height: 63px;
    text-align: center;
    text-transform: capitalize;
    color: #FFFFFF;
    margin: 0px;
    padding:0px;
    margin-top: 20px !important;
}

.second_heading {
    font-family: 'Marcellus';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 63px;
    text-align: center;
    text-transform: capitalize;
    color: #FFFFFF;
    margin: 0px;
    padding:0px;
}

.main_div {
    padding: 20px;
}

.done_icon {
    text-align: center;
}
.sucicon{
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background: #1c1c1c;
    margin: 0 auto;
    color: #fff;
    line-height: 47px;
    font-size: 50px;
    text-align: center;
    margin-bottom: 10px;
}
.done_icon>h1 {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    color: #1c1c1c;
    margin: 0px;
    margin-bottom: 15px;
}
.done_icon p{
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    color: #636365;
    opacity: .8;
    margin: 0;
    margin-bottom: 0;
}
.upload_first_text {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 37px;
    text-align: center;
    color: #636365;
    margin-bottom: 20px;
}

.upload_second_text {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 37px;
    text-align: center;
    color: #636365;
    margin:0px;
}

.main_div>h2 {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    color: #1C1C1C;
}

.btn_div {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
    margin-top: 30px;
}
.thankimg{
    max-width: 45%;
    margin: 0 auto;
    text-align: center;
}
@media(max-width:767px){
    .hero_main_curator{
        margin-top: 57px;
    }
    .done_icon>h1{
        font-size: 22px;
        line-height: 34px;
        text-align: center;
    }
}