.back_img {
 /* margin-bottom: 10px; */
 cursor: pointer;
}

.upload_pic {
 height: 200px;
 text-align: center;
 display: flex;
 justify-content: center;
 align-items: center;
 background-position: center;
 background-repeat: no-repeat;
}
.back_img {
 font-family: "Roboto", sans-serif;
 font-style: normal;
 font-weight: 500;
 font-size: 18px;
 line-height: 23px;
 color: #665d6f;
 cursor: pointer;
}
.upload_img {
 background-color: #f6f6f6;
 height: 300px;
 background-image: url("../../../../assets/img/upload_img.png");
 background-size: 100px;
 text-align: center;
 display: flex;
 justify-content: center;
 align-items: center;
 background-position: center;
 background-repeat: no-repeat;
}
.img_main_prev {
 width: 100%;
 height: 100%;
}

.discription_div {
 background-color: #f6f6f6;
 padding: 20px;
 margin-top: 20px;
}

.main_heading {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #1c1c1c;
  text-align: left;
  text-transform: capitalize;
  margin-bottom: 7px;
}

.search_action {
 margin: 15px 0px;
}

.labels {
 font-family: "Roboto", sans-serif;
 font-style: normal;
 font-weight: 500;
 font-size: 18px;
 line-height: 23px;
 color: #1c1c1c;
 /* margin-right: 10px; */
}

  .a_palette_color {
    width: 27px;
    height: 27px;
  margin: 4px 7px;
    display: inline-block;
    /* margin: 7px; */
    border: 1px solid transparent;
    border-radius: 5px;
  /* padding: 4px; */
    cursor: pointer;
    transition: padding 0.2s ease, border-radius 0.2s ease, box-shadow 0.2s ease, border-color 0.2s ease;
  }
  .a_palette_color:hover {
    border: 1px solid #adadad;
    /* padding: 4px; */
    border-radius: 5px;
    box-shadow: inset 0 0 0 .2rem #fff;
  }

.radio_label {
 font-family: "Roboto", sans-serif;
 font-style: normal;
 font-weight: 400;
 font-size: 16px;
 line-height: 21px;
 text-transform: capitalize;
 color: #665d6f;
 opacity: 0.6;
 margin: 0px;
}

.dimensions_div {
 display: flex;
 column-gap: 20px;
 align-items: center;
}

.medium_div {
 display: flex;
 justify-content: space-between;
 align-items: center;
}

.keyword_div {
 margin: 20px 0px 5px 0px;
}

.medium_child {
 display: flex;
 column-gap: 10px;
 align-items: center;
}

.medium_input {
 width: 50%;
}

.textarea {
 width: 100%;
 min-width: 99%;
 background-color: #f6f6f6;
 max-width: 100%;
 border: 1px solid #ccc;
 margin-top: 5px;
}

.para {
 font-family: "Roboto", sans-serif;
 font-style: normal;
 font-weight: 400;
 font-size: 14px;
 line-height: 18px;
 color: #665d6f;
}

.textarea_div {
 width: 99%;
}

.btn_div {
 display: flex;
 justify-content: center;
 align-items: center;
 margin: 15px 0px;
}
select {
 height: 54px;
 width: 100%;
 padding: 15px;
 border-radius: 4px;
 margin-top: 6px;
 padding-right: 30px;
 border: 1px solid #bebcc0;
 -moz-appearance: none;
 -webkit-appearance: none;
 appearance: none;
 background-color: #f6f6f6;
 /* background: url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='12' height='12' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='black'/></g></svg>")
  no-repeat; */
 background-position: right 5px top 50%;
}
.material_list_main {
 display: flex;
 justify-content: start;
 column-gap: 8px;
 flex-wrap: wrap;
 margin-top: 5px;
}
.material_list_div {
 display: flex;
 flex-wrap: nowrap;
 justify-content: center;
 column-gap: 8px;
 background-color: #eeeeee;
 padding: 8px;
 font-size: 22px;
 border-radius: 5px;
 width: fit-content;
 cursor: pointer;
 border: 1px solid white;
}
@media (max-width: 600px) {
 .discription_div {
  padding: 20px 10px;
 }
 .medium_input {
  width: 100%;
 }

 .medium_div {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: flex-start;
 }
 .medium_child {
  display: flex;
  flex-direction: column;
  column-gap: 10px;
  align-items: flex-start;
 }
}









  
  .palette-name {
    position: absolute;
    right: 40px;
    bottom: 50px;
    font-size: 25px;
    color: #969595;
  }
  
  .a_palette_color {
    width: 27px;
    height: 27px;
  margin: 4px 7px;
    display: inline-block;
    /* margin: 7px; */
    border: 1px solid transparent;
    border-radius: 5px;
  /* padding: 4px; */
    cursor: pointer;
    transition: padding 0.2s ease, border-radius 0.2s ease, box-shadow 0.2s ease, border-color 0.2s ease;
  }
  .a_palette_color:hover {
    border: 1px solid #adadad;
    /* padding: 4px; */
    border-radius: 5px;
    box-shadow: inset 0 0 0 .2rem #fff;
  }
  .a_palette_color:nth-child(20n + 1) {
    background-color: #ffffff;
  }
  .active {
    border: 1px solid #adadad;
    /* padding: 4px; */
    border-radius: 5px;
    box-shadow: inset 0 0 0 .2rem #fff;
  }
.a_palette_color:nth-child(20n + 2) {
  background-color: lightgray; /* #c8c8c8 */
}

.a_palette_color:nth-child(20n + 3) {
  background-color: hotpink; /* #FF69B4 */
}

.a_palette_color:nth-child(20n + 4) {
  background-color: darkred; /* #A7194B */
}

.a_palette_color:nth-child(20n + 5) {
  background-color: red; /* #FE2712 */
}

.a_palette_color:nth-child(20n + 6) {
  background-color: orange; /* #FB9902 */
}

.a_palette_color:nth-child(20n + 7) {
  background-color: blanchedalmond; /* #FFEBCD */
}

.a_palette_color:nth-child(20n + 8) {
  background-color: yellow; /* #FEFE33 */
}

.a_palette_color:nth-child(20n + 9) {
  background-color: yellowgreen; /* #D0EA2B */
}

.a_palette_color:nth-child(20n + 10) {
  background-color: green; /* #66B032 */
}

.a_palette_color:nth-child(20n + 11) {
  background-color: deepskyblue; /* #0391CE */
}

.a_palette_color:nth-child(20n + 12) {
  background-color: blue; /* #0247FE */
}

.a_palette_color:nth-child(20n + 13) {
  background-color: indigo; /* #3D01A5 */
}

.a_palette_color:nth-child(20n + 14) {
  background-color: purple; /* #8601AF */
}

.a_palette_color:nth-child(20n + 15) {
  background-color: purple; /* #8601AF (same as nth-child(20n + 14)) */
}

.a_palette_color:nth-child(20n + 16) {
  background-color: darkslategray; /* #2d3436 */
}

.a_palette_color:nth-child(20n + 17) {
  background-color: palevioletred; /* #e84393 */
}

.a_palette_color:nth-child(20n + 18) {
  background-color: crimson; /* #d63031 */
}

.a_palette_color:nth-child(20n + 19) {
  background-color: coral; /* #e17055 */
}

.a_palette_color:nth-child(20n + 20) {
  background-color: gold; /* #fdcb6e */
}

  
  /* Aussie Pallete */
  
  .au_palette_color {
    width: 64px;
    height: 64px;
    background: red;
    display: inline-block;
    margin: 14px;
    border-radius: 5%;
    box-shadow: 7px 7px 15px rgba(55, 84, 170, 0.15),
      -7px -7px 20px rgba(255, 255, 255, 1),
      inset 0px 0px 4px rgba(255, 255, 255, 0.2),
      inset 7px 7px 15px rgba(55, 84, 170, 0),
      inset -7px -7px 20px rgba(255, 255, 255, 0),
      0px 0px 4px rgba(255, 255, 255, 0) !important;
    cursor: pointer;
  }
  
  .au_palette_color:nth-child(20n + 1) {
    background-color: #f6e58d;
  }
  
  .au_palette_color:nth-child(20n + 2) {
    background-color: #ffbe76;
  }
  
  .au_palette_color:nth-child(20n + 3) {
    background-color: #ff7979;
  }
  
  .au_palette_color:nth-child(20n + 4) {
    background-color: #badc58;
  }
  
  .au_palette_color:nth-child(20n + 5) {
    background-color: #dff9fb;
  }
  
  .au_palette_color:nth-child(20n + 6) {
    background-color: #c7ecee;
  }
  
  .au_palette_color:nth-child(20n + 7) {
    background-color: #6ab04c;
  }
  
  .au_palette_color:nth-child(20n + 8) {
    background-color: #eb4d4b;
  }
  
  .au_palette_color:nth-child(20n + 9) {
    background-color: #f0932b;
  }
  
  .au_palette_color:nth-child(20n + 10) {
    background-color: #f9ca24;
  }
  
  .au_palette_color:nth-child(20n + 11) {
    background-color: #7ed6df;
  }
  
  .au_palette_color:nth-child(20n + 12) {
    background-color: #e056fd;
  }
  
  .au_palette_color:nth-child(20n + 13) {
    background-color: #686de0;
  }
  
  .au_palette_color:nth-child(20n + 14) {
    background-color: #30336b;
  }
  
  .au_palette_color:nth-child(20n + 15) {
    background-color: #95afc0;
  }
  
  .au_palette_color:nth-child(20n + 16) {
    background-color: #535c68;
  }
  
  .au_palette_color:nth-child(20n + 17) {
    background-color: #130f40;
  }
  
  .au_palette_color:nth-child(20n + 18) {
    background-color: #4834d4;
  }
  
  .au_palette_color:nth-child(20n + 19) {
    background-color: #be2edd;
  }
  
  .au_palette_color:nth-child(20n + 20) {
    background-color: #22a6b3;
  }