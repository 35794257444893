.headerCategory {
  width: 100%;
  background: url("./banner bg img.png");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  padding-top: 5%;
  padding-bottom: 5%;
  margin-top: 125px;
}
.pageitle{
  text-align: center;
}
.pageitle h1{
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: 0;
  margin-bottom: 10px;
  margin-top: 0;
}
.pageitle ul{
  padding: 0;
  list-style-type: none;
  margin: 0;
}
.pageitle ul li{
  display: inline-block;
  margin-top: 0;
}
.pageitle ul li:last-child{
  padding-left: 5px;
}
.pageitle ul li a{
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #14142B;
  text-decoration: none !important;
}
.headerSubTitle {
  margin-left: -25%;
}
.content__image{
    width: 100%;
}
.predimg{
  margin: 0 auto;
  text-align: center; 
}
.pressce{
  padding: 50px 0;
}
.predtl_cont h4{
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  font-weight: 400;
  line-height: 37.62px;
  text-align: left;
  margin-bottom: 0px;
}
.predtl_cont h6{
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: #1C1C1C;
  margin: 0 0 7px;
}
.predtl_cont p{
  font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    text-align: left;
    color: #636365;
    margin: 0 0 5px;
}
.img_fluid{
  height: auto;
  max-width: 100%;
}
@media(max-width:767px){
  .headerCategory{
    margin-top: 57px;
  }
  .headerSubTitle {
    margin-left: -100%;
  }
  .headerTitle{
    margin-left: 17.5%;
  }
  .pageitle h1{
    font-size: 25px;
        line-height: 26px;
        margin-bottom: 5px;
  }
  .predtl_cont h4{
    font-size: 23px;
    line-height: 26px;
  }
}