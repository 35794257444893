.table_row {
    /* min-height: 30px; */
  }
  .table_column {
    /* height: 50px; */
  }
  .table_columns {
    display: flex;
    justify-content: space-between;
    /* min-height: 30px; */
    border-collapse: separate;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #636365;
  }
  .table_head {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #1c1c1c;
  }
  
  .table_column {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #636365;
  }
  
  .status_active {
    width: 57px;
    /* height:31px; */
    background: #ffffff;
    border: 1px solid #c4c4c4;
    border-radius: 30px;
    color: #219653;
    margin-left: 20px;
    padding: 5px 19px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
  }
  .nested_span {
    width: 6px;
    height: 6px;
    background-color: #219653;
  }
  .status_inactive {
    width: 65px;
  
    /* height:31px; */
    background: #ffffff;
    border: 1px solid #c4c4c4;
    border-radius: 30px;
    color: #eb5757;
    margin-left: 20px;
    padding: 5px 15px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
  }
  .table_campaign_col {
    display: flex;
    justify-content: space-between;
  }
  /* .status_inactive */
  .flex_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .loader_box {
    display: flex;
    justify-content: center;
    align-items: center;
  }
    .interested {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    }