.headerCategory {
    width: 100%;
    background: url("../banner bg img.png");
    background-size: cover;
    padding-top: 5%;
    padding-bottom: 5%;
    margin-top: 125px;
}

.pageitle {
    text-align: center;
}

.pageitle h1 {
    font-family: "Roboto", sans-serif;
    font-size: 30px;
    font-weight: 500;
    line-height: 1.235;
    letter-spacing: 0;
    margin-bottom: 10px;
    margin-top: 0;
}

.pageitle ul {
    padding: 0;
    list-style-type: none;
    margin: 0;
}

.pageitle ul li {
    display: inline-block;
    margin-top: 0;
}

.pageitle ul li:last-child {
    padding-left: 5px;
}

.pageitle ul li a {
font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: #14142B;
    text-decoration: none !important;
}

.curatorlist {
    padding: 50px 0;
}

.otherartistnamerating {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 27px;
}

.otherartistcards {
    background-color: white;
    padding: 13px;
    box-shadow: 0px 0px 7.24px 0px #0000000F;
    border: 0.9px solid #63636533;
    position: relative;
}

.Other_otheim {
    height: 200px;
    max-height: 200px;
    min-height: 200px;
    overflow: hidden;
}

.otherartimg {
    width: 100%;
    object-fit: fill;
    height: 100%;
}

.otherartistimaged {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    overflow: hidden;
    border: 3px solid #fff;
}

.otherartistimg {
    cursor: pointer;
    max-width: 100%;
    height: 100%;
    width: 100%;
}

.Other_otherartist {
    margin-top: 40px;
}

.Other_otherartist h4 {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #1c1c1c;
    text-transform: capitalize;
    margin: 0;
}

.Other_otherartist p {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #636365;
    opacity: 0.6;
    margin: 0;
}

.exprlobtn {
    padding: 10px 35px;
    border-radius: 10px;
    background: #fff;
    border: 1px solid #1c1c1c;
    color: #1c1c1c;
font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    display: block;
    width: 100%;
    margin-top: 8px;
}

.exprlobtn:hover {
    background: #1c1c1c;
    color: #fff;
}

.otherartistnamerating {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 27px;
}

.rticon {
    color: #FFDC64;
    font-size: 20px;
}

.otherartistnamerating h5 {
    font-size: 15px;
    margin-bottom: 0;
}

.locvn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
}

.locvn p {
    color: #636365;
font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    margin: 0;
    opacity: .8;
}

.abncr {
    font-weight: 500;
  font-size: 32px;
  line-height: 40px;
    text-align: left;
    margin: 0;
    margin-bottom: 10px;
    font-family: "Roboto", sans-serif;
    border-bottom: 1px solid #E7EBED;
    padding-bottom: 10px;
}

.abutp {
    padding-top: 25px;
}