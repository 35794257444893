.modal_box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow-y: auto;
    width: 60%;
    max-height: 90%;
    background-color: #fff;
    padding-top: 15px;
    padding-left: 15px;
    padding-bottom: 15px;
    padding-right: 15px;
    border-radius: 10px;
}
.aofvsrch{
    width: 100%;
    display: flex;
    align-items: center;
}
.aofvsrch input{
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    font-size: 16px;
}
.sericon{
    padding: .375rem .75rem;
    font-size: 17px;
    font-weight: 400;
    line-height: 1.4;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
}
.input_action_div {
    width: 100%;
    display: flex;
    column-gap: 10px;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1px solid #000000;
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.input_div {
    width: 100%;
    /* border:2px solid green; */
    /* height: 44px; */
}

.cancel_btn {
    background-color: white;
    color: black;
    width: '144px';
    height: '44px';
    font-family: "Roboto", sans-serif;
}

.search_icon {
    position: absolute;
    left: 83%;
    top: 12px;
}

.selector {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* margin-bottom: -20px; */
}

.main_content {
    background-color: white;
}

.details_div {
    display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 10px;
}

.detail {
    display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
}

.title {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #1C1C1C;
    margin: 0px;
}

.content {
    font-family: "Roboto", sans-serif;
    text-transform: capitalize;
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    color: #636365;
    margin-bottom: 0;
}

.hrverticle {
    height: 65px;
    width: 1px;
    opacity: 0.07;
    background-color: #1C1C1C;
}

.hori_hrs {
    width: 100%;
    opacity: 0.3;
    /* border: 1px solid #665D6F; */
    margin:5px 0px;
    /* padding:0px 30px; */
}

.img_detail_div {
    display: flex;
    column-gap: 30px;
    justify-content: space-between;
    align-items: center;
}

.button_detail_div {
    display: flex;
    column-gap: 30px;
    justify-content: space-between;
    align-items: center;
}
@media(min-width:993px) and (max-width:1025px) {
    .modal_box {
        width: 90%;
    }
}
@media(min-width:768px) and (max-width:992px) {
    .modal_box {
        width: 95%;
    }
    .detail {
        padding: 0 5px;
    }
    .img_detail_div {
        column-gap: 5px;
    }
}
@media(max-width:767px) {
    .modal_box {
     width: 95%;
    }
    .main_content{
        padding: 5px;
    }
    .details_div{
        flex-direction: column;
    }
    .hrverticle {
        height: 1px;
        width: 100%;
        margin: 5px 0;
}
.content {
    margin-bottom: 0;
}
.img_detail_div{
    flex-direction: column;
}
.detail{
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.hori_hrs{
    margin-top: 5px;
    margin-bottom: 5px;
}
}