.components {
  background-color: #f6f6f6;
  border-radius: 10px;
  padding-bottom: 20px;
}

.jobDetailsContainer {
  background-color: #f6f6f6;
  border-radius: 10px;
  padding: 20px;
}
.sethng{
  font-family: "Roboto", sans-serif;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    color: #1c1c1c;
    text-align: left;
    text-transform: capitalize;
    margin-bottom: 7px;
}

.odlist{
  color: #1C1C1C;
font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    margin-bottom: 10px;
    display: flex;
}
.odlist span{
  max-width: 20%;
  width: 20%;
  display: block;
}
.odlist span + span{
  color: #636365;
  opacity: .8;
  max-width: initial;
  width: 80%;
}
.jobDetail {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
  gap: 10px;
  text-align: justify;
}

.jobDetailLabel {
  font-weight: bold;
  color: #555;
}

.jobDetailValue {
  color: #000;
}


@media (max-width: 767px) {
  .mains {
    width: 100%;
  }
  .odlist span{
    width: auto;
    max-width: initial;
  }
  .odlist span + span{
    width: auto;
  }
}
