
.login_main{
    display: flex;
    align-items: center;
    justify-content: center;
}
.forgot_form_login input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.login_main_f {
    height: 100vh;
    width: 100vw;
    display: flex;
}

.left_main {
    background-color: #ebebeb;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
    height: 100vh;
    width: 100%;
}

.img_login {
    height: 60%;
    width: 50%;
}

.right_main_f {
    display: flex;
    justify-content: center;
    padding: 3% 0% 0% 0%;
    width: 100%;
}

.forgot_center {
    width: 60%;
    margin:0px auto;
}

.forgot_form_login {
    margin-top: 5px;
}
.span_input {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 40px;
    letter-spacing: 0.02em;
    color: #000000;
    margin: 5px 0px;
    cursor: pointer;
}
.img_forgot {
    height: auto;
    width: 25%;
    cursor: pointer;
}

.input_forgot {
    padding: 0px 0px 0px 15px;
    width: 45%;
    height: 31px;
    border-radius: 5px;
    border: 0.7px solid #D0D0D0;
    /* padding: 0px 15px; */
    margin: 10px 0px;
}

.opt_btn {
    width: 100%;
}


.heading_forgot {
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    margin: 0px;
    font-size: 30px;
    margin-top: 10px;
}
.apply_btn{
    padding: 10px 35px;
    border-radius: 10px;
    background:  #1c1c1c;
    border: 1px solid  #1c1c1c;
    color: #fff !important;
    font-family: DM Sans !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 26px !important;
    cursor: pointer; 
    text-decoration: none !important;
    display: inline-block;
  }
  .apply_btn:hover{
    background: #fff;
    color:  #1c1c1c !important;
  }
  .full_width{
    width: 100%;
  }
  @media (min-width: 993px) and (max-width:1025px) {
    .forgot_center{
      width: 80%;
    }
    .img_login{
        width: 50%;
        height: auto;
      }
    }
    @media (max-width: 992px) {
        .login_main {
          display: flex; 
        }
        .forgot_center{
          width: 90%;
        }
        .img_login{
          width: 80%;
          height: auto;
        }
      } 
@media (max-width:767px) {
    .left_main{
        display: none;
    }
    .text_center{
        text-align: center;
      }
      .right_main_f {
        padding: 3% 0% 0% 0%;
      }
}

@media (max-width:540px) {
 
.img_login {
    height: 60%;
    width: 50%;
}
    .forgot_center {
        width: 90%;
        margin:0px auto;
    }
}