.artistdetails {
  margin-top: 2%;
}
.artistImgDiv {
  width: 10%;
  margin-left: 43%;
  margin-top: -6%;
}
.artistImg {
  width: 100%;
  border: 8px solid white;
  border-radius: 50%;
}
.verticalLine3 {
  border-left: 1.5px solid black;
  height: 4.5vw;
  margin-left: 0.5%;
  margin-right: 0.5%;
}
.artistStats {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -1.5%;
}
.artistShare {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1%;
}
.sharelogo {
  margin-left: 1%;
  background-color: #efefef;
  width: 2.5vw;
  height: 2.5vw;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.shareimage {
  width: 45%;
}
.artist__description {
  padding: 2% 12.5%;
}

.colorcheckbox {
  background-color: red;
}
.orientation {
  margin-right: 7%;
}
.color__image {
  margin-right: 2%;
  width: 10%;
}

.card__middle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card__main {
  /* background-color: #ccc; */
  width: 95%;
}
.card__middleRight {
  display: flex;
  margin-bottom: 6.5%;
}
.card__bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.verticalLine {
  border-left: 1.5px solid grey;
  height: 2.75vw;
}
.buy__text {
  margin-top: -8%;
}
.rent__text {
  margin-top: -8%;
}
.print__text {
  margin-top: -8%;
}
.ProductCards {
  margin-left: 1%;
  width: 100%;
}
.card__main {
  cursor: pointer;
  /* margin-bottom: 20%;   */
  width: 100%;
}
.card__top {
  height: 300px;
}
.card__top img {
  object-fit: fill;
  height: 100%;
  width: 100%;
}
.card__top:hover .cardImage {
  opacity: 0.5;
}
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40vh;
  /* margin: 20px auto; */
}
.artwork__div {
  display: flex;
  /* justify-content: center; */
  align-items: flex-start;
  margin-left: 7%;
}
.otherartist__div {
  margin-top: 3%;
  padding: 0% 17%;
}
.designimage {
  width: 50%;
}
.title {
  display: flex;
  justify-content: center;
  align-items: center;
}
.otherartistimage__div {
  margin-top: -14.25%;
  margin-left: 33%;
  width: 25%;
}
.otherartistimg {
  width: 100%;
}
.otherartistnamerating {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.otherartistcards {
  width: 100%;
  border: 0.905px solid rgba(99, 99, 101, 0.2);
  box-shadow: 0px 0px 7.24px rgba(0, 0, 0, 0.06);
  padding: 2%;
  margin-top: 8%;
  margin-bottom: 32%;
}
.ratingdiv {
  display: flex;
  justify-content: center;
  align-items: center;
}
.otherartimg {
  width: 100%;
}
.latestartworks {
  margin-top: 3%;
  background-color: #f2f2f2;
  margin-bottom: 4%;
}
.designImage {
  width: 35%;
}
.latestartworksheading {
  margin-top: 45%;
  margin-bottom: 45%;
}
.latestartworks {
  padding: 5% 15%;
}
.artist__grid {
  margin-left: 1%;
  width: 100%;
}
.artist__card {
  width: 100%;
  margin-bottom: 5%;
  border: 0.905px solid rgba(99, 99, 101, 0.2);
  box-shadow: 0px 0px 7.24px rgba(0, 0, 0, 0.06);
  padding: 3%;
}
.artist__top {
  height: 230px;
}
.actualart {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.artist__photo {
  width: 100%;
}
.artistphoto__div {
  width: 26%;
  margin-left: 38%;
  margin-top: -9%;
}
.artistphoto__div > img {
  border-radius: 50%;
}
.artist__namestars {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rating {
  display: flex;
  justify-content: center;
  align-items: center;
}
.headerMain {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2% 2% 2% 7%;
}
.artist__location {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.artistlist__main {
  display: flex;
  /* justify-content: center; */
  margin-left: 7%;
  align-items: flex-start;
}
.artist__sidebar {
  margin-top: 3%;
}
.ProductSidebar {
  /* margin-left: 23%; */
  /* border: 1px; */
  /* padding: 0.5%; */
  background-color: rgb(231, 230, 230);
  min-width: 300px;
  max-width: 200px;
  margin-bottom: 10%;
  border-radius: 4px;
}
.radio__button {
  margin-bottom: 5%;
}
.radio__div {
  margin-top: -10%;
  margin-left: -2%;
  margin-bottom: -5%;
}
.orientation {
  margin-right: 5%;
}
.color__image {
  margin-right: 3%;
}
.radio__label {
  margin-left: 3.5%;
}
.bannerbigimg {
  width: 100%;
}
@media (max-width: 900px) {
  .ProductSidebar {
    display: none;
  }
  .sharelogo {
    height: 9.6vw;
    width: 9.6vw;
  }
}
