.modal_campaigns {
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* // height:700px, */
  background-color: #ffffff;
  width: 30%;
  padding: 30px;
  overflow-y: auto;
  max-height: 90%;
}
.mpophndg{
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  text-align: center; 
  text-transform: capitalize;
}
.clobtn{
  position: absolute;
  right: 10px;
  top: 10px;
}
.clobtn a{
  cursor: pointer;
}
.iconru{
  font-size: 30px;
  color: #1C1C1C;
}
.action_btn{
  padding: 10px 25px;
  border-radius: 10px;
  background: #000;
  border: 1px solid #000;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  cursor: pointer;
}
.action_btn:hover{
  background: transparent;
  color: #000;
}

.inputs_gird {
  margin: 0px 0px;
}

.inputs_gird > label {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #1c1c1c;
  margin-left: 5px;
}
.astricksymb{
  color: red;
}

.inputs_gird > input {
  background: #f3f3f3;
  height: 40px;
  padding-left: 10px;
  border-radius: 6px;
  border: none;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #636365;
  width: 92%;
  margin: 5px 0px 0px 0px;
}

.textarea_div {
  width: 99%;
}

.textarea_div1 {
  width: 99%;
  margin-bottom: 10px;
}
.errorMessage {
  color: red;
  font-weight: bold;
  font-size: 12px;
  margin-top: 10px; /* Adds margin above the error message */
  display: block; /* Ensure the error message spans the whole width and stays below */
}

.textarea_label {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;

  /* Black */

  color: #1c1c1c;
}

.input_title1 {
  width: 50%;
  margin: 10px 0px;
}
.input_title_press {
  width: 100%;
  margin: 45px 0px;
}

.input_title > label {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #1c1c1c;
}
.input_titlett > label {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #1c1c1c;
  /* margin: 10px 0px 0px 0px; */
}
.input_titlett {
  margin: 10px 0px 0px 0px;
}
.textarea {
  min-width: 100%;
  border: none;
  background: #f3f3f3;
  border-radius: 6px;
  /* padding-left: 10px; */
  margin-top: 8px;
}
.textarea_blog {
  min-width: 100%;
  height: 150px;
  border: none;
  /* background: #f3f3f3; */
  border-radius: 6px;
  /* padding-left: 10px; */
  margin-top: 8px;
}
.customEditor {
  /* height: 150px;  */
  .ql-editor {
    min-height: 250px; /* Ensure the editable area has a specific height */
  }
}

.action {
  text-align: center;
  margin: 90px 0px 0;
}
 

/* for image */

.upload_img > p {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #1c1c1c;
  opacity: 0.5;
  margin-bottom: 0px;
}
 
.preview_img {
  width: 100%;
  height: 100%;
}

.img_box {
  width: 100%;
  height: 150px;
  align-items: center;
  background-color: #f6f6f6;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  display: flex
;
  justify-content: center;
  text-align: center;
}
.actionv{
  text-align: center;
}
.labelv{
  text-align: center !important;
  color: #1c1c1c !important;
  opacity: .6;
}
.labelv p{
  font-size: 14px;
    margin-bottom: 0;
    word-break: break-all;
}

.label {
  /* margin: 5px 0px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.label > p {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #665d6f;
  margin: 5px 0px;
  opacity: 0.5;
}

.img_view {
  width: 100%;
  height: 150px;
}

.img_view_hide {
  width: 100%;
  background-color: #f6f6f6;
}

.startDate {
  display: flex;
  gap: 50px;
}
@media(min-width:993px) and (max-width:1025px){
  .modal_campaigns{
    width: 50%;
  }
}
@media(min-width:768px) and (max-width:992px){
  .modal_campaigns{
    width: 60%;
  }
}
@media (max-width: 767px) {
  .modal_campaigns {
    width: 95%;
  }
}
