.cancel {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #eb5757;
    min-width: 80px;
    display: inline-block;
  }
  .hyperlink_title{
    text-decoration: underline;
    color: #636365;
  }
  .pending {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #f2c94c;
    min-width: 80px;
    display: inline-block;
  }
  
  .career_lastcol {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }
  
  .curator_order {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
   
  }
  
  .table_column {
    white-space: nowrap;
  }
  
  .column_gallery_artist_true {
    /* color:red; */
    white-space: nowrap;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .column_gallery_artist {
    color: red;
    white-space: nowrap;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .quoterequest {
    display: flex;
    column-gap: 20px;
    justify-content: center;
    align-items: center;
  }
  
  .rejected {
    color: #ff2222;
  }
  
  .loader_box {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .action {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .viewIcon {
    color: #1C1C1C;
    margin-right: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .action img {
    cursor: pointer;
  }
  .editicon{
    color: #665D6F;
    font-size: 22px;
  }
  .deleicon{
    color: #1C1C1C;
    font-size: 22px;
  }