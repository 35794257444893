.mains {
  width: 69%;
  background-color: #f6f6f6;
}

.components {
  margin: 50px auto;
  margin-top: 0px;
  padding-bottom: 25px;
  border-radius: 6px;
}

@media (max-width: 600px) {
  .mains {
    width: 100%;
  }
}
