.modal_del {
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* // height:700px, */
  background-color: #ffffff;
  width: 30%;
  padding: 30px;
  overflow-y: auto;
  max-height: 90%;
}
.asterisk{
  color: red;
}

.mpophndg{
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  text-align: center; 
  text-transform: capitalize;
}
.clobtn{
  position: absolute;
  right: 10px;
  top: 10px;
}
.clobtn a{
  cursor: pointer;
}
.iconru{
  font-size: 30px;
  color: #1C1C1C;
}
.action_btn{
  padding: 10px 25px;
  border-radius: 10px;
  background: #000;
  border: 1px solid #000;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  cursor: pointer;
}
.action_btn:hover{
  background: transparent;
  color: #000;
}
.textarea {
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  margin: 30px 0px;
  /* width: 97%; */
  /* width: 420px; */
}
/* 
.textareas {
    min-width: 100%;
    border: 1px solid #665D6F;
    border-radius: 5px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #636365;
    padding:15px 15px;
    /* min-row:10; */
/* } */

.action {
  text-align: center;
  margin: 10px 0px 0px 0px;
}

.preview_img {
  width: 100%;
  height: 100%;
}

.img_box {
  width: 100%;
  height: 150px;
  align-items: center;
  background-color: #f6f6f6;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  display: flex
;
  justify-content: center;
  text-align: center;
}
.actionv{
  text-align: center;
}
.labelv{
  text-align: center !important;
  color: #1c1c1c !important;
  opacity: .6;
}
.labelv p{
  font-size: 14px;
    margin-bottom: 0;
    word-break: break-all;
}
.label {
  /* margin: 5px 0px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.label > p {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #665d6f;
  margin: 3px 0px;
  opacity: 0.5;
}
.img_view {
  width: 100%;
  height: 150px;
}
.img_view_hide {
  border: 1px solid red;
  width: 100%;
  background-color: #f6f6f6;
}
@media(min-width:993px) and (max-width:1025px){
  .modal_del{
    width: 50%;
  }
}
@media(min-width:768px) and (max-width:992px){
  .modal_del{
    width: 60%;
  }
}
@media (max-width: 767px) {
  .modal_del {
    width: 95%;
  }
}