.ProductSidebar {
  margin-bottom: 10%;
  box-shadow: 0 0 6px 2px #edebeb;
  border-radius: 10px;
  padding: 20px;
  background: #fff;
}
.ctfilist{
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}
.ctfilist1{
  list-style-type: none;
  padding-left: 0px;
  margin-bottom: 0;
  max-height: 160px;
  overflow-y: scroll;
}
.ctfilist li{
  margin-top: 0;
  padding-bottom: 10px;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  text-transform: capitalize;
}
.ctfilist li label, .ctfilist1 li label{
  margin-top: 0 !important;
  font-family: "Roboto", sans-serif;
  line-height: 21px;
  opacity: 0.6;
}
.ctfilist li:last-child, .ctfilist1 li:last-child{
  padding-bottom: 0;
}
.checkbox_selected{
  cursor: pointer;
  margin-right: 16px;
  height: 100%;
}
.searchInput{
  width: 100%;
  height: 35px;
  margin-bottom: 16px;
}
.searchStyleInput{
  width: 100%;
  height: 35px;
  margin-bottom: 30px;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 3px;
}
.checkbox_animated {
  cursor: pointer;
  position: relative;
  margin-right: 13px;
  height: 100%;
  left: 5px;
}
.checkbox_animated:before {
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  transition: -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75), -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  -webkit-transform: rotate(-45deg) scale(0, 0);
  transform: rotate(-45deg) scale(0, 0);
  content: "";
  position: absolute;
  left: 1px;
  top: 3px;
  z-index: 1;
  width: 9px;
  height: 4px;
  border: 2px solid #000;
  border-top-style: none;
  border-right-style: none;
}
.checkbox_animated:after {
  content: "";
  position: absolute;
  top: -3px;
  left: -5px;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #665D6F;
  cursor: pointer;
}
.checkbox_animated:checked:before {
  -webkit-transform: rotate(-45deg) scale(1, 1);
  transform: rotate(-45deg) scale(1, 1);
}
.labels {
  display: flex;
  justify-content: space-between;
  
  margin-top: -5px;
  font-weight: 500;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
}
 
@media (max-width: 900px) {
  .ProductSidebar {
    display: none;
  }
}
