.buyer_header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    /* margin:10px 30px; */
    padding: 20px 30px 20px 30px;
}

.input_div{
    position: relative;
    /* height: 20%; */
    width: 20%;
}
.search_icon{
    position: absolute;
    left: 99%;
    top:23%;
}
.header_action {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.search_input {
    /* margin: 20px 0px 20px 30px; */
    width: 100%;
    border:0.6px solid #665D6F;
    border-radius: 6px;
    height: 37px;
    background-color: #F6F6F6;
    padding: 0px 15px;
}

.table_head {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #1C1C1C;
}
.table_column {
    color: #636365;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    white-space: nowrap;
    /* max-width: 40px; */
}

.pagination_box {
    display: flex;
    justify-content: flex-end;
    background-color: #F6F6F6;
    padding-top: 25px;
    padding-right: 25px;
    /* margin-bottom: 20px; */
  }
  
  .pagination_box>div {
    margin-bottom: 25px;
  }

.pagination_btn {
    background-color: #FFFFFF;
    width: 20px;
    border: 0.841897px solid black;
    border-radius: 4.20949px;
    color: black;
    font-family: "Roboto", sans-serif;
    border: 1px solid rgba(255, 0, 0, .5);
    -webkit-background-clip: padding-box;
    /* for Safari */
    background-clip: padding-box;
}


.header_action_filter {
    margin: 5px 16px;

}

.header_action_btn {
    margin: 5px 8px;
}

.page_btn {
    height: 20px;
    width: 10px;
}


.quoterequest {
    display: flex;
    column-gap: 20px;
    justify-content: center;
    align-items: center;
}

.rejected {
    color: #FF2222;
}
.loader_box{
    display: flex;
    justify-content: center;
    align-items: center;
}
.action_btn{
    padding: 10px 25px;
    border-radius: 10px;
    background: #000;
    border: 1px solid #000;
    color: #fff;
font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    cursor: pointer;
  }
  .action_btn:hover{
    background: transparent;
    color: #000;
  }
  .delicon{
    color: #1C1C1C;
    font-size: 25px;
  }
@media(max-width:520px) {

    .buyer_main {
        background-color: #F6F6F6;
        width: 95%;
        margin: 50px auto;
        padding-bottom: 25px;
        border-radius: 6px;
    }
    .search_icon{
        position: absolute;
        left: 90%;
        top:23%;
    }
    .input_div{
        position: relative;
        width: 100%;
    }
    .search_input {
        align-self: center;
        width: 82%;
        border: 0.6px solid #665D6F;
        border-radius: 6px;
        height: 44px;
        background-color: #F6F6F6;
        padding: 0px 30px;
    }

    .header_action_filter {
        margin: 8px 0px;
        /* width:50% */
    }

    .header_action_btn {
        margin: 2px 0px;
    }

    .page_btn {
        height: 20px;
        width: 10px;
    }
}

@media(max-width:676px) {
    .buyer_header {
        display: flex;
        flex-wrap: wrap;
        /* flex-direction: column; */
        justify-content: space-between;
        align-items: center;
        /* margin-left: 30px; */
        padding: 20px 10px;
    }

    .search_input {
        /* margin: 20px 0px 20px 30px; */
        align-self: center;
        /* width: 50%; */
        /* width: 100%; */
        border: 0.6px solid #665D6F;
        border-radius: 6px;
        height: 44px;
        background-color: #F6F6F6;
        padding: 0px 30px;
    }

    .header_action {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
        flex-wrap: wrap;
        width: 100%;

    }
}