.modal_del {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #FFFFFF;
    box-shadow: none;
    border-radius: 10px;
    border: none;
    padding:30px;
    width:35%;
    max-height: 90%;
    overflow-y: auto;
}

.cancel {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #EB5757;
    min-width: 80px;
    display: inline-block;
}

.pending {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #F2C94C;
    min-width: 80px;
    display: inline-block;
}

.career_lastcol {
    display: flex;
    justify-content: space-between;
}

.curator_order {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.table_column {
    white-space: nowrap;
}

.column_gallery_artist_true {
    /* color:red; */
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-right: 55px; */
}

.column_gallery_artist {
    /* color: red; */
    white-space: nowrap;
    
}

.quoterequest {
    display: flex;
    column-gap: 20px;
    justify-content: center;
    align-items: center;
}

.rejected {
    color: #FF2222;
}

.loader_box {
    display: flex;
    justify-content: center;
    align-items: center;
}