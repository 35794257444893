.modal_box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 900px;
    max-width: 900px;
    max-height: 90%;
    overflow-x: scroll;
    overflow-y: scroll;
    background-color: white;
}

.cancel_btn_div {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 20px;
    margin-right: 20px;

}

.cancel_btn_div>img {
    cursor: pointer;
}

.main_heading {
    margin: 0px;
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 34px;
    text-align: center;
    color: #1C1C1C;
    margin-bottom: 30px;

}

.input_label {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #1C1C1C;
}

.main_content {
    background-color: white;
    padding: 0px 30px;
}

.details_div {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
}

.detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.title {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #1C1C1C;
    margin: 0px;
}
.add_btn_div_hide{
    visibility: hidden;
}

.content {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #636365;
}

.hrverticle {
    height: 65px;
    width: 1px;
    opacity: 0.07;
    background-color: #1C1C1C;
}

.hori_hrs {
    width: 100%;
    opacity: 0.3;
    border: 1px solid #665D6F;
    margin: 20px 0px;
    /* padding:0px 30px; */
}

.img_detail_div {
    display: flex;
    column-gap: 30px;
    justify-content: space-between;
    align-items: center;
}

.button_detail_div {
    display: flex;
    column-gap: 30px;
    justify-content: space-between;
    align-items: center;
}

@media(max-width:600px) {
    .modal_box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 900px;
        max-width: 900px;
        max-height: 90%;
        overflow-y: scroll;
        overflow-x: scroll;
    }

}