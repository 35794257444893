.img_view {
    width: 100%;
    height: 150px;
  object-fit: cover;
  }
.img_boxs {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* opacity: 0.5; */
    /* border-radius: 5px; */
}
.preview_img {
    width: 30%;
    align-items: center;
    background-color: #f6f6f6;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    display: flex
;
    height: 150px;
    justify-content: center;
    text-align: center;
 

}
.label {
    /* margin: 5px 0px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}
.image_div {
    width: 46px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
}.img_box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* opacity: 0.5; */
    border-radius: 5px;
    object-fit: cover;
  
    
}
.img_boxss {
    width: 280px;
    /* border: none !important; */
     /* height: 50%; 
     hei */
     height: 100%;
     display: flex;
     justify-content: center;
     text-align: center;
     align-items: center;
    /* opacity: 0.5; */
    border-radius: 5px;
    object-fit: cover;
  
    
}
.back_img {
 font-family: "Roboto", sans-serif;
 font-style: normal;
 font-weight: 500;
 font-size: 18px;
 line-height: 23px;
 color: #665d6f;
 cursor: pointer;
}
.upload_pic {
 background-color: #f6f6f6;
 height: 250px;
 /* background-image: url('../../../../assets/img/upload_img.png'}); */
 background-size: 100px;
 text-align: center;
 display: flex;
 justify-content: center;
 align-items: center;
 background-position: center;
 background-repeat: no-repeat;
}

.discription_div {
 background-color: #f6f6f6;
 padding: 20px;
 margin-top: 20px;
}

.main_heading {
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    color: #1c1c1c;
    text-align: left;
    text-transform: capitalize;
    margin-bottom: 7px;
}

.search_action {
 margin: 8px 0px;
}
.add_btn {
 display: flex;
 justify-content: flex-end;
 align-items: center;
}
.labels {
 font-family: "Roboto", sans-serif;
 font-style: normal;
 font-weight: 500;
 font-size: 18px;
 line-height: 18px;
 color: #1c1c1c;
 /* margin-right: 10px; */
}

.status_avail {
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: #1c1c1c;
    margin: 10px 0 5px;
}
.labelv{
    color: #1c1c1c !important;
    opacity: .6;
    text-align: center !important;
  }
  .labelv p{
    font-size: 14px;
    margin-bottom: 0;
    word-break: break-all;
  }
.checkbox_label {
 font-family: "Mulish";
 font-style: normal;
 font-weight: 400;
 font-size: 16px;
 line-height: 14px;
 letter-spacing: 0.02em;
 color: #636365;
}

.sale_price {
 font-family: "Roboto", sans-serif;
 font-style: normal;
 font-weight: 500;
 font-size: 18px;
 line-height: 23px;
 color: #1c1c1c;
 margin: 0px;
}

.radio_label {
 font-family: "Roboto", sans-serif;
 font-style: normal;
 font-weight: 400;
 font-size: 16px;
 line-height: 21px;
 text-transform: capitalize;
 color: #665d6f;
 opacity: 0.6;
 margin: 0px;
}

.dimensions_div {
 display: flex;
 column-gap: 20px;
 align-items: center;
}

.medium_div {
 display: flex;
 justify-content: space-between;
 align-items: center;
}

.keyword_div {
 margin: 20px 0px 5px 0px;
}

.medium_child {
 display: flex;
 column-gap: 10px;
 align-items: center;
}

.medium_input {
 width: 50%;
}

.textarea {
 width: 100%;
 min-width: 99%;
 background-color: #f6f6f6;
 max-width: 100%;
}

.para {
 font-family: "Roboto", sans-serif;
 font-style: normal;
 font-weight: 400;
 font-size: 14px;
 line-height: 18px;
 color: #665d6f;
}
.para_slider {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;

    color: #665d6f;
   }

.textarea_div {
 width: 99%;
}

.btn_div {
 display: flex;
 justify-content: center;
 align-items: center;
 margin: 15px 0px 0;
}
.add_btn_hide {
 display: none;
}
.print_qty{
    display: none;
}
@media (max-width: 600px) {
 .discription_div {
  padding: 20px 10px;
 }

 .medium_input {
  width: 100%;
 }

 .medium_div {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: flex-start;
 }

 .medium_child {
  display: flex;
  flex-direction: column;
  column-gap: 10px;
  align-items: flex-start;
 }
}
