.qnasection {
  padding: 50px 0;
}
.verticalLine3 {
  border-left: 1.5px solid grey;
  height: 2.5vw;
  margin-left: 1%;
}
.questiondiv {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.answerdiv {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.authorinfo {
  margin-bottom: 4%;
  margin-left: 21.5%;
  margin-top: -1%;
}
.uservector {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  text-align: center;
  background-color: #E7E7E7;
  line-height: 33px;
}
.revicon{
  color: #636365;
  font-size: 21px;
}
.review__user{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.top__reviews p{
  color: #1C1C1C;
  font-family: "Roboto", sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19.53px;
text-align: left;
margin: 0;
}


.rating__review{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-top: 8px;
} 
.starrating3 {
  margin-right: 2%;
  margin-top: 5%;
}

.reactions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
}
.reacimg {
  width: 60%;
}
.reac__flex{
  display: flex;
  align-items: center;
  justify-content: center;
}
.ratingflex{
  display: flex;
  justify-content: center;
  align-items: center;
}
.qnasection h3{
  font-family: "Roboto", sans-serif;
  font-size: 32px;
  font-weight: 500;
  line-height: 45px;
  text-align: left;
  margin: 0;
  margin-bottom: 10px;
}
.revilist{
  list-style-type: none;
  padding: 0;
}
.revilist li{
  margin-top: 0;
  margin-bottom: 15px;
  border-top: 2px solid #c5c5c6;
  padding-top: 15px;
}
.revilist li:first-child{
  border-top: 0;
  padding-top: 0px;
}
.revilist li:last-child{
  margin-bottom: 0;
}

.stratinglst{
  list-style-type: none;
  padding: 0;
  margin: 0 0;
}
.stratinglst li{
  display: inline-block;
  margin: 0 !important;
  margin-right: 2px !important;
  border-top: 0;
  padding-top: 0px;
}
.stratinglst li:last-child{
  margin-right: 0;
}
.sticon{
  color: #2C2844;
  opacity: .2;
  font-size: 15px;
}
.redate{
  font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    color: #636365;
    opacity: 0.8;
    margin: 8px 0 !important;
}
.like__dislike{
  margin: 8px 0;
}
@media(max-width:767px){
.qnasection h3{
  font-size: 23px;
    line-height: 26px;
    text-align: center;
}
}