.nav_main {
  text-align: center;
  width: 100%;
  /* margin:auto auto; */
  /* margin-top:-40px; */

  /* z-index: 0; */
  /* display: flex;
    justify-content: center; */
  /* background-color: black; */
}

.nav_mains {
    margin-top: 45px;
    padding: 0 0 !important;
}

.navLeft {
  display: flex;
}

.navRight {
  display: flex;
  justify-content: flex-end;
}

.profilebtn {
  margin-top: 4%;
}

.nav_btn {
  color: #636365;
  /* background-color: red; */
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 13px;
  text-align: center;
  margin: auto;
  white-space: nowrap;
  /* margin-top: -30px; */
  /* z-index: 0; */
}

.nav_btn_box .submenu { 
  /* display: none;
  width: 0%;
  background-color: white;
  left: 0%;
  position: absolute;
  height: 0vh; 
  transition-property: height;
  transition-duration: 5s;
  transition-delay: 2s; */
  opacity: 0;
  width: 85%;
  margin: auto;
  border: 2px solid black;
  border-top: 7px solid black;
  transition-delay: 3s;
  left: 0%;
  right: 0%;
  padding: 20px;
    position: absolute;
    top: 100%;
    color: #000;
    z-index: 1000;
    margin: auto;
    background-color: #fff;
    visibility: hidden;
    -o-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -webkit-transform-origin: 0% 0%;
    -o-transition: -o-transform 0.3s, opacity 0.3s;
    -ms-transition: -ms-transform 0.3s, opacity 0.3s;
    -moz-transition: -moz-transform 0.3s, opacity 0.3s;
    -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
    transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    transform: rotateX(-75deg);
    -o-transform: rotateX(-75deg);
    -moz-transform: rotateX(-75deg);
    -webkit-transform: rotateX(-75deg);
}

.nav_btn_box:hover .submenu {
  opacity: 1;
  visibility: visible;
  transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -webkit-transform: rotateX(0deg);
  /* display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  column-gap: 50px;
  z-index: 1;
  width: 80%;
  margin: auto;
  border: 2px solid black;
  border-top: 7px solid black;
  transition-delay: 3s;
  left: 0%;
  right: 0%;
  padding: 20px;
  min-height: 40vh;
  top: 100%;
  height: auto; */
}

.submenu_right {
  display: none;
  position: absolute;
  background-color: white;
  border: 2px solid black;
  border-top: 7px solid black;
  padding: 20px;
  right: 0%;
}

.nav_btns_box:hover .submenu_right {
  display: block;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  column-gap: 50px;
  z-index: 1;
  width: 96%;
  margin: auto;
  right: 0%;
  left: 0%;
  min-height: 40vh;
}

.submenu_header {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 23px;
  text-transform: capitalize;
  color: #1c1c1c;
  text-align: left;
  margin-bottom: 5px;
}

.vertical_line {
  border-right: 1px solid rgba(0, 0, 0, 0.981);
}

.submenu_links {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
    font-size: 13px;
    line-height: 24px;
  text-transform: capitalize;
  color: #1c1c1c;
  opacity: 0.5;
  transition: 200ms linear;
}
.submenu_links:hover{
  opacity: 1;
  /* padding-left: 5px; */
}

.submenu_link_active {
  text-align: center;
  padding: 5px;
  border-radius: 10px;
  color: white;
  background-color: #636365;
  margin: 0px;
}

.ponterstlye {
  cursor: pointer;
}

.submenu>div>h2 {
  cursor: pointer;
}

.submenu>div>h5 {
  cursor: pointer;
}

.logo_wrapper {
  display: inline-block;
  position: relative;
  /* border-radius: 25px 25px 0px 0px;
  background-color: white; */
  width: 95px;
  vertical-align: middle;
  /* height: 90px;
  margin-top: -33px; */
  cursor: pointer;
  padding: 9px 0;
}

.logo_wrapper .logo_img {
  /* position: absolute;

  top: 30%;
  left: 50%;
  width: 100px;
  object-fit: cover;
  margin-top: 14px;
  transform: translate(-50%, -50%); */
  max-width: 100%;
  height: auto;
}

.button_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
}

.svg_icon {
  left: 100%;
  top: -2px;
  position: absolute;
  width: 44px;
  margin-left: -40px;
}

.svg_icon1 {
  right: 100%;
  top: -2px;
  position: absolute;
  width: 44px;
  margin-right: -40px;
  transform: scaleX(-1);
}

.nav_btn_div {
  width: 80%;
  margin: auto;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
}

.submenu_cart {
  display: none;
  position: absolute;
  background-color: rgb(255, 255, 255);
  border: 2px solid black;
  border-top: 7px solid black;
  padding: 8px;
  right: 0%;
  /* overflow-y: scroll; */
  margin-right: 2%;
}

.nav_btns_box:hover .submenu_cart {
  display: block;
  column-gap: 8px;
  z-index: 1;
  width: 24%;
  overflow-y: scroll;
  height: 50vh;
}

.card_row {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid gray;
}

.submenu_img_cart>img {
  border-radius: 10px;
  margin-top: 10px;
}

.submenu_header_card1 {
  margin-top: 20px;
}

.submenu_header_cart {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  text-transform: capitalize;
  color: #000000;
  width: 170px;
  text-align: start;
}

.submenu_links_cart {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  /* line-height: 190%; */
  text-transform: capitalize;
  color: #1c1c1c;
  opacity: 0.6;
  margin: 0px;
  margin-left: -90px;
}

.btn_cart {
  color: #000000;
  padding: 0%;
  height: 30px;
  /* margin-top: 10px; */
  position: relative;
}

.btn_button {
  display: flex;
  justify-content: space-evenly;
  margin-top: 30px;
  margin-left: 20px;
}

.header_logo {
  max-width: 150px;
  display: block;
}

.headderacion {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: flex-start;
  margin-left: 15px;
}

.headderacion li {
  margin: 0;
  margin-top: 0;
  margin-right: 15px;
  position: relative;
}

.headderacion li:last-child {
  margin-right: 0;
}

.headderacion li button {
  color: #1c1c1c;
  background: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
}

.rficon {
  font-size: 22px;
}

.nav_btn_box {
  display: flex;
  align-items: center;
}

.menulsit {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.menulsit li {
  display: inline-block;
  margin-top: 0;
}

.menulsit li a {
  text-decoration: none !important;
  padding: 29px 7px;
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 23.44px;
  color: #636365;
  opacity: 0.6;
  display: block;
}

.menulsit li:last-child a {
  padding-right: 4;
}

.submenuli {
  text-align: left;
}

.submenuli ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

.submenuli ul li {
  margin-top: 0;
}

.submenuli ul li a {
  text-decoration: none;
}

@media(min-width:991px) and (max-width:1025px) {
  .logo_wrapper {
    width: 65px;
  }

  .menulsit li a {
    text-decoration: none !important;
    padding: 20px 3px;
        font-size: 15px;
  }
}

@media(min-width:991px) {
  .mobileviewtop {
    display: none;
  }
}

@media(max-width:991px) {
  .button_box {
    display: none;
  }

  .profilebtn {
    margin-top: 0;
  }

  .mobileviewtop {
    padding: 5px 0;
    box-shadow: rgb(0 0 0 / 12%) 0 1px 3px;
  }

  .logo_smll {
    width: 70px;
    display: block;
    text-align: center;
    margin: 0 auto;
  }

  .navRight button {
    color: #1c1c1c;
    background: transparent;
    border: 0;
    padding: 0 0;
    cursor: pointer;
    margin: 0 7px;
  }

  .navLeft {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .menuicn {
    font-size: 28px;
    color: #1c1c1c;
  }


  .menulsitmob {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .menulsitmob li {
    display: block;
  }

  .menulsitmob li a {
    text-decoration: none;
  }

  .category {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  .category h5 {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 15px;
    text-transform: capitalize;
    color: #000000;
    margin: 0px;
    text-align: left;
    display: block;
  }

  .subctry {
    border-bottom: 1px solid rgb(240 234 234);
    padding: 10px;
  }
}

@media (max-width: 767px) {
  .nav_mains {
    margin-top: 0px;
    padding: 0 !important;
  }

  .logo_wrapper {
    display: none;
  }

  .logo_img1 {
    width: 20%;
    height: 20%;
  }
}