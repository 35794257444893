.banner {
  background: url("Banner-1.png"); 
 background-size: cover;
 padding: 5% 0;
 margin-top: 125px;
}
.bannercont h3{
 font-family: "Roboto", sans-serif;
   font-size: 28px;
   font-weight: 400;
   line-height: 37.62px;
   text-align: left;
   color: #fff;
   margin-top: 0;
   margin-bottom: 10px;
}
.bannercont h1{
 font-family: "Roboto", sans-serif;
   font-size: 41px;
   font-weight: 400;
   line-height: 51px;
   text-align: left;
   color: #fff;
   margin-top: 0;
   margin-bottom: 25px;
}
.bannercont ul{
 padding-left: 15px;
}
.bannercont ul li{
 font-family: "Roboto", sans-serif;
font-size: 16px;
font-weight: 400;
line-height: 20.83px;
text-align: left;
color: #fff;
}
.giftcarduser {
 margin-bottom: 5%;
}
.img_fluid {
 max-width: 100%;
 height: auto;
}
.gclabel {
 margin-left: 54%;
}
.giftsec{
 padding: 50px 0;
}

.gift_img{
 max-height: 290px;
 min-height: 290px;
 overflow: hidden;
 border-radius: 20px;
 border: 1px solid #e0e0e0;
 width: 100%;
}
.text_center{
 text-align: center;
}
.title_image{
 max-width: 110px;
}
.sec_title{
 font-family: "Roboto", sans-serif;
   font-style: normal;
   font-weight: 500;
   font-size: 40px;
   line-height: 50px;
   text-transform: capitalize;
   color: #1c1c1c;
   margin: 0px;
   margin-bottom: 10px;
}
.verticalLine {
 border-left: 3px solid white;
 height: 3vw;
 margin-right: 5%;
}
.graduation {
 background-color: white;
 display: flex;
 justify-content: center;
 align-items: center;
 width: 25%;
 padding: 2%;
 margin-left: -3%;
 border-radius: 3px;
}
.graduationDiv {
 display: flex;
 justify-content: flex-start;
 align-items: flex-start;
}
.list {
 color: white;
 /* margin-left: -2%; */
 width: 100%;
}
.bannercontainer {
 display: flex;
 justify-content: center;
 align-items: center;
 margin-left: 10%;
}
.gcimgDiv { 
 width: 100%;
}
.gradImg {
 width: 20%;
}
.bulletdiv {
 width: 90%;
}
.gctext {
 width: 100%;
}
.giftcard__heading {
 display: flex;
 justify-content: center;
 align-items: center;
 margin-top: 5%;
 margin-left: 5%;
}
.designImage {
 width: 50%;
}
.headingspan {
 margin-left: -15%;
}
.giftcard__grid {
 width: 60%;
 margin-left: 20%;
 margin-top: 1%;
}
.gcImagemain {
 width: 90%;
height: 300px;
}
.logo{
   width: 100%;
}
.logodiv{
   margin-top: -15%;
   margin-left: 3%;
   width: 15%;
}
@media (max-width: 767px) {
 .graduation {
   width: 20%;
 }
 .verticalLine {
   height: 3vw;
 }
 .bulletdiv {
   margin-left: -15%;
 }
 .banner {
   margin-top: 57px;
 }
 .designImage {
   width: 30%;
   margin-left: 17.5%;
 }
}
