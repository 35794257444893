.form_login input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.login_main {
  height: 100vh;
  width: 100vw;
  display: flex;
  overflow-x: hidden;
}

.left_main {
  background-color: #ebebeb;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  height: 100%;
  width: 100%;
}

.img_signup {
  height: 60%;
  width: 50%;
}

.right_main {
  background-color: white;
  padding: 3% 0% 0% 0%;
  width: 100%;
}

.signup_center {
  width: 60%;
  margin: 0px auto;
}

.img_art {
  height: auto;
  width: 25%;
  cursor: pointer;
}

.heading_art_s {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  margin: 0px;
  font-size: 30px;
  margin-top: 10px;
}

.para_s {
  color: #000000;
  margin: 5px 0px 7px 0px;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.02em;
}

.policy .para_s {
  letter-spacing: 0.02em;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  /* opacity: 0.4; */
}

.para_span {
  color: #1c1c1c;
  margin: 10px;
  font-weight: 500;
  font-size: 17px;
}

.input_login_s {
  width: 91.3%;
  height: 29px;
  border-radius: 5px;
  border: 0.7px solid #d0d0d0;
  padding: 0px 15px;
  margin: 4px 0px;
}

.login_button {
  width: 100%;
  margin: 20px 0px;
}

.newacc_head {
  font-size: 30px;
  margin: 5px 0px 0px 0px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
}

::placeholder {
  color: #636365;
}

.span_input {
  font-family: "Roboto", sans-serif; 
  font-weight: 500;
  font-size: 16px;
  line-height: 40px;
  letter-spacing: 0.02em;
  color: #000000;
  margin: 5px 0px;
  cursor: pointer;
}

.forgot_s {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 5px 0px;
}
.policy {
  display: flex;
  align-items: center;
}

.forgot_s > span {
  font-size: 16px;
  letter-spacing: 0.02em;
  color: #1c1c1c;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
}

.checks[type="checkbox"] {
  accent-color: black;
  width: 20px;
  height: 20px;
}
.policy .para_s{
  padding-left: 5px;
}
.apply_btn{
  padding: 10px 35px;
  border-radius: 10px;
  background:  #1c1c1c;
  border: 1px solid  #1c1c1c;
  color: #fff !important;
  font-family: DM Sans !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 26px !important;
  cursor: pointer; 
  text-decoration: none !important;
  display: inline-block;
}
.apply_btn:hover{
  background: #fff;
  color:  #1c1c1c !important;
}
.full_width{
  width: 100%;
}
.apply_btnwht{
  padding: 10px 35px;
  border-radius: 10px;
  background: #fff;
  border: 1px solid #000;
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  cursor: pointer;
}
.apply_btnwht:hover{
  background: #1c1c1c;
    color: #fff;
}
.sign_button{
  padding-bottom: 5%;
}
@media (min-width: 993px) and (max-width:1025px) {
  .signup_center{
    width: 80%;
  }
  .img_signup{
    width: 50%;
    height: auto;
  }
  }
@media (max-width: 992px) {
  .login_main {
    display: flex; 
  }
  .signup_center{
    width: 90%;
  }
  .img_signup{
    width: 80%;
    height: auto;
  }
}
@media (max-width:767px){
  .left_main{
    display: none;
  }
  .text_center{
    text-align: center;
  }
  
}
@media (max-width: 600px) {
  .signup_center {
    width: 90%;
    margin: 0px auto;
  }

  .img_signup {
    height: 70%;
    width: 50%;
  }
}
